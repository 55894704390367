.wrapper {
}

.inputContainer {
}
canvas {
  height: 92px;
}
.title {
  font-size: 16px;
  margin: 5px 0;
  background: #e6e7e9 !important;
  border-bottom: 2px solid #3c5555;
  margin-bottom: 5px;
  padding: 5px 0;
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
}

.inputWrapper label {
  width: 100%;
  font-weight: 400;
  padding: 5px 0 !important;
  font-size: 12px;
}
.reviewInput {
  background-color: #f2f3f4;
}
.defaultLabel {
  font-weight: 400 !important;
}

.inputWrapper span {
  font-style: italic;
  width: 100%;
  padding: 3px 0;
  min-height: 23px;
}

.colorBox {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  border: 1px solid #000;
  box-shadow: inset 0 0 0px 1px #fff;
}

.uploadedLogo {
  height: 92px;
}
