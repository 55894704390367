h1.static-title {
  width: 95%;
  margin-left: 2.5%;
  padding-top: 1rem;
}

.privacy-california,
.static-container {
  min-height: 100vh;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
}

.static-container p,
.static-container table {
  margin-bottom: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.875rem;
}

.static-container table td,
.static-container table th {
  border: 1px solid #000;
  padding: 0 7px;
}

.static-container table th {
  font-weight: 600;
}

.static-container > table.terms-and-condition-table {
  font-size: 1.125rem;
  line-height: 1.875rem;
}

.static-container ul {
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin-bottom: 2.5rem;
}

.static-container a {
  color: #0060f0;
}

.static-container a:hover,
.static-container a:active,
.static-container a:focus,
.static-container a:visited {
  color: #0060f0 !important;
  text-decoration: underline;
}

.terms-and-condition-table td,
.terms-and-condition-table th {
  border: none !important;
}
