.wrapper {
}

.checkboxInputFieldWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.checkboxInputFieldWrapper label {
  font-weight: 600;
  padding: 5px 0 !important;
  width: 90%;
  padding-left: 5px !important;
}

.checkboxInputFieldWrapper > input:first-child {
}

.checkboxInputFieldWrapper > input:nth-child(2) {
  flex-grow: 1;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.intputGroup > label {
  margin-top: 10px;
  padding: 5px !important;
  font-weight: 600;
  background: #e6e7e9 !important;
  border-bottom: 2px solid #78a9a9;
  margin-bottom: 5px;

  width: 100%;
  font-size: 14px;
}

.inputWrapper input {
  border: 2px solid #999 !important;
  border-radius: 2px;
  font-size: 14px;
  padding: 7px 2px !important;
  padding-left: 5px !important;
  margin-bottom: 5px;
}

.inputWrapper input:read-only {
  background-color: #eee;
  cursor: not-allowed;
}

.inputWrapper label {
  padding: 5px 0 !important;
  font-weight: 600;
  font-size: 12px;
}

.validationMessage {
  color: #cc0000;
  width: 100%;
}
input.inputValidationFailed {
  border: 1px solid #cc0000 !important;
  box-shadow: 0 0 2px #cc0000;
}
