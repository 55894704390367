.sectionBtns {
  display: flex;

  border-bottom: 1px solid #666;
}

.sectionBtn {
  display: flex;
  flex-direction: column;
  color: #038203;
  padding: 10px;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}

.sectionBtn:focus {
  outline: 2px dashed #000 !important;
  outline-offset: 3px !important;
  z-index: 1;
}

.sectionBtn.active {
  color: #1c1c1c;
  background-color: #fff !important;
}

.sectionBtn h2 {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 600;
  font-size: 1.08em !important;
}

.sectionBtn p {
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  transform: translateY(100%);
}

.sectionBtn:not(:last-of-type) {
  border-right: 1px solid #ccc;
}

.sectionBtn.active h2 {
  font-weight: 600;
}

@media all and (max-width: 768px) {
  .sectionBtns {
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }
}
