.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.wrapper {
  background-color: #fff;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5);
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #008a00;
  color: #fff;
}

.head i {
  cursor: pointer;
  font-size: 28px;
}

.body {
  padding: 20px;
  line-height: initial;
}

.errTextWrapper {
  display: flex;
  margin-top: 10px;
}

.errText {
  color: rgba(230, 20, 20);
  padding: 5px 7px 5px 0;
  font-weight: bold;
}

.errText:not(:last-of-type)::after {
  content: ",";
}
