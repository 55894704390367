.wrapper {
}

.contact {
  font-weight: 600;
  padding: 5px 0;
}

.contact a {
  color: #038203 !important;
  text-decoration: underline;
  font-weight: 400;
}

.fileValidate {
  position: absolute;
  color: red !important;
  top: 5px;
  width: 95%;
}

.dropZone {
  cursor: pointer;
  margin-top: 5px;
  padding: 30px;
  height: 100px;
  border: 2px dashed #999;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.dropZone.haveLogo {
  padding: 0;
}

.dropZone p {
  color: #038203;
}

.logo,
.canvas {
  height: 100%;
}

.logoRemove {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 24px;
  background-color: #66666666;
  color: #fff;
}

.inputValidationFailed {
  border: 1px solid #cc0000 !important;
  box-shadow: 0 0 2px #cc0000;
}

.validationText {
  color: #cc0000;
  width: 100%;
  margin-top: 5px;
}

.uploadSizeText {
  padding: 5px 0;
}
