.outerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 144px);
  background: #fff;
}

.outerContainer.checkProduct {
  height: calc(100% - 218px);
}

.container {
  background: #fff;

  overflow: auto;
  height: 100%;
}

.wrapper {
  padding: 0.5rem 1rem;
}

.sectionHeader h2 {
  margin: 0 !important;
  font-size: 16px !important;
  padding: 5px 10px !important;
  background: #e6e7e9 !important;
  border-bottom: 2px solid #78a9a9;
}

.sectionWrapper {
  display: none;
}

.sectionWrapper.active {
  display: block;
}

.questionsWrapper {
}
.questionWrapper {
}

.sectionsWrapper {
}

.disclaimer {
  padding: 0.5rem 1rem !important;
  font-weight: 600;
  background: #e6e7e9 !important;
  border-bottom: 2px solid #78a9a9;
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  line-height: initial;
}

@media all and (max-width: 768px) {
  .outerContainer {
    height: 100% !important;
  }
  .container {
    height: calc(100% - 104px) !important;
  }
}
