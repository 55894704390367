img {
  vertical-align: baseline !important;
  max-width: none !important;
}

.react-transform-component {
  height: 100% !important;
  width: 100% !important;
}

.react-transform-component,
.react-transform-element {
  cursor: grab;
}

.react-transform-component,
.react-transform-element {
  /*   height: 100%;
  width: 100%; */
}

.react-transform-element img {
  /* object-fit: contain;
  height: 100%;
  width: 100%; */
}

.magnified-image-container {
  z-index: 1;
  background-color: #fff;
}

.preview-wrapper {
  height: 100%;
}

.magnifier-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.magnifier-zoom-wrapper {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 60vw;
  left: 100%;
  margin-left: 10px;
  height: 73vh;
  min-height: 100%;
}

.magnifier-zoom {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.5);
  height: 100%;
  transition: none !important;
  min-width: 100%;
  min-height: 100%;
  background-color: #fff;
}

.magnifier-zoom > img {
  background-color: #fff;
  min-width: 100%;
  min-height: 100%;
}

.pq-user-input-over-image {
  position: absolute;
  font-size: 7px;
  word-break: break-all;
}

.pq-user-input-over-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.initial-position {
  position: initial;
}

.zoom-tools {
  position: absolute;
  z-index: 1;
  padding: 5px;
}

.zoom-tools button {
  display: flex;
  align-items: center;
  padding: 5px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-bottom: 5px;
}

#product_carousel .img-thumb {
  cursor: pointer;
}

.preview {
  overflow: hidden;
  position: relative;
}

@media all and (max-width: 768px) {
  .magnified-image-container {
    margin-left: 0 !important;
    left: 0 !important;
  }

  #imageGrid #item-img {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  }

  .preview {
    height: 350px;
    overflow: hidden;
  }

  .preview .mobile-magnifier-container {
    height: 100%;
  }
}
