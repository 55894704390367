.wrapper {
  padding: 10px 0;
}

.selectSection {
  display: flex;
  justify-content: space-between;
}

.selectSection > div {
  flex-direction: column;
}

.selectSection label {
  font-weight: bold;
  padding: 10px 0 !important;
}

.selectedArtBox {
  height: 64px;
  width: 64px;
  box-shadow: 0 0 0 1px #66666666;
  background-color: #ddd;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.clipartsContainer {
  margin: 10px 0;
}
.clipartsWrapper {
  max-height: 275px;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5px;
  display: flex;
  flex-wrap: wrap;
}

.clipartBoxWrapper {
  width: 20%;
  padding-top: 20%;
  position: relative;
}

.clipartBox {
  top: 5%;
  left: 5%;
  position: absolute;
  height: 90%;
  width: 90%;
  box-shadow: 0 0 0 1px #66666666;
  background-color: #ddd;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.clipartBox.active {
  border: 1px solid #ffff00;
}

.clipartBox.active::after {
  content: "\2713";
  height: 24px;
  width: 24px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff00;
  font-weight: 600;
  font-size: 24px;
}
