.wrapper {
}
.wrapper p {
  font-weight: 600;
  padding: 5px 0;
}

.checkbox {
  display: block !important;
  margin-right: 5px !important;
}

.checkboxWrapper {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.checkboxWrapper img {
  margin-right: 5px;
}

.checkboxWrapper.image {
  width: 100%;
}

.colorBox {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  border: 1px solid #000;
  box-shadow: inset 0 0 0px 1px #fff;
}
