.wrapper {
  display: flex;
  align-items: center;
}

.wrapper label {
  font-weight: bold;
  padding: 10px 0 !important;
}

.wrapper select {
  display: block !important;
  border: 1px solid #c8c8c8;
  font-weight: 500;
  color: #000;
  padding-left: 5px !important;
  height: 34px;
  max-width: 350px;
  text-transform: capitalize;
}
