.itemCard-buttons-container {
  opacity: 0;
  position: absolute;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  display: block;
  bottom: 22px;
  cursor: pointer;
  right: 0px;
}

.home-item--wrapper:hover .itemCard-buttons-container,
.home-item--wrapper.mobile .itemCard-buttons-container {
  opacity: 1;
  z-index: 1;
}

.home-item--wrapper.more-active .itemCard-buttons-container {
  opacity: 1;
  z-index: 2;
}

.item-card-figure {
  overflow: hidden;
}

.home-item span.figure {
  display: block;
  background: no-repeat 50% 50%;
  background-size: contain;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
}

.item-card-figure {
  height: 80%;
  width: 80%;
  position: relative;
}
/* 
.product-price.price-deal::before {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "Sale";
  position: absolute;
  bottom: 60px;
  right: 0px;
  font-size: 12px;
  font-weight: 400;
  width: 50px;
  height: 18px;
  background-color: rgb(238, 20, 20);
}
 */

.item-card-sale-container {
  pointer-events: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 60px;
  right: 0px;
}

.item-card-promotion-container {
  pointer-events: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.item-card-sale--sale {
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(216, 28, 28);
  font-size: 12px;
  font-weight: 600;
  padding: 0 5px;
}
.item-card-promotion--promo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(22, 145, 22);
  color: #fff;
  width: 100%;
  font-size: 15px;
  height: 24px;
  width: 100%;
}

.home-item.promo-item .compare-icon,
.home-item.promo-item .item-card-wishlist-icon {
  top: 24px;
}

@media all and (max-width: 520px) {
  .home-item.more-active .itemCard-buttons-container {
    z-index: unset;
  }

  .item-card-promotion--promo {
    font-size: 14px;
  }
}
