.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

.wrapper {
  background-color: #fff;
  padding: 30px;
  width: 80%;
  position: relative;
  line-height: initial;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
}

.wrapper h6 {
  font-size: 2em;
  line-height: initial;
  margin-bottom: 25px;
}

.subHeading {
  font-weight: 600;
  font-size: 1.4em;
  line-height: initial;
  margin-bottom: 15px;
}

.wrapper button {
  margin-top: 20px;
  min-width: 200px;
  font-size: 20px;
  padding: 10px;
  font-weight: normal;
  float: right;
}
