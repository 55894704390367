.wrapper {
  padding-bottom: 5px;
}

.inputValidationFailed {
  border: 1px solid #cc0000 !important;
  box-shadow: 0 0 2px #cc0000;
}
.wrapper > p {
  font-weight: 600;
  padding: 5px;
  background: #e6e7e9 !important;
  border-bottom: 2px solid #78a9a9;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.wrapper p input {
  margin-right: 5px;
  height: 18px;
  width: 18px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.radioBtn {
  display: block !important;
  margin-right: 5px !important;
}

.radioBtnWrapper {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.radioBtnWrapper input,
.radioBtnWrapper label {
  cursor: pointer;
}

.radioBtnWrapper img {
  margin-right: 5px;
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.radioBtnWrapper.image {
  width: 100%;
}

.colorBox {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  border: 1px solid #000;
  box-shadow: inset 0 0 0px 1px #fff;
}

.selectedFontLabel {
  font-weight: 600;
  text-transform: uppercase;
  font-style: italic;
}

.inkColor {
  padding: 4px;
}
.inkColor img {
  margin: 0;
  cursor: pointer;
}
.inkColor img.disabled {
  cursor: not-allowed;
  filter: brightness(0.5);
}

.inkColor.active {
  outline: 2px solid #1074bc;
}

.inkColor .radioBtn,
.inkColor label {
  display: none !important;
}

.validationMessage {
  color: rgb(204, 0, 0);
}
