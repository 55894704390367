.attributes-container {
  padding: 10px 0px;
  width: 100%;
}

.attributes-wrapper {
  /*  padding-top: 10px; */
  padding-left: 0;
  /*  padding-bottom: 10px; */
}

.attribute-option-header {
  padding: 10px 0;
  float: left;
  width: 30%;
}
.attribute-option-select-wrapper {
  float: left;
  width: 70%;
}
.attribute-option-image-wrapper {
  float: left;
  margin: 5px;
  position: relative;
}

.attribute-option-image:not(.active) {
  box-shadow: 0 0 0px 2px #000;
}

.attribute-option-image.disabled {
  border: 2px dashed #fff;
  box-shadow: 0 0 0px 2px #000, inset 0 0 4px #000;
  filter: grayscale(0.5);
}

.attribute-option-textarea-field {
  border: 1px solid #c8c8c8;
  padding: 10px;
  line-height: initial;
}

.attribute-option-message {
  width: 90%;
}

.attribute-option-span {
  font-weight: 600;
  font-size: 1.5em;
}

/* Adds a check mark to the selected attribute - disabled as asked
.attribute-option-image-wrapper.active .attribute-option-image::after {
  content: "\2713";
  position: absolute;
  font-size: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #111;
  text-shadow: 0px 0px 7px #fff, 0 0 0px #fff;
} */
.attribute-option-image-wrapper,
.attribute-option-image-wrapper label {
  cursor: pointer;
}

.attribute-option-image-wrapper:not(.active):not(.disabled):hover {
  transform: scale(1.1);
}

.attribute-option-image-wrapper.active::after {
  content: "";
  position: absolute;
  height: 64px;
  width: 64px;
  border: 4px solid #000;
  top: -6px;
  left: -6px;
  z-index: -1;
}

.attribute-size .attribute-option-image-wrapper.active::after {
  content: "";
  position: absolute;
  height: 140%;
  width: 120%;
  border: 2px solid #000;
  top: -20%;
  left: -10%;
  z-index: -1;
}

.attribute-wrapper {
  display: flex;
  margin-bottom: 15px;
}
.attribute-wrapper > div {
  display: flex;
  align-items: center;
}

.attribute-option-title {
  font-weight: bold;
  font-size: 14px;
  float: left;
}

.attribute-option--select {
  display: block !important;
  border: 1px solid #c8c8c8;
  font-weight: 500;
  color: #000;
  padding-left: 5px !important;
  height: 34px;
  max-width: 350px;
}

.attribute-option--select option {
  color: #000;
}

.attribute-option-tooltip {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(-40%, -120%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  z-index: 1;
}

.attribute-option-tooltip span {
  white-space: nowrap;
}

.attribute-option-image-wrapper.disabled:hover .attribute-option-tooltip {
  display: block;
}
