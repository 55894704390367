.product-details-wrapper h2,
.product-specs-wrapper h2 {
  margin: 0 !important;
}

.product-details-wrapper,
.product-specs-wrapper {
  padding: 0;
}

.product-details-specs-container {
  margin-bottom: 20px;
  margin: 0 auto;
}

.product-details-specs-wrapper {
  border: 1px solid #cdcdcd;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 12%;
}

.specs-list {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.specs-list .table > thead > tr > th,
.specs-list .table > tbody > tr > th,
.specs-list .table > tfoot > tr > th,
.specs-list .table > thead > tr > td,
.specs-list .table > tbody > tr > td,
.specs-list .table > tfoot > tr > td {
  white-space: pre-wrap;
}
#description,
#Specifi {
  padding: 10px;
}

.specs-list .table-striped:first-of-type > tbody > tr:nth-of-type(odd),
.specs-list .table-striped:last-of-type > tbody > tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.specs-list .table-striped:first-of-type > tbody > tr:nth-of-type(even),
.specs-list .table-striped:last-of-type > tbody > tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.specs-list {
  border: 2px solid #f9f9f9;
}

.specs-list .table.table-striped tbody th strong {
  font-weight: bold !important;
}

.specs-list .table.table-striped tbody td {
  font-weight: normal !important;
}

@media all and (max-width: 768px) {
  .product-details-wrapper {
    width: 100%;
  }

  .specs-list table.table {
    width: 100%;
    margin: 0;
    font-size: 12px;
  }
  table td {
    padding: 2px !important;
  }
}

@media all and (min-width: 769px) and (max-width: 1024px) {
  .product-details-wrapper {
    width: 100%;
  }

  .specs-list table.table {
    width: 50%;
    margin: 0;
  }
}

@media all and (min-width: 1025px) {
  .product-details-wrapper {
    width: 40%;
  }

  .specs-list table.table {
    width: 50%;
    margin: 0;
  }
}
