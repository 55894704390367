.loginForm {
  width: 50%;
  margin: 0 auto;
}

.loginInputContainer {
  padding: 10px;
}
.forgotPasswordLink {
  font-weight: 600;
  font-size: 14px;
}

.allServices {
  padding: 10px;
}

.loginContainer {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginWrapper {
  width: 95%;
}

@media all and (max-width: 768px) {
  .loginForm {
    width: 100%;
    margin: 0 auto;
  }
}
