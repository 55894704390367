.radioBtn {
  display: block !important;
  margin-right: 5px !important;
}

.radioBtnWrapper {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.radioBtnWrapper input,
.radioBtnWrapper label {
  cursor: pointer;
}
