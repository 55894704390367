.gothicBoldUp {
  font-family: "ITC Franklin Gothic LT W05 Boo";
  text-transform: uppercase;
  font-weight: bold;
}

.timesTenRoman {
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
    serif;
  text-transform: uppercase;
}

.timesNewRomanBoldItalic {
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
    serif;
  font-style: italic;
  font-weight: bold;
}

.gothicBoldLow {
  font-family: "ITC Franklin Gothic LT W05 Boo";
  font-weight: bold;
}

.gothicUp {
  font-family: "ITC Franklin Gothic LT W05 Boo";
  text-transform: uppercase;
}

.timesNewRoman {
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
    serif;
}

.commScript {
  font-family: "Commercial Script W05 Regular";
}

.euroBold {
  font-family: "Eurostile LT W05 Bold";
  text-transform: uppercase;
  font-weight: bold;
}

.copperplate32AB {
  font-family: "Copperplate Gothic W03 Rom";
  text-transform: uppercase;
}

.optimaOblique {
  font-family: "noptimaobliqueregular";
}

.souvenirLight {
  font-family: "ITC Souvenir W05 Light";
}

.souvenirMedium {
  font-family: "ITC Souvenir W05 Medium";
}

.cheltenhamBold {
  font-family: "ITC Cheltenham W05 Bold";
  font-weight: bold;
}

.avantGardeExtraLight {
  font-family: "ITC Avant Garde Gothic W05 X L";
  text-transform: uppercase;
}

.copperplate32BC {
  font-family: "Copperplate Gothic W03 Rom";
  text-transform: uppercase;
}

.gothicBoldCondensed {
  font-family: "ITC Franklin Gothic LT W05 Boo";
  text-transform: uppercase;
  font-weight: bold;
}

.domCasualBold {
  font-family: "DomBold BT W05 Regular";
  text-transform: uppercase;
}

.oldEnglishBold {
  font-family: "Old English W05 Regular";
  font-weight: bold;
}

.cheltenhamLight {
  font-family: "ITC Cheltenham W05 Light";
}

.belweMedium {
  font-family: "Belwe Lt BT W05 Medium";
}

.coronelBold {
  font-family: "Coronet MT Pro Bold W05 Regular";
}

.helveticaLightItalic {
  font-family: Helvetica, Helvetica Neue, Arial, sans-serif;
  font-style: italic;
}

.plantinBoldItalic {
  font-family: "nplantinbolditalicbold";
}

.zapfChanceryMediumItalic {
  font-family: "ITC Zapf Chancery W05 Demi";
  font-style: italic;
}

.cheltenhamBook {
  font-family: "ITC Cheltenham W05 Book";
}

.cheltenhamLightItalic {
  font-family: "ITC Cheltenham W05 Light";
  font-style: italic;
}

.brushScript {
  font-family: "Brush Script MT W00 Italic", "Brush Script MT", cursive;
}

.garamondBook {
  font-family: "Garamond MT W05 Regular", Garamond, Baskerville,
    "Baskerville Old Face", "Hoefler Text", "Times New Roman", serif;
}

.parkAvenue {
  font-family: "ParkAvenue BT W05 Regular";
}

.avantGardeBook {
  font-family: "ITC Avant Garde Gothic W05 Boo";
}

.helveticaMedium {
  font-family: Helvetica, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
}

.helveticaBold {
  font-family: Helvetica, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.helveticaBlack {
  font-family: "nhelveticablack";
  text-transform: uppercase;
}

.avantGardeBookObliqueUp {
  font-family: "ITC Avant Garde Gothic W05BkOb";
  text-transform: uppercase;
}

.avantGardeBookOblique {
  font-family: "ITC Avant Garde Gothic W05BkOb";
}

.boulevard {
  font-family: "nboulevardregular";
}

.copperplate31BC {
  font-family: "Copperplate Gothic W03 Rom", Copperplate,
    "Copperplate Gothic Light", fantasy;
  text-transform: uppercase;
}

.copperplateSmallCaps31BC {
  font-family: "Copperplate Gothic W03 Rom", Copperplate,
    "Copperplate Gothic Light", fantasy;
  text-transform: uppercase;
}

.graphiteBoldUp {
  font-family: "graphite-std", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.graphiteBold {
  font-family: "graphite-std", sans-serif;
  font-weight: bold;
}

.graphiteRegularUp {
  font-family: "graphite-std", sans-serif;
  text-transform: uppercase;
}

.graphiteRegular {
  font-family: "graphite-std", sans-serif;
}

.avantGardeBoldCondensedObliqueUp {
  font-family: "navantgardeboldcdoblique";
  text-transform: uppercase;
}

.avantGardeBoldCondensedOblique {
  font-family: "navantgardeboldcdoblique";
}

.kaufmannBold {
  font-family: "Kaufmann W05 Bold";
}

.meliorItalicUp {
  font-family: "nmelioritalicitalic";
  text-transform: uppercase;
}

.meliorItalic {
  font-family: "nmelioritalicitalic";
}

.optima {
  font-family: "noptimaregular";
}

.optimaSmallCapsOblique {
  font-family: "noptimasmcapsoblique";
}

.optimaSmallCaps {
  font-family: "noptimasmcaps";
}

.cheltenhamBoldItalicUp {
  font-family: "ITC Cheltenham W05 Bold";
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
}

.cheltenhamBoldItalic {
  font-family: "ITC Cheltenham W05 Bold";
  font-weight: bold;
  font-style: italic;
}

.cheltenhamBookItalicUp {
  font-family: "ITC Cheltenham W05 Book";
  font-style: italic;
  text-transform: uppercase;
}

.cheltenhamBookItalic {
  font-family: "ITC Cheltenham W05 Book";
  font-style: italic;
}

.oldEnglish {
  font-family: "Old English W05 Regular";
}

.futuraMediumUp {
  font-family: "Futura LT W05 Medium", Futura, "Trebuchet MS", Arial, sans-serif;
  text-transform: uppercase;
}

.futuraMedium {
  font-family: "Futura LT W05 Medium", Futura, "Trebuchet MS", Arial, sans-serif;
}

.plantinItalicUp {
  font-family: "nplantinitalic";
  text-transform: uppercase;
}

.plantinItalic {
  font-family: "nplantinitalic";
}

.franklinGothicBookUp {
  font-family: "ITC Franklin Gothic LT W05 Boo", "Franklin Gothic Medium",
    "Franklin Gothic", "ITC Franklin Gothic", Arial, sans-serif;
  text-transform: uppercase;
}

.franklinGothicBook {
  font-family: "ITC Franklin Gothic LT W05 Boo", "Franklin Gothic Medium",
    "Franklin Gothic", "ITC Franklin Gothic", Arial, sans-serif;
}

.franklinGothicDemiUp {
  font-family: "nfranklingothicdemi", "Franklin Gothic Medium",
    "Franklin Gothic", "ITC Franklin Gothic", Arial, sans-serif;
  text-transform: uppercase;
}

.franklinGothicDemi {
  font-family: "nfranklingothicdemi", "Franklin Gothic Medium",
    "Franklin Gothic", "ITC Franklin Gothic", Arial, sans-serif;
}

.copperplate31AB {
  font-family: "Copperplate Gothic W03 Rom", Copperplate,
    "Copperplate Gothic Light", fantasy;
  text-transform: uppercase;
}

.copperplateSmallCaps31AB {
  font-family: "Copperplate Gothic W03 Rom", Copperplate,
    "Copperplate Gothic Light", fantasy;
  text-transform: uppercase;
}

.meliorUp {
  font-family: "Melior LT W05 Regular";
  text-transform: uppercase;
}

.script {
  font-family: "Brush Script MT W05 Italic";
}

.melior {
  font-family: "Melior LT W05 Regular";
}

.meliorBoldUp {
  font-family: "Melior LT W05 Regular";
  text-transform: uppercase;
  font-weight: bold;
}

.meliorBold {
  font-family: "Melior LT W05 Regular";
  font-weight: bold;
}

.souvenirBoldUp {
  font-family: "ITC Souvenir W05 Medium";
  text-transform: uppercase;
  font-weight: bold;
}

.souvenirBold {
  font-family: "ITC Souvenir W05 Medium";
  font-weight: bold;
}

.embassyRegular {
  font-family: "Embassy BT W05 Regular";
  font-style: italic;
}

.MICRDigits {
  font-family: "MICR W05 Medium";
}

.vertical {
  transform-origin: left;
  transform: rotate(90deg);
}
