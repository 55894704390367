/*------------------------------------------------------------------
[Layout]

* body
	+ Header / .site-header
	+ Content / .site-main
		- Contetnt Area / .section
	+ Footer / .site-footer

-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Color codes]

Background: #fff (White)
Primary:    #0D52FF (primary)
Title  :    #444444 (dark gray)
Content:    #848484 (Gray)

a (standard):   #0D52FF (primary)
a (hover)   :   #0D52FF (primary)

-----------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]

1.  basic
	1.1	 Variale
	1.2	 mixins
	1.3  flexbox mixins
	1.4  reset

2. Magnific popup
3. Fileinput
4. form elemnts
5. header
6. title
7. slider
8. home pages
9. service
10.portfolio
11. features
12. price table
13. testimonials
14. shop
15. cta
16. parallax
17. tabs
18. progress bars
19. blog
20. process
21. team
22. clients
23. counter
24. subscriptions
25. widgets
26. misc
27. page
28. elements
29. accordion
30. footer widgets
31. footer
32. media queries

-------------------------------------------------------------------*/
/*--------------------------- Color variations ----------------------*/
/* =================================== */
/*  Basic Style 
/* =================================== */
/*PAGINATION*/
.buttonPage{
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.listPrice{
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  text-decoration: line-through;
}

.savePrice{
  font-weight: normal;
  font-size: 12px;
}

.buttonPage:focus{
  background-color: #fff;
}

.buttonPageActive{
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #ffffff;
  text-decoration: none;
  background-color: #000000;
    border-color: #000000;
}
.buttonPageActive:focus{
  background-color: #000000;
}

.buttonPageRight{
  border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.buttonPageLeft{
  border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}



.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

::-moz-selection {
  /* Code for Firefox */
  background-color: #0D52FF;
  color: #fff; }
.search-wrapper .search-popup button i{
    font-size: 35px;
}
::selection {
  background-color: #0D52FF;
  color: #fff; }

body {
  color: #848484;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: initial;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none; }

figure {
  margin: 0; }

a {
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }

iframe {
  border: 0; }

a, a:focus, a:hover,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  box-shadow: none;
  text-decoration: none;
  outline: 0; }

a, a:hover, a:focus {
  color: #0D52FF; }
 .mobiletab_menu{
					            width: 100%;
                                float: left;
                                padding: 0px 30px;
					    }
					    .mobiletab_menu a{
					        width: 100%;
    float: left;
    padding: 5px 0px;
    font-size: 15px;
					    }
/* .card-panel {
  margin: 0;
  padding: 60px; } */

/**
 *  Typography
 *
 **/

h1, .h1 {
  font-size: 55px; }

h2, .h2 {
  font-size: 39px; }

h3, .h3 {
  font-size: 27px; }

h4, .h4 {
  font-size: 19px; }

h5, .h5 {
  font-size: 16px; }

h6, .h6 {
  font-size: 14px; }

abbr[title] {
  font-style: italic; }
  abbr[title] a {
    color: #0D52FF; }

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clear::before, .clear::after {
  content: " ";
  display: table; }
.clear::after {
  clear: both; }

/**
 *  Reset input fields opacity
 *
 **/
::-webkit-input-placeholder {
  opacity: 1;
  color: inherit; }

:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
  color: inherit; }

::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
  color: inherit; }

:-ms-input-placeholder {
  opacity: 1;
  color: inherit; }

.fz-11 {
  font-size: 11px; }

.fz-12 {
  font-size: 12px; }

.fz-13 {
  font-size: 13px; }

.fz-14 {
  font-size: 14px; }

.fz-15 {
  font-size: 15px; }

.fz-16 {
  font-size: 16px; }

.fz-18 {
  font-size: 18px; }

.fz-20 {
  font-size: 20px; }

.fz-24 {
  font-size: 24px; }

.fz-30 {
  font-size: 30px; }

.fw100 {
  font-weight: 100; }

.fw300 {
  font-weight: 300; }

.fw400 {
  font-weight: 400; }

.fw500 {
  font-weight: 500; }

.f700 {
  font-weight: 700; }

.fsi {
  font-style: italic; }

.mt-10 {
  margin-top: 10px; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-25 {
  margin-top: 25px; }

.mt-30 {
  margin-top: 30px; }

.mt-35 {
  margin-top: 35px; }

.mt-40 {
  margin-top: 40px; }

.mt-50 {
  margin-top: 50px; }

.mt-60 {
  margin-top: 60px; }

.mt-80 {
  margin-top: 80px; }

.mt-100 {
  margin-top: 100px; }

.ml-0 {
  margin-left: 0 !important; }

.ml-5 {
  margin-left: 5px; }

.ml-10 {
  margin-left: 10px; }

.ml-15 {
  margin-left: 15px; }

.ml-20 {
  margin-left: 20px; }

.ml-30 {
  margin-left: 30px; }

.ml-50 {
  margin-left: 50px; }

.ml-100 {
  margin-left: 100px; }

.mr-0 {
  margin-right: 0 !important; }

.mr-5 {
  margin-right: 5px; }

.mr-15 {
  margin-right: 15px; }

.mr-10 {
  margin-right: 10px; }

.mr-20 {
  margin-right: 20px; }

.mr-30 {
  margin-right: 30px; }

.mr-50 {
  margin-right: 50px; }

.mb-0 {
  margin-bottom: 0px; }

.mb-0-i {
  margin-bottom: 0px !important; }

.mb-2 {
  margin-bottom: 2px; }

.mb-5 {
  margin-bottom: 5px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-25 {
  margin-bottom: 25px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-90 {
  margin-bottom: 90px; }

.mb-100 {
  margin-bottom: 100px; }

.pt-0 {
  padding-top: 0px; }

.pt-10 {
  padding-top: 10px; }

.pt-15 {
  padding-top: 15px; }

.pt-20 {
  padding-top: 20px; }

.pt-25 {
  padding-top: 25px; }

.pt-30 {
  padding-top: 30px; }

.pt-40 {
  padding-top: 40px; }

.pt-50 {
  padding-top: 50px; }

.pt-60 {
  padding-top: 60px; }

.pt-70 {
  padding-top: 70px; }

.pt-80 {
  padding-top: 80px; }

.pt-90 {
  padding-top: 90px; }

.pt-100 {
  padding-top: 100px; }

.pb-0 {
  padding-bottom: 0px; }

.pb-10 {
  padding-bottom: 10px; }

.pb-15 {
  padding-bottom: 15px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-25 {
  padding-bottom: 25px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-40 {
  padding-bottom: 40px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-60 {
  padding-bottom: 60px; }

.pb-70 {
  padding-bottom: 70px; }

.pb-80 {
  padding-bottom: 80px; }

.pb-90 {
  padding-bottom: 90px; }

.pb-100 {
  padding-bottom: 100px; }

.p-40 {
  padding: 40px; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.text-italic {
  font-style: italic; }

.transition, .entry-post.style6, .entry-post.style6 .content {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.highlight {
  color: #0D52FF; }

.lead {
  font-size: 18px;
  line-height: 30px; }

.lead-half {
  font-size: 16px;
  line-height: 26px; }
 #app {
      color: #fff;
    /* margin-top: 20px; */
    /* margin-left: 50px; */
    /* background: #fff; */
    float: left;
    margin-top: 10px;
    width: 100%;
}

#app h1 {
  font-size: 30px;
}

#app h2 {
      color: #fff;
}

#app input {
      border: 0;
    background: transparent;
    font-size: 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    color: 1a1a1a;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
}
.ff{
    height: 300px;
    overflow-y: scroll;
}
.ac_results{
    z-index:99999999 !important;
}
.ac_even{
    font-size: 27px;
    color: #fff;
    cursor: pointer;
    margin: 20px 0px;
}


.btn {
  background-color: #0D52FF;
  border: 0 none;
  border-radius: 2px;
  line-height: 36px;
  padding: 0 15px;
  text-transform: none; }
  .btn:hover, .btn:focus {
    background-color: #0D52FF;
    color: #fff; }
  .btn.uppercase, .btn.text-uppercase {
    text-transform: uppercase; }

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:active:focus,
.btn-primary:focus,
.btn-large,
.btn-large:hover,
.btn-large:focus {
  background-color: #0D52FF;
  color: #fff; }

.no-border {
  border: 0 none; }

.no-radius {
  border-radius: 0; }
  .no-radius:hover {
    background-color: transparent;
    box-shadow: none; }

.btn-no-shadow {
  box-shadow: none; }

.btn-group a {
  margin-bottom: 30px; }
  .btn-group a:not(:last-child) {
    margin-right: 28px; }

.btn-large i {
  font-size: 16px;
  margin-right: 5px; }

.btn-large, .btn-flat {
  font-weight: 500;
  height: 55px;
  line-height: 55px;
  padding: 0 59px;
  white-space: nowrap;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1); }
  .btn-large:hover, .btn-flat:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }

.btn-flat {
  border-radius: 0;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px; }

.btn-md {
  font-weight: 500;
  height: 46px;
  line-height: 46px;
  padding: 0 22px;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1); }
  .btn-md:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }

.btn-icon-left i {
  float: left;
  margin-right: 15px; }

.btn-icon-right i {
  float: right;
  margin-left: 15px; }

.btn-floating {
  background-color: #0D52FF;
  padding: 0; }
  .btn-floating:hover {
    background-color: #0D52FF; }
  .btn-floating i {
    font-size: 22px; }

.icon-circle {
  background-color: #0D52FF;
  border-radius: 100px;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  height: 72px;
  line-height: 72px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 72px; }
  .icon-circle::before {
    background-color: rgba(246, 81, 76, 0.15);
    border-radius: 100px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-transform: scale(1.6);
    -moz-transform: scale(1.6);
    -ms-transform: scale(1.6);
    -o-transform: scale(1.6);
    transform: scale(1.6); }
  .icon-circle:hover, .icon-circle:focus {
    background-color: #fff;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    color: #0D52FF; }
    .icon-circle:hover::before, .icon-circle:focus::before {
      background-color: rgba(0, 0, 0, 0.04); }

.btn-circle {
  border: 1px solid;
  border-radius: 100px;
  color: #848484;
  display: inline-block;
  height: 96px;
  line-height: 96px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 96px; }
  .btn-circle:hover {
    color: #0D52FF; }
.arrowpos{
    position:absolute;
}
.arrowposs{
    position: relative;
    top: 8px;
}
.btn-gray {
  background-color: #ddd;
  color: #0D52FF;
  box-shadow: none; }
  .btn-gray:hover, .btn-gray:focus {
    background-color: #0D52FF;
    color: #fff; }

.btn-border {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
  color: #444; }
  .btn-border:hover, .btn-border:focus {
    background-color: #fff;
    color: #444 !important; }
  .btn-border.light {
    border-color: #fff;
    color: #fff; }

.btn-disabled {
  background-color: transparent;
  color: #848484;
  box-shadow: none;
  cursor: default; }
  .btn-disabled:hover, .btn-disabled:focus {
    background-color: #ddd;
    box-shadow: none;
    color: #848484; }

.btn-dark {
  background-color: #000; }
  .btn-dark:hover, .btn-dark:focus {
    background-color: #000; }

.btn-round {
  border-radius: 50px; }

.btn-flat.round {
  border-radius: 50px; }
.btn-flat.radius {
  border-radius: 5px; }
.btn-flat.btn-3d {
  box-shadow: 0 -3px 0 0 rgba(0, 0, 0, 0.2) inset; }
  .btn-flat.btn-3d:hover {
    box-shadow: none; }
.btn-flat.icon-left i {
  margin-right: 8px; }
.btn-flat.icon-right i {
  margin-left: 8px; }
.btn-flat.icon-border-left {
  padding: 0 37px 0 80px;
  position: relative; }
  .btn-flat.icon-border-left i {
    border-right: 1px solid;
    position: absolute;
    left: 0;
    text-align: center;
    top: -1px;
    width: 45px; }
.btn-flat.icon-border-r8 {
  padding: 0 80px 0 37px;
  position: relative; }
  .btn-flat.icon-border-r8 i {
    border-left: 1px solid;
    position: absolute;
    right: 0;
    text-align: center;
    top: -1px;
    width: 45px; }

.btn-flat.btn-primary {
  border: 1px solid transparent; }
  .btn-flat.btn-primary:hover {
    background-color: transparent;
    border-color: #0D52FF;
    color: #0D52FF; }

.btn-default {
  background-color: #4d4d4d;
  border: 1px solid transparent;
  color: #fff; }
  .btn-default:hover, .btn-default:focus {
    background-color: transparent;
    border-color: #4d4d4d;
    color: #4d4d4d; }

.btn-light-dark {
  background-color: #303030; }
  .btn-light-dark:hover, .btn-light-dark:focus {
    background-color: #303030; }

.btn-magenta {
  background-color: #e40079; }
  .btn-magenta:hover, .btn-magenta:focus {
    background-color: #e40079; }

.btn-sky-blue {
  background-color: #009deb; }
  .btn-sky-blue:hover, .btn-sky-blue:focus {
    background-color: #009deb; }

.btn-green {
  background-color: #00993f; }
  .btn-green:hover, .btn-green:focus {
    background-color: #00993f; }

.btn-asphalt {
  background-color: #455769; }
  .btn-asphalt:hover, .btn-asphalt:focus {
    background-color: #455769; }

.btn-info {
  background-color: #988ae9;
  border: 1px solid transparent;
  color: #fff; }
  .btn-info:hover, .btn-info:focus {
    background-color: transparent;
    border-color: #988ae9;
    color: #988ae9; }

.btn-success {
  background-color: #00c96d;
  border: 1px solid transparent;
  color: #fff; }
  .btn-success:hover, .btn-success:focus {
    background-color: transparent;
    border-color: #00c96d;
    color: #00c96d; }

.btn-warning {
  background-color: #fec12a;
  border: 1px solid transparent;
  color: #fff; }
  .btn-warning:hover, .btn-warning:focus {
    background-color: transparent;
    border-color: #fec12a;
    color: #fec12a; }

.btn-danger {
  background-color: #ff2b2a;
  border: 1px solid transparent;
  color: #fff; }
  .btn-danger:hover, .btn-danger:focus {
    background-color: transparent;
    border-color: #ff2b2a;
    color: #ff2b2a; }

.btn-white {
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #444; }

.btn-white:hover, .btn-white:focus {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  color: #444; }

.btn-white i, .btn-white:hover i {
  color: #444; }

.btn-tp {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: none;
  color: #fff; }
  .btn-tp:hover, .btn-tp:focus {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: none; }

.btn-light-border {
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: none;
  color: #fff;
  background-color: transparent; }

.store-badge {
  display: inline-block; }
  .store-badge img {
    width: 215px; }

.shadow-default {
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s; }
  .shadow-default:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }

.container {
  max-width: none; }
  .container.fullwidth {
    width: 100%; }
  .container.no-padding {
    padding-left: 0;
    padding-right: 0; }

.no-gutter {
  margin-left: 0;
  margin-right: 0; }
  .no-gutter > [class^="col"] {
    padding-left: 0;
    padding-right: 0; }

.row.no-margin {
  margin-left: 0;
  margin-right: 0; }

/*
nav {
    background-color: transparent;
    box-shadow: none;
    height: auto;
    line-height: inherit;
    width: auto;
    i {
        height: auto;
    }
    ul {
        li {
            float: none;
            a {
                color: inherit;
                font-size: inherit;
                padding: inherit;
                @include transition(all 0.3s ease 0s);
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}*/
nav li a {
  color: #fff; }

.slick-slide {
  outline: 0 none; }

/* Date Picker */
.picker__box {
  border: 0; }

.picker__weekday-display {
  background-color: #ea120c;
  font-size: 14px;
  font-weight: 400; }

.picker__date-display,
.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background-color: #0D52FF; }

.picker__day.picker__day--today,
.picker__close, .picker__today {
  color: #0D52FF; }

.picker__nav--prev:hover, .picker__nav--next:hover {
  background-color: #fcc7c5; }

.picker__header {
  margin-bottom: 0.75em;
  margin-top: 0.75em; }

.picker__nav--prev,
.picker__nav--next {
  margin: 0.25rem 0; }

.picker__footer .btn-flat {
  height: 34px;
  line-height: 36px;
  padding: 0 14px; }
  .picker__footer .btn-flat:hover {
    background-color: rgba(0, 0, 0, 0.1); }

.clockpicker-display,
.clockpicker-span-am-pm {
  color: #fcc7c5; }

.clockpicker-canvas line {
  stroke: rgba(246, 81, 76, 0.25); }

.clockpicker-canvas-bg {
  fill: rgba(246, 81, 76, 0.25); }

.clockpicker-canvas-bearing,
.clockpicker-canvas-fg.active {
  fill: rgba(246, 81, 76, 0.5); }

.clockpicker-tick {
  font-size: 16px !important; }
  .clockpicker-tick:hover {
    background-color: rgba(246, 81, 76, 0.25); }
  .clockpicker-tick.active {
    background-color: #0D52FF;
    color: #fff; }

.clockpicker-am-pm-block .clockpicker-button:hover,
.clockpicker-am-pm-block .clockpicker-button.active {
  background-color: #0D52FF;
  color: #fff; }

/**
 *  Helper Class
 *
 **/
.margin-b-0 {
  margin-bottom: 0 !important; }

.section {
  padding: 75px 0; }

.top-half {
  padding-top: 75px; }

.top-full {
  padding-top: 150px; }

.bottom-half {
  padding-bottom: 75px; }

.bottom-full {
  padding-bottom: 150px; }

.section-full {
  padding: 150px 0; }

.primary {
  color: #0D52FF; }

.primary-bg {
  background-color: #0D52FF; }

.title-color {
  color: #444; }

.title-hover, .title-hover:hover {
  color: #444; }

.gray-bg, .section-gray {
  background-color: #eeeeee; }

.white-bg {
  background-color: #fff; }

.gray-bg-2 {
  background-color: #f3f3f3; }

.gray-bg-3 {
  background-color: #f7f7f7; }

.gray-bg-4 {
  background-color: #fafafa; }

.light-bg {
  background-color: #f8f8f8; }

.light-dark-bg {
  background-color: #303030; }

.text-light {
  color: rgba(255, 255, 255, 0.87); }

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.float-none {
  float: none; }

.title-light, .text-white {
  color: #fff; }

.hover-shadow {
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }
  .hover-shadow:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }

.facebook {
  background-color: #42599e; }
  .facebook:hover, .facebook:focus {
    color: #fff; }

.twitter {
  background-color: #55acee; }
  .twitter:hover, .twitter:focus {
    color: #fff; }

.gplus {
  background-color: #db4437; }
  .gplus:hover, .gplus:focus {
    color: #fff; }

.linkedin {
  background-color: #0798cf; }
  .linkedin:hover, .linkedin:focus {
    color: #fff; }

.dribbble {
  background-color: #e40079; }
  .dribbble:hover, .dribbble:focus {
    color: #fff; }

.pinterest {
  background-color: #fc3030; }
  .pinterest:hover, .pinterest:focus {
    color: #fff; }

.behance {
  background-color: #1769ff; }
  .behance:hover, .behance:focus {
    color: #fff; }

.youtube {
  background-color: #bf0918; }
  .youtube:hover, .youtube:focus {
    color: #fff; }

.roboto {
  font-family: "Roboto", sans-serif; }

.roboto-slab {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.text-color {
  color: #848484; }

.relative, .service.style15 {
  position: relative; }

.ov-hidden {
  overflow: hidden; }

.display-table {
  display: table; }

.table-cell {
  display: table-cell; }

.v-top {
  vertical-align: top; }

.v-middle {
  vertical-align: middle; }

.v-bottom {
  vertical-align: bottom; }

.w100 {
  width: 100%; }

.title-ls {
  letter-spacing: 2.8px; }

.no-ls {
  letter-spacing: 0; }

.block {
  display: block; }

.d-none {
  display: none; }

.inline {
  display: inline-block; }

.border-radius {
  border-radius: 2px; }

.material-tooltip {
  line-height: 36px;
  padding: 0 15px; }

.absolute-center {
  left: 50%;
  position: absolute;
  top: 50%;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.link-underline:hover {
  text-decoration: underline; }

.text-link {
  color: #848484; }
  .text-link:hover {
    color: #0D52FF; }

.title-link {
  color: #444; }
  .title-link:hover {
    color: #0D52FF; }

.title-link-light {
  color: #fff; }
  .title-link-light:hover {
    color: #0D52FF; }

.white-link, .white-link:hover {
  color: #fff; }

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

.inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.flex-grow {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.flex-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.flex-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.flex-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.flex-top {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.flex-bottom {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end; }

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.space-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -moz-justify-content: space-evenly;
  justify-content: space-evenly; }

.flex-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.parallax-bg {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }
  .overlay.light {
    background-color: rgba(255, 255, 255, 0.6); }
  .overlay.op-3 {
    background-color: rgba(0, 0, 0, 0.3); }
  .overlay.op-4 {
    background-color: rgba(0, 0, 0, 0.4); }
  .overlay.dark, .overlay.op-5 {
    background-color: rgba(0, 0, 0, 0.5); }
  .overlay.op-6 {
    background-color: rgba(0, 0, 0, 0.6); }
  .overlay.dark-2 {
    background-color: rgba(48, 48, 48, 0.8); }
  .overlay.op-7 {
    background-color: rgba(0, 0, 0, 0.7); }
  .overlay.dark-3, .overlay.dark-8, .overlay.op-8 {
    background-color: rgba(0, 0, 0, 0.8); }
  .overlay.dark-9 {
    background-color: rgba(0, 0, 0, 0.9); }

.ratings {
  color: #f3bc39;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .ratings::before {
    content: "\f006  \f006  \f006  \f006  \f006"; }
  .ratings.star-half::before {
    content: "\f123  \f006  \f006  \f006  \f006"; }
  .ratings.star-1::before {
    content: "\f005  \f006  \f006  \f006  \f006"; }
  .ratings.star-1-half::before {
    content: "\f005  \f123  \f006  \f006  \f006"; }
  .ratings.star-2::before {
    content: "\f005  \f005  \f006  \f006  \f006"; }
  .ratings.star-2-half::before {
    content: "\f005  \f005  \f123  \f006  \f006"; }
  .ratings.star-3::before {
    content: "\f005  \f005  \f005  \f006  \f006"; }
  .ratings.star-3-half::before {
    content: "\f005  \f005  \f005  \f123  \f006"; }
  .ratings.star-4::before {
    content: "\f005  \f005  \f005  \f005  \f006"; }
  .ratings.star-4-half::before {
    content: "\f005  \f005  \f005  \f005  \f123"; }
  .ratings.star-5::before {
    content: "\f005  \f005  \f005  \f005  \f005"; }

.slick-vertical .slick-slide {
  border: 0; }

.caret-up {
  clip: rect(-10px, 60px, 25px, -10px);
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.caret-up::before {
  border: 1px solid #fff;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 50px;
  width: 50px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.icon-share {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg); }

.v-center-content::after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle; }
.v-center-content .vcc-inner {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle; }

.z-index-1 {
  z-index: 1; }

.z-index-2 {
  z-index: 2; }

.z-index-3 {
  z-index: 3; }

.z-index-4 {
  z-index: 4; }

.z-index-5 {
  z-index: 5; }

.z-index-12 {
  z-index: 12; }

.z-index-13 {
  z-index: 13; }

.z-index-14 {
  z-index: 14; }

.z-index-15 {
  z-index: 15; }

.z-depth-1 {
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1); }

.btn-primary {
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1); }
  .btn-primary:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }

/*--------------------------- Color variations ----------------------*/
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #444; }

/* 
====== Move-horizontal effect ======
*/
.mfp-move-horizontal {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-move-horizontal .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    -o-transform: translateX(-50px);
    transform: translateX(-50px); }
  .mfp-move-horizontal.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
  .mfp-move-horizontal.mfp-ready .mfp-with-anim {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  .mfp-move-horizontal.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-move-horizontal.mfp-removing .mfp-with-anim {
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -o-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0; }
  .mfp-move-horizontal.mfp-removing.mfp-bg {
    opacity: 0; }

/*
====== Zoom effect ======
*/
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8); }
  .mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-zoom-in.mfp-removing .mfp-with-anim {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; }
  .mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0; }

/*
====== Team Single BG ======
*/
.mfp-bg {
  background-color: #fff;
  opacity: 1; }
  .mfp-bg.dark {
    background-color: #000;
    opacity: 0.9; }

.dark .mfp-close {
  color: #fff; }

/*!
 * bootstrap-fileinput v4.3.8
 * http://plugins.krajee.com/file-input
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2017, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD 3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */





.input-gray:not([type="submit"]) {
  background-color: #f7f7f7;
  border-radius: 2px;
  border: 1px solid transparent;
  height: 48px;
  padding: 0 30px;
  width: -webkit-calc(100% - 60px);
  width: -moz-calc(100% - 60px);
  width: calc(100% - 60px); }

.form-field input:not([type="submit"]) {
  height: 49px;
  padding: 0 30px;
  width: -webkit-calc(100% - 60px);
  width: -moz-calc(100% - 60px);
  width: calc(100% - 60px); }
.form-field .input-group-addon {
  background-color: #f7f7f7;
  border-radius: 0 2px 2px 0;
  border: 0 none;
  cursor: pointer;
  line-height: 50px;
  margin-left: 2px;
  padding: 0;
  text-align: center;
  min-width: 50px;
  width: 50px; }
.form-field.light input:not([type="submit"]) {
  border-bottom-color: rgba(255, 255, 255, 0.15); }
  .form-field.light input:not([type="submit"]):focus {
    border-bottom-color: rgba(255, 255, 255, 0.54); }
.form-field.light .input-group-addon {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff; }

.input-gray:focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.1); }

/*==============================================
    Contact Form
===============================================*/
.contact-block {
  font-size: 15px; }
  .contact-block i {
    font-size: 30px;
    margin-bottom: 27px; }
  .contact-block.style-2 {
    padding: 95px; }

.input-field {
  margin: 0 0 30px; }
  .input-field input,
  .input-field .materialize-textarea {
    font-size: 14px;
    height: 40px;
    position: relative;
    z-index: 10; }
  .input-field label {
    font-size: 14px;
    font-weight: 300;
    left: 0;
    letter-spacing: 1px;
    margin: 0;
    text-transform: capitalize; }
    .input-field label.active {
      font-size: 12px;
      -webkit-transform: translateY(-80%);
      -moz-transform: translateY(-80%);
      -ms-transform: translateY(-80%);
      -o-transform: translateY(-80%);
      transform: translateY(-80%); }
  .input-field .ttn {
    text-transform: none; }

.contact-form .input-field label.active {
  color: #444; }
.contact-form input:focus:not([readonly]) + label.error.active,
.contact-form .materialize-textarea:focus:not([readonly]) + label.error.active {
  color: #d60909; }
.contact-form label {
  color: #444; }
  .contact-form label.error {
    color: #d60909;
    display: block;
    font-weight: 300;
    letter-spacing: 0;
    opacity: 1;
    position: relative;
    text-transform: none; }
    .contact-form label.error.active {
      font-size: 14px;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px); }
.contact-form .form-submit {
  color: #848484;
  border-color: rgba(0, 0, 0, 0.1);
  font-weight: 300; }
  .contact-form .form-submit:hover {
    border-color: #444; }

input.ml-input,
textarea.materialize-textarea {
  border-bottom-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 0; }
  input.ml-input:focus, input.ml-input.valid,
  textarea.materialize-textarea:focus,
  textarea.materialize-textarea.valid {
    border-bottom-color: #444;
    box-shadow: none; }
    input.ml-input:focus:focus, input.ml-input.valid:focus,
    textarea.materialize-textarea:focus:focus,
    textarea.materialize-textarea.valid:focus {
      border-bottom-color: #444;
      box-shadow: none; }
    input.ml-input:focus + label.active, input.ml-input.valid + label.active,
    textarea.materialize-textarea:focus + label.active,
    textarea.materialize-textarea.valid + label.active {
      color: #444; }

.contact-form [class^="msg-"] {
  display: none;
  margin-top: 16px; }
.contact-form .msg-success {
  color: #4caf50;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px; }

.cf-light input,
.cf-light .materialize-textarea {
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.3); }
  .cf-light input:focus:not([readonly]),
  .cf-light .materialize-textarea:focus:not([readonly]) {
    border-bottom-color: #fff;
    box-shadow: none; }
    .cf-light input:focus:not([readonly]) + label,
    .cf-light .materialize-textarea:focus:not([readonly]) + label {
      color: #fff; }
.cf-light label {
  color: #fff; }
.cf-light input.ml-input:focus, .cf-light input.ml-input.valid, .cf-light textarea.materialize-textarea:focus, .cf-light textarea.materialize-textarea.valid {
  border-bottom-color: #fff;
  box-shadow: none; }
  .cf-light input.ml-input:focus:focus, .cf-light input.ml-input.valid:focus, .cf-light textarea.materialize-textarea:focus:focus, .cf-light textarea.materialize-textarea.valid:focus {
    border-bottom-color: #fff;
    box-shadow: none; }
  .cf-light input.ml-input:focus + label.active, .cf-light input.ml-input.valid + label.active, .cf-light textarea.materialize-textarea:focus + label.active, .cf-light textarea.materialize-textarea.valid + label.active {
    color: #fff; }
.cf-light .input-field label.active {
  color: #fff; }
.cf-light .form-submit:not(.btn-white) {
  color: #fff; }
  .cf-light .form-submit:not(.btn-white):hover {
    border-color: rgba(255, 255, 255, 0.3); }
.cf-light .form-submit.btn-white:hover {
  color: #444; }

/*==============================================
    Google Maps
===============================================*/
.map-control {
  padding-top: 180px;
  padding-bottom: 150px; }

#map-half {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%; }

/*==============================================
    Cuatom Checkbox
===============================================*/
input[type="radio"] {
  height: auto;
  margin: 0;
  width: auto; }

.custom-checkbox input {
  display: none;
  width: auto; }
  .custom-checkbox input:checked + label::before {
    background-position: 0 -12px; }
.custom-checkbox label {
  cursor: pointer;
  position: relative; }
  .custom-checkbox label:before {
    background: blue;
    content: "";
    display: inline-block;
    height: 12px;
    margin-right: 7px;
    position: relative;
    top: 2px;
    width: 12px; }
.custom-checkbox.light input:checked + label::before {
  background-position: -12px -12px; }
.custom-checkbox.light label::before {
  background-position: -12px 0; }

.custom-chekbox-2 label {
  cursor: pointer;
  margin: 0;
  padding-left: 40px;
  position: relative; }
  .custom-chekbox-2 label::before {
    border: 1px solid rgba(0, 0, 0, 0.15);
    content: "";
    height: 20px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 20px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .custom-chekbox-2 label::after {
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 50%;
    width: 14px;
    -webkit-transform: translateY(-50%) scale(0);
    -moz-transform: translateY(-50%) scale(0);
    -ms-transform: translateY(-50%) scale(0);
    -o-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s; }
.custom-chekbox-2 input {
  height: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 0; }
  .custom-chekbox-2 input:checked + label::after {
    background-color: #0D52FF;
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1); }
.custom-chekbox-2.round label::before, .custom-chekbox-2.round label::after {
  border-radius: 30px; }
.custom-chekbox-2.dark input:checked + label::after {
  background-color: #000; }
.custom-chekbox-2.icon-check label::after {
  color: #fff;
  content: "\f00c";
  font: normal normal normal 14px/20px "FontAwesome";
  font-size: inherit;
  left: 0;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 20px; }

/*==============================================
    Login Forms
===============================================*/
.round-input input {
  background-color: #f7f7f7;
  border: 1px solid transparent;
  border-radius: 50px;
  height: 48px;
  margin-bottom: 0;
  padding: 0 30px 0 65px;
  width: -webkit-calc(100% - 95px);
  width: -moz-calc(100% - 95px);
  width: calc(100% - 95px); }
  .round-input input:focus {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.1); }
.round-input .icon {
  left: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
.round-input.light input {
  background-color: rgba(255, 255, 255, 0.15); }
  .round-input.light input:focus {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.15); }

.selectize-control {
  z-index: 11; }

/*==============================================
    Site Header
===============================================*/
.site-header {
  background-color: transparent;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  /**-*-*-*-*-* Header 2 -*-*-*-*-*-*-**/
  /**-*-*-*-*-*- Header 3 -*-*-*-*-*-*-**/
  /**-*-*-*-*-* Header 4 -*-*-*-*-*-*-**/
  /**-*-*-*-*-* Header 5 -*-*-*-*-*-*-**/ }
  .site-header .mid-content .left-content {
    color: rgba(255, 255, 255, 0.87); }
  .site-header .currency-select,
  .site-header .lang-select {
    display: inline-block;
    white-space: nowrap; }
  .site-header .nice-select {
    background-color: transparent;
    border: 0 none;
    border-radius: 0;
    float: none;
    height: auto;
    line-height: inherit;
    z-index: 99; }
    .site-header .nice-select::after {
      border-bottom-color: rgba(255, 255, 255, 0.87);
      border-right-color: rgba(255, 255, 255, 0.87); }
    .site-header .nice-select span {
      color: rgba(255, 255, 255, 0.87);
      font-size: 13px; }
    .site-header .nice-select.dark::before, .site-header .nice-select.dark::after {
      border-bottom-color: rgba(0, 0, 0, 0.87);
      border-right-color: rgba(0, 0, 0, 0.87); }
    .site-header .nice-select.dark span {
      color: #848484; }
  .site-header .header-cart {
    display: inline-block;
    position: relative;
    white-space: nowrap; }
  .site-header .cart-button {
    background-color: #0D52FF;
    color: rgba(255, 255, 255, 0.87);
    display: inline-block;
    padding: 0 38px; }
  .site-header .mid-content {
    float: right;
    margin: 8px 0; }
  .site-header .middle-search {
    background-color: #fff;
    border-radius: 2px;
    min-width: 580px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
    .site-header .middle-search input {
      border-bottom: 0;
      font-size: 14px;
      height: 50px;
      margin: 0; }
      .site-header .middle-search input:focus {
        border-bottom: 0;
        box-shadow: none; }
    .site-header .middle-search label {
      font-size: 24px;
      height: 50px;
      margin: 0;
      text-align: center;
      width: 50px;
      min-width: 50px; }
      .site-header .middle-search label i {
        line-height: 50px; }
  .site-header.is-sticky {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    -webkit-animation: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s alternate none 1 running fadeInDown;
    animation: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s alternate none 1 running fadeInDown;
    z-index: 150; }
  .site-header.static, .site-header.sticky {
    background-color: #fff;
    position: relative;
    z-index: 100;
    animation: none; }
  .site-header.bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .site-header.bakery-gray {
    background-color: #e9eef1; }
  .site-header.no-shadow, .site-header.is-sticky.no-shadow {
    box-shadow: none; }
  .site-header.style2 {
    background-color: rgba(0, 0, 0, 0.3); }
    .site-header.style2.is-sticky {
      background-color: #fff; }
  .site-header.style3 {
    background-color: rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05); }
    .site-header.style3.is-sticky {
      background-color: #fff; }
  .site-header.style4 {
    background-color: #0D52FF; }
    .site-header.style4 .header-top {
      background-color: #d7423e; }
    .site-header.style4 .social-links li a {
      font-size: 17px;
      padding: 0 17px; }
    .site-header.style4 .shop-nav {
      display: inline-block; }
      .site-header.style4 .shop-nav li {
        display: inline-block; }
        .site-header.style4 .shop-nav li a {
          display: block;
          font-size: 12px;
          padding: 0 13px; }
    .site-header.style4 a {
      color: rgba(255, 255, 255, 0.87);
      line-height: 40px; }
      .site-header.style4 a:hover {
        color: #fff; }
    .site-header.style4 .cart-button {
      color: rgba(255, 255, 255, 0.87); }
      .site-header.style4 .cart-button i {
        font-size: 20px;
        margin-left: 15px; }
    .site-header.style4 .select-box {
      padding-right: 80px; }
    .site-header.style4 .more-vert {
      display: table-cell; }
    .site-header.style4 .more-vert-menu {
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: right; }
      .site-header.style4 .more-vert-menu li a {
        color: #848484;
        line-height: 25px; }
        .site-header.style4 .more-vert-menu li a:hover {
          color: #444; }
    .site-header.style4 .toggle-my-account {
      padding-left: 30px; }
      .site-header.style4 .toggle-my-account i {
        display: inline-block;
        line-height: inherit; }
    .site-header.style4 .primary-nav li a {
      color: rgba(255, 255, 255, 0.87); }
    .site-header.style4.is-sticky {
      background-color: #fff; }
      .site-header.style4.is-sticky .site-logo {
        padding: 10px 0; }
        .site-header.style4.is-sticky .site-logo img {
          height: 40px; }
      .site-header.style4.is-sticky .header-top, .site-header.style4.is-sticky .mid-content {
        display: none; }
      .site-header.style4.is-sticky .menu-list > li > a {
        color: #848484; }
  .site-header.style5, .site-header.style6, .site-header.style7, .site-header.style8, .site-header.style9 {
    background-color: #0D52FF; }
    .site-header.style5 .header-top, .site-header.style6 .header-top, .site-header.style7 .header-top, .site-header.style8 .header-top, .site-header.style9 .header-top {
      background-color: #d7423e; }
      .site-header.style5 .header-top .col-md-6 > *, .site-header.style6 .header-top .col-md-6 > *, .site-header.style7 .header-top .col-md-6 > *, .site-header.style8 .header-top .col-md-6 > *, .site-header.style9 .header-top .col-md-6 > * {
        display: inline-block;
        line-height: 45px;
        vertical-align: middle; }
    .site-header.style5 .shop-nav, .site-header.style6 .shop-nav, .site-header.style7 .shop-nav, .site-header.style8 .shop-nav, .site-header.style9 .shop-nav {
      display: inline-block; }
      .site-header.style5 .shop-nav li, .site-header.style6 .shop-nav li, .site-header.style7 .shop-nav li, .site-header.style8 .shop-nav li, .site-header.style9 .shop-nav li {
        display: inline-block; }
        .site-header.style5 .shop-nav li a, .site-header.style6 .shop-nav li a, .site-header.style7 .shop-nav li a, .site-header.style8 .shop-nav li a, .site-header.style9 .shop-nav li a {
          color: rgba(255, 255, 255, 0.87);
          display: inline-block;
          padding: 0 15px;
          line-height: 40px; }
          .site-header.style5 .shop-nav li a:hover, .site-header.style6 .shop-nav li a:hover, .site-header.style7 .shop-nav li a:hover, .site-header.style8 .shop-nav li a:hover, .site-header.style9 .shop-nav li a:hover {
            color: #fff; }
    .site-header.style5 .welcome, .site-header.style6 .welcome, .site-header.style7 .welcome, .site-header.style8 .welcome, .site-header.style9 .welcome {
      margin-right: 10px; }
    .site-header.style5 .header-middle .middle-search, .site-header.style6 .header-middle .middle-search, .site-header.style7 .header-middle .middle-search, .site-header.style8 .header-middle .middle-search, .site-header.style9 .header-middle .middle-search {
      min-width: auto; }
    .site-header.style5 .header-middle .social-links a, .site-header.style6 .header-middle .social-links a, .site-header.style7 .header-middle .social-links a, .site-header.style8 .header-middle .social-links a, .site-header.style9 .header-middle .social-links a {
      color: rgba(255, 255, 255, 0.87);
      font-size: 17px;
      padding: 0 17px; }
      .site-header.style5 .header-middle .social-links a:hover, .site-header.style6 .header-middle .social-links a:hover, .site-header.style7 .header-middle .social-links a:hover, .site-header.style8 .header-middle .social-links a:hover, .site-header.style9 .header-middle .social-links a:hover {
        color: #fff; }
    .site-header.style5 .header-middle .header-cart, .site-header.style6 .header-middle .header-cart, .site-header.style7 .header-middle .header-cart, .site-header.style8 .header-middle .header-cart, .site-header.style9 .header-middle .header-cart {
      display: block; }
    .site-header.style5 .mini-cart-wrapper .buttons .col-xs-6, .site-header.style6 .mini-cart-wrapper .buttons .col-xs-6, .site-header.style7 .mini-cart-wrapper .buttons .col-xs-6, .site-header.style8 .mini-cart-wrapper .buttons .col-xs-6, .site-header.style9 .mini-cart-wrapper .buttons .col-xs-6 {
      padding: 0 15px; }
    .site-header.style5 .cart-button, .site-header.style6 .cart-button, .site-header.style7 .cart-button, .site-header.style8 .cart-button, .site-header.style9 .cart-button {
      background-color: #e94843;
      line-height: 40px;
      padding: 0 20px; }
      .site-header.style5 .cart-button i, .site-header.style6 .cart-button i, .site-header.style7 .cart-button i, .site-header.style8 .cart-button i, .site-header.style9 .cart-button i {
        font-size: 22px;
        line-height: 25px;
        margin: 0 0 0 20px;
        position: relative;
        top: 3px; }
      .site-header.style5 .cart-button:hover, .site-header.style6 .cart-button:hover, .site-header.style7 .cart-button:hover, .site-header.style8 .cart-button:hover, .site-header.style9 .cart-button:hover {
        color: #fff; }
    .site-header.style5 .toggle-my-account, .site-header.style6 .toggle-my-account, .site-header.style7 .toggle-my-account, .site-header.style8 .toggle-my-account, .site-header.style9 .toggle-my-account {
      color: rgba(255, 255, 255, 0.87); }
      .site-header.style5 .toggle-my-account:hover, .site-header.style6 .toggle-my-account:hover, .site-header.style7 .toggle-my-account:hover, .site-header.style8 .toggle-my-account:hover, .site-header.style9 .toggle-my-account:hover {
        color: #fff; }
    .site-header.style5 .header-bottom, .site-header.style6 .header-bottom, .site-header.style7 .header-bottom, .site-header.style8 .header-bottom, .site-header.style9 .header-bottom {
      background-color: #e94843; }
      .site-header.style5 .header-bottom .site-logo, .site-header.style6 .header-bottom .site-logo, .site-header.style7 .header-bottom .site-logo, .site-header.style8 .header-bottom .site-logo, .site-header.style9 .header-bottom .site-logo {
        display: none;
        line-height: 70px; }
    .site-header.style5 .primary-nav, .site-header.style6 .primary-nav, .site-header.style7 .primary-nav, .site-header.style8 .primary-nav, .site-header.style9 .primary-nav {
      float: none; }
      .site-header.style5 .primary-nav ul:first-child, .site-header.style6 .primary-nav ul:first-child, .site-header.style7 .primary-nav ul:first-child, .site-header.style8 .primary-nav ul:first-child, .site-header.style9 .primary-nav ul:first-child {
        float: left; }
      .site-header.style5 .primary-nav > ul > li > a, .site-header.style6 .primary-nav > ul > li > a, .site-header.style7 .primary-nav > ul > li > a, .site-header.style8 .primary-nav > ul > li > a, .site-header.style9 .primary-nav > ul > li > a {
        line-height: 40px; }
        .site-header.style5 .primary-nav > ul > li > a:hover, .site-header.style5 .primary-nav > ul > li > a:focus, .site-header.style6 .primary-nav > ul > li > a:hover, .site-header.style6 .primary-nav > ul > li > a:focus, .site-header.style7 .primary-nav > ul > li > a:hover, .site-header.style7 .primary-nav > ul > li > a:focus, .site-header.style8 .primary-nav > ul > li > a:hover, .site-header.style8 .primary-nav > ul > li > a:focus, .site-header.style9 .primary-nav > ul > li > a:hover, .site-header.style9 .primary-nav > ul > li > a:focus {
          background-color: #d7423e;
          color: #fff; }
    .site-header.style5.is-sticky .header-top,
    .site-header.style5.is-sticky .header-middle, .site-header.style6.is-sticky .header-top,
    .site-header.style6.is-sticky .header-middle, .site-header.style7.is-sticky .header-top,
    .site-header.style7.is-sticky .header-middle, .site-header.style8.is-sticky .header-top,
    .site-header.style8.is-sticky .header-middle, .site-header.style9.is-sticky .header-top,
    .site-header.style9.is-sticky .header-middle {
      display: none; }
    .site-header.style5.is-sticky .header-bottom, .site-header.style6.is-sticky .header-bottom, .site-header.style7.is-sticky .header-bottom, .site-header.style8.is-sticky .header-bottom, .site-header.style9.is-sticky .header-bottom {
      background-color: #fff; }
      .site-header.style5.is-sticky .header-bottom .site-logo, .site-header.style6.is-sticky .header-bottom .site-logo, .site-header.style7.is-sticky .header-bottom .site-logo, .site-header.style8.is-sticky .header-bottom .site-logo, .site-header.style9.is-sticky .header-bottom .site-logo {
        display: block; }
        .site-header.style5.is-sticky .header-bottom .site-logo img, .site-header.style6.is-sticky .header-bottom .site-logo img, .site-header.style7.is-sticky .header-bottom .site-logo img, .site-header.style8.is-sticky .header-bottom .site-logo img, .site-header.style9.is-sticky .header-bottom .site-logo img {
          height: 35px; }
    .site-header.style5.is-sticky .menu-list > li > a, .site-header.style6.is-sticky .menu-list > li > a, .site-header.style7.is-sticky .menu-list > li > a, .site-header.style8.is-sticky .menu-list > li > a, .site-header.style9.is-sticky .menu-list > li > a {
      line-height: 70px; }
  .site-header.style5.is-sticky .header-bottom .site-logo {
    line-height: 60px; }
  .site-header.style5.is-sticky .menu-list > li > a {
    line-height: 60px; }
  .site-header.style6 .header-top,
  .site-header.style6 .header-bottom, .site-header.style7 .header-top,
  .site-header.style7 .header-bottom {
    background-color: transparent; }
  .site-header.style6 .middle-search .nice-select:after, .site-header.style7 .middle-search .nice-select:after {
    border-bottom-color: #848484;
    border-right-color: #848484; }
  .site-header.style6 .middle-search .nice-select span, .site-header.style7 .middle-search .nice-select span {
    color: #848484;
    display: inline-block;
    line-height: 50px; }
  .site-header.style6 .middle-search .nice-select .list, .site-header.style7 .middle-search .nice-select .list {
    border-radius: 2px;
    margin-top: 0;
    z-index: 20; }
  .site-header.style6 .product-actions li, .site-header.style7 .product-actions li {
    display: inline-block; }
    .site-header.style6 .product-actions li a, .site-header.style7 .product-actions li a {
      color: rgba(255, 255, 255, 0.87);
      font-size: 24px;
      display: block;
      margin-right: 40px; }
      .site-header.style6 .product-actions li a:hover, .site-header.style7 .product-actions li a:hover {
        color: rgba(255, 255, 255, 0.87); }
    .site-header.style6 .product-actions li:last-child a, .site-header.style7 .product-actions li:last-child a {
      margin-right: 0; }
  .site-header.style6.is-sticky .header-bottom .site-logo, .site-header.style7.is-sticky .header-bottom .site-logo {
    line-height: 60px; }
  .site-header.style6.is-sticky .menu-list > li > a, .site-header.style7.is-sticky .menu-list > li > a {
    line-height: 60px; }
  .site-header.style7 {
    background-color: #fff; }
    .site-header.style7 .header-top {
      background-color: #0D52FF; }
    .site-header.style7 .header-middle {
      background-color: rgba(0, 0, 0, 0.87); }
    .site-header.style7 .header-bottom {
      background-color: #fff; }
    .site-header.style7 .middle-search {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 50px; }
      .site-header.style7 .middle-search label {
        background-color: #fff;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px; }
      .site-header.style7 .middle-search .nice-select span,
      .site-header.style7 .middle-search input {
        color: rgba(255, 255, 255, 0.87);
        font-size: 14px;
        font-weight: 300; }
      .site-header.style7 .middle-search input {
        text-align: right;
        padding-right: 30px; }
    .site-header.style7 .product-actions {
      margin: 0 40px;
      white-space: nowrap; }
      .site-header.style7 .product-actions li a {
        height: 44px;
        line-height: 44px;
        margin-right: 10px;
        text-align: center;
        width: 44px; }
        .site-header.style7 .product-actions li a:hover {
          background-color: rgba(255, 255, 255, 0.1); }
    .site-header.style7 .header-cart {
      border: 1px solid rgba(255, 255, 255, 0.87);
      border-radius: 50px; }
    .site-header.style7 .cart-button {
      background-color: transparent;
      line-height: 48px;
      padding-right: 77px;
      position: relative; }
      .site-header.style7 .cart-button i {
        background-color: #fff;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        color: #444;
        line-height: 50px;
        position: absolute;
        right: 0;
        text-align: center;
        top: -1px;
        width: 52px; }
    .site-header.style7 .menu-list > li > a {
      color: #444; }
    .site-header.style7 .menu-list > li:hover > a, .site-header.style7 .menu-list > li.active > a {
      background-color: #0D52FF;
      color: rgba(255, 255, 255, 0.87); }
    .site-header.style7 .nav-hamburger span,
    .site-header.style7 .nav-hamburger span::before,
    .site-header.style7 .nav-hamburger span::after {
      background-color: #ccc; }
    .site-header.style7 .nav-hamburger.active span {
      background-color: transparent; }
    .site-header.style7 .social-links {
      float: right;
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      .site-header.style7 .social-links a {
        color: #848484; }
        .site-header.style7 .social-links a i {
          height: auto; }
  .site-header.style8 {
    background-color: #fff; }
    .site-header.style8 .header-top {
      background-color: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 2px; }
    .site-header.style8 .nice-select::after {
      border-bottom-color: #848484;
      border-right-color: #848484; }
    .site-header.style8 .nice-select span {
      color: #848484; }
    .site-header.style8 .shop-nav li a {
      color: #848484; }
      .site-header.style8 .shop-nav li a:hover {
        color: #444; }
    .site-header.style8 .header-middle {
      padding: 35px 0; }
      .site-header.style8 .header-middle .site-logo {
        line-height: inherit; }
    .site-header.style8 .middle-search {
      background-color: transparent; }
      .site-header.style8 .middle-search > span {
        color: #fff;
        display: block;
        font-weight: 500;
        font-size: 20px;
        padding-right: 30px;
        white-space: nowrap; }
      .site-header.style8 .middle-search label, .site-header.style8 .middle-search .nice-select, .site-header.style8 .middle-search input {
        background-color: #fff;
        line-height: 50px; }
      .site-header.style8 .middle-search .active {
        color: #848484; }
    .site-header.style8 .primary-nav ul:first-child {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      float: right;
      font-size: 0;
      text-align: center; }
    .site-header.style8 .primary-nav > ul > li {
      display: block; }
      .site-header.style8 .primary-nav > ul > li > a {
        color: #848484; }
      .site-header.style8 .primary-nav > ul > li:hover > a {
        background-color: rgba(0, 0, 0, 0.05);
        color: #848484; }
    .site-header.style8 .header-bottom {
      background-color: rgba(0, 0, 0, 0.87);
      padding: 20px 0; }
      .site-header.style8 .header-bottom .table-cell {
        float: none;
        padding-left: 0;
        padding-right: 0; }
      .site-header.style8 .header-bottom .nice-select {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px; }
      .site-header.style8 .header-bottom input {
        padding-right: 20px;
        text-align: right; }
      .site-header.style8 .header-bottom label {
        background-color: #e9e7e7;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        min-width: 60px; }
    .site-header.style8 .product-actions {
      white-space: nowrap; }
      .site-header.style8 .product-actions li {
        display: inline-block; }
        .site-header.style8 .product-actions li a {
          color: rgba(255, 255, 255, 0.87);
          display: block;
          font-size: 24px;
          height: 44px;
          width: 44px; }
          .site-header.style8 .product-actions li a:hover {
            background-color: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.87); }
          .site-header.style8 .product-actions li a i {
            line-height: 44px; }
    .site-header.style8 .header-cart {
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 50px;
      display: block;
      height: 50px; }
    .site-header.style8 .cart-button {
      background-color: transparent;
      line-height: 50px;
      padding: 0 75px 0 0;
      position: relative; }
      .site-header.style8 .cart-button i {
        background-color: #fff;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        color: #848484;
        height: 50px;
        line-height: 52px;
        position: absolute;
        right: 0;
        text-align: center;
        top: -1px;
        width: 52px; }
    .site-header.style8.is-sticky .header-top, .site-header.style8.is-sticky .header-bottom {
      display: none; }
    .site-header.style8.is-sticky .header-middle {
      display: block;
      padding: 0; }
      .site-header.style8.is-sticky .header-middle .site-logo {
        line-height: 60px; }
    .site-header.style8.is-sticky .menu-list {
      text-align: right; }
      .site-header.style8.is-sticky .menu-list > li > a {
        line-height: 60px; }
  .site-header.style9 {
    background-color: transparent; }
    .site-header.style9 .header-top {
      background-color: rgba(255, 255, 255, 0.5); }
    .site-header.style9 .social-links li a {
      color: rgba(255, 255, 255, 0.87);
      font-size: 17px;
      padding-right: 34px; }
      .site-header.style9 .social-links li a:hover {
        color: #fff; }
    .site-header.style9 .social-links li:last-child a {
      padding-right: 0; }
    .site-header.style9 .header-middle {
      background-color: rgba(0, 0, 0, 0.5); }
      .site-header.style9 .header-middle .site-logo {
        display: inline-block;
        float: none; }
    .site-header.style9 .middle-search {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 50px; }
      .site-header.style9 .middle-search label {
        background-color: #fff;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px; }
      .site-header.style9 .middle-search input {
        color: rgba(255, 255, 255, 0.87);
        text-indent: 20px; }
    .site-header.style9 .header-cart {
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 50px; }
      .site-header.style9 .header-cart .cart-button {
        background-color: transparent;
        line-height: 50px;
        padding-right: 70px;
        position: relative; }
        .site-header.style9 .header-cart .cart-button i {
          background-color: #fff;
          border-bottom-right-radius: 50px;
          border-top-right-radius: 50px;
          color: #848484;
          height: 50px;
          line-height: 50px;
          position: absolute;
          right: 0;
          text-align: center;
          top: 0;
          width: 52px; }
    .site-header.style9 .header-bottom {
      background-color: #fff; }
    .site-header.style9 .primary-nav ul:first-child {
      float: none;
      text-align: center; }
    .site-header.style9 .menu-list > li {
      display: inline-block;
      float: none;
      margin-left: -4px;
      vertical-align: middle; }
      .site-header.style9 .menu-list > li > a {
        color: #848484; }
    .site-header.style9.is-sticky .header-bottom .site-logo {
      line-height: 60px; }
    .site-header.style9.is-sticky .menu-list > li > a {
      line-height: 60px; }
  .site-header.style10, .site-header.style11 {
    background-color: #fff; }
    .site-header.style10 .header-middle .site-logo, .site-header.style11 .header-middle .site-logo {
      display: inline-block;
      float: none;
      line-height: inherit;
      padding: 40px 0; }
    .site-header.style10 .header-bottom .site-logo, .site-header.style11 .header-bottom .site-logo {
      display: none; }
    .site-header.style10 .shop-nav, .site-header.style11 .shop-nav {
      margin: 7px 0 19px; }
      .site-header.style10 .shop-nav li, .site-header.style11 .shop-nav li {
        display: inline-block;
        vertical-align: middle; }
        .site-header.style10 .shop-nav li a, .site-header.style11 .shop-nav li a {
          color: #848484;
          display: block;
          font-size: 12px;
          font-weight: 400;
          padding-left: 28px; }
          .site-header.style10 .shop-nav li a:hover, .site-header.style11 .shop-nav li a:hover {
            color: #444; }
        .site-header.style10 .shop-nav li:first-child a, .site-header.style11 .shop-nav li:first-child a {
          padding-left: 0; }
    .site-header.style10 .info-cell i, .site-header.style11 .info-cell i {
      font-size: 36px; }
    .site-header.style10 .info-cell h5, .site-header.style11 .info-cell h5 {
      font-size: 15px; }
    .site-header.style10 .info-cell p, .site-header.style11 .info-cell p {
      font-size: 12px; }
    .site-header.style10 .cart-button, .site-header.style11 .cart-button {
      background-color: transparent;
      color: #848484;
      padding-right: 0; }
    .site-header.style10 .header-bottom, .site-header.style11 .header-bottom {
      background-color: #303030;
      position: relative; }
      .site-header.style10 .header-bottom::before, .site-header.style10 .header-bottom::after, .site-header.style11 .header-bottom::before, .site-header.style11 .header-bottom::after {
        height: 3px;
        position: absolute;
        left: 0;
        right: 0;
        content: ""; }
      .site-header.style10 .header-bottom::before, .site-header.style11 .header-bottom::before {
        background-color: rgba(0, 0, 0, 0.2);
        top: -3px; }
      .site-header.style10 .header-bottom::after, .site-header.style11 .header-bottom::after {
        background-color: rgba(255, 255, 255, 0.2);
        bottom: -3px; }
    .site-header.style10 .primary-nav, .site-header.style11 .primary-nav {
      float: left; }
    .site-header.style10 .menu-list > li > a, .site-header.style11 .menu-list > li > a {
      line-height: 50px; }
    .site-header.style10 .top-search > a, .site-header.style11 .top-search > a {
      height: 50px;
      line-height: 50px; }
    .site-header.style10.is-sticky .header-middle, .site-header.style11.is-sticky .header-middle {
      display: none; }
    .site-header.style10.is-sticky .header-bottom, .site-header.style11.is-sticky .header-bottom {
      margin-top: 3px; }
      .site-header.style10.is-sticky .header-bottom .site-logo, .site-header.style11.is-sticky .header-bottom .site-logo {
        display: inline-block;
        line-height: 60px; }
        .site-header.style10.is-sticky .header-bottom .site-logo img, .site-header.style11.is-sticky .header-bottom .site-logo img {
          height: 35px; }
    .site-header.style10.is-sticky .primary-nav, .site-header.style11.is-sticky .primary-nav {
      float: none;
      display: inline-block;
      vertical-align: middle; }
    .site-header.style10.is-sticky .menu-list > li > a, .site-header.style11.is-sticky .menu-list > li > a {
      color: rgba(255, 255, 255, 0.87);
      line-height: 60px; }
      .site-header.style10.is-sticky .menu-list > li > a:hover, .site-header.style11.is-sticky .menu-list > li > a:hover {
        color: #fff; }
    .site-header.style10.is-sticky .top-search > a, .site-header.style11.is-sticky .top-search > a {
      height: 60px;
      line-height: 60px; }
  .site-header.style11 .header-top {
    background-color: #303030;
    padding: 14px 0; }
    .site-header.style11 .header-top li a {
      color: rgba(255, 255, 255, 0.87);
      display: block; }
      .site-header.style11 .header-top li a:hover {
        color: #fff; }
  .site-header.style11 .social-links li {
    margin-right: 34px; }
    .site-header.style11 .social-links li:last-child {
      margin-right: 0; }
    .site-header.style11 .social-links li a {
      font-size: 17px; }
  .site-header.style11 .shop-nav {
    margin: 0; }
  .site-header.style11 .header-middle .site-logo {
    padding: 23px 170px 23px 0; }
  .site-header.style11.is-sticky .header-top {
    display: none; }
  .site-header.style12, .site-header.style13 {
    background-color: #fff; }
    .site-header.style12 .header-top, .site-header.style13 .header-top {
      padding-top: 30px;
      margin-bottom: -15px; }
      .site-header.style12 .header-top .site-logo, .site-header.style13 .header-top .site-logo {
        line-height: inherit; }
    .site-header.style12 .info-cell i, .site-header.style13 .info-cell i {
      font-size: 24px;
      vertical-align: middle; }
    .site-header.style12 .info-cell h5, .site-header.style13 .info-cell h5 {
      font-size: 14px;
      font-weight: 500; }
    .site-header.style12 .info-cell .table-cell:first-child, .site-header.style13 .info-cell .table-cell:first-child {
      color: #0D52FF;
      border-right: 1px solid;
      padding-right: 15px; }
    .site-header.style12 .info-cell .table-cell:last-child, .site-header.style13 .info-cell .table-cell:last-child {
      padding-left: 15px; }
    .site-header.style12 .social-links li a, .site-header.style13 .social-links li a {
      color: #848484;
      font-size: 17px;
      margin-right: 17px; }
      .site-header.style12 .social-links li a:hover, .site-header.style13 .social-links li a:hover {
        color: #0D52FF; }
    .site-header.style12 .social-links li:last-child a, .site-header.style13 .social-links li:last-child a {
      margin-right: 0; }
    .site-header.style12 .primary-nav, .site-header.style13 .primary-nav {
      float: left; }
    .site-header.style12 .menu-list > li, .site-header.style13 .menu-list > li {
      display: inline-block;
      float: none;
      vertical-align: middle; }
      .site-header.style12 .menu-list > li > a, .site-header.style13 .menu-list > li > a {
        color: #848484;
        line-height: 50px; }
        .site-header.style12 .menu-list > li > a:hover, .site-header.style13 .menu-list > li > a:hover {
          color: #fff; }
    .site-header.style12 .header-right .devider, .site-header.style13 .header-right .devider {
      margin-top: 0;
      position: relative;
      top: -1px; }
    .site-header.style12 .top-cart > a,
    .site-header.style12 .top-search > a, .site-header.style13 .top-cart > a,
    .site-header.style13 .top-search > a {
      color: #848484;
      font-size: 24px;
      height: 50px;
      line-height: 50px; }
    .site-header.style12 .header-bottom, .site-header.style13 .header-bottom {
      z-index: 99;
      -webkit-transform: translateY(50%);
      -moz-transform: translateY(50%);
      -ms-transform: translateY(50%);
      -o-transform: translateY(50%);
      transform: translateY(50%); }
      .site-header.style12 .header-bottom .bottom-inner, .site-header.style13 .header-bottom .bottom-inner {
        background-color: #fff;
        padding-bottom: 20px;
        padding-top: 20px;
        box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1); }
    .site-header.style12 .header-right .devider, .site-header.style13 .header-right .devider {
      background-color: #848484; }
    .site-header.style12.is-sticky, .site-header.style13.is-sticky {
      background-color: transparent;
      box-shadow: none;
      padding: 0; }
      .site-header.style12.is-sticky .header-top, .site-header.style13.is-sticky .header-top {
        background-color: #fff;
        display: none; }
      .site-header.style12.is-sticky .header-bottom, .site-header.style13.is-sticky .header-bottom {
        margin-top: 0;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px); }
        .site-header.style12.is-sticky .header-bottom .bottom-inner, .site-header.style13.is-sticky .header-bottom .bottom-inner {
          padding-bottom: 0px;
          padding-top: 0px; }
      .site-header.style12.is-sticky .site-logo,
      .site-header.style12.is-sticky .menu-list > li > a,
      .site-header.style12.is-sticky .header-right > div > a, .site-header.style13.is-sticky .site-logo,
      .site-header.style13.is-sticky .menu-list > li > a,
      .site-header.style13.is-sticky .header-right > div > a {
        height: inherit; }
  .site-header.style13 .site-logo {
    line-height: inherit; }
  .site-header.style13 .header-bottom .bottom-inner {
    padding-bottom: 18.5px;
    padding-top: 18.5px; }
  .site-header.style13 .header-bottom .primary-bg {
    background-color: #0D52FF; }
  .site-header.style13 .primary-bg .menu-list > li > a {
    color: #fff;
    font-weight: 400; }
    .site-header.style13 .primary-bg .menu-list > li > a:hover {
      background-color: #d7423e; }
  .site-header.style13 .primary-bg .devider {
    background-color: #d7423e; }
  .site-header.style13 .primary-bg .top-search > a,
  .site-header.style13 .primary-bg .top-cart > a {
    color: #fff; }
  .site-header.style13.is-sticky {
    position: fixed; }
    .site-header.style13.is-sticky .primary-bg .top-search > a,
    .site-header.style13.is-sticky .primary-bg .top-cart > a {
      color: #fff; }
    .site-header.style13.is-sticky .top-search,
    .site-header.style13.is-sticky .top-cart {
      height: auto; }
  .site-header.style15 {
    border-color: rgba(255, 255, 255, 0.1); }

/*----------------------------------------
    Header action buttons
------------------------------------------*/
.header-action-btns {
  float: right; }
  .header-action-btns .top-search, .header-action-btns .top-cart {
    float: left;
    height: 95px;
    position: relative;
    width: 50px; }
    .header-action-btns .top-search::after, .header-action-btns .top-cart::after {
      background-color: rgba(0, 0, 0, 0.1);
      content: '';
      height: 30px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 1px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
  .header-action-btns > div:last-child::after {
    content: none; }
  .header-action-btns .top-cart-trigger,
  .header-action-btns .top-search-trigger {
    background: url(https://t95dev.avetti.ca/preview/store//20180420050/reactImages/h-actions.png) no-repeat scroll 0 0 transparent;
    display: block;
    height: 21px;
    opacity: 0.54;
    width: 20px; }
    .header-action-btns .top-cart-trigger:hover,
    .header-action-btns .top-search-trigger:hover {
      opacity: 1; }
  .header-action-btns .top-cart-trigger {
    background-position: 0 -21px; }

.count-badge {
  bottom: unset;
  color: #fff;
  display: block;
  font-size: 11.63px;
  height: 23px;
  left: unset;
  line-height: 22px;
  position: absolute;
  text-align: center;
  width: 23px; }
  .count-badge::before {
    background-color: black;
    border-radius: 3px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg); }

.header-cart-2 {
  background-color: #fff;
  font-size: 12px;
  min-width: 300px;
  position: absolute;
  right: 0;
  top: 100%;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: left top 0;
  -moz-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
  -o-transform-origin: left top 0;
  transform-origin: left top 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .header-cart-2 .cart-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 0 19px;
    text-align: center; }
  .header-cart-2 .cart-product {
    padding: 0 60px 30px 30px; }
  .header-cart-2 .remove-product {
    background: blue;
    height: 20px;
    opacity: 0.3;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor:pointer;
    width: 20px; }
    .header-cart-2 .remove-product:hover {
      opacity: 0.8; }
  .header-cart-2 .desc h6, .header-cart-2 .desc .price {
    font-size: 12px; }
  .header-cart-2 .product-total {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 12px;
    line-height: 39px; }
    .header-cart-2 .product-total span:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.1); }
  .header-cart-2 .actions {
    padding-bottom: 30px;
    padding-top: 12px; }
    .header-cart-2 .actions span {
      display: block;
      letter-spacing: 2.1px; }
    .header-cart-2 .actions .btn {
      font-size: 10px;
      font-weight: 300;
      letter-spacing: 2.1px;
      margin-top: 13px;
      text-transform: uppercase; }

.top-cart:hover .header-cart-2 {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1); }

.style15 .site-logo {
  height: 79px;
  line-height: 79px; }
  .style15 .site-logo img {
    height: 40px; }
.style15 .menu-list > li > a {
  line-height: 79px; }

.header-megazine {
  background-color: #fff;
  position: relative; }
  .header-megazine .header-top {
    padding: 15px 0; }
  .header-megazine .site-logo {
    display: block; }
  .header-megazine .header-add {
    display: block; }
  .header-megazine .header-bottom {
    background-color: #fff;
    position: relative;
    z-index: 10; }
    .header-megazine .header-bottom .site-logo img {
      height: 30px; }
  .header-megazine .primary-nav {
    background-color: #fff;
    position: relative;
    z-index: 10;
    margin-left: -83px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .header-megazine .search-box input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 30px 0 0 30px;
    font-size: 13px;
    font-style: italic;
    height: 40px;
    margin: 0;
    max-width: 100%;
    padding: 0 0 0 25px;
    width: 350px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .header-megazine .search-box input:focus {
      border-color: #0D52FF; }
  .header-megazine .search-box button {
    background-color: #ccc;
    border: 0;
    border-radius: 0 30px 30px 0;
    color: #fff;
    height: 40px;
    padding: 0;
    width: 60px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .header-megazine .search-box button:hover, .header-megazine .search-box button:focus {
      background-color: #0D52FF; }
  .header-megazine.is-sticky {
    position: relative !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    animation: none !important; }
    .header-megazine.is-sticky .primary-nav {
      margin-left: 10px; }

.header-magazine-2 {
  background-color: #fff;
  position: relative; }
  .header-magazine-2 .header-top {
    background-color: #eeeeee;
    padding: 10px 0; }
  .header-magazine-2 .header-middle {
    padding: 35px 0; }
  .header-magazine-2 .header-bottom {
    background-color: #fff;
    position: relative;
    z-index: 10; }
    .header-magazine-2 .header-bottom .site-logo img {
      height: 30px; }
  .header-magazine-2 .primary-nav {
    background-color: #fff;
    position: relative;
    z-index: 10;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .header-magazine-2 .search-box input {
    background-color: #fff;
    border: 0;
    border-radius: 30px 0 0 30px;
    font-size: 13px;
    font-style: italic;
    height: 40px;
    margin: 0;
    max-width: 100%;
    padding: 0 0 0 25px;
    width: 250px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .header-magazine-2 .search-box input:focus {
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .header-magazine-2 .search-box button {
    background-color: #ccc;
    border: 0;
    border-radius: 0 30px 30px 0;
    color: #fff;
    height: 40px;
    padding: 0;
    width: 60px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .header-magazine-2 .search-box button:hover, .header-magazine-2 .search-box button:focus {
      background-color: #0D52FF; }
  .header-magazine-2.is-sticky {
    position: relative !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    animation: none !important; }
    .header-magazine-2.is-sticky .primary-nav {
      float: right; }

.header-interior .site-logo {
  line-height: 70px; }
  .header-interior .site-logo img {
    height: 33px; }
.header-interior .header-right .top-cart > a,
.header-interior .header-right .top-search > a {
  height: 70px;
  line-height: 70px; }
.header-interior .menu-list > li > a {
  line-height: 70px; }

.header-travel .site-logo img {
  height: 35px; }
.header-travel .search-box {
  float: right;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .header-travel .search-box input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 30px 0 0 30px;
    font-size: 13px;
    font-style: italic;
    height: 40px;
    margin: 0;
    max-width: 100%;
    padding: 0 0 0 25px;
    width: 190px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .header-travel .search-box input:focus {
      border-color: transparent;
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .header-travel .search-box button {
    background-color: #ccc;
    border: 0;
    border-radius: 0 30px 30px 0;
    color: #fff;
    height: 40px;
    padding: 0;
    width: 60px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .header-travel .search-box button:hover, .header-travel .search-box button:focus {
      background-color: #0D52FF; }

.header-seo {
  position: relative; }
  .header-seo .header-middle {
    padding: 25px 0; }
  .header-seo .site-logo {
    line-height: 60px; }
    .header-seo .site-logo.bottom {
      display: none; }
  .header-seo .primary-nav {
    background-color: #0D52FF;
    z-index: 9;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .header-seo .menu-list > li > a,
  .header-seo .right-nav > li > a {
    line-height: 60px; }
  .header-seo .is-sticky .primary-nav {
    margin-left: 20px; }
  .header-seo .is-sticky .site-logo.bottom {
    display: block; }
  .header-seo .right-nav > li > a {
    color: #fff;
    display: block;
    padding: 0 65px; }
    .header-seo .right-nav > li > a:hover {
      background-color: rgba(0, 0, 0, 0.15); }

.is-sticky .primary-bg .menu-list > li > a {
  color: #fff; }

.welcome-notice {
  line-height: 50px; }

.seo-top-nav li {
  display: inline-block;
  margin-left: -4px; }
  .seo-top-nav li a {
    color: #848484;
    display: block;
    line-height: 50px;
    padding: 0 20px; }
    .seo-top-nav li a:hover {
      background-color: rgba(0, 0, 0, 0.1); }
    .seo-top-nav li a i {
      margin-right: 5px; }

.seo-top-search {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  .seo-top-search .box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    float: left; }
    .seo-top-search .box .icon {
      background-color: #f7f7f7;
      cursor: pointer;
      font-size: 18px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      width: 40px;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
    .seo-top-search .box input {
      border: 0 none;
      height: 40px;
      margin: 0;
      outline: 1px solid rgba(0, 0, 0, 0.1);
      outline-offset: -1px;
      text-align: left;
      width: 0; }
    .seo-top-search .box:first-child .icon {
      border-radius: 30px 0 0 30px;
      width: 50px; }
    .seo-top-search .box:last-child .icon {
      border-radius: 0 30px 30px 0;
      width: 50px; }
    .seo-top-search .box:hover input {
      padding: 0 20px;
      width: 200px; }
    .seo-top-search .box:hover .icon {
      background-color: rgba(0, 0, 0, 0.1); }

.header-wedding {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  animation: none !important; }
  .header-wedding .header-top {
    margin-top: 50px; }
  .header-wedding .site-logo {
    display: none; }
    .header-wedding .site-logo img {
      height: 45px; }
  .header-wedding.is-sticky {
    position: relative; }
    .header-wedding.is-sticky .site-logo {
      display: block; }
      .header-wedding.is-sticky .site-logo img {
        height: 45px; }
    .header-wedding.is-sticky .primary-nav {
      float: right; }
  .header-wedding .is-sticky .header-bottom {
    background-color: #fff;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }

.header-sp::after {
  background-color: #0D52FF;
  bottom: 0px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
.header-sp .site-logo,
.header-sp .header-right div > a,
.header-sp .menu-list > li > a {
  height: 70px;
  line-height: 70px; }
.header-sp.is-sticky::after {
  bottom: 0;
  height: 1px; }
.header-sp.is-sticky .site-logo,
.header-sp.is-sticky .header-right div > a,
.header-sp.is-sticky .menu-list > li > a {
  height: 70px;
  line-height: 70px; }

.header-blog-3 .logo-shape {
  margin-left: auto;
  margin-right: auto;
  width: 175px; }
.header-blog-3 .blog-3-intro p {
  letter-spacing: 5px; }
.header-blog-3 .header-inner {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.header-shop-3 .header-bottom {
  padding-bottom: 25px;
  padding-top: 25px; }
.header-shop-3 .search-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px; }
  .header-shop-3 .search-box input {
    border: 0 none;
    height: 43px;
    margin: 0;
    padding-left: 30px; }
    .header-shop-3 .search-box input:focus {
      border-bottom: 0;
      box-shadow: none; }
.header-shop-3 .nice-select {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 158px;
  padding-left: 29px;
  padding-right: 29px; }
  .header-shop-3 .nice-select span {
    font-size: 13px;
    font-weight: 300; }
  .header-shop-3 .nice-select .list {
    border-radius: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    margin: 10px 0 0;
    width: 100%; }
  .header-shop-3 .nice-select .option,
  .header-shop-3 .nice-select .selected {
    font-weight: 300; }
  .header-shop-3 .nice-select .option {
    font-size: 12px;
    line-height: 30px;
    min-height: 30px; }
.header-shop-3 .search-sub {
  background-color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 0 30px 30px 0;
  color: #fff;
  height: 41px;
  margin-right: 1px;
  min-width: 44px;
  position: relative; }
  .header-shop-3 .search-sub::before {
    background: blue;
    content: "";
    height: 20px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 20px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
.header-shop-3 .header-action-btns > li {
  float: none;
  margin-right: 15px;
  padding-right: 15px;
  position: relative;
  width: auto; }
  .header-shop-3 .header-action-btns > li::after {
    background-color: rgba(0, 0, 0, 0.1);
    content: "";
    height: 30px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .header-shop-3 .header-action-btns > li:last-child {
    margin-right: 0;
    padding-right: 0; }
    .header-shop-3 .header-action-btns > li:last-child::after {
      content: none; }
  .header-shop-3 .header-action-btns > li > a {
    color: #848484;
    font-size: 20px; }
  .header-shop-3 .header-action-btns > li .et-line::before {
    display: block; }
.header-shop-3 .top-cart,
.header-shop-3 .nav-hamburger {
  height: auto; }
.header-shop-3 .nav-hamburger {
  margin-left: 0; }

.header-sports .site-logo::before {
  background-color: #0D52FF;
  bottom: -20px;
  content: "";
  left: -1110px;
  position: absolute;
  right: -50px;
  top: -40px;
  z-index: -1;
  -webkit-transform: skew(-33deg);
  -moz-transform: skew(-33deg);
  -ms-transform: skew(-33deg);
  -o-transform: skew(-33deg);
  transform: skew(-33deg);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
.header-sports .social-links li a {
  color: #999999;
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px; }
  .header-sports .social-links li a:hover {
    background-color: #666;
    color: #fff; }
.header-sports.is-sticky .site-logo::before {
  bottom: -10px; }

.is-sticky .is-sticky {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
.is-sticky .site-logo,
.is-sticky .menu-list > li > a,
.is-sticky .header-right > div > a,
.is-sticky .nav-hamburger {
  line-height: 65px;
  height: 65px; }
.is-sticky .menu-list > li > a {
  color: #848484; }
.is-sticky .top-search,
.is-sticky .top-cart {
  height: 65px; }
.is-sticky .site-logo img {
  height: 30px; }

.menu-dark .header-right div > a,
.menu-dark .menu-list > li > a {
  color: #848484; }

.more-vert {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  z-index: 10; }
  .more-vert > a {
    color: #848484;
    display: inline-block;
    font-size: 24px; }
  .more-vert:hover .more-vert-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1) translate(0px);
    -moz-transform: scale(1) translate(0px);
    -ms-transform: scale(1) translate(0px);
    -o-transform: scale(1) translate(0px);
    transform: scale(1) translate(0px); }

.more-vert-menu {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  min-width: 150px;
  opacity: 0;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 100%;
  -webkit-transform: scale(0.7) translate(20px, -50px);
  -moz-transform: scale(0.7) translate(20px, -50px);
  -ms-transform: scale(0.7) translate(20px, -50px);
  -o-transform: scale(0.7) translate(20px, -50px);
  transform: scale(0.7) translate(20px, -50px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25) 0s;
  -moz-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25) 0s;
  -o-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25) 0s;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25) 0s;
  visibility: hidden;
  z-index: 9; }
  .more-vert-menu > li > a {
    color: #848484;
    display: block;
    font-size: 13px;
    padding: 2px 0; }

.select-wrapper .caret {
  border: 0 none;
  height: auto;
  margin-left: 0;
  width: auto; }

.mini-cart-wrapper {
  background-color: #454545;
  position: absolute;
  right: 0;
  top: -webkit-calc(100% + 10px);
  top: calc(100% + 10px);
  z-index: 33;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: left top 0;
  -moz-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
  -o-transform-origin: left top 0;
  transform-origin: left top 0;
  -webkit-transition: all 0.5s cubic-bezier(0.5, 0, 0, 1.25) 0s;
  -moz-transition: all 0.5s cubic-bezier(0.5, 0, 0, 1.25) 0s;
  -o-transition: all 0.5s cubic-bezier(0.5, 0, 0, 1.25) 0s;
  transition: all 0.5s cubic-bezier(0.5, 0, 0, 1.25) 0s; }
  .mini-cart-wrapper::after {
    border-color: transparent transparent #454545;
    border-style: solid;
    border-width: 10px;
    content: "";
    position: absolute;
    right: 15px;
    top: -20px; }
  .mini-cart-wrapper td, .mini-cart-wrapper th {
    border-radius: 0; }
  .mini-cart-wrapper.light {
    background-color: #fff; }
    .mini-cart-wrapper.light::after {
      border-color: transparent transparent #fff; }
    .mini-cart-wrapper.light tbody tr td {
      color: #848484;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .mini-cart-wrapper.light tbody tr a {
      color: #848484; }

.header-cart-inner:hover .mini-cart-wrapper {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1); }

.mini-cart tbody tr {
  padding: 10px 0; }
  .mini-cart tbody tr td {
    color: rgba(255, 255, 255, 0.87);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 13px;
    font-weight: 400;
    padding: 18px 0; }
  .mini-cart tbody tr:last-child td {
    border-bottom: 0; }
  .mini-cart tbody tr a {
    color: rgba(255, 255, 255, 0.87); }
.mini-cart .product-thumb {
  padding-right: 18px;
  padding-left: 20px; }
.mini-cart .product-name {
  padding-right: 50px; }
.mini-cart .product-count {
  padding-right: 35px; }
.mini-cart .product-price {
  padding-right: 20px; }
.mini-cart .product-remove {
  padding-right: 30px; }
  .mini-cart .product-remove a:hover {
    color: #d60909; }
  .mini-cart .product-remove i {
    font-size: 14px;
    line-height: inherit; }
.mini-cart .total {
  background-color: #0D52FF; }
.mini-cart tfoot tr {
  color: #fff;
  font-size: 16px;
  font-weight: 500; }
  .mini-cart tfoot tr td {
    padding: 20px 30px 20px 0; }
.mini-cart .buttons {
  padding: 25px 0; }

/*----------------------------------------
    Logo
------------------------------------------*/
.site-logo {
  float: left;
  line-height: 95px;
  position: relative;
  z-index: 9; }
  .site-logo img {
    height: 53px;
    -webkit-transition: height 0.3s ease 0s;
    -moz-transition: height 0.3s ease 0s;
    -o-transition: height 0.3s ease 0s;
    transition: height 0.3s ease 0s; }
  .site-logo .logo2 {
    display: none; }

/*==============================================
    Sticky Header
===============================================*/
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }
.is-sticky {
  background-color: #fff; }
  .is-sticky .site-logo .logo1 {
    display: none; }
  .is-sticky .site-logo .logo2 {
    display: inline-block; }
  .is-sticky .site-logo,
  .is-sticky .header-right > div:not(.active) > a {
    color: #848484; }
  .is-sticky .nav-hamburger span, .is-sticky .nav-hamburger span::before, .is-sticky .nav-hamburger span::after {
    background-color: #848484; }

/*----------------------------------------
    Nav Toggle
------------------------------------------*/
.nav-hamburger {
  background-color: transparent;
  border: medium none;
  float: right;
  height: 95px;
  margin-left: 30px;
  padding: 0;
  position: relative;
  text-indent: -9999px;
  width: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .nav-hamburger:focus {
    background-color: transparent; }
  .nav-hamburger span {
    background-color: #fff;
    display: block;
    height: 2px;
    left: 0;
    margin-top: -1px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transition: background 0.3s ease 0s;
    -moz-transition: background 0.3s ease 0s;
    -o-transition: background 0.3s ease 0s;
    transition: background 0.3s ease 0s; }
    .nav-hamburger span:before, .nav-hamburger span:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      content: "";
      -webkit-transition-duration: 0.3s, 0.3s;
      -moz-transition-duration: 0.3s, 0.3s;
      -o-transition-duration: 0.3s, 0.3s;
      transition-duration: 0.3s, 0.3s;
      -webkit-transition-delay: 0.3s, 0s;
      -moz-transition-delay: 0.3s, 0s;
      -o-transition-delay: 0.3s, 0s;
      transition-delay: 0.3s, 0s; }
    .nav-hamburger span:before {
      top: -7px;
      -webkit-transition-property: top, transform;
      -moz-transition-property: top, transform;
      -o-transition-property: top, transform;
      transition-property: top, transform; }
    .nav-hamburger span:after {
      bottom: -7px;
      -webkit-transition-property: bottom, transform;
      -moz-transition-property: bottom, transform;
      -o-transition-property: bottom, transform;
      transition-property: bottom, transform; }
  .nav-hamburger.dark span {
    background-color: #848484 !important; }
    .nav-hamburger.dark span::before, .nav-hamburger.dark span::after {
      background-color: #848484 !important; }
  .nav-hamburger.dark.active span {
    background-color: transparent !important; }
  .nav-hamburger.active span {
    background-color: transparent; }
    .nav-hamburger.active span:before, .nav-hamburger.active span:after {
      -webkit-transition-delay: 0s, 0.3s;
      -moz-transition-delay: 0s, 0.3s;
      -o-transition-delay: 0s, 0.3s;
      transition-delay: 0s, 0.3s; }
    .nav-hamburger.active span:before {
      top: 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
    .nav-hamburger.active span:after {
      bottom: 0;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg); }

/*----------------------------------------
    Primary Nav 
------------------------------------------*/
.login-box-inline {
  margin: 0px;
  line-height: 20px;
}
.primary-nav {
  justify-self: flex-end;
  position: static !important; }
  .primary-nav .active {
    background-color: transparent; }
  .primary-nav li {
    position: relative; }
    .primary-nav li a {
      display: block;
      font-weight: 400;
      position: relative; }
  .primary-nav.center {
    float: none; }
    .primary-nav.center .menu-list > li {
      float: none;
      display: inline-block; }

.menu-list > li {
  float: left;
  position: relative; }
  .menu-list > li > a {
    font-size: 14px;
    line-height: 100px;
    padding: 0 15px;
    position: relative;
    text-transform: uppercase; }
.menu-list li.megamenu {
  position: static;
  line-height: 100px;
  padding: 0 0px; 
}

/*----------------- DropDown ---------------*/
.menu-list .sub-menu {
  background-color: #303030;
  left: 0;
  min-width: 190px;
  opacity: 0;
  position: absolute;
  text-align: left;
  top: 100%;
  visibility: hidden;
  white-space: nowrap;
  width: 100%;
  z-index: 12;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s; }
  .menu-list .sub-menu > li {
    float: none; }
    .menu-list .sub-menu > li > a {
      line-height: inherit;
      padding: 7.5px 25px 7.5px 22px; }
  .menu-list .sub-menu .sub-menu {
    top: 0;
    left: 100%; }

.has-dropdown:hover > .sub-menu {
  opacity: 1;
  visibility: visible; 
  z-index: 10000;
}
.has-dropdown .dropdown-holder {
  display: none; }

.sub-menu > li .arrow {
  font-size: 16px;
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.sub-menu.megamenu-wrapper {
  min-width: auto;
  padding: 30px;
  text-transform: uppercase; }
  .sub-menu.megamenu-wrapper > li {
    width: 100%; }
    .sub-menu.megamenu-wrapper > li a {
      font-size: 11px; }
      .sub-menu.megamenu-wrapper > li a:focus {
        color: #fff;
        opacity: 0.6; }

.megamenu-child {
  font-size: 11px;
  min-width: 180px; 
}
.megamenu-child li{
  line-height: 25px;
  font-weight: bold;
}
.megamenu-child .megamenu-title {
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 10px;
  padding: 3px 15px;
  position: relative; 
  padding-left: 0px;
}
    .megamenu-child .megamenu-title::before {
      background-color: #fff;
      bottom: 0;
      content: "";
      height: 1px;
      left: 15px;
      opacity: 0.25;
      position: absolute;
      width: 30px; }
  .megamenu-child li a {
    left: 0;
    opacity: 0.6;
    padding: 3px 15px;
    position: relative; }
    .megamenu-child li a:hover {
      color: #fff;
      opacity: 1;
      left: 5px; }

.submenu-bg-image {

  position: relative; }
  .submenu-bg-image::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }
  .submenu-bg-image.dark::before {
    background-color: rgba(0, 0, 0, 0.9); }
  .submenu-bg-image.light::before {
    background-color: rgba(255, 255, 255, 0.9); }
  .submenu-bg-image.light li a {
    color: #333;
    opacity: 0.6; }
    .submenu-bg-image.light li a:hover {
      opacity: 1; }
  .submenu-bg-image.light .megamenu-title::before {
    background-color: #000; }

/*----------------- Menu Hover ---------------*/
.nav-hover-1 {
  /*
  .sub-menu {
      li {
          a {
              font-size: 13px;
          }
          &:hover > {
              a {
                  background-color: $primary-color;
                  color: $light;
              }
          }
      }
  }*/ }
  .nav-hover-1 > li > a {
    font-size: 12px; }
  .nav-hover-1 > li:hover > a, .nav-hover-1 > li:focus > a {
    background-color: #303030;
    color: #fff; }

.nav-hover-2 {
  /*
  .sub-menu {
      top: -webkit-calc(100% + 3px);
      top: calc(100% + 3px);
      li {
          a {
              font-size: 11px;
          }
          &:hover > {
              a {
                  background-color: $primary-color;
                  color: $light;
              }
          }
      }
  }*/ }
  .nav-hover-2 > li > a {
    font-size: 12px; }
    .nav-hover-2 > li > a::before, .nav-hover-2 > li > a::after {
      height: 3px;
      position: absolute;
      left: 0;
      width: 0;
      content: "";
      z-index: 1;
      -webkit-transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      -moz-transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      -o-transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s; }
    .nav-hover-2 > li > a::before {
      top: -3px; }
    .nav-hover-2 > li > a::after {
      bottom: -3px;
      left: auto;
      right: 0; }
  .nav-hover-2 > li:hover > a, .nav-hover-2 > li:focus > a {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff; }
    .nav-hover-2 > li:hover > a::before, .nav-hover-2 > li:hover > a::after, .nav-hover-2 > li:focus > a::before, .nav-hover-2 > li:focus > a::after {
      background-color: #0D52FF;
      width: 100%; }

.nav-hover-3 > li > a {
  position: relative; }
  .nav-hover-3 > li > a::after {
    background-color: #0D52FF;
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 3px;
    -webkit-transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -moz-transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -o-transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }
.nav-hover-3 > li:hover a::after, .nav-hover-3 > li.active a::after {
  width: 100%; }
.nav-hover-3 > li .mouseout::after {
  left: auto;
  right: 0; }

.nav-hover-4 > li > a {
  font-size: 12px; }
  .nav-hover-4 > li > a::after {
    background-color: transparent;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 10px;
    z-index: -1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
.nav-hover-4 > li:hover > a, .nav-hover-4 > li.active > a {
  color: #fff; }
  .nav-hover-4 > li:hover > a::after, .nav-hover-4 > li.active > a::after {
    background-color: #0D52FF; }

/*----------------------------------------
    Header right
------------------------------------------*/
.header-right {
  float: right;
  position: relative; }
  .header-right .devider {
    background-color: #fff;
    height: 25px;
    left: 0;
    margin-top: -12.5px;
    position: absolute;
    top: 50%;
    width: 1px; }
  .header-right .top-cart,
  .header-right .top-search {
    float: left; }
  .header-right .top-cart {
    position: relative; }
  .header-right .top-cart > a,
  .header-right .top-search > a {
    color: #fff;
    display: block;
    height: 95px;
    font-size: 24px;
    line-height: 95px;
    padding: 0 15px; }
  .header-right .top-search > a {
    padding-right: 0;
    position: relative;
    z-index: 3; }

.search-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  opacity: 0;
  top: 0;
  visibility: hidden;
  z-index: 200;
  -webkit-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -moz-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -o-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
  .search-wrapper::before {
    background-color: rgba(35, 35, 40, 0.8);
    border-bottom-left-radius: 100px;
    content: "";
    height: 100px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: right top 0;
    -moz-transform-origin: right top 0;
    -ms-transform-origin: right top 0;
    -o-transform-origin: right top 0;
    transform-origin: right top 0;
    -webkit-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    -moz-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    -o-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
  .search-wrapper.active {
    opacity: 1;
    visibility: visible; }
    .search-wrapper.active::before {
      -webkit-transform: scale(40);
      -moz-transform: scale(40);
      -ms-transform: scale(40);
      -o-transform: scale(40);
      transform: scale(40); }
  .search-wrapper .close-search {
    position: absolute;
    right: 50px;
    top: 50px;
    color: #fff;
    font-size: 25px; }
  .search-wrapper .search-popup {
    left: 45%;
    max-width: 1170px;
    padding: 0 15px;
    position: absolute;
    top: 50%;
    width: 80%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .search-wrapper .search-popup input {
      border-bottom-color: rgba(255, 255, 255, 0.2);
      color: #fff;
      font-size: 38px;
      height: 60px;
      margin: 0;
      padding: 0; }
      .search-wrapper .search-popup input:focus {
        background-color: transparent;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        color: #fff; }
        .search-wrapper .search-popup input:focus + label {
          color: #fff; }
    .search-wrapper .search-popup label {
      color: #fff;
      font-size: 38px;
      font-weight: 400;
      line-height: 38px;
      margin: 0;
      text-transform: none; }
      .search-wrapper .search-popup label.active {
        font-size: 20px;
        left: 0; }
    .search-wrapper .search-popup button {
      background-color: transparent;
      border: 0 none;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      line-height: 60px;
      padding: 0;
      position: absolute;
      right: -80px;
      text-align: center;
      top: 0;
      width: 40px; 
}

/*==============================================
    Side Nav
===============================================*/
.side-nav-wrap {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 240px;
  z-index: 50;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }
  .side-nav-wrap.opened {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px); }
    .side-nav-wrap.opened + .sidenav-overlay {
      opacity: 1;
      position: fixed;
      visibility: visible; }
  .side-nav-wrap .user-view {
    background: #fff;
    padding: 30px 30px 20px; }
    .side-nav-wrap .user-view.logo-only {
      padding: 60px 30px; }
    .side-nav-wrap .user-view a {
      border: 0 none; }
  .side-nav-wrap .logo-only img {
    height: 53px; }
  .side-nav-wrap.primary {
    background-color: #0D52FF; }
    .side-nav-wrap.primary .user-view {
      background-color: #0D52FF; }
    .side-nav-wrap.primary a {
      color: #fff; }

.sidenav-overlay {
  opacity: 0;
  visibility: hidden;
  z-index: 40;
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

.toggle-side-nav {
  background-color: #0D52FF;
  border-radius: 0 30px 30px 0;
  color: #fff;
  font-size: 30px;
  height: 50px;
  left: 240px;
  line-height: 50px;
  width: 57px;
  position: absolute;
  text-align: center;
  top: 60px;
  z-index: 1000; }
  .toggle-side-nav .bar {
    background-color: #fff;
    height: 3px;
    left: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 22px; }
    .toggle-side-nav .bar::before, .toggle-side-nav .bar::after {
      background-color: #fff;
      content: "";
      height: 3px;
      left: 0;
      position: absolute;
      top: -7px;
      width: 100%; }
    .toggle-side-nav .bar::after {
      top: auto;
      bottom: -7px; }
  .toggle-side-nav.active {
    background-color: #425963;
    border-radius: 30px 0 0 30px;
    left: -webkit-calc(100% - 55px);
    left: -moz-calc(100% - 55px);
    left: calc(100% - 55px); }
    .toggle-side-nav.active span {
      left: 17px;
      right: 13px; }

.nav-content {
  max-height: 500px;
  overflow: auto; }
  .nav-content .megamenu-title {
    display: none; }
  .nav-content li a {
    display: block;
    font-size: 12px;
    font-weight: 400;
    height: auto;
    position: relative;
    text-transform: uppercase; }
  .nav-content > li > a {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    line-height: 45px;
    padding: 0 30px; }
  .nav-content.primary li:hover > a, .nav-content.primary li.active > a {
    background-color: #fff;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.14);
    color: #0D52FF; }
  .nav-content .sub-menu {
    display: none; }
    .nav-content .sub-menu li li a {
      padding-left: 25px; }

.c-hamburger {
  overflow: hidden;
  padding: 0;
  width: 55px;
  height: 50px;
  text-indent: -9999px;
  appearance: none;
  border: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .c-hamburger span {
    display: block;
    position: absolute;
    top: 50%;
    left: 13px;
    right: 17px;
    height: 2px;
    background-color: #fff;
    -webkit-transition: background 0s 0.3s;
    -moz-transition: background 0s 0.3s;
    -o-transition: background 0s 0.3s;
    transition: background 0s 0.3s;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    .c-hamburger span::before, .c-hamburger span::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      content: "";
      -webkit-transition-duration: 0.3s, 0.3s;
      -moz-transition-duration: 0.3s, 0.3s;
      -o-transition-duration: 0.3s, 0.3s;
      transition-duration: 0.3s, 0.3s;
      -webkit-transition-delay: 0.3s, 0s;
      -moz-transition-delay: 0.3s, 0s;
      -o-transition-delay: 0.3s, 0s;
      transition-delay: 0.3s, 0s; }
    .c-hamburger span::before {
      top: -7px;
      -webkit-transition-property: top, transform;
      -moz-transition-property: top, transform;
      -o-transition-property: top, transform;
      transition-property: top, transform; }
    .c-hamburger span::after {
      bottom: -7px;
      -webkit-transition-property: bottom, transform;
      -moz-transition-property: bottom, transform;
      -o-transition-property: bottom, transform;
      transition-property: bottom, transform; }
  .c-hamburger.active span {
    background-color: transparent; }
    .c-hamburger.active span::before, .c-hamburger.active span::after {
      transition-delay: 0s, 0.3s; }
    .c-hamburger.active span::before {
      top: 0;
      transform: rotate(45deg); }
    .c-hamburger.active span::after {
      bottom: 0;
      transform: rotate(-45deg); }

.toggle-detault {
  background-color: transparent;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  float: right;
  margin-bottom: 8px;
  margin-right: 15px;
  margin-top: 8px;
  padding: 9px 10px;
  position: relative; }
  .toggle-detault .icon-bar {
    background-color: #848484;
    border-radius: 1px;
    display: block;
    height: 2px;
    width: 22px; }

.navbar-form.navbar-right {
  padding-right: 0; }
.navbar-form input {
  margin-bottom: 0; }

.new-color {
  background: #0D52FF none repeat scroll 0 0;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  padding: 2px 7px;
  line-height: 1.2em;
  text-transform: capitalize;
  font-size: 10px;
  margin-left: 5px; }

/*==============================================
    Section Title
===============================================*/
.section-title {
  margin-bottom: 100px;
  position: relative; }
  .section-title.m-half {
    margin-bottom: 65px; }
  .section-title.no-margin {
    margin-bottom: 0; }
  .section-title h2 {
    font-weight: 100; }
  .section-title .icon {
    font-size: 60px;
    margin-top: 30px; }
  .section-title .sub {
    margin-top: 60px; }
    .section-title .sub + p {
      font-size: 18px; }
  .section-title p {
    color: rgba(0, 0, 0, 0.38);
    font-size: 16px;
    margin: 33px 0 0;
    line-height: 28px; }
  .section-title + hr {
    margin: 0; }
  .section-title .shade-text {
    color: #000;
    font-size: 200px;
    font-weight: 700;
    left: 50%;
    line-height: 0.8;
    opacity: 0.1;
    position: absolute;
    top: 0;
    text-transform: uppercase;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
    .section-title .shade-text + h2 {
      padding: 107px 0 0; }
    .section-title .shade-text.fruits + h2 {
      margin: 60px 0 0; }
  .section-title.style2 {
    padding: 40px 0 50px;
    margin-bottom: 0;
    position: relative; }
    .section-title.style2 h3 {
      font-size: 24px;
      color: #fff;
      margin-bottom: 15px; }
    .section-title.style2 p {
      color: rgba(255, 255, 255, 0.87);
      font-size: 16px; }
    .section-title.style2 .toggle-section-btn {
      bottom: 0;
      left: 50%;
      position: absolute;
      -webkit-transform: translate(-50%, 50%);
      -moz-transform: translate(-50%, 50%);
      -ms-transform: translate(-50%, 50%);
      -o-transform: translate(-50%, 50%);
      transform: translate(-50%, 50%); }
      .section-title.style2 .toggle-section-btn i {
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s; }
      .section-title.style2 .toggle-section-btn.active i {
        -webkit-transform: rotate(180deg) translateY(-4px);
        -moz-transform: rotate(180deg) translateY(-4px);
        -ms-transform: rotate(180deg) translateY(-4px);
        -o-transform: rotate(180deg) translateY(-4px);
        transform: rotate(180deg) translateY(-4px); }
  .section-title.style3 h2 {
    color: #444;
    padding-bottom: 13px; }
  .section-title.style3 hr {
    border-color: rgba(0, 0, 0, 0.1);
    margin: 0; }
  .section-title.style3 p {
    color: #444;
    font-size: 14px;
    line-height: 22px;
    margin-top: 18px; }
  .section-title.style4 h2 {
    font-size: 36px;
    letter-spacing: 2.8px; }
  .section-title.style4 p {
    font-size: 14px; }
  .section-title.style4 .sep {
    background-color: #444;
    height: 3px;
    margin: 25px auto 0;
    width: 100px; }
    .section-title.style4 .sep.left {
      margin-left: 0;
      float: none !important; }
    .section-title.style4 .sep.right {
      margin-right: 0;
      float: none !important; }
  .section-title.style5 p {
    font-size: 18px;
    margin-top: 0; }
  .section-title.style6 h2 {
    font-size: 36px; }
    .section-title.style6 h2 + p {
      font-size: 16px; }
  .section-title.style6 p {
    font-size: 18px;
    margin-top: 0; }

  .section-title.style8 .shade {
    color: rgba(0, 0, 0, 0.1);
    display: inline-block;
    font-size: 100px;
    line-height: .8;
    white-space: nowrap; }
  .section-title.style8 .title-color {
    font-size: 24px;
    left: 50%;
    position: absolute;
    top: 50%;
    white-space: nowrap;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .section-title.style8 .title-color.right {
      left: auto;
      right: 0;
      -webkit-transform: translate(0px, -50%);
      -moz-transform: translate(0px, -50%);
      -ms-transform: translate(0px, -50%);
      -o-transform: translate(0px, -50%);
      transform: translate(0px, -50%); }
  .section-title.style9 h4::before, .section-title.style9 h4::after {
    background-color: rgba(0, 0, 0, 0.38);
    content: '';
    height: 1px;
    position: absolute;
    width: 50px; }
  .section-title.style9 h4::before {
    top: -10px;
    left: 0; }
  .section-title.style9 h4::after {
    bottom: -9px;
    right: 0; }
  .section-title.white {
    background-color: transparent !important; }
    .section-title.white .icon, .section-title.white h2 {
      color: #fff; }
    .section-title.white p {
      color: rgba(255, 255, 255, 0.87); }
    .section-title.white .sep {
      background-color: #fff; }

.sub-title {
  margin-bottom: 60px; }
  .sub-title h2 {
    font-size: 32px; }
  .sub-title.style2 {
    font-family: "Poppins", sans-serif; }
    .sub-title.style2 h2 {
      font-weight: 300; }

.heading-s3 {
  background-color: #f7f7f7;
  padding: 18px 30px 19px 30px; }

/*----------------------------------------
    Slider Controls
------------------------------------------*/
.owl-controls-custom {
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0;
  padding: 30px 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10; }
  .owl-controls-custom .owl-nav {
    left: 0;
    position: absolute;
    right: 0; }
  .owl-controls-custom .owl-prev, .owl-controls-custom .owl-next {
    color: #0D52FF;
    cursor: pointer;
    font-size: 36px;
    vertical-align: middle; }
  .owl-controls-custom .owl-prev {
    float: left;
    margin-left: 21px; }
  .owl-controls-custom .owl-next {
    float: right;
    margin-right: 21px; }
  .owl-controls-custom .owl-dot {
    background-color: rgba(48, 48, 48, 0.54);
    cursor: pointer;
    width: 12px;
    height: 12px;
    position: relative;
    z-index: 10; }
    .owl-controls-custom .owl-dot.active {
      background-color: #0D52FF; }

/*----------------------------------------
    Slider Control
------------------------------------------*/
/*----------- Navigation One ----------------*/
.ctrl-nav-one .owl-prev,
.ctrl-nav-one .owl-next {
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
.ctrl-nav-one .owl-next {
  left: auto;
  right: 50px; }
.ctrl-nav-one:hover .owl-nav > div {
  opacity: 1; }

/*----------- Navigation One ----------------*/
.ctrl-nav-two .owl-nav {
  text-align: center;
  margin-top: 50px; }
.ctrl-nav-two .owl-next, .ctrl-nav-two .owl-prev {
  background-color: #fff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  color: #0D52FF;
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 0 6px;
  line-height: 48px;
  font-size: 20px; }

/*----------- Navigation five ----------------*/
.ctrl-nav-3 .owl-nav {
  display: block;
  text-align: center;
  margin-top: 50px; }
.ctrl-nav-3 .owl-next, .ctrl-nav-3 .owl-prev {
  background-color: #fff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  color: #0D52FF;
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 0 6px;
  line-height: 48px;
  font-size: 20px; }

/*----------- Navigation Four ----------------*/
.ctrl-nav-4 .owl-nav div {
  height: 32px;
  position: absolute;
  top: 50%;
  width: 32px; }
.ctrl-nav-4 .owl-prev {
  left: -75px;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg); }
  .ctrl-nav-4 .owl-prev::before {
    background-color: red;
    content: "";
    height: 32px;
    position: absolute;
    width: 2px; }
  .ctrl-nav-4 .owl-prev::after {
    background-color: red;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 32px; }
.ctrl-nav-4 .owl-next {
  right: -75px;
  -webkit-transform: translateY(-50%) rotate(-45deg);
  -moz-transform: translateY(-50%) rotate(-45deg);
  -ms-transform: translateY(-50%) rotate(-45deg);
  -o-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg); }
  .ctrl-nav-4 .owl-next::before {
    background-color: red;
    content: "";
    height: 32px;
    position: absolute;
    right: 0;
    top: 0;
    width: 2px; }
  .ctrl-nav-4 .owl-next::after {
    background-color: red;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 32px; }

/*----------- Navigation five ----------------*/
.ctrl-nav-5 .owl-next, .ctrl-nav-5 .owl-prev {
  background-color: #fff;
  font-size: 30px;
  position: absolute;
  left: 0;
  height: 40px;
  line-height: 40px;
  width: 60px;
  text-align: center;
  top: -webkit-calc(50% - 98px);
  top: -moz-calc(50% - 98px);
  top: calc(50% - 98px); }
  .ctrl-nav-5 .owl-next:hover, .ctrl-nav-5 .owl-prev:hover {
    color: #0D52FF; }
.ctrl-nav-5 .owl-next {
  left: auto;
  right: 0; }

/*----------- Navigation five ----------------*/
.ctrl-nav-6 .owl-prev,
.ctrl-nav-6 .owl-next {
  border-radius: 50px;
  height: 48px;
  line-height: 48px;
  min-width: 110px;
  position: absolute;
  top: 50%;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
.ctrl-nav-6 .text {
  background-color: #fff;
  font-size: 12px;
  height: 48px;
  letter-spacing: 3px;
  line-height: 46px;
  overflow: hidden;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 0;
  z-index: -1; }
.ctrl-nav-6 .icon {
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  display: block;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  top: 0;
  width: 48px; }
.ctrl-nav-6 .owl-prev {
  left: 30px;
  text-align: right; }
  .ctrl-nav-6 .owl-prev .text {
    border-radius: 0 50px 50px 0;
    left: 24px; }
  .ctrl-nav-6 .owl-prev:hover .text {
    padding-right: 30px;
    width: 100%; }
.ctrl-nav-6 .owl-next {
  right: 30px;
  text-align: left; }
  .ctrl-nav-6 .owl-next .text {
    border-radius: 50px 0 0 50px;
    right: 24px; }
  .ctrl-nav-6 .owl-next .icon {
    float: right; }
  .ctrl-nav-6 .owl-next:hover .text {
    padding-left: 30px;
    width: 100%; }
.ctrl-nav-6:hover .owl-prev,
.ctrl-nav-6:hover .owl-next {
  opacity: 1; }

/*----------- Bullet One ----------------*/
.bullet-one .owl-dots {
  text-align: center; }
.bullet-one .owl-dot {
  background-color: #eeeeee;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  margin: 0 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .bullet-one .owl-dot.active {
    background-color: #0D52FF; }

/*----------- Bullet One ----------------*/
.bullet-two .owl-dots {
  margin-top: 60px;
  text-align: center; }
.bullet-two.bull-left .owl-dots {
  text-align: left; }
.bullet-two .owl-dot {
  border: 2px solid #fff;
  border-radius: 50px;
  display: inline-block;
  height: 20px;
  line-height: 17px;
  margin: 0 2.5px;
  position: relative;
  width: 20px; }
  .bullet-two .owl-dot span {
    border-radius: 50px;
    display: inline-block;
    height: 10px;
    margin: 0 auto;
    width: 10px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-200%, -50%);
    -moz-transform: translate(-200%, -50%);
    -ms-transform: translate(-200%, -50%);
    -o-transform: translate(-200%, -50%);
    transform: translate(-200%, -50%);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .bullet-two .owl-dot.active span {
    background-color: #fff;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
.bullet-two .slick-dots li {
  border: 2px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 21px;
  line-height: 1;
  margin: 0 2px;
  width: 21px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
.bullet-two .slick-dots .slick-active {
  border-color: #0D52FF; }
  .bullet-two .slick-dots .slick-active button {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px); }
.bullet-two .slick-dots button {
  background-color: #0D52FF;
  border: 0 none;
  border-radius: 15px;
  height: 13px;
  margin: 2px 0 0 2px;
  opacity: 0;
  padding: 0;
  text-indent: -9999em;
  width: 13px;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
.bullet-two.light .slick-dots li {
  border-color: #fff; }
.bullet-two.light .slick-dots button {
  background-color: #fff; }
.bullet-two.dark .owl-dot {
  border-color: rgba(0, 0, 0, 0.38); }
  .bullet-two.dark .owl-dot.active {
    border-color: rgba(0, 0, 0, 0.87); }
    .bullet-two.dark .owl-dot.active span {
      background-color: rgba(0, 0, 0, 0.87); }
.bullet-two.dark .slick-dots li {
  border-color: #848484; }
  .bullet-two.dark .slick-dots li button {
    background-color: #848484; }
.bullet-two.dark .slick-dots .slick-active {
  border-color: #444; }
  .bullet-two.dark .slick-dots .slick-active button {
    background-color: #444; }

.bullet-vertical .slick-dots li {
  display: block;
  margin: 5px 0; }
  .bullet-vertical .slick-dots li button {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%); }
.bullet-vertical .owl-dots {
  bottom: auto;
  left: auto;
  margin: 0;
  position: absolute;
  right: 5%;
  top: 50%;
  z-index: 60;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
.bullet-vertical .owl-dot {
  display: block;
  margin: 5px 0; }
  .bullet-vertical .owl-dot span {
    -webkit-transform: translate(-50%, -200%);
    -moz-transform: translate(-50%, -200%);
    -ms-transform: translate(-50%, -200%);
    -o-transform: translate(-50%, -200%);
    transform: translate(-50%, -200%); }

.bullet-out-50 .owl-prev {
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
.bullet-out-50 .owl-next {
  right: 0;
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
  transform: translate(50%, -50%); }

.p-v-10 .owl-stage-outer {
  padding-bottom: 10px;
  padding-top: 10px; }

/*----------- Bullet Three ----------------*/
.bullet-3 .owl-dots {
  bottom: 23px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center; }
.bullet-3 .owl-dot {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: inline-block;
  height: 8px;
  margin: 0 5px;
  width: 8px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .bullet-3 .owl-dot.active {
    background-color: #444;
    height: 18px; }

/*----------- Bullet four ----------------*/
.bullet-4 .owl-dots {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%); }
.bullet-4 .owl-dot {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: block;
  height: 8px;
  margin: 5px 0;
  width: 8px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .bullet-4 .owl-dot.active {
    background-color: #444;
    width: 18px; }

/*==============================================
    Slider
===============================================*/
.slider-section .item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.slide-content img.logo {
  margin: 0 auto 130px;
  width: auto; }

.banner-content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.creative-banner .slide-content h2,
.business-banner .slide-content h2 {
  color: #444;
  font-size: 60px;
  font-weight: 300;
  line-height: 1; }

/*----------------------------------------
    slider1
------------------------------------------*/
.slider1 .slide-content {
  color: #fff; }
  .slider1 .slide-content h1 {
    color: #fff;
    font-weight: 700;
    margin-bottom: 15px;
    text-shadow: 2px 3.464px 4px rgba(0, 0, 0, 0.7); }
  .slider1 .slide-content p {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 36px;
    font-weight: 100;
    text-shadow: 1px 1.732px 2px rgba(0, 0, 0, 0.7); }
.slider1 .owl-dots {
  display: none !important; }

/*----------------------------------------
    slider2
------------------------------------------*/
.slider2 .slide-content {
  color: #fff;
  position: relative; }
  .slider2 .slide-content h1 {
    color: #fff;
    font-size: 60px;
    font-weight: 900;
    line-height: 80px;
    margin-bottom: 30px; }
  .slider2 .slide-content h2 {
    color: #fff;
    font-size: 56px; }
  .slider2 .slide-content p {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    margin-bottom: 30px; }
  .slider2 .slide-content img.logo {
    margin-bottom: 50px; }
  .slider2 .slide-content a:last-child {
    margin-left: 20px; }
.slider2 .owl-dots {
  display: none !important; }

/*----------------------------------------
    banner3
------------------------------------------*/
.banner3 .slide-content {
  position: relative; }
  .banner3 .slide-content h1, .banner3 .slide-content h2, .banner3 .slide-content p {
    color: #fff; }
  .banner3 .slide-content h2 {
    font-size: 24px;
    font-weight: 900; }
  .banner3 .slide-content h1 {
    font-size: 60px;
    font-weight: 900;
    line-height: 70px;
    margin-top: 15px; }
  .banner3 .slide-content p {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.7px; }
.banner3 .owl-dots {
  display: none !important; }

/*----------------------------------------
    slider4
------------------------------------------*/
.slider4 {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }
  .slider4 .slide-content h2 {
    line-height: 1.2; }

/*----------------------------------------
    Magazine slider-1 / home-magazine
------------------------------------------*/
.mag-slider-1 .item {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }
.mag-slider-1 .container {
  position: relative;
  height: 100vh; }
.mag-slider-1 .owl-dots {
  bottom: 70px;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0; }

.mag-slide-content {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  .mag-slide-content h2 {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 35px; }
  .mag-slide-content .sep {
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    height: 1px;
    position: relative;
    margin-bottom: 37px;
    z-index: 0; }
    .mag-slide-content .sep::before {
      background-color: #0D52FF;
      content: '';
      width: 110px;
      height: 3px;
      position: absolute;
      left: 50%;
      top: -1px;
      z-index: 1;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%); }
  .mag-slide-content p {
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 45px; }

.charity-slide-parallax h2 {
  padding-bottom: 30px;
  position: relative; }
.charity-slide-parallax .text-shade {
  display: block;
  font-size: 120px;
  font-weight: 700;
  left: 0;
  line-height: 1; }
.charity-slide-parallax .button::before {
  background-color: #fff;
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 200%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }

.sp-slide-parallax h3 {
  font-size: 48px;
  line-height: 60px; }

.shop-slider-1 {
  background-color: #ddd; }
  .shop-slider-1 h4::after {
    background-color: #444;
    content: "";
    height: 1px;
    position: absolute;
    top: -14px;
    right: 4px;
    width: 80px; }
  .shop-slider-1 h5 {
    letter-spacing: 3px; }
    .shop-slider-1 h5::before {
      background-color: #444;
      content: "";
      height: 1px;
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 80px; }

.shop-slider-2 .slide-content h2 {
  color: #000;
  font-size: 55px;
  line-height: 60px; }
.shop-slider-2 .slide-content .sep {
  background-color: #000;
  display: block;
  height: 2px;
  width: 80px; }
.shop-slider-2 .slide-content .img1, .shop-slider-2 .slide-content .img2 {
  width: 50%;
  margin-left: auto; }
.shop-slider-2 .item2 {
  background-color: #dcdbe0; }

.shop-slider-3 .item {
  position: relative; }
.shop-slider-3 .caption {
  bottom: 44px;
  left: 60px;
  position: absolute;
  right: 60px; }
  .shop-slider-3 .caption h2 {
    line-height: 1.44;
    margin-bottom: 7px; }
    .shop-slider-3 .caption h2 span {
      background-color: rgba(0, 0, 0, 0.87);
      padding: 0 13px 2px; }
.shop-slider-3 .owl-dots {
  bottom: 0;
  min-height: 62px;
  position: static;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.banner-sports .left-bg,
.banner-sports .right-bg {
  background-color: rgba(0, 0, 0, 0.3);
  height: 340px;
  left: -130px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) skewX(-33deg);
  -moz-transform: translateY(-50%) skewX(-33deg);
  -ms-transform: translateY(-50%) skewX(-33deg);
  -o-transform: translateY(-50%) skewX(-33deg);
  transform: translateY(-50%) skewX(-33deg);
  width: -webkit-calc(50% + 130px);
  width: -moz-calc(50% + 130px);
  width: calc(50% + 130px); }
.banner-sports .right-bg {
  background-color: rgba(246, 81, 76, 0.3);
  left: auto;
  right: -130px;
  -webkit-transform: translateY(-50%) skew(-33deg);
  -moz-transform: translateY(-50%) skew(-33deg);
  -ms-transform: translateY(-50%) skew(-33deg);
  -o-transform: translateY(-50%) skew(-33deg);
  transform: translateY(-50%) skew(-33deg); }
.banner-sports h2 {
  font-size: 100px;
  line-height: 1.1; }

/*==============================================
    home-default
===============================================*/
.home-default .earphone,
.home-default .coffemug,
.home-default .penholder,
.home-default .wave {
  position: absolute; }
.home-default .earphone {
  top: 0;
  left: 0; }
.home-default .coffemug {
  top: 30%;
  right: 0; }
.home-default .penholder {
  bottom: 0;
  left: 40px;
  z-index: 1;
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%); }
.home-default .wave {
  bottom: 0;
  left: 0;
  right: 0; }
  .home-default .wave img {
    width: 100%; }

[class^="section-bubble-"] {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute; }

.section-bubble-left {
  height: 300px;
  left: 0;
  position: absolute;
  top: 44%;
  width: 100px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.service-bubble {
  height: 233px;
  position: absolute;
  right: 0;
  top: 0;
  width: 75px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.s-service-carousel-nav .item {
  margin-bottom: 7px;
  margin-top: 7px;
  max-width: 300px;
  text-align: center;
  position: relative; }
  .s-service-carousel-nav .item::before {
    background-color: #333333;
    border-radius: 12px;
    content: "";
    height: 12px;
    position: absolute;
    right: 0;
    top: 65%;
    width: 12px;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
    -webkit-transform: translateY(-50%) scale(0);
    -moz-transform: translateY(-50%) scale(0);
    -ms-transform: translateY(-50%) scale(0);
    -o-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0); }
  .s-service-carousel-nav .item::after {
    background-color: #333333;
    content: "";
    height: 2px;
    left: 100%;
    position: absolute;
    top: 65%;
    width: 0;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
.s-service-carousel-nav .slick-center {
  color: #444;
  font-size: 24px;
  font-weight: 400; }
  .s-service-carousel-nav .slick-center::before {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1); }
  .s-service-carousel-nav .slick-center::after {
    width: 100%; }

.iphone-holder {
  margin-bottom: -79px;
  margin-left: -45px;
  margin-top: -70px; }

.projects-bg {
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: -webkit-calc(50% - 15px);
  width: -moz-calc(50% - 15px);
  width: calc(50% - 15px); }

.portfolio-bubble {
  height: 233px;
  position: absolute;
  right: 0;
  bottom: 20%;
  width: 75px; }

.wave-shape-2 {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0; }
  .wave-shape-2 path {
    fill: #0D52FF; }

.envato-logos-carousel img {
  opacity: 0.25;
  width: 180px; }
  .envato-logos-carousel img:hover {
    opacity: 1; }

.blog-bubble {
  height: 233px;
  position: absolute;
  right: 0;
  top: 150px;
  width: 75px; }

/*==============================================
    index-7
===============================================*/
.indent-process-list {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.indent-process {
  background-color: #303030;
  color: #555454;
  margin-right: 40px;
  padding: 25px 0 25px 55px;
  position: relative;
  text-transform: uppercase; }
  .indent-process::before {
    border-color: #303030 transparent;
    border-style: solid;
    border-width: 62px;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
  .indent-process::after {
    border-color: transparent transparent transparent #303030;
    border-style: solid;
    border-width: 62px;
    content: "";
    position: absolute;
    right: -124px;
    top: 0; }
  .indent-process .number {
    font-size: 50px;
    font-weight: 100;
    line-height: 1; }
  .indent-process .process-name {
    font-size: 25px;
    font-weight: 900; }
  .indent-process.active .number {
    color: rgba(255, 255, 255, 0.87); }
  .indent-process.active .process-name {
    color: rgba(255, 255, 255, 0.87); }

.col-sm-4:first-child .indent-process::before {
  content: none; }

/*==============================================
    home-Business
===============================================*/
.business-banner .slide-content h2 {
  color: #fff; }
.business-banner .slide-content p {
  color: rgba(255, 255, 255, 0.87); }

/*==============================================
    home-Business-3
===============================================*/
.business-3-banner h2 {
  font-size: 100px; }
.business-3-banner p {
  font-size: 18px;
  line-height: 30px;
  margin: 0 auto;
  width: 90%; }

/*==============================================
    home-resturant
===============================================*/
.restaurant-banner .banner-content h3 {
  font-size: 30px; }
.restaurant-banner .banner-content h2 {
  font-size: 72px;
  margin: 24px 0 29px; }
  .restaurant-banner .banner-content h2 b {
    background-color: #0D52FF;
    padding: 16px 10px 20px 23px; }
.restaurant-banner .banner-content h4 {
  font-size: 24px; }
.restaurant-banner .half-bg {
  background-color: #0D52FF;
  position: absolute;
  right: 0; }

.single-dish {
  background-color: #fff;
  padding: 30px; }

/*==============================================
    home-bakery
===============================================*/
.bakery-banner {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e9eef1+0,ffffff+100 */
  background-color: #e9eef1;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e9eef1 0%, #fff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e9eef1 0%, #fff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e9eef1 0%, #fff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  .bakery-banner .slide-content {
    margin-top: 80px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
    .bakery-banner .slide-content h3 {
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 40px; }
  .bakery-banner + .cta-bakery {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%); }

.food-nav {
  margin-top: 20px; }
  .food-nav .content h2 {
    font-size: 30px;
    margin-bottom: 20px; }
  .food-nav .slick-dots {
    text-align: center;
    margin-top: 50px; }
    .food-nav .slick-dots li {
      display: inline-block;
      margin: 0 2px;
      border-color: #0D52FF; }
    .food-nav .slick-dots button {
      background-color: transparent;
      border: 0 none;
      display: block;
      padding: 0; }
    .food-nav .slick-dots .slick-active button {
      background-color: #0D52FF; }

/* ------------- dish--1-carousel ------------- */
.dish--content-1,
.dish--content-2,
.dish--content-3 {
  padding: 62px 100px 50px; }
  .dish--content-1.imgs,
  .dish--content-2.imgs,
  .dish--content-3.imgs {
    padding: 0; }
  .dish--content-1 .item,
  .dish--content-2 .item,
  .dish--content-3 .item {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    .dish--content-1 .item h3,
    .dish--content-2 .item h3,
    .dish--content-3 .item h3 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 26px; }
    .dish--content-1 .item .price,
    .dish--content-2 .item .price,
    .dish--content-3 .item .price {
      color: #0D52FF;
      font-family: "Poppins", sans-serif;
      font-size: 36px;
      font-weight: 700;
      line-height: 1;
      padding-top: 23px; }

.dish--carousel-1,
.dish--carousel-2,
.dish--carousel-3 {
  padding: 30px 80px; }
  .dish--carousel-1 .item,
  .dish--carousel-2 .item,
  .dish--carousel-3 .item {
    cursor: pointer;
    text-align: center;
    opacity: 0.5;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .dish--carousel-1 .item img,
    .dish--carousel-2 .item img,
    .dish--carousel-3 .item img {
      background-color: #fff;
      border-radius: 50%;
      margin: 0 auto;
      max-width: 125px;
      padding: 5px; }
    .dish--carousel-1 .item .price,
    .dish--carousel-2 .item .price,
    .dish--carousel-3 .item .price {
      display: inline-block;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-weight: 700;
      margin-top: 6px; }
  .dish--carousel-1 .slick-center,
  .dish--carousel-2 .slick-center,
  .dish--carousel-3 .slick-center {
    cursor: default;
    opacity: 1; }
  .dish--carousel-1 .slick-arrow,
  .dish--carousel-2 .slick-arrow,
  .dish--carousel-3 .slick-arrow {
    background-color: transparent;
    border: 0 none;
    font-size: 36px;
    left: 60px;
    padding: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .dish--carousel-1 .slick-next,
  .dish--carousel-2 .slick-next,
  .dish--carousel-3 .slick-next {
    left: auto;
    right: 60px; }

.food-carousel .slick-list {
  padding: 60px 0 !important; }
.food-carousel .item {
  border: 15px solid #fff;
  margin: 0;
  outline: 0 none;
  overflow: hidden;
  position: relative; }
  .food-carousel .item.slick-active {
    opacity: 1; }
  .food-carousel .item img {
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-45deg) scale(1.4);
    -moz-transform: rotate(-45deg) scale(1.4);
    -ms-transform: rotate(-45deg) scale(1.4);
    -o-transform: rotate(-45deg) scale(1.4);
    transform: rotate(-45deg) scale(1.4); }
.food-carousel .content {
  position: absolute; }
.food-carousel .slick-slide {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(45deg) scale(0.75);
  -moz-transform: rotate(45deg) scale(0.75);
  -ms-transform: rotate(45deg) scale(0.75);
  -o-transform: rotate(45deg) scale(0.75);
  transform: rotate(45deg) scale(0.75); }
  .food-carousel .slick-slide.slick-active {
    opacity: 1; }
.food-carousel .slick-center {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  -webkit-transform: rotate(45deg) scale(1);
  -moz-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  -o-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1); }
  .food-carousel .slick-center + .slick-active {
    z-index: 4; }
.food-carousel .owl-stage-outer {
  padding: 60px 50px 60px 0; }
.food-carousel .owl-item {
  border: 15px solid red;
  overflow: hidden;
  position: relative;
  opacity: 0;
  margin-left: 9px;
  -webkit-transform: rotate(45deg) scale(0.9);
  -moz-transform: rotate(45deg) scale(0.9);
  -ms-transform: rotate(45deg) scale(0.9);
  -o-transform: rotate(45deg) scale(0.9);
  transform: rotate(45deg) scale(0.9); }
  .food-carousel .owl-item.center {
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;
    -webkit-transform: rotate(45deg) scale(1);
    -moz-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); }
    .food-carousel .owl-item.center + .active {
      z-index: 4; }
  .food-carousel .owl-item.active {
    opacity: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .food-carousel .owl-item img {
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-45deg) scale(1.4);
    -moz-transform: rotate(-45deg) scale(1.4);
    -ms-transform: rotate(-45deg) scale(1.4);
    -o-transform: rotate(-45deg) scale(1.4);
    transform: rotate(-45deg) scale(1.4); }

/*==============================================
    home-event
===============================================*/
.event-schedule .entry-post {
  width: 33.3333%;
  float: left;
  margin-bottom: 250px; }
  .event-schedule .entry-post .entry-thumb {
    position: relative; }
    .event-schedule .entry-post .entry-thumb::before {
      background-color: rgba(0, 0, 0, 0.1);
      bottom: 0;
      content: "";
      height: 1px;
      left: 100%;
      position: absolute;
      width: 50%; }
    .event-schedule .entry-post .entry-thumb .icon-box {
      background-color: #0D52FF;
      border: 4px solid #fbafad;
      border-radius: 50px;
      bottom: 0;
      color: #fff;
      font-size: 20px;
      height: 80px;
      line-height: 75px;
      position: absolute;
      text-align: center;
      width: 80px;
      z-index: 10;
      right: -webkit-calc(-50% - 2px);
      right: -moz-calc(-50% - 2px);
      right: calc(-50% - 2px);
      -webkit-transform: translate(50%, 50%);
      -moz-transform: translate(50%, 50%);
      -ms-transform: translate(50%, 50%);
      -o-transform: translate(50%, 50%);
      transform: translate(50%, 50%); }
  .event-schedule .entry-post:nth-child(2n) {
    margin-left: 33.3333%; }
    .event-schedule .entry-post:nth-child(2n) .entry-thumb::before {
      left: auto;
      right: 100%; }
    .event-schedule .entry-post:nth-child(2n) .entry-thumb .icon-box {
      right: auto;
      left: -webkit-calc(-50% - 2px);
      left: -moz-calc(-50% - 2px);
      left: calc(-50% - 2px);
      -webkit-transform: translate(-50%, 50%);
      -moz-transform: translate(-50%, 50%);
      -ms-transform: translate(-50%, 50%);
      -o-transform: translate(-50%, 50%);
      transform: translate(-50%, 50%); }
  .event-schedule .entry-post:nth-child(2) {
    margin-top: 450px; }
  .event-schedule .entry-post:nth-last-child(2) {
    margin-bottom: 0; }
.event-schedule .es-devider {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px; }

/* -------------- Evelt Carousel 1 ------------- */
.event-carousel-1 .text h2 {
  font-size: 36px; }

/*==============================================
    home-resume
===============================================*/
/*----------------------------------------
    resume-banner
------------------------------------------*/
.resume-banner .slide-content h2, .resume-banner .slide-content h3, .resume-banner .slide-content p {
  color: #fff;
  font-family: "Poppins", sans-serif; }
.resume-banner .slide-content h3 {
  font-size: 30px;
  margin-bottom: 25px; }
.resume-banner .slide-content h2 {
  font-size: 72px;
  font-weight: 400;
  line-height: 1.1; }
.resume-banner .slide-content p {
  font-size: 24px;
  line-height: 36px;
  margin-top: 29px; }

/*----------------------------------------
    resume-contact
------------------------------------------*/
.resume-contact [class^="bg-"] {
  height: 100%;
  position: absolute;
  top: 0;
  width: 50%; }
.resume-contact .bg-left {
  left: 0; }
.resume-contact .bg-right {
  right: 0; }
.resume-contact .section-title h2 {
  font-size: 30px; }
.resume-contact .section-title p {
  font-size: 14px;
  margin-top: 19px; }
.resume-contact .contact-block h4 {
  margin-bottom: 14px; }

/*==============================================
    home-creative-3
===============================================*/
.our-story-1 .left {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  line-height: 45px; }
.our-story-1 .right {
  font-size: 16px;
  line-height: 26px; }
.our-story-1 .sep {
  background-color: #dedede;
  bottom: 10px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 10px;
  width: 4px; }

/*==============================================
    home-realestate
===============================================*/
.slider5 .owl-dots {
  bottom: 10px;
  left: 0;
  position: absolute;
  right: 0;
  display: none !important; }

.realestate-slide-content {
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 520px;
  padding: 72px 80px 80px;
  width: 100%;
  position: relative; }
  .realestate-slide-content::after {
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.9);
    border-style: solid;
    border-width: 43px 50px;
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%); }
  .realestate-slide-content h2 {
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 30px; }
  .realestate-slide-content .price {
    color: #0D52FF;
    font-size: 60px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 40px; }
    .realestate-slide-content .price small {
      color: #444;
      font-size: 18px;
      font-weight: 300; }



.re-block {
  padding: 80px 40px 90px; }
  .re-block h2 {
    font-size: 36px;
    margin-bottom: 23px; }

/*==============================================
    home-event
===============================================*/
.event-parallax {
  color: #fff; }
  .event-parallax .date {
    font-size: 36px;
    font-weight: 300;
    line-height: 1; }
  .event-parallax h2 {
    color: #fff;
    font-size: 100px;
    font-weight: 400; }
  .event-parallax p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 42px; }

/*==============================================
    home-gym
===============================================*/
.schedule-top {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  margin-bottom: 30px; }
  .schedule-top.dark {
    background-color: rgba(0, 0, 0, 0.05); }
  .schedule-top > li {
    line-height: 45px; }

.schedule-bottom .class-name li {
  border-radius: 5px 0 0 5px; }
.schedule-bottom .trainer-name {
  padding-right: 15px; }
  .schedule-bottom .trainer-name li {
    border-radius: 0 5px 5px 0; }
.schedule-bottom .join-class {
  padding-left: 15px; }
  .schedule-bottom .join-class li {
    border-radius: 5px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .schedule-bottom .join-class li:hover {
      box-shadow: -1px -1px 0 0 #fff inset, 1px 1px 0 0 #fff inset, 1px -1px 0 0 #fff inset, -1px 1px 0 0 #fff inset; }
  .schedule-bottom .join-class a {
    color: #fff;
    display: block; }
  .schedule-bottom .join-class.dark a {
    color: #848484;
    border-radius: 5px; }
    .schedule-bottom .join-class.dark a:hover {
      background-color: #0D52FF;
      color: #fff; }
.schedule-bottom li {
  background-color: rgba(255, 255, 255, 0.2);
  line-height: 45px;
  margin-bottom: 10px; }
  .schedule-bottom li:nth-child(2n) {
    background-color: rgba(255, 255, 255, 0.4); }
.schedule-bottom.dark li {
  background-color: rgba(0, 0, 0, 0.03); }
  .schedule-bottom.dark li:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.05); }

/*==============================================
    home-apps
===============================================*/
.app-content p {
  font-size: 18px;
  line-height: 30px; }
.app-content .btn-large {
  margin-right: 5px; }
.app-content img {
  max-height: 700px;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.template-layer {
  height: 525px;
  position: relative; }
  .template-layer img {
    bottom: 0;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s; }
    .template-layer img:nth-child(1) {
      margin-top: 0;
      z-index: 5;
      -webkit-transition-delay: 0.5s, 0.08s;
      -moz-transition-delay: 0.5s, 0.08s;
      -o-transition-delay: 0.5s, 0.08s;
      transition-delay: 0.5s, 0.08s; }
    .template-layer img:nth-child(2) {
      z-index: 4;
      -webkit-transition-delay: 0.5s, 0.06s;
      -moz-transition-delay: 0.5s, 0.06s;
      -o-transition-delay: 0.5s, 0.06s;
      transition-delay: 0.5s, 0.06s; }
    .template-layer img:nth-child(3) {
      z-index: 3;
      -webkit-transition-delay: 0.5s, 0.04s;
      -moz-transition-delay: 0.5s, 0.04s;
      -o-transition-delay: 0.5s, 0.04s;
      transition-delay: 0.5s, 0.04s; }
    .template-layer img:nth-child(4) {
      z-index: 2;
      -webkit-transition-delay: 0.5s, 0.02s;
      -moz-transition-delay: 0.5s, 0.02s;
      -o-transition-delay: 0.5s, 0.02s;
      transition-delay: 0.5s, 0.02s; }
    .template-layer img:nth-child(5) {
      z-index: 1; }
  .template-layer.active img:nth-child(1) {
    bottom: 250px; }
  .template-layer.active img:nth-child(2) {
    bottom: 180px; }
  .template-layer.active img:nth-child(3) {
    bottom: 110px; }
  .template-layer.active img:nth-child(4) {
    bottom: 40px; }

/*==============================================
    home-medical
===============================================*/
.medical-content h2 {
  font-size: 60px;
  line-height: 72px;
  margin-bottom: 37px; }
.medical-content .sep {
  background-color: rgba(255, 255, 255, 0.2);
  display: block;
  height: 1px;
  position: relative; }
  .medical-content .sep::before {
    background-color: #fff;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 110px; }
.medical-content p {
  font-size: 18px;
  line-height: 30px; }

.leaned-layer {
  height: 200%;
  position: absolute;
  right: 0;
  top: 50%;
  width: 779px;
  -webkit-transform: skew(-40.2deg) rotate(11.65deg) translate(-50%, -50%);
  -moz-transform: skew(-40.2deg) rotate(11.65deg) translate(-50%, -50%);
  -ms-transform: skew(-40.2deg) rotate(11.65deg) translate(-50%, -50%);
  -o-transform: skew(-40.2deg) rotate(11.65deg) translate(-50%, -50%);
  transform: skew(-40.2deg) rotate(11.65deg) translate(-50%, -50%); }
  .leaned-layer.light {
    background-color: rgba(255, 255, 255, 0.25); }

.appointment-form {
  padding: 50px 50px 90px;
  position: relative;
  z-index: 1; }
  .appointment-form::before, .appointment-form::after {
    background-color: #0D52FF;
    content: "";
    height: 90px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 90px; }
  .appointment-form::after {
    left: auto;
    right: 10px;
    top: auto;
    bottom: 10px; }
  .appointment-form .bg-shape {
    background: blue;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    -webkit-transform: rotate(1deg) translate(2px, 1px);
    -moz-transform: rotate(1deg) translate(2px, 1px);
    -ms-transform: rotate(1deg) translate(2px, 1px);
    -o-transform: rotate(1deg) translate(2px, 1px);
    transform: rotate(1deg) translate(2px, 1px); }
  .appointment-form h4 {
    margin-bottom: 30px;
    position: relative;
    z-index: 1; }
  .appointment-form svg {
    fill: white;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; }
  .appointment-form form {
    position: relative;
    z-index: 2; }
  .appointment-form .input-field {
    margin: 0 0 13px; }
    .appointment-form .input-field:last-child {
      margin-bottom: 0; }
    .appointment-form .input-field label.active:not(.label-icon) {
      font-size: 1rem;
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      -o-transform: translateY(-100%);
      transform: translateY(-100%); }
  .appointment-form .ml-input {
    margin-bottom: 0; }
  .appointment-form label {
    text-transform: none; }
    .appointment-form label.active {
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      -o-transform: translateY(-100%);
      transform: translateY(-100%); }
  .appointment-form .materialize-textarea {
    padding: 15px 0 0;
    margin-bottom: 0; }

.mdcl-patient--forum,
.bevel-edge-corner {
  z-index: 0; }
  .mdcl-patient--forum::before, .mdcl-patient--forum::after,
  .bevel-edge-corner::before,
  .bevel-edge-corner::after {
    background-color: #0D52FF;
    content: "";
    height: 100px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 100px;
    z-index: -1; }
  .mdcl-patient--forum::after,
  .bevel-edge-corner::after {
    left: auto;
    right: 10px;
    top: auto;
    bottom: 10px; }
  .mdcl-patient--forum .leaned-layer,
  .bevel-edge-corner .leaned-layer {
    background-color: rgba(255, 255, 255, 0.25);
    bottom: 242px;
    height: 200%;
    left: 50%;
    width: 1432px;
    z-index: 0;
    -webkit-transform: translate(-50%, -50%) skewX(-44.8deg);
    -moz-transform: translate(-50%, -50%) skewX(-44.8deg);
    -ms-transform: translate(-50%, -50%) skewX(-44.8deg);
    -o-transform: translate(-50%, -50%) skewX(-44.8deg);
    transform: translate(-50%, -50%) skewX(-44.8deg); }
  .mdcl-patient--forum .bevel,
  .bevel-edge-corner .bevel {
    border-width: 100px;
    border-color: #fff;
    border-style: solid; }
  .mdcl-patient--forum .top-left,
  .mdcl-patient--forum .bottom-right,
  .bevel-edge-corner .top-left,
  .bevel-edge-corner .bottom-right {
    height: 0px;
    width: 100%; }
  .mdcl-patient--forum .top-left,
  .bevel-edge-corner .top-left {
    border-top: 0;
    border-left-color: transparent; }
  .mdcl-patient--forum .bottom-right,
  .bevel-edge-corner .bottom-right {
    border-bottom: 0;
    border-right-color: transparent; }

.mdcl-patient-forum {
  background-color: #fff;
  position: relative;
  padding: 44px 190px 60px; }
  .mdcl-patient-forum h2 {
    font-size: 30px;
    margin-bottom: 30px; }
  .mdcl-patient-forum p {
    font-size: 16px;
    line-height: 30px; }

/*==============================================
    home-megazine
===============================================*/
.mag-row {
  margin-bottom: 30px; }
  .mag-row .mag-widget-title-2 {
    margin-bottom: 30px; }

.popular-news-carousel-wrapper {
  margin: 30px 0; }

.popular-news-item::before {
  background-color: #000;
  content: '';
  position: absolute;
  left: 0;
  opacity: 0.2;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
.popular-news-item figcaption {
  color: #fff;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
.popular-news-item .news-tag {
  background-color: #0D52FF;
  border-radius: 2px;
  display: inline-block;
  line-height: 30px;
  margin: 0 0 19px;
  padding: 0 60px; }
.popular-news-item time {
  display: inline-block;
  margin-top: 3px; }
.popular-news-item a {
  color: #fff; }
.popular-news-item:hover::before {
  opacity: 0.6; }

.pnc-custom-control div {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  font-size: 30px;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 80px; }
  .pnc-custom-control div i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
.pnc-custom-control .owl-next {
  left: auto;
  right: 0; }

.magazine-post {
  background-color: #f7f7f7;
  padding: 30px; }

.mag-featured-top .content {
  padding: 40px 20px 30px; }
.mag-featured-top .news-tag {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 5px; }
.mag-featured-top h3 {
  margin-bottom: 12px; }
.mag-featured-top time {
  display: inline-block; }
.mag-featured-top p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 30px; }
.mag-featured-top .btn-floating {
  margin-top: 24px; }

.accordion-post-style {
  overflow: hidden;
  position: relative; }

.accordion-mag-post {
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.4);
  float: left;
  height: 300px;
  position: relative;
  width: 33.3333%;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .accordion-mag-post:nth-child(2) {
    z-index: 2; }
  .accordion-mag-post:nth-child(3) {
    z-index: 3; }
  .accordion-mag-post:nth-child(4) {
    z-index: 4; }
  .accordion-mag-post:nth-child(5) {
    z-index: 5; }
  .accordion-mag-post::before {
    background-color: #000;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .accordion-mag-post .thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 323px; }
    .accordion-mag-post .thumb img {
      width: 100%;
      height: 100%; }
  .accordion-mag-post figcaption {
    bottom: 30px;
    color: #fff;
    left: 30px;
    position: absolute;
    width: 210px;
    z-index: 2; }
  .accordion-mag-post .news-tag {
    background-color: #0D52FF;
    display: inline-block;
    border-radius: 2px;
    line-height: 30px;
    padding: 0 35px;
    margin-bottom: 10px; }
  .accordion-mag-post h4 {
    font-size: 18px;
    margin-bottom: 5px; }
  .accordion-mag-post a {
    color: #fff; }
  .accordion-mag-post:hover {
    width: 45%; }
    .accordion-mag-post:hover::before {
      opacity: 0.5; }

/*==============================================
    home-magazine-2
===============================================*/
.gpc-control > svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 10; }
  .gpc-control > svg polygon {
    fill: rgba(255, 255, 255, 0.25); }
.gpc-control .owl-next {
  left: auto;
  right: 0; }
.gpc-control span {
  border-style: solid;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 11;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
.gpc-control .prev-arrow {
  border-color: transparent rgba(255, 255, 255, 0.25) transparent transparent;
  border-width: 36px 36px 36px 22px;
  left: 0; }
  .gpc-control .prev-arrow:hover {
    border-color: transparent #0D52FF transparent transparent; }
.gpc-control .next-arrow {
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.25);
  border-width: 36px 22px 36px 36px;
  right: 0; }
  .gpc-control .next-arrow:hover {
    border-color: transparent transparent transparent #0D52FF; }

.slider-grid-post {
  color: #fff;
  position: relative;
  z-index: 0; }
  .slider-grid-post::before {
    background-color: #000;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .slider-grid-post .content {
    left: 30px;
    position: absolute;
    right: 30px;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .slider-grid-post .title {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 33px 0 15px;
    position: relative; }
  .slider-grid-post h3 {
    font-size: 18px; }
  .slider-grid-post .post-tag {
    color: #fff;
    font-size: 14px;
    left: 50%;
    line-height: 40px;
    padding: 0 50px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .slider-grid-post p {
    margin-top: 21px; }
  .slider-grid-post.col-md-6 .content {
    left: 80px;
    right: 80px; }
  .slider-grid-post.col-md-6 .title {
    padding: 56px 0 35px; }
  .slider-grid-post.col-md-6 h3 {
    font-size: 30px; }
  .slider-grid-post.col-md-6 .post-tag {
    font-size: 18px;
    line-height: 50px;
    padding: 0 90px; }
  .slider-grid-post.col-md-6 p {
    font-size: 18px;
    line-height: 30px;
    margin-top: 50px; }
  .slider-grid-post.col-md-6 .meta {
    font-size: 18px; }

.pnc-custom-control div {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  font-size: 30px;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 80px; }
  .pnc-custom-control div i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
.pnc-custom-control .owl-next {
  left: auto;
  right: 0; }

.magazine-post {
  background-color: #f7f7f7;
  padding: 30px; }

.mag-featured-top .content {
  padding: 40px 20px 30px; }
.mag-featured-top .news-tag {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 5px; }
.mag-featured-top h3 {
  margin-bottom: 12px; }
.mag-featured-top time {
  display: inline-block; }
.mag-featured-top p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 30px; }
.mag-featured-top .btn-floating {
  margin-top: 24px; }
.mag-featured-top.bottom-border h4 {
  background-color: #f7f7f7;
  font-size: 20px;
  padding: 11px 30px 12px; }
.mag-featured-top.bottom-border .meta {
  font-size: 12px; }
.mag-featured-top.bottom-border .content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 21px 30px 42px; }

.accordion-post-style {
  overflow: hidden;
  position: relative; }

.accordion-mag-post {
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.4);
  float: left;
  height: 300px;
  position: relative;
  width: 33.3333%;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .accordion-mag-post:nth-child(2) {
    z-index: 2; }
  .accordion-mag-post:nth-child(3) {
    z-index: 3; }
  .accordion-mag-post:nth-child(4) {
    z-index: 4; }
  .accordion-mag-post:nth-child(5) {
    z-index: 5; }
  .accordion-mag-post::before {
    background-color: #000;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .accordion-mag-post .content {
    left: 80px;
    position: absolute;
    right: 80px;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .accordion-mag-post .title {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 56px 0 35px;
    position: relative; }
  .accordion-mag-post .post-tag {
    color: #fff;
    font-size: 18px;
    left: 50%;
    line-height: 50px;
    padding: 0 90px;
    position: absolute;
    top: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .accordion-mag-post p {
    font-size: 18px;
    line-height: 30px;
    margin-top: 50px; }
  .accordion-mag-post .lead {
    margin-top: 50px; }
  .accordion-mag-post.col-sm-3 .content {
    left: 30px;
    right: 30px; }
  .accordion-mag-post.col-sm-3 .title {
    padding: 33px 0 15px; }
  .accordion-mag-post.col-sm-3 .post-tag {
    font-size: 14px;
    line-height: 40px;
    padding: 0 50px; }
  .accordion-mag-post.col-sm-3 h3 {
    font-size: 18px; }
  .accordion-mag-post.col-sm-3 p {
    margin-top: 21px; }
  .accordion-mag-post .thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 323px; }
    .accordion-mag-post .thumb img {
      width: 100%;
      height: 100%; }
  .accordion-mag-post figcaption {
    bottom: 30px;
    color: #fff;
    left: 30px;
    position: absolute;
    width: 210px;
    z-index: 2; }
  .accordion-mag-post .news-tag {
    background-color: #0D52FF;
    display: inline-block;
    border-radius: 2px;
    line-height: 30px;
    padding: 0 35px;
    margin-bottom: 10px; }
  .accordion-mag-post h4 {
    font-size: 18px;
    margin-bottom: 5px; }
  .accordion-mag-post a {
    color: #fff; }
  .accordion-mag-post:hover {
    width: 45%; }
    .accordion-mag-post:hover::before {
      opacity: 0.5; }

/*==============================================
    home-interior
===============================================*/
.interior-slider-wrap .item {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }

.interior-slide h2 {
  font-family: "Roboto", sans-serif;
  font-size: 100px;
  font-weight: 700;
  line-height: 0.75;
  opacity: 0.2;
  position: absolute;
  top: 0;
  z-index: 0; }
.interior-slide h3 {
  font-size: 24px;
  margin: 20px 0 50px; }
.interior-slide p {
  margin-bottom: 42px; }
.interior-slide a:last-child {
  margin-left: 10px; }

/*==============================================
    home-trave/adventure
===============================================*/
.travel-overlap {
  margin-top: -110px;
  position: relative;
  z-index: 3; }

.travel-place figcaption,
.travel-place .info {
  background-color: #fff;
  padding: 33px 40px 40px; }
.travel-place img {
  width: 100%; }
.travel-place h4 {
  margin-bottom: 15px; }
.travel-place.hover-2::before {
  background-color: #fff;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }
.travel-place.hover-2 .tour-info {
  color: #444;
  font-size: 16px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }
.travel-place.hover-2 figcaption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }
.travel-place.hover-2:hover::before {
  opacity: 0.9; }
.travel-place.hover-2:hover .tour-info {
  opacity: 1;
  visibility: visible; }
.travel-place.hover-2:hover figcaption {
  background-color: transparent; }

.img-container {
  position: relative; }
  .img-container [class^="img-"] {
    position: absolute;
    left: 0;
    top: 0; }
  .img-container .img-1 {
    z-index: 1; }
  .img-container .img-3 {
    z-index: 3; }
    .img-container .img-3::before {
      background-color: rgba(0, 0, 0, 0.6);
      content: "";
      height: 226px;
      left: 0;
      position: absolute;
      top: 50%;
      width: 200%;
      -webkit-transform: rotate(45deg) translate(-25%, 65px);
      -moz-transform: rotate(45deg) translate(-25%, 65px);
      -ms-transform: rotate(45deg) translate(-25%, 65px);
      -o-transform: rotate(45deg) translate(-25%, 65px);
      transform: rotate(45deg) translate(-25%, 65px); }
  .img-container .img-2 {
    z-index: 2; }

/*==============================================
    home-travel
===============================================*/
.travel--slider .owl-dots {
  bottom: auto;
  left: 40px; }

.booking-form-inner {
  background-color: #fff; }
  .booking-form-inner .input-field label {
    text-transform: none; }
  .booking-form-inner .ml-input {
    margin-bottom: 0; }

.travel-booking-nav {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 60px; }
  .travel-booking-nav li {
    display: inline-block; }
    .travel-booking-nav li a {
      background-color: #f3f3f3;
      color: #444;
      display: block;
      line-height: 35px;
      margin: 0 0 1px -3px;
      padding: 0 40px; }
    .travel-booking-nav li:first-child a {
      border-top-left-radius: 10px; }
    .travel-booking-nav li:last-child a {
      border-top-right-radius: 10px; }
  .travel-booking-nav .active a {
    background-color: #0D52FF;
    color: #fff; }

/*==============================================
    home-charity
===============================================*/
.charity-donation-form .input-field {
  margin-bottom: 30px; }
  .charity-donation-form .input-field label {
    font-size: 14px;
    letter-spacing: 0.2px;
    margin-top: 5px;
    position: relative;
    text-transform: none;
    top: 0;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none; }
    .charity-donation-form .input-field label.error {
      color: #d60909; }
.charity-donation-form .nice-select {
  line-height: 46px;
  padding: 0;
  width: 100%; }
  .charity-donation-form .nice-select ul {
    width: 100%; }
  .charity-donation-form .nice-select li {
    color: #848484;
    font-weight: 300;
    padding: 0; }
  .charity-donation-form .nice-select .selected {
    font-weight: 300; }
.charity-donation-form input, .charity-donation-form textarea, .charity-donation-form .nice-select {
  background-color: rgba(252, 255, 254, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  color: #fff;
  font-weight: 300;
  height: 48px;
  margin: 0;
  text-indent: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .charity-donation-form input:hover, .charity-donation-form textarea:hover, .charity-donation-form .nice-select:hover {
    background-color: rgba(246, 81, 76, 0.3);
    border-color: rgba(246, 81, 76, 0.5); }
.charity-donation-form textarea {
  height: 198px;
  padding-top: 15px; }
.charity-donation-form .donation-sub {
  background-color: rgba(252, 255, 254, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  color: #fff;
  padding: 12px 48px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .charity-donation-form .donation-sub:hover {
    background-color: rgba(246, 81, 76, 0.3);
    border-color: rgba(246, 81, 76, 0.5); }

/*==============================================
    home sports
===============================================*/
.match-update-tab-nav li {
  display: block; }
  .match-update-tab-nav li + li {
    margin-top: 1px; }
  .match-update-tab-nav li a {
    background-color: #fff;
    color: #848484;
    font-size: 24px;
    font-weight: 300;
    padding: 33.5px 0 33.5px 40px; }
  .match-update-tab-nav li i {
    font-size: 42px; }
.match-update-tab-nav .active a {
  background-color: #f3f3f3;
  color: #444; }

.match-list li {
  background-color: #fff;
  padding: 18px 0; }
  .match-list li + li {
    margin-top: 1px; }
.match-list .results {
  margin: 0 30px; }
  .match-list .results span {
    font-size: 18px; }
  .match-list .results span:not(.vs) {
    height: 45px;
    line-height: 45px;
    width: 45px; }
  .match-list .results .primary-bg {
    padding-left: 14px; }
  .match-list .results .gray-bg {
    padding-right: 14px; }
  .match-list .results .vs {
    background-color: #fff;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 300;
    height: 30px;
    left: 50%;
    line-height: 29px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 30px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.donate-section {
  padding: 60px; }
  .donate-section .donate-inner {
    border: 1px solid;
    padding: 85px; }

.qt-parallax::before {
  background-color: #fff;
  bottom: 0;
  content: "";
  height: 200px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1; }
.qt-parallax .container {
  position: relative;
  z-index: 10; }

.sports-sponsors li {
  height: 270px;
  line-height: 270px; }
  .sports-sponsors li + li {
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
  .sports-sponsors li img {
    opacity: .1; }
  .sports-sponsors li:hover img {
    opacity: .54; }

/*==============================================
    home-portfolio-2
===============================================*/
.left-about-head h3 {
  color: #000;
  opacity: .6;
  font-size: 38px;
  font-weight: 500; }

.portfolio-2-banner {
  background-attachment: fixed;
  background-position: 90% bottom;
  background-repeat: no-repeat;
  background-size: 30% auto; }

.portfolio2-parallax h2 {
  font-size: 72px;
  line-height: 1.11; }
.portfolio2-parallax .sep {
  background-color: #fff;
  display: block;
  height: 2px;
  margin-top: 28px;
  opacity: 0.5;
  width: 100px; }

/*==============================================
    home-realestate
===============================================*/
/*==============================================
    Services
===============================================*/
.service.type-1 .icon {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.16), 0 0 3px 0 rgba(0, 0, 0, 0.12);
  font-size: 30px;
  height: 120px;
  margin: 0 auto 20px;
  text-align: center;
  width: 120px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .service.type-1 .icon i {
    line-height: 120px; }
.service.type-1 p {
  font-size: 13px;
  font-weight: 300;
  margin: 20px 0 0; }
.service.type-1:hover .icon {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13);
  color: #0D52FF; }
.service.style2 {
  margin-bottom: 30px; }
  .service.style2 .icon {
    box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 100px;
    margin: 0 auto 45px;
    width: 100px; }
    .service.style2 .icon i {
      font-size: 30px;
      line-height: 100px; }
  .service.style2 h3 {
    font-size: 24px;
    letter-spacing: .7px;
    margin-bottom: 26px;
    text-transform: uppercase; }
.service.style3 {
  background-color: #fff;
  padding: 50px 35px 35px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .service.style3 .icon {
    color: #0D52FF;
    display: block;
    margin-bottom: 40px; }
    .service.style3 .icon i {
      font-size: 35px; }
  .service.style3 h3 {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 16px; }
  .service.style3 p {
    color: #a3a3a3;
    font-size: 16px;
    line-height: 24px; }
  .service.style3 a {
    float: right;
    margin-top: 54px; }
  .service.style3:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); }
.service.style4 span {
  background-color: #fff;
  margin-bottom: 20px; }
  .service.style4 span i {
    color: #848484; }
.service.style4 h3 {
  font-size: 24px;
  margin-bottom: 20px; }
.service.style4:hover span:hover {
  background-color: #0D52FF; }
  .service.style4:hover span:hover i {
    color: #fff; }
.service.style5 {
  margin-bottom: 30px; }
  .service.style5 .btn-large {
    margin-bottom: 22px; }
  .service.style5 h3 {
    font-size: 24px;
    margin-bottom: 17px; }
  .service.style5:hover .btn-large {
    background-color: #fff; }
    .service.style5:hover .btn-large i {
      color: #0D52FF; }
.service.style6 {
  background-color: #fff; }
  .service.style6 h4 {
    background-color: #0D52FF;
    color: #fff;
    font-size: 20px;
    padding: 25px 30px; }
  .service.style6 p {
    padding: 25px 30px; }
.service.style7 {
  padding: 57px 70px 53px;
  -webkit-transition: box-shadow 0.4s ease 0s;
  -moz-transition: box-shadow 0.4s ease 0s;
  -o-transition: box-shadow 0.4s ease 0s;
  transition: box-shadow 0.4s ease 0s; }
  .service.style7 .icon {
    font-size: 60px;
    margin-bottom: 22px;
    -webkit-transition: color 0.4s ease 0s;
    -moz-transition: color 0.4s ease 0s;
    -o-transition: color 0.4s ease 0s;
    transition: color 0.4s ease 0s; }
  .service.style7 h4 {
    font-size: 24px;
    margin-bottom: 22px; }
  .service.style7 a {
    color: #444; }
    .service.style7 a:hover {
      color: #0D52FF; }
  .service.style7:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .service.style7:hover .icon, .service.style7:hover a {
      color: #0D52FF; }
.service.style8 .icon {
  color: #444;
  font-size: 48px;
  margin-bottom: 30px;
  -webkit-transition: color 0.4s ease 0s;
  -moz-transition: color 0.4s ease 0s;
  -o-transition: color 0.4s ease 0s;
  transition: color 0.4s ease 0s; }
.service.style8 h4 {
  font-size: 24px;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
  -webkit-transition: color 0.4s ease 0s;
  -moz-transition: color 0.4s ease 0s;
  -o-transition: color 0.4s ease 0s;
  transition: color 0.4s ease 0s; }
.service.style8:hover .icon, .service.style8:hover h4 {
  color: #0D52FF; }
.service.style9 .icon, .service.style14 .icon {
  color: #444;
  font-size: 60px;
  margin-bottom: 26px;
  -webkit-transition: color 0.4s ease 0s;
  -moz-transition: color 0.4s ease 0s;
  -o-transition: color 0.4s ease 0s;
  transition: color 0.4s ease 0s; }
.service.style9 h4, .service.style14 h4 {
  font-size: 14px;
  letter-spacing: 1.4px;
  margin-bottom: 39px;
  letter-spacing: 2.8px;
  -webkit-transition: color 0.4s ease 0s;
  -moz-transition: color 0.4s ease 0s;
  -o-transition: color 0.4s ease 0s;
  transition: color 0.4s ease 0s; }
.service.style9 .sep, .service.style14 .sep {
  background-color: #444;
  display: block;
  height: 2px;
  margin: -24px auto 22px;
  width: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .service.style9 .sep.left, .service.style14 .sep.left {
    margin-left: 0; }
.service.style9:hover .icon, .service.style9:hover h4, .service.style14:hover .icon, .service.style14:hover h4 {
  color: #0D52FF; }
.service.style9.shadow {
  padding: 60px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
.service.style9:hover h4 {
  color: #444; }
.service.style9:hover .sep {
  background-color: #0D52FF; }
.service.style10 {
  margin-bottom: 75px; }
  .service.style10 .icon {
    color: #444;
    font-size: 60px;
    margin-bottom: 26px; }
  .service.style10 h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 39px;
    position: relative; }
    .service.style10 h4::after {
      content: "";
      background-color: #444;
      height: 2px;
      width: 30px;
      margin: 0 auto; }
  .service.style10 .sep {
    background-color: #444;
    display: block;
    height: 2px;
    margin: -24px auto 22px;
    width: 30px; }
    .service.style10 .sep.left {
      margin-left: 0; }
  .service.style10.light {
    color: #fff; }
    .service.style10.light .icon, .service.style10.light h4 {
      color: #fff; }
    .service.style10.light .sep {
      background-color: #fff; }
.service.style11 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 60px 45px;
  -webkit-transition: border-color 0.3s ease 0s;
  -moz-transition: border-color 0.3s ease 0s;
  -o-transition: border-color 0.3s ease 0s;
  transition: border-color 0.3s ease 0s; }
  .service.style11 .icon {
    color: #444;
    font-size: 60px;
    margin-bottom: 30px; }
  .service.style11 h4 {
    margin-bottom: 33px; }
  .service.style11 .more {
    bottom: 0;
    height: 80px;
    opacity: 0;
    position: absolute;
    right: 0;
    width: 80px; }
    .service.style11 .more::after {
      border-color: transparent #0D52FF #0D52FF transparent;
      border-style: solid;
      border-width: 40px;
      content: "";
      position: absolute;
      right: 0;
      top: 0; }
    
  .service.style11:hover {
    border-color: #0D52FF; }
    .service.style11:hover .more {
      opacity: 1; }
.service.style12 h2 {
  font-size: 22px;
  margin-bottom: 15px; }
.service.style12 .icon {
  display: inline-block;
  font-size: 40px;
  margin-left: 25px; }
.service.style13 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 100px 80px 70px;
  -webkit-transition: border-color 0.3s ease 0s;
  -moz-transition: border-color 0.3s ease 0s;
  -o-transition: border-color 0.3s ease 0s;
  transition: border-color 0.3s ease 0s; }
  .service.style13 .icon {
    background-color: #fff;
    font-size: 60px;
    left: 65px;
    padding: 0 16px;
    position: absolute;
    top: 0;
    z-index: 1;
    -webkit-transition: color 0.3s ease 0s;
    -moz-transition: color 0.3s ease 0s;
    -o-transition: color 0.3s ease 0s;
    transition: color 0.3s ease 0s;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .service.style13 h4 {
    margin-bottom: 35px; }
  .service.style13:hover {
    border-color: #0D52FF; }
    .service.style13:hover .icon {
      color: #0D52FF; }

  .service.style14 .icon::after {
    background-color: #f7f7f7;
    border-radius: 50%;
    bottom: -1px;
    content: "";
    left: 0;
    opacity: 1;
    position: absolute;
    right: -1px;
    top: -1px;
    z-index: -1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
.service.style14:hover .icon::after {
  background-color: rgba(255, 255, 255, 0.75); }
.service.style15 {
  background-color: #fafafa;
  padding: 53px 40px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -moz-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s; }
  .service.style15::before, .service.style15::after {
    background-color: #0D52FF;
    bottom: 0;
    content: "";
    height: 0;
    position: absolute;
    right: 0;
    width: 2px;
    z-index: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .service.style15::before {
    height: 2px;
    width: 0; }
  .service.style15 img {
    margin-bottom: 38px; }
  .service.style15 h6 {
    margin-bottom: 15px;
    -webkit-transition: color 0.3s ease 0s;
    -moz-transition: color 0.3s ease 0s;
    -o-transition: color 0.3s ease 0s;
    transition: color 0.3s ease 0s; }
  .service.style15:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .service.style15:hover::before {
      width: 80px; }
    .service.style15:hover::after {
      height: 80px; }
    .service.style15:hover h6 {
      color: #0D52FF; }
.service.style16 img {
  margin-bottom: 24px; }
.service.style16 h6 {
  font-weight: 400;
  margin-bottom: 20px; }
.service.style16 .more {
  background-color: #00b8d3;
  border-radius: 0 30px 30px 0;
  color: #fff;
  display: block;
  height: 35px;
  position: relative;
  width: 80px;
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }
.service.style16 .text {
  position: absolute;
  top: 50%;
  opacity: 0;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  left: -webkit-calc(50% - 17px);
  left: -moz-calc(50% - 17px);
  left: calc(50% - 17px);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
.service.style16 .arrow {
  background-color: #fff;
  border-radius: 30px;
  color: #00b8d3;
  font-size: 18px;
  height: 33px;
  line-height: 33px;
  position: absolute;
  right: 1px;
  text-align: center;
  top: 1px;
  width: 33px; }
.service.style16:nth-child(2) .more {
  background-color: #ff1644; }
.service.style16:nth-child(2) .arrow {
  color: #ff1644; }
.service.style16:nth-child(3) .more {
  background-color: #00c752; }
.service.style16:nth-child(3) .arrow {
  color: #00c752; }
.service.style16:nth-child(4) .more {
  background-color: #ffd500; }
.service.style16:nth-child(4) .arrow {
  color: #ffd500; }
.service.style16:nth-child(5) .more {
  background-color: #ff0000; }
.service.style16:nth-child(5) .arrow {
  color: #ff0000; }
.service.style16:nth-child(6) .more {
  background-color: #efefef; }
.service.style16:nth-child(6) .arrow {
  color: #efefef; }
.service.style16:nth-child(7) .more {
  background-color: #f5ba8f; }
.service.style16:nth-child(7) .arrow {
  color: #f5ba8f; }
.service.style16:nth-child(5) .more {
  background-color: #00b8ed; }
.service.style16:nth-child(5) .arrow {
  color: #00b8ed; }
.service.style16:hover .more {
  width: 250px; }
.service.style16:hover .text {
  opacity: 1; }
.service.style17 .text {
  padding: 45px 40px 42px;
  position: relative; }
  .service.style17 .text::before {
    background-color: red;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
.service.style17 h4 {
  font-size: 24px;
  margin-bottom: 20px; }
.service.style17 .more {
  font-weight: 500;
  margin-top: 10px; }
  .service.style17 .more i {
    margin-left: 5px; }
.service.style17:hover .text::before {
  width: 100%; }
.service.style18 .icon {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: inline-block;
  font-size: 26px;
  height: 80px;
  line-height: 80px;
  margin-right: 30px;
  min-width: 80px;
  text-align: center;
  width: 80px; }
.service.style18 h3 {
  font-size: 24px;
  margin: -8px 0 12px; }
.service.style18:hover .icon {
  border-color: #0D52FF;
  color: #0D52FF; }
.service.style18:hover h3 {
  color: #0D52FF; }
.service.style19 {
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff; }
  .service.style19 .icon {
    font-size: 48px;
    padding: 70px 0; }
  .service.style19 h4 {
    margin-top: 14px; }
  .service.style19 .text {
    background-color: rgba(255, 255, 255, 0.95);
    bottom: 0;
    height: 0;
    padding: 0 30px;
    position: absolute;
    right: 0; }
    .service.style19 .text h4 {
      margin-bottom: 22px; }
  .service.style19:hover .text {
    height: 100%; }
.service.style20 {
  background-color: #fff;
  border-radius: 3px;
  padding: 100px 70px 95px; }
  .service.style20 .icon, .service.style20 h3 {
    font-size: 24px; }
  .service.style20:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .service.style20:hover .icon, .service.style20:hover h3 {
      color: #0D52FF; }
.service.style21 img {
  width: 100%; }
.service.style21:hover {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .service.style21:hover .gray-bg-3 {
    background-color: #fff; }
  .service.style21:hover h5, .service.style21:hover a {
    color: #0D52FF; }
.service.style22 {
  border-radius: 2px;
  padding: 80px 40px 72px; }
  .service.style22 .icon {
    font-size: 40px; }
  .service.style22:hover {
    background-color: #fff;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .service.style22:hover .icon, .service.style22:hover a {
      color: #0D52FF; }
.service.style23 .icon {
  font-size: 60px;
  line-height: 300px;
  min-height: 300px; }
.service.style23 .desc {
  padding: 0 30px; }
.service.style23 h3 {
  font-size: 24px; }
.service.style23:hover .icon {
  background-color: #fff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  color: #0D52FF; }
.service.style23:hover a {
  color: #0D52FF; }

.bg-75-parcent {
  height: 75%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }



/*==============================================
    Portfolio Filter
===============================================*/
.masonry-filter li,
.masonry-filter-2 li {
  display: inline-block;
  margin-left: -4px; }
  .masonry-filter li:first-child,
  .masonry-filter-2 li:first-child {
    margin-left: 0; }
  .masonry-filter li a,
  .masonry-filter-2 li a {
    display: block; }
.masonry-filter.style1,
.masonry-filter-2.style1 {
  border-radius: 5px;
  margin-bottom: 80px; }
  .masonry-filter.style1 li a,
  .masonry-filter-2.style1 li a {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 0;
    color: #fff;
    font-size: 12px;
    line-height: 29px;
    padding: 0 33px; }
  .masonry-filter.style1 li:first-child a,
  .masonry-filter-2.style1 li:first-child a {
    border-radius: 2px 0 0 2px;
    border-left: 1px solid rgba(255, 255, 255, 0.2); }
  .masonry-filter.style1 li:last-child a,
  .masonry-filter-2.style1 li:last-child a {
    border-radius: 0 2px 2px 0; }
  .masonry-filter.style1 .active a,
  .masonry-filter-2.style1 .active a {
    background-color: #fff;
    color: #444; }
  .masonry-filter.style1 a:hover, .masonry-filter.style1 a.active,
  .masonry-filter-2.style1 a:hover,
  .masonry-filter-2.style1 a.active {
    background-color: #fff;
    color: #444; }
  .masonry-filter.style1.dark li a,
  .masonry-filter-2.style1.dark li a {
    border-color: rgba(0, 0, 0, 0.1);
    color: #444; }
  .masonry-filter.style1.dark li:last-child a,
  .masonry-filter-2.style1.dark li:last-child a {
    border-right-color: rgba(0, 0, 0, 0.1); }
  .masonry-filter.style1.dark li a:hover, .masonry-filter.style1.dark li a.active,
  .masonry-filter-2.style1.dark li a:hover,
  .masonry-filter-2.style1.dark li a.active {
    background-color: #0D52FF;
    border-color: #0D52FF;
    color: #fff; }
.masonry-filter.style-2,
.masonry-filter-2.style-2 {
  margin-bottom: 90px; }
  .masonry-filter.style-2 li,
  .masonry-filter-2.style-2 li {
    margin-left: -3px; }
    .masonry-filter.style-2 li a,
    .masonry-filter-2.style-2 li a {
      background-color: #f3f3f3;
      color: #444;
      font-size: 10px;
      font-weight: 400;
      padding: 5px 40px;
      text-transform: uppercase; }
    .masonry-filter.style-2 li .active,
    .masonry-filter-2.style-2 li .active {
      background-color: #0D52FF;
      color: #fff; }
    .masonry-filter.style-2 li:first-child a,
    .masonry-filter-2.style-2 li:first-child a {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px; }
    .masonry-filter.style-2 li:last-child a,
    .masonry-filter-2.style-2 li:last-child a {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px; }
.masonry-filter.style-3, .masonry-filter.style-4,
.masonry-filter-2.style-3,
.masonry-filter-2.style-4 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .masonry-filter.style-3 li a, .masonry-filter.style-4 li a,
  .masonry-filter-2.style-3 li a,
  .masonry-filter-2.style-4 li a {
    color: #444;
    font-size: 12px;
    padding: 0 15px 20px;
    position: relative; }
    .masonry-filter.style-3 li a::before, .masonry-filter.style-4 li a::before,
    .masonry-filter-2.style-3 li a::before,
    .masonry-filter-2.style-4 li a::before {
      background-color: #0D52FF;
      content: "";
      height: 2px;
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 0;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
  .masonry-filter.style-3 li .active::before, .masonry-filter.style-4 li .active::before,
  .masonry-filter-2.style-3 li .active::before,
  .masonry-filter-2.style-4 li .active::before {
    width: 100%; }
  .masonry-filter.style-3 .active a::before, .masonry-filter.style-4 .active a::before,
  .masonry-filter-2.style-3 .active a::before,
  .masonry-filter-2.style-4 .active a::before {
    width: 100%; }
.masonry-filter.style-3.light,
.masonry-filter-2.style-3.light {
  border-bottom-color: rgba(255, 255, 255, 0.3); }
  .masonry-filter.style-3.light li a,
  .masonry-filter-2.style-3.light li a {
    color: #fff; }
.masonry-filter.style-4 li a::before,
.masonry-filter-2.style-4 li a::before {
  background-color: #444; }
.masonry-filter.style-5 li a,
.masonry-filter-2.style-5 li a {
  border-radius: 2px;
  color: #848484;
  line-height: 1;
  padding: 8px 18px; }
  .masonry-filter.style-5 li a:hover, .masonry-filter.style-5 li a.active,
  .masonry-filter-2.style-5 li a:hover,
  .masonry-filter-2.style-5 li a.active {
    background-color: #0D52FF;
    color: #fff; }
.masonry-filter.style-6 li a,
.masonry-filter-2.style-6 li a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 0;
  color: #848484;
  line-height: 29px;
  padding: 0 33px; }
  .masonry-filter.style-6 li a:hover, .masonry-filter.style-6 li a.active,
  .masonry-filter-2.style-6 li a:hover,
  .masonry-filter-2.style-6 li a.active {
    background-color: #444;
    border-color: #444;
    color: #fff; }
.masonry-filter.style-6 li:first-child a,
.masonry-filter-2.style-6 li:first-child a {
  border-radius: 15px 0 0 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.1); }
.masonry-filter.style-6 li:last-child a,
.masonry-filter-2.style-6 li:last-child a {
  border-radius: 0 15px 15px 0; }
.masonry-filter.style-7 li a,
.masonry-filter-2.style-7 li a {
  color: #848484;
  line-height: 1;
  padding: 17px 15px 11px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  position: relative; }
  .masonry-filter.style-7 li a:hover,
  .masonry-filter-2.style-7 li a:hover {
    color: #444; }
  .masonry-filter.style-7 li a.active,
  .masonry-filter-2.style-7 li a.active {
    background-image: -moz-linear-gradient(90deg, #fff 0%, #f3f4f4 65%, #e7e8e9 100%);
    background-image: -webkit-linear-gradient(90deg, #fff 0%, #f3f4f4 65%, #e7e8e9 100%);
    background-image: -ms-linear-gradient(90deg, #fff 0%, #f3f4f4 65%, #e7e8e9 100%);
    background-image: linear-gradient(0deg, #fff 0%, #f3f4f4 65%, #e7e8e9 100%);
    border-color: rgba(0, 0, 0, 0.1);
    color: #444; }
    .masonry-filter.style-7 li a.active::before,
    .masonry-filter-2.style-7 li a.active::before {
      border: 1px solid #fff;
      bottom: -2px;
      content: "";
      left: 0;
      position: absolute;
      width: 100%; }
.masonry-filter.style-7 li:first-child a,
.masonry-filter-2.style-7 li:first-child a {
  border-left: 1px solid transparent; }

.filter-with-actions {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .filter-with-actions .masonry-filter {
    border-bottom: 0; }

.portfolio-heading {
  padding-bottom: 50px;
  padding-top: 40px;
  position: relative; }

.portfolio-toggle {
  bottom: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

/*==============================================
    Portfolio
===============================================*/
.portfolio-carousel .owl-prev, .portfolio-carousel .owl-next {
  position: absolute;
  top: 50%;
  left: 10%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
.portfolio-carousel .owl-next {
  left: auto;
  right: 10%; }

.portfolio-carousel-3-col .owl-prev,
.portfolio-carousel-3-col .owl-next {
  position: absolute;
  top: 50%; }
.portfolio-carousel-3-col .owl-prev {
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
.portfolio-carousel-3-col .owl-next {
  right: 0;
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
  transform: translate(50%, -50%); }

.portfolio-wrapper {
  background-color: #212121; }
  .portfolio-wrapper .portfolio {
    margin-bottom: 30px; }

/*----------------------------------------
    Portfolio type-1
------------------------------------------*/
.portfolio {
  position: relative;
  margin-bottom: 30px;
  /*----------------------------------------
      Portfolio type-2
  ------------------------------------------*/
  /*----------------------------------------
      Portfolio type-3
  ------------------------------------------*/ }
  .portfolio.mb-0 {
    margin-bottom: 0; }
  .portfolio .portfolio-center {
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%; }
    .portfolio .portfolio-center::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle; }
  .portfolio .portfolio-center-inner {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%; }
  .portfolio.type-1 {
    text-align: center; }
    .portfolio.type-1 .pf-thumb {
      position: relative; }
      .portfolio.type-1 .pf-thumb img {
        margin: 0 auto;
        width: 100%;
        display: block; }
    .portfolio.type-1 .hover {
      background-color: rgba(246, 81, 76, 0.9);
      bottom: 20px;
      left: 20px;
      opacity: 0;
      position: absolute;
      right: 20px;
      text-align: center;
      top: 20px;
      -webkit-transition: opacity 0.3s ease 0s;
      -moz-transition: opacity 0.3s ease 0s;
      -o-transition: opacity 0.3s ease 0s;
      transition: opacity 0.3s ease 0s; }
      .portfolio.type-1 .hover::after {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle; }
    .portfolio.type-1 .hover-inner {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle; }
    .portfolio.type-1 a {
      margin: 0 6px; }
    .portfolio.type-1 h5 {
      color: #fff;
      font-size: 16px;
      margin-top: 25px;
      text-transform: uppercase; }
    .portfolio.type-1:hover .hover {
      opacity: 1; }
  .portfolio.type-2 {
    background-color: #f5f2f2;
    padding: 30px;
    text-align: left; }
    .portfolio.type-2 figure {
      min-height: 520px;
      position: relative; }
      .portfolio.type-2 figure img {
        bottom: 0;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: -50px; }
    .portfolio.type-2 figcaption {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; }
    .portfolio.type-2 .cat {
      font-weight: 700; }
    .portfolio.type-2 h4 a {
      color: #444;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center; }
      .portfolio.type-2 h4 a:hover {
        color: #0D52FF; }
        .portfolio.type-2 h4 a:hover i {
          -webkit-transform: translateX(5px);
          -moz-transform: translateX(5px);
          -ms-transform: translateX(5px);
          -o-transform: translateX(5px);
          transform: translateX(5px); }
    .portfolio.type-2 h4 i {
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
  .portfolio.type-3 {
    background-color: #fff;
    -webkit-transition: box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    -moz-transition: box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    -o-transition: box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transition: box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
    .portfolio.type-3 .actions {
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%;
      -webkit-transform: scale(0) translateY(-50%);
      -moz-transform: scale(0) translateY(-50%);
      -ms-transform: scale(0) translateY(-50%);
      -o-transform: scale(0) translateY(-50%);
      transform: scale(0) translateY(-50%);
      -webkit-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      -moz-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      -o-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
      .portfolio.type-3 .actions a {
        margin: 0 4px; }
      .portfolio.type-3 .actions i {
        display: block;
        margin: 0; }
    .portfolio.type-3 .caption {
      padding: 13px 20px 15px;
      position: relative; }
      .portfolio.type-3 .caption p {
        margin: 0; }
      .portfolio.type-3 .caption .more-vert {
        position: absolute;
        right: 25px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
        .portfolio.type-3 .caption .more-vert > a {
          box-shadow: none; }
          .portfolio.type-3 .caption .more-vert > a:hover {
            box-shadow: none; }
    .portfolio.type-3:hover {
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
      .portfolio.type-3:hover .hover-ripple {
        -webkit-transform: scale(6);
        -moz-transform: scale(6);
        -ms-transform: scale(6);
        -o-transform: scale(6);
        transform: scale(6); }
      .portfolio.type-3:hover .actions {
        -webkit-transform: scale(1) translateY(-50%);
        -moz-transform: scale(1) translateY(-50%);
        -ms-transform: scale(1) translateY(-50%);
        -o-transform: scale(1) translateY(-50%);
        transform: scale(1) translateY(-50%); }
  .portfolio.type-4 img {
    width: 100%; }
  .portfolio.type-5, .portfolio.type-6, .portfolio.type-7 {
    margin: 0 7.5px 15px; }
    .portfolio.type-5.mb-30, .portfolio.type-6.mb-30, .portfolio.type-7.mb-30 {
      margin: 0 0 30px 0; }
    .portfolio.type-5 img, .portfolio.type-6 img, .portfolio.type-7 img {
      width: 100%; }
    .portfolio.type-5 h3, .portfolio.type-6 h3, .portfolio.type-7 h3 {
      font-size: 24px;
      margin-bottom: 6px; }
    .portfolio.type-5 h3, .portfolio.type-5 p, .portfolio.type-6 h3, .portfolio.type-6 p, .portfolio.type-7 h3, .portfolio.type-7 p {
      color: #fff;
      letter-spacing: 2.8px; }
    .portfolio.type-5 .hover-ripple, .portfolio.type-6 .hover-ripple, .portfolio.type-7 .hover-ripple {
      opacity: .7;
      -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      -moz-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      -o-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
    .portfolio.type-5 .hover, .portfolio.type-6 .hover, .portfolio.type-7 .hover {
      opacity: 0;
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
      -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      -moz-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      -o-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
    .portfolio.type-5:hover .hover-ripple, .portfolio.type-5.hover .hover-ripple, .portfolio.type-5.slick-active.slick-center .hover-ripple, .portfolio.type-6:hover .hover-ripple, .portfolio.type-6.hover .hover-ripple, .portfolio.type-6.slick-active.slick-center .hover-ripple, .portfolio.type-7:hover .hover-ripple, .portfolio.type-7.hover .hover-ripple, .portfolio.type-7.slick-active.slick-center .hover-ripple {
      -webkit-transform: scale(11);
      -moz-transform: scale(11);
      -ms-transform: scale(11);
      -o-transform: scale(11);
      transform: scale(11); }
    .portfolio.type-5:hover .hover, .portfolio.type-5.hover .hover, .portfolio.type-5.slick-active.slick-center .hover, .portfolio.type-6:hover .hover, .portfolio.type-6.hover .hover, .portfolio.type-6.slick-active.slick-center .hover, .portfolio.type-7:hover .hover, .portfolio.type-7.hover .hover, .portfolio.type-7.slick-active.slick-center .hover {
      opacity: 1;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1); }
  .portfolio.type-6 {
    margin: 0; }
    .portfolio.type-6 .hover a {
      color: #fff;
      font-size: 40px; }
  .portfolio.type-7 {
    margin: 0 0 30px 0; }
  .portfolio.type-8 img {
    width: 100%; }
  .portfolio.type-8 .overlay {
    background: -webkit-linear-gradient(-35deg, rgba(0, 0, 0, 0.5) 50%, rgba(246, 81, 76, 0.5) 50%) repeat scroll 0 0;
    background: -moz-linear-gradient(-35deg, rgba(0, 0, 0, 0.5) 50%, rgba(246, 81, 76, 0.5) 50%) repeat scroll 0 0;
    background: -o-linear-gradient(-35deg, rgba(0, 0, 0, 0.5) 50%, rgba(246, 81, 76, 0.5) 50%) repeat scroll 0 0;
    background: linear-gradient(-35deg, rgba(0, 0, 0, 0.5) 50%, rgba(246, 81, 76, 0.5) 50%) repeat scroll 0 0;
    opacity: 0; }
  .portfolio.type-8 .hover- {
    bottom: 0;
    left: 50px;
    position: absolute;
    right: 50px;
    z-index: 1;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%); }
  .portfolio.type-8 .image-popup {
    border: 1px solid;
    border-radius: 5px;
    color: #fff;
    font-size: 30px;
    left: 50%;
    line-height: 60px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 60px;
    z-index: 1;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -moz-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    -o-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0); }
  .portfolio.type-8:hover .overlay {
    opacity: 1; }
  .portfolio.type-8:hover .image-popup {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); }
  .portfolio.type-8:hover .hover- {
    bottom: 50px;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  .portfolio.type-9 {
    padding: 25px 20px; }
    .portfolio.type-9 img {
      width: 100%; }
    .portfolio.type-9:hover img {
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      transform: rotate(-3deg); }
  .portfolio.style-10 + .portfolio {
    margin-top: 50px; }
  .portfolio.style-10 img {
    width: 100%; }
  .portfolio.style-10 .actions {
    background-color: rgba(255, 255, 255, 0.95);
    bottom: 30px;
    font-size: 24px;
    left: 0;
    padding-bottom: 15px;
    padding-top: 15px;
    position: absolute;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .portfolio.style-10:hover .actions {
    opacity: 1;
    bottom: 50px; }
  .portfolio.style-11 img {
    outline: 0px solid #fff;
    outline-offset: -10px;
    width: 100%; }
  .portfolio.style-11 figcaption {
    background-color: rgba(255, 255, 255, 0.8);
    bottom: 0;
    left: 0;
    padding: 30px 50px;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%); }
  .portfolio.style-11 .link {
    background-color: #0D52FF;
    border-radius: 30px;
    color: #fff;
    height: 40px;
    line-height: 43px;
    opacity: 0;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10px;
    width: 40px; }
  .portfolio.style-11:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .portfolio.style-11:hover img {
      outline: 10px solid #fff; }
    .portfolio.style-11:hover figcaption {
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px); }
    .portfolio.style-11:hover .link {
      opacity: 1; }
  .portfolio.style-12 .caption {
    background-color: rgba(255, 255, 255, 0.95);
    height: 100%;
    max-width: 520px;
    padding: 0 80px;
    position: absolute;
    right: 0;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%); }
    .portfolio.style-12 .caption i {
      font-size: 48px; }
    .portfolio.style-12 .caption i, .portfolio.style-12 .caption h3, .portfolio.style-12 .caption p, .portfolio.style-12 .caption .btn-large {
      position: relative;
      left: 30px;
      opacity: 0; }
    .portfolio.style-12 .caption i {
      -webkit-transition: all 0.3s ease 0.2s;
      -moz-transition: all 0.3s ease 0.2s;
      -o-transition: all 0.3s ease 0.2s;
      transition: all 0.3s ease 0.2s; }
    .portfolio.style-12 .caption h3 {
      -webkit-transition: all 0.3s ease 0.3s;
      -moz-transition: all 0.3s ease 0.3s;
      -o-transition: all 0.3s ease 0.3s;
      transition: all 0.3s ease 0.3s; }
    .portfolio.style-12 .caption p {
      -webkit-transition: all 0.3s ease 0.4s;
      -moz-transition: all 0.3s ease 0.4s;
      -o-transition: all 0.3s ease 0.4s;
      transition: all 0.3s ease 0.4s; }
    .portfolio.style-12 .caption .btn-large {
      -webkit-transition: all 0.3s ease 0.5s;
      -moz-transition: all 0.3s ease 0.5s;
      -o-transition: all 0.3s ease 0.5s;
      transition: all 0.3s ease 0.5s; }
  .portfolio.style-12:hover .caption {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
    .portfolio.style-12:hover .caption i, .portfolio.style-12:hover .caption h3, .portfolio.style-12:hover .caption p, .portfolio.style-12:hover .caption .btn-large {
      left: 0;
      opacity: 1; }
  .portfolio.style-13 .overlay,
  .portfolio.style-13 .container {
    opacity: 0;
    visibility: hidden; }
  .portfolio.style-13 .icon {
    font-size: 48px; }
  .portfolio.style-13:hover .overlay,
  .portfolio.style-13:hover .container {
    opacity: 0.8;
    visibility: visible; }
  .portfolio.style-14:hover .thumb {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }
  .portfolio.style-14:hover h4 a {
    color: #0D52FF; }

.col-lg-3 .portfolio.style-11 figcaption {
  padding: 20px 30px; }

.col-lg-2 .portfolio.style-11 figcaption {
  padding: 14px 30px 16px;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px); }

.masonry.fullwidth-gutter {
  margin-left: -7.5px;
  margin-right: -7.5px; }
  .masonry.fullwidth-gutter > [class^="col"] {
    padding-right: 7.5px;
    padding-left: 7.5px; }

.isotope-gutter [class^="col-"]:nth-last-child(1) .portfolio,
.isotope-gutter [class^="col-"]:nth-last-child(2) .portfolio {
  margin-bottom: 0; }

/*==============================================
    cp Gallery
===============================================*/
.cp-gallery {
  position: relative;
  overflow: hidden; }
  .cp-gallery figcaption {
    bottom: 40px;
    left: 50px;
    opacity: 0;
    position: absolute;
    right: 50px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left bottom 0;
    -moz-transform-origin: left bottom 0;
    -ms-transform-origin: left bottom 0;
    -o-transform-origin: left bottom 0;
    transform-origin: left bottom 0;
    -webkit-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    -moz-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    -o-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
    .cp-gallery figcaption span {
      color: #0D52FF; }
    .cp-gallery figcaption h3 {
      color: #fff; }
  .cp-gallery:hover .hover-ripple {
    -webkit-transform: scale(7);
    -moz-transform: scale(7);
    -ms-transform: scale(7);
    -o-transform: scale(7);
    transform: scale(7); }
  .cp-gallery:hover figcaption {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }

/*==============================================
    Image Gallery
===============================================*/
.image-gallery-1 .owl-prev {
  left: 10px; }
.image-gallery-1 .owl-next {
  right: 10px; }

.expander-details-wrapper {
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10; }
  .expander-details-wrapper.static {
    position: static; }

.expander-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 16px; }

.popup-portfolio .expander-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
.popup-portfolio .mfp-close {
  display: none; }
.popup-portfolio .expander-close::before {
  background-color: #fff; }
.popup-portfolio .expander-close:hover {
  color: rgba(255, 255, 255, 0.87); }

.expander-actions a {
  color: #848484;
  display: block;
  font-size: 24px;
  height: 30px;
  line-height: 30px;
  position: relative;
  text-align: center;
  width: 55px; }
  .expander-actions a::before {
    background-color: #000;
    bottom: -17px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .expander-actions a:hover {
    color: #444; }
    .expander-actions a:hover::before {
      width: 100%; }
.expander-actions .expander-close {
  margin-right: 0; }

.pf-carousel-3col,
.pf-slider-1col {
  margin-left: -15px;
  margin-right: -15px; }
  .pf-carousel-3col .portfolio,
  .pf-slider-1col .portfolio {
    margin-left: 15px;
    margin-right: 15px; }
  .pf-carousel-3col .btn-large,
  .pf-slider-1col .btn-large {
    position: absolute;
    top: 50%; }
  .pf-carousel-3col .slick-prev,
  .pf-slider-1col .slick-prev {
    left: 15px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .pf-carousel-3col .slick-next,
  .pf-slider-1col .slick-next {
    right: 15px;
    -webkit-transform: translate(50%, -50%);
    -moz-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    -o-transform: translate(50%, -50%);
    transform: translate(50%, -50%); }

.pf-carousel-3col .portfolio.type-7 .hover-ripple {
  -webkit-transform: scale(11);
  -moz-transform: scale(11);
  -ms-transform: scale(11);
  -o-transform: scale(11);
  transform: scale(11); }
.pf-carousel-3col .portfolio.type-7.slick-active.slick-center .hover-ripple {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0); }
.pf-carousel-3col .slick-arrow {
  background-color: #fff;
  opacity: 0; }
  .pf-carousel-3col .slick-arrow i {
    color: #ccc; }
  .pf-carousel-3col .slick-arrow:hover {
    background-color: #0D52FF;
    opacity: 1; }
    .pf-carousel-3col .slick-arrow:hover i {
      color: #fff; }
.pf-carousel-3col:hover .slick-arrow {
  opacity: 1; }

.pf-slider-1col {
  margin-left: auto;
  margin-right: auto; }
  .pf-slider-1col .item {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    position: relative; }
  .pf-slider-1col .portfolio {
    margin-left: auto;
    margin-right: auto; }

.portfolio-meta li {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.7; }
  .portfolio-meta li span {
    display: inline-block;
    font-weight: 400;
    min-width: 130px; }

/*==============================================
    Features
===============================================*/
.feature-1 {
  padding-right: 3px !important; }
  .feature-1:last-child {
    padding-right: 0 !important; }
  .feature-1 .feature-inner {
    color: #fff;
    padding: 40px 15px 46px;
    position: relative;
    z-index: 10; }
    .feature-1 .feature-inner > * {
      position: relative;
      z-index: 2; }
  .feature-1 .hover {
    background-color: #0D52FF;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .feature-1:hover .hover, .feature-1.active .hover {
    bottom: -20px;
    top: -20px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .feature-1 .icon {
    font-size: 60px;
    padding: 9px 28px 0 0; }
  .feature-1 h3 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px; }

.feature-2 .number {
  color: rgba(0, 0, 0, 0.1);
  font-size: 51px;
  font-weight: 700; }
.feature-2 h4 {
  margin-bottom: 13px; }

.feature-3-devider {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .feature-3-devider::before, .feature-3-devider::after {
    background-color: rgba(0, 0, 0, 0.2);
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    top: 50%; }
  .feature-3-devider::after {
    height: 100%;
    width: 1px;
    top: 0;
    left: 50%; }
  .feature-3-devider .icon {
    background-color: #f7f7f7;
    padding: 10px;
    left: 50%;
    position: absolute;
    top: 50%;
    z-index: 1;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.feature-3 {
  padding: 60px 65px 70px; }
  .feature-3 h2 {
    font-size: 36px;
    margin-bottom: 18px; }

.feature-4 .icon span {
  background-color: #fff;
  border-radius: 50%;
  display: block;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1);
  font-size: 30px;
  height: 80px;
  line-height: 80px;
  margin-right: 30px;
  text-align: center;
  width: 80px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
.feature-4 .icon i {
  line-height: inherit; }
.feature-4 h4 {
  font-size: 18px;
  margin-bottom: 7px; }
.feature-4:hover .icon span {
  color: #fff;
  background-color: #0D52FF;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }

.feature-5 {
  margin-bottom: 33px; }
  .feature-5:last-child {
    margin-bottom: 0; }
  .feature-5 h3 {
    font-size: 24px;
    margin-bottom: 23px; }

.feature-6 {
  margin-bottom: 30px; }
  .feature-6 .icon {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 130px;
    float: left;
    font-size: 48px;
    height: 130px;
    line-height: 130px;
    position: relative;
    text-align: center;
    width: 130px;
    z-index: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .feature-6 .icon::before {
      background-color: #ddd;
      border-radius: 130px;
      bottom: 3px;
      content: "";
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: -1;
      -webkit-transition: background 0.3s ease 0s;
      -moz-transition: background 0.3s ease 0s;
      -o-transition: background 0.3s ease 0s;
      transition: background 0.3s ease 0s; }
    .feature-6 .icon::after {
      background-color: rgba(0, 0, 0, 0.2);
      content: "";
      height: 2px;
      left: 100%;
      position: absolute;
      top: 50%;
      width: 60px;
      -webkit-transition: background 0.3s ease 0s;
      -moz-transition: background 0.3s ease 0s;
      -o-transition: background 0.3s ease 0s;
      transition: background 0.3s ease 0s; }
  .feature-6 .text {
    float: left;
    margin: 45px 0 0 90px;
    width: -webkit-calc(100% - 220px);
    width: -moz-calc(100% - 220px);
    width: calc(100% - 220px); }
    .feature-6 .text h3 {
      font-size: 30px;
      margin-bottom: 11px;
      -webkit-transition: color 0.3s ease 0s;
      -moz-transition: color 0.3s ease 0s;
      -o-transition: color 0.3s ease 0s;
      transition: color 0.3s ease 0s; }
    .feature-6 .text p {
      font-size: 14px; }
  .feature-6:hover .icon {
    color: #fff;
    border-color: #0D52FF; }
    .feature-6:hover .icon::before {
      background-color: #0D52FF; }
    .feature-6:hover .icon::after {
      background-color: #0D52FF; }
  .feature-6:hover h3 {
    color: #0D52FF; }
  .feature-6:nth-child(4) {
    margin-bottom: 0; }


/*==============================================
    Price Table
===============================================*/
.price-table {
  padding: 43px 35px 70px; }

.price-head h3 {
  font-size: 24px;
  font-weight: 100; }
.price-head p {
  font-size: 18px;
  margin-top: 3px; }
.price-head .price {
  color: #0D52FF;
  font-size: 60px;
  font-weight: 500;
  line-height: 1;
  margin-top: 7px; }
.price-head span {
  display: inline-block;
  font-size: 18px;
  font-weight: 100;
  margin-top: 20px; }

.price-body {
  margin-top: 36px; }
  .price-body li {
    font-size: 13px;
    line-height: 17px; }

.price-foot {
  margin-top: 52px; }

/*--------- Price 1 ----------*/
.price-table.style1 {
  color: #444; }
  .price-table.style1 li {
    position: relative;
    padding-left: 24px;
    margin-bottom: 15px; }
    .price-table.style1 li:before, .price-table.style1 li::after {
      background-color: #444;
      border-radius: 20px;
      content: "";
      height: 10px;
      left: 0;
      position: absolute;
      top: 4px;
      width: 10px; }
    .price-table.style1 li::after {
      background-color: #fff;
      height: 4px;
      left: 3px;
      top: 7px;
      width: 4px; }
.price-table.style2 li, .price-table.style3 li, .price-table.style4 li {
  font-size: 16px;
  font-weight: 500;
  line-height: 48px; }
.price-table.style3 .price-body, .price-table.style4 .price-body {
  margin-left: -35px;
  margin-right: -35px; }
.price-table.style3 li, .price-table.style4 li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 35px; }
  .price-table.style3 li:last-child, .price-table.style4 li:last-child {
    border-bottom: 0; }
.price-table.style4 {
  padding-bottom: 40px; }
  .price-table.style4:hover .price-body {
    color: #444; }

/*==============================================
    Testimonial
===============================================*/
.tm-carousel-2 .owl-stage-outer,
.tm-carousel-3 .owl-stage-outer,
.tm-carousel-4 .owl-stage-outer {
  padding: 1px 0px 30px 1px; }

.tm-carousel-3 .owl-dots {
  margin-top: 40px; }
.tm-carousel-3 .owl-dot {
  opacity: 0.38; }
  .tm-carousel-3 .owl-dot.active {
    opacity: 1; }

.tm-carousel-4 {
  /*.owl-nav {
      bottom: 10px;
      left: 0;
      margin-top: 0;
      position: absolute;
      right: 0;
  }*/ }

.tm-carousel-5 .owl-stage-outer,
.tm-carousel-7 .owl-stage-outer {
  padding: 5px 0 30px; }

.tm-carousel-12 {
  background-color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .tm-carousel-12:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }
  .tm-carousel-12 .owl-dots {
    left: 0;
    position: absolute;
    right: 0; }

.tm-title {
  color: #fff; }
  .tm-title h2 {
    color: #fff;
    font-size: 46px;
    line-height: 50px;
    margin-top: 35px; }
  .tm-title p {
    font-size: 16px;
    font-style: italic;
    line-height: 26px;
    margin-top: 26px; }

.tm-item.style1 {
  text-align: center; }
  .tm-item.style1 .chip {
    background-color: #fff;
    border-radius: 50px;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 30px;
    padding-right: 35px; }
    .tm-item.style1 .chip img {
      height: 50px;
      margin-right: 20px;
      width: 50px; }
  .tm-item.style1 p {
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px; }
.tm-item.style2, .tm-item.style4 {
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 48px 35px 40px;
  position: relative; }
  .tm-item.style2::before, .tm-item.style2::after, .tm-item.style4::before, .tm-item.style4::after {
    background-color: #fff;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    content: '';
    position: absolute;
    left: 10px;
    bottom: -10px;
    right: 10px;
    height: 10px;
    z-index: -1; }
  .tm-item.style2::after, .tm-item.style4::after {
    bottom: -20px;
    left: 20px;
    right: 20px;
    z-index: -2; }
  .tm-item.style2 i, .tm-item.style4 i {
    font-size: 36px;
    margin-bottom: 14px; }
  .tm-item.style2 blockquote, .tm-item.style4 blockquote {
    border: 0;
    margin: 0;
    padding: 0; }
  .tm-item.style2 p, .tm-item.style4 p {
    color: #444;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 32px; }
  .tm-item.style2 .author-info, .tm-item.style4 .author-info {
    text-align: center;
    margin-top: 24px; }
    .tm-item.style2 .author-info span, .tm-item.style4 .author-info span {
      display: block;
      font-weight: 500;
      text-transform: uppercase; }
      .tm-item.style2 .author-info span a, .tm-item.style4 .author-info span a {
        color: #444; }
    .tm-item.style2 .author-info .name, .tm-item.style4 .author-info .name {
      color: #0D52FF; }
.tm-item.style3 {
  background-color: #fff;
  padding: 44px 30px 50px;
  margin-bottom: 30px; }
  .tm-item.style3 p {
    color: #444;
    font-size: 16px; }
  .tm-item.style3 .media-left {
    padding-right: 15px; }
    .tm-item.style3 .media-left img {
      width: 80px;
      height: 80px; }
  .tm-item.style3 .media-body {
    vertical-align: middle; }
    .tm-item.style3 .media-body a {
      color: #444; }
      .tm-item.style3 .media-body a:hover {
        color: #0D52FF; }
  .tm-item.style3 span {
    display: block; }
  .tm-item.style3 .name {
    color: #0D52FF;
    font-size: 16px; }
.tm-item.style4 {
  margin: 0 7px;
  padding: 85px 50px 50px; }
  .tm-item.style4 p {
    font-size: 14px;
    font-weight: 300; }
  .tm-item.style4 span {
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap; }
    .tm-item.style4 span span {
      color: #444; }
.tm-item.style5 .thumb {
  margin-bottom: 24px; }
  .tm-item.style5 .thumb img {
    margin: 0 auto;
    width: auto; }
  .tm-item.style5 .thumb svg {
    width: 100px; }
.tm-item.style5 .border-shape {
  stroke: #fff;
  fill: #fff; }
.tm-item.style5 .meta {
  margin-bottom: 44px; }
.tm-item.style6 {
  background-color: #fcfcfc;
  padding: 50px 53px 60px;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95); }
  .tm-item.style6:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }
  .tm-item.style6 .thumb {
    width: 60px;
    height: 60px;
    margin: 0 auto 35px; }
  .tm-item.style6 img {
    border-radius: 50%;
    height: 100%;
    width: 100%; }
  .tm-item.style6 p {
    color: #444;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 30px; }
  .tm-item.style6 h4 {
    color: #0D52FF;
    font-size: 16px;
    font-weight: 500; }
  .tm-item.style6:hover {
    background-color: #fff; }
.tm-item.style7 .thumb {
  min-width: 220px;
  padding-right: 30px; }
.tm-item.style7 h3 {
  font-size: 24px; }
.tm-item.style8 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 45px 60px;
  margin-bottom: 30px;
  opacity: 0.2;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  .tm-item.style8.slick-center {
    box-shadow: 0px 2px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    border-color: transparent;
    opacity: 1; }
  .tm-item.style8 blockquote {
    border: 0 none;
    color: #444;
    font-size: 18px;
    font-style: italic;
    line-height: 31px;
    padding: 0; }
  .tm-item.style8 .thumb {
    padding-right: 20px; }
  .tm-item.style8 img {
    height: 60px;
    width: 60px; }
  .tm-item.style8 h4 {
    font-size: 16px; }
  .tm-item.style8 a {
    color: #848484; }
    .tm-item.style8 a:hover {
      color: #0D52FF; }
.tm-item.style9 {
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 50px;
  -webkit-transition: border-color 0.4s ease 0s;
  -moz-transition: border-color 0.4s ease 0s;
  -o-transition: border-color 0.4s ease 0s;
  transition: border-color 0.4s ease 0s; }
  .tm-item.style9 p {
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 30px; }
  .tm-item.style9 img {
    float: left;
    height: 60px;
    margin-right: 20px;
    width: 60px; }
  .tm-item.style9 .name {
    color: #0D52FF;
    font-size: 16px; }
  .tm-item.style9 a {
    color: #848484; }
  .tm-item.style9:hover {
    border-bottom-color: #0D52FF; }

.tm-item-10 {
  padding: 6px; }
  .tm-item-10 blockquote {
    border-radius: 5px;
    border: 0 none;
    font-size: 14px;
    font-style: italic;
    padding: 53px 50px; }
  .tm-item-10 .caret-bottom {
    clip: rect(15px, 40px, 45px, -15px);
    left: 65px;
    opacity: 1;
    position: absolute;
    top: -webkit-calc(100% - 15px);
    top: -moz-calc(100% - 15px);
    top: calc(100% - 15px); }
    .tm-item-10 .caret-bottom::before {
      background-color: #fff;
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      display: block;
      content: "";
      height: 30px;
      width: 30px;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
  .tm-item-10 img {
    height: 60px;
    width: 60px; }
  .tm-item-10:hover blockquote {
    background-color: #0D52FF;
    color: #fff; }
  .tm-item-10:hover h5 {
    color: #0D52FF; }
  .tm-item-10:hover .caret-bottom::before {
    background-color: #0D52FF; }

.tm-item-11 {
  background-color: #fff;
  padding: 60px 50px;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1); }
  .tm-item-11 blockquote {
    border: 0;
    color: #444;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 43px;
    padding: 0; }
  .tm-item-11 .et-line {
    font-size: 30px; }

.tm-item-12 .thumb {
  min-width: 260px;
  padding: 5px; }
.tm-item-12 .text {
  margin: 0 30px 0 60px; }
.tm-item-12 blockquote {
  border: 0 none;
  font-size: 14px;
  font-style: italic;
  margin: 0 0 25px;
  padding: 0; }

.tm-4-title {
  left: 70px;
  position: absolute;
  top: 40px;
  z-index: 9; }

.tm-carousel-6 .owl-nav {
  display: none; }
.tm-carousel-6.ctrl-nav-3 .owl-nav {
  display: block;
  margin: 0; }
.tm-carousel-6.ctrl-nav-3 .owl-prev,
.tm-carousel-6.ctrl-nav-3 .owl-next {
  background-color: #f7f7f7;
  border-radius: 3px;
  box-shadow: none;
  height: 50px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .tm-carousel-6.ctrl-nav-3 .owl-prev i,
  .tm-carousel-6.ctrl-nav-3 .owl-next i {
    color: #848484;
    font-size: 36px;
    line-height: 50px; }
  .tm-carousel-6.ctrl-nav-3 .owl-prev:hover,
  .tm-carousel-6.ctrl-nav-3 .owl-next:hover {
    background-color: #0D52FF; }
    .tm-carousel-6.ctrl-nav-3 .owl-prev:hover i,
    .tm-carousel-6.ctrl-nav-3 .owl-next:hover i {
      color: #fff; }
.tm-carousel-6.ctrl-nav-3 .owl-next {
  left: auto;
  right: 0; }

.tm-carousel-8 .slick-list {
  padding: 0 17px !important; }
.tm-carousel-8 .slick-dots {
  position: absolute;
  right: -70px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  .tm-carousel-8 .slick-dots li {
    display: block; }

.tm-carousel-11 .owl-dots {
  left: 0;
  margin-top: 50px;
  position: absolute;
  right: 0; }

.tm-carousel-13 .owl-stage-outer {
  padding-bottom: 50px; }
.tm-carousel-13 .owl-dots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }
.tm-carousel-13 .owl-dot {
  background-color: #ccc;
  border-radius: 2px;
  height: 4px;
  margin: 0 5px;
  width: 15px;
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }
  .tm-carousel-13 .owl-dot.active {
    background-color: #0D52FF; }

.tm-13 {
  background-color: #f9fafc;
  margin: 13px 25px 30px 40px;
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }
  .tm-13 .thumb {
    min-width: 130px; }
  .tm-13 img {
    border: 10px solid #fff;
    border-radius: 0 50% 50%;
    box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1); }
  .tm-13 .text {
    padding: 42px 50px 46px 40px;
    letter-spacing: 0; }
  .tm-13:hover {
    background-color: #fff;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }

/*==============================================
    Shop Single
===============================================*/
.product--summery {
  padding: 0 100px; }
  .product--summery .sku {
    font-size: 16px; }
  .product--summery .price span {
    display: inline-block;
    font-family: "Poppins", sans-serif;
    font-size: 19px;
    line-height: 40px; }
  .product--summery .price .reg {
    margin-right: 15px;
    text-decoration: line-through; }
  .product--summery .price .sale {
    background-color: #303030;
    border-radius: 30px;
    color: #fff;
    padding: 0 28px; }
  .product--summery .quantity-field {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    display: inline-block;
    height: 40px;
    position: relative;
    width: 80px; }
    .product--summery .quantity-field button {
      background-color: transparent;
      border: 0 none;
      padding: 0;
      position: absolute;
      line-height: 0; }
    .product--summery .quantity-field input {
      border: 0 none;
      height: 38px;
      padding: 0;
      text-indent: 23px;
      width: 100%; }
    .product--summery .quantity-field .add {
      padding-top: 3px;
      top: 0; }
    .product--summery .quantity-field .sub {
      bottom: 0;
      padding-top: 3px; }
  .product--summery .size .nice-select {
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    display: inline-block;
    float: none;
    height: 40px;
    width: 80px; }
    .product--summery .size .nice-select .list {
      border-radius: 0 0 2px 2px;
      margin: 0;
      width: 100%; }
  .product--summery .colors p:last-child {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 86px; }
  .product--summery .quantity .action-label,
  .product--summery .size .action-label,
  .product--summery .tags .action-label,
  .product--summery .colors .action-label {
    display: inline-block;
    min-width: 45px;
    margin-right: 40px;
    position: relative; }
    .product--summery .quantity .action-label::after,
    .product--summery .size .action-label::after,
    .product--summery .tags .action-label::after,
    .product--summery .colors .action-label::after {
      content: ":";
      position: absolute;
      right: 0; }
  .product--summery .add-to-cart {
    background-color: #303030;
    border-radius: 30px;
    color: #fff;
    line-height: 50px;
    padding: 0 35px; }
  .product--summery .bag-icon {
    background: blue;
    display: inline-block;
    height: 20px;
    margin: 0 15px 0 0;
    position: relative;
    top: 5px;
    width: 17px; }
  .product--summery .wishlist {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 50px; }

.product-radio {
  border: 2px solid;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 20px; }
  .product-radio + .product-radio {
    margin-left: 7px; }
  .product-radio input {
    cursor: pointer;
    border: 0 none;
    height: 22px;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 20px; }
    .product-radio input:checked + label {
      -webkit-transform: scale(1.08);
      -moz-transform: scale(1.08);
      -ms-transform: scale(1.08);
      -o-transform: scale(1.08);
      transform: scale(1.08); }
  .product-radio label {
    border-radius: 18px;
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    text-indent: -9999em;
    top: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }

.product-specs {
  width: 100%; }
  .product-specs li {
    background-color: #f5f5f5; }
    .product-specs li:nth-child(2n+1) {
      background-color: #fbfbfb; }
    .product-specs li span {
      padding: 10px 80px 9px;
      width: 50%; }
      .product-specs li span:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.1); }

.comment-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 50px;
  padding-bottom: 32px; }
  .comment-container:first-child {
    padding-top: 0; }
  .comment-container .avatar {
    margin-right: 40px; }
    .comment-container .avatar img {
      border: 8px solid #fff;
      width: 115px; }
  .comment-container h4 {
    font-size: 16px; }
  .comment-container .star-rating {
    font-size: 13px; }

.star-rating::before {
  content: '\f006\f006\f006\f006\f006'; }
.star-rating.star-half::before {
  content: '\f123\f006\f006\f006\f006'; }
.star-rating.star-1::before {
  content: '\f005\f006\f006\f006\f006'; }
.star-rating.star-1-half::before {
  content: '\f005\f123\f006\f006\f006'; }
.star-rating.star-2::before {
  content: '\f005\f005\f006\f006\f006'; }
.star-rating.star-2-half::before {
  content: '\f005\f005\f123\f006\f006'; }
.star-rating.star-3::before {
  content: '\f005\f005\f005\f006\f006'; }
.star-rating.star-3-half::before {
  content: '\f005\f005\f005\f123\f006'; }
.star-rating.star-4::before {
  content: '\f005\f005\f005\f005\f006'; }
.star-rating.star-4-half::before {
  content: '\f005\f005\f005\f005\f123'; }
.star-rating.star-5::before {
  content: '\f005\f005\f005\f005\f005'; }

/*==============================================
    Products
===============================================*/
/*----------------------------------------
    Product type-1
------------------------------------------*/
.product.type-1 .product-inner, .product.type-2 .product-inner {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 15px 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .product.type-1 .product-inner .product-hover, .product.type-2 .product-inner .product-hover {
    background-color: rgba(246, 81, 76, 0.8);
    border-top-right-radius: 150%;
    bottom: 0;
    height: 100px;
    position: absolute;
    left: 0;
    width: 100px;
    z-index: 1;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left bottom 0;
    -moz-transform-origin: left bottom 0;
    -ms-transform-origin: left bottom 0;
    -o-transform-origin: left bottom 0;
    transform-origin: left bottom 0;
    -webkit-transition: all 0.4s cubic-bezier(0.71, 0.07, 0.4, 0.88) 0s;
    -moz-transition: all 0.4s cubic-bezier(0.71, 0.07, 0.4, 0.88) 0s;
    -o-transition: all 0.4s cubic-bezier(0.71, 0.07, 0.4, 0.88) 0s;
    transition: all 0.4s cubic-bezier(0.71, 0.07, 0.4, 0.88) 0s; }
  .product.type-1 .product-inner:hover, .product.type-2 .product-inner:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .product.type-1 .product-inner:hover .product-hover, .product.type-2 .product-inner:hover .product-hover {
      -webkit-transform: scale(8);
      -moz-transform: scale(8);
      -ms-transform: scale(8);
      -o-transform: scale(8);
      transform: scale(8); }
    .product.type-1 .product-inner:hover .thumb .sale, .product.type-2 .product-inner:hover .thumb .sale {
      opacity: 1;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1); }
    .product.type-1 .product-inner:hover .thumb .quick-view, .product.type-2 .product-inner:hover .thumb .quick-view {
      opacity: 1;
      -webkit-transform: scale(1) translate(50%, -50%);
      -moz-transform: scale(1) translate(50%, -50%);
      -ms-transform: scale(1) translate(50%, -50%);
      -o-transform: scale(1) translate(50%, -50%);
      transform: scale(1) translate(50%, -50%); }
    .product.type-1 .product-inner:hover .product-name, .product.type-2 .product-inner:hover .product-name {
      background-color: transparent;
      z-index: 1; }
      .product.type-1 .product-inner:hover .product-name:before, .product.type-2 .product-inner:hover .product-name:before {
        border-bottom-color: rgba(255, 255, 255, 0.2); }
      .product.type-1 .product-inner:hover .product-name a, .product.type-2 .product-inner:hover .product-name a {
        color: #fff; }
.product.type-1 .thumb, .product.type-2 .thumb {
  display: block;
  position: relative;
  min-height: 460px; }
  .product.type-1 .thumb .image, .product.type-2 .thumb .image {
    display: block; }
  .product.type-1 .thumb img, .product.type-2 .thumb img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0; }
  .product.type-1 .thumb a:not(.image), .product.type-2 .thumb a:not(.image) {
    position: absolute;
    opacity: 0; }
  .product.type-1 .thumb .sale, .product.type-2 .thumb .sale {
    font-size: 18px;
    font-weight: 500;
    right: 0;
    text-transform: none;
    top: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  .product.type-1 .thumb .quick-view, .product.type-2 .thumb .quick-view {
    right: 50%;
    top: 50%;
    -webkit-transform: scale(0) translate(50%, -50%);
    -moz-transform: scale(0) translate(50%, -50%);
    -ms-transform: scale(0) translate(50%, -50%);
    -o-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%); }
.product.type-1 .product-name, .product.type-2 .product-name {
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0 -15px;
  padding: 37px 30px 35px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .product.type-1 .product-name:before, .product.type-2 .product-name:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    content: "";
    left: 15px;
    position: absolute;
    right: 15px;
    top: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .product.type-1 .product-name a, .product.type-2 .product-name a {
    color: #303030;
    display: block;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px; }
.product.type-1 .product-meta, .product.type-2 .product-meta {
  background-color: #ddd;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0 22px;
  padding: 20px 0;
  position: relative;
  text-align: center;
  z-index: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .product.type-1 .product-meta a, .product.type-2 .product-meta a {
    color: #444;
    display: inline-block;
    font-size: 15px;
    font-weight: 300;
    padding: 0 14px 0 0; }
    .product.type-1 .product-meta a:after, .product.type-2 .product-meta a:after {
      content: "|";
      display: inline-block;
      margin-left: 14px;
      vertical-align: middle; }
    .product.type-1 .product-meta a:last-child, .product.type-2 .product-meta a:last-child {
      padding-right: 0; }
      .product.type-1 .product-meta a:last-child:after, .product.type-2 .product-meta a:last-child:after {
        content: none; }
.product.type-1:hover .product-meta, .product.type-2:hover .product-meta {
  background-color: #0D52FF; }
  .product.type-1:hover .product-meta a, .product.type-2:hover .product-meta a {
    color: #fff; }
.product.type-2 .product-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  text-align: left;
  padding: 30px;
  min-height: 156px; }
  .product.type-2 .product-info a {
    color: #303030;
    font-size: 18px; }
.product.type-2 .fixed-action-btn {
  bottom: auto;
  padding: 0;
  position: relative;
  right: -20px; }
  .product.type-2 .fixed-action-btn > a > i {
    color: #0D52FF; }
  .product.type-2 .fixed-action-btn ul {
    bottom: 40px; }
.product.type-3 {
  border-radius: 2px;
  font-family: "Poppins", sans-serif; }
  .product.type-3 img {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    width: 100%; }
  .product.type-3 .actions {
    background-color: rgba(255, 255, 255, 0.7);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    bottom: 0;
    left: 0;
    position: absolute;
    padding: 13px 0;
    right: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .product.type-3 .actions li {
      display: inline-block;
      margin-left: -4px; }
      .product.type-3 .actions li a {
        border-right: 1px solid;
        color: #000;
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        padding: 0 14px; }
        .product.type-3 .actions li a:hover {
          text-decoration: underline; }
      .product.type-3 .actions li:last-child a {
        border-right: 0; }
  .product.type-3 .btn-floating {
    font-size: 18px;
    font-weight: 300;
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .product.type-3 .desc {
    padding: 18px 35px 35px; }
  .product.type-3 h4 {
    font-size: 18px;
    line-height: 1.7; }
    .product.type-3 h4 a {
      color: #444; }
  .product.type-3 .price {
    color: #444;
    font-size: 24px;
    font-weight: 700;
    margin-top: 18px; }
  .product.type-3:hover .actions {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  .product.type-3:hover .btn-floating {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
.product.type-4 .meta {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1; }
  .product.type-4 .meta::before {
    border-color: transparent transparent #fff #fff;
    border-style: solid;
    border-width: 115px 200px;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    z-index: -1; }
  .product.type-4 .meta::after {
    border-color: transparent #0D52FF #0D52FF transparent;
    border-style: solid;
    border-width: 75px 100px;
    bottom: 0;
    content: "";
    right: 0;
    position: absolute;
    z-index: -1; }
  .product.type-4 .meta > div {
    float: left;
    margin: 0 0 15px 19px; }
  .product.type-4 .meta h4 {
    margin-bottom: 10px; }
    .product.type-4 .meta h4 a {
      color: #848484; }
  .product.type-4 .meta .ratings {
    color: #f6c21b;
    font-size: 16px; }
  .product.type-4 .meta .price {
    color: #fff;
    float: right;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin: 26px 18px 0 0; }
  .product.type-4 .meta ins {
    text-decoration: none; }
.product.type-4 .actions {
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 1;
  opacity: 0;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%) scale(0.1);
  -moz-transform: translate(-50%, -50%) scale(0.1);
  -ms-transform: translate(-50%, -50%) scale(0.1);
  -o-transform: translate(-50%, -50%) scale(0.1);
  transform: translate(-50%, -50%) scale(0.1);
  -webkit-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -moz-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -o-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
  .product.type-4 .actions a {
    background-color: #fff;
    border-radius: 5px;
    color: #444;
    display: inline-block;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 50px; }
.product.type-4:hover .actions {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1); }
.product.type-4:hover .hover-ripple {
  -webkit-transform: scale(6.5);
  -moz-transform: scale(6.5);
  -ms-transform: scale(6.5);
  -o-transform: scale(6.5);
  transform: scale(6.5); }
.product.type-5 {
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .product.type-5 .thumb {
    position: relative; }
    .product.type-5 .thumb::before {
      background-color: #fff;
      border-radius: 10px 10px 0 0;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
  .product.type-5 .quick-view {
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #0D52FF;
    border-radius: 70px;
    height: 70px;
    width: 70px;
    z-index: 1;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -moz-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    -o-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0); }
    .product.type-5 .quick-view .plus {
      width: 36px;
      height: 36px; }
      .product.type-5 .quick-view .plus::before, .product.type-5 .quick-view .plus::after {
        background-color: #0D52FF;
        content: "";
        height: 100%;
        left: 50%;
        margin-left: -1px;
        position: absolute;
        width: 2px; }
      .product.type-5 .quick-view .plus::after {
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        margin: -1px 0 0; }
  .product.type-5 img {
    border-radius: 10px 10px 0 0; }
  .product.type-5 .desc {
    border-radius: 0 0 10px 10px;
    padding: 36px 60px 40px; }
  .product.type-5 .price {
    color: #444;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 700; }
    .product.type-5 .price .primary {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 300; }
  .product.type-5:hover .thumb::before {
    opacity: 0.75; }
  .product.type-5:hover .quick-view {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); }
.product.type-6 .product-badge {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2; }
.product.type-6 img {
  width: 100%; }
.product.type-6 .view-details {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden; }
.product.type-6:hover figure {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
.product.type-6:hover .hover-ripple {
  -webkit-transform: scale(9.2);
  -moz-transform: scale(9.2);
  -ms-transform: scale(9.2);
  -o-transform: scale(9.2);
  transform: scale(9.2); }
.product.type-6:hover .price {
  opacity: 0; }
.product.type-6:hover .view-details {
  opacity: 1;
  visibility: visible; }
.product.type-6:hover .product-badge {
  background-color: #444;
  color: #fff; }
.product.type-7 .title {
  padding: 34px 0 20px; }
.product.type-7 img {
  width: 100%; }
.product.type-7 .product-actions {
  bottom: 0;
  position: absolute;
  right: 30px; }
  .product.type-7 .product-actions li {
    margin-bottom: 5px; }
    .product.type-7 .product-actions li:last-child {
      margin-bottom: 0; }
  .product.type-7 .product-actions a {
    background-color: #f2f2f2;
    border-radius: 30px;
    color: #444;
    display: block;
    height: 35px;
    line-height: 37px;
    opacity: 0;
    position: relative;
    width: 35px;
    -webkit-transform: scale(0.4) translate(0px, 30px);
    -moz-transform: scale(0.4) translate(0px, 30px);
    -ms-transform: scale(0.4) translate(0px, 30px);
    -o-transform: scale(0.4) translate(0px, 30px);
    transform: scale(0.4) translate(0px, 30px); }
    .product.type-7 .product-actions a:hover {
      background-color: #444;
      color: #fff; }
  .product.type-7 .product-actions .add-to-cart::before {
    
    height: 18px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 16px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .product.type-7 .product-actions .add-to-cart:hover::before {
    background-position: 1px -19px; }
.product.type-7 .price {
  padding: 22px 0 10px; }
.product.type-7:hover .product-actions a {
  opacity: 1;
  -webkit-transform: scale(1) translate(0px);
  -moz-transform: scale(1) translate(0px);
  -ms-transform: scale(1) translate(0px);
  -o-transform: scale(1) translate(0px);
  transform: scale(1) translate(0px); }
.product.type-8 .product-title {
  padding: 43px 50px 44px; }
.product.type-8 .meta {
  padding: 35px; }
.product.type-8:hover.light-bg {
  background-color: #fff; }

.shop-3-left-wdget ul {
  max-height: 315px;
  overflow: auto; }

.product-cat-style-1 p {
  margin: 4px 0 12px; }

.product-cat-style-2 img {
  width: 100%; }
.product-cat-style-2 figcaption {
  position: absolute;
  bottom: 75px;
  left: 75px;
  right: 75px; }
.product-cat-style-2 .sep {
  display: block;
  height: 2px;
  width: 60px; }
.product-cat-style-2:hover .hover-ripple {
  -webkit-transform: scale(10);
  -moz-transform: scale(10);
  -ms-transform: scale(10);
  -o-transform: scale(10);
  transform: scale(10); }

.product-cat-style-3 img {
  width: 100%; }
.product-cat-style-3 .desc {
  padding-left: 50px;
  padding-right: 50px; }
.product-cat-style-3 .sep {
  display: block;
  height: 2px;
  width: 60px; }

.deals-countdown .time-block {
  display: inline-block;
  margin-right: 30px; }
  .deals-countdown .time-block:last-child {
    margin-right: 0; }
.deals-countdown .time {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  color: #fff;
  font-size: 39px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  display: block;
  height: 80px;
  line-height: 80px;
  margin-bottom: 10px;
  width: 80px; }
.deals-countdown .label {
  display: block;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding: 0;
  letter-spacing: 2.8px;
  text-transform: uppercase; }

.count-wrapper {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  padding: 44px 40px 50px 40px;
  position: absolute;
  right: 0; }
  .count-wrapper > span {
    display: block;
    margin-bottom: 7px; }

.product-countdown .time-slot {
  font-size: 29px; }

.product--cat {
  margin-right: 2px;
  margin-bottom: 2px; }
  .product--cat .hover {
    bottom: 20px;
    left: 20px;
    padding: 40px 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .product--cat .hover::before {
      background-color: #fff;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0.7;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
  .product--cat h4, .product--cat a {
    left: -40px;
    position: relative;
    opacity: 0; }
  .product--cat h4 {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .product--cat a {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .product--cat:hover .hover::before {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .product--cat:hover h4, .product--cat:hover a {
    left: 0;
    opacity: 1; }

.shop-3-cat {
  padding: 30px 30px 48px 30px; }
  .shop-3-cat li {
    margin-bottom: 7px; }
    .shop-3-cat li:last-child {
      margin-bottom: 0; }
    .shop-3-cat li a {
      color: #848484;
      display: block; }
      .shop-3-cat li a:hover {
        color: #444; }

.shop-3-cat2 {
  min-height: 407px;
  padding: 70px 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.shop3-cat-slider figcaption {
  background-color: #fff;
  left: 0;
  padding: 26px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 30px; }

.table-cart thead tr th {
  background-color: #f7f7f7;
  color: #444;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  padding: 19px 50px 16px; }
.table-cart tbody tr td {
  padding: 20px 30px; }
.table-cart .remove-product svg {
  height: 20px;
  width: 20px;
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }
  .table-cart .remove-product svg:hover {
    fill: #0D52FF; }

.quantity-field {
  border: 1px solid #eee;
  display: block;
  position: relative;
  width: 80px; }
  .quantity-field .field {
    border: 0 none;
    height: 40px;
    margin: 0;
    padding-left: 25px; }
  .quantity-field button {
    background-color: transparent;
    border: 0 none;
    height: 20px;
    padding: 0;
    position: absolute;
    right: 0;
    width: 35px; }
  .quantity-field .active {
    color: #444; }
  .quantity-field .add {
    padding-top: 4px;
    top: 0; }
  .quantity-field .sub {
    bottom: 0;
    padding-bottom: 4px; }
    .quantity-field .sub i {
      position: relative;
      top: -8px; }

.shipping_tax_box .selectize-control .selectize-input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.shipping-method input {
  margin: -3px 0 0 20px; }
.shipping-method label {
  cursor: pointer; }

.cart-subtotal {
  padding-left: 50px;
  padding-right: 50px; }
  .cart-subtotal hr {
    margin-left: -50px;
    margin-right: -50px; }

.shop-order-progress {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .shop-order-progress li a {
    color: #848484;
    display: block;
    padding: 0 25px 22px;
    position: relative; }
    .shop-order-progress li a::after {
      background-color: #000;
      bottom: -1px;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
      -ms-transform: scaleX(0);
      -o-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: left bottom 0;
      -moz-transform-origin: left bottom 0;
      -ms-transform-origin: left bottom 0;
      -o-transform-origin: left bottom 0;
      transform-origin: left bottom 0;
      -webkit-transition: all 0.2s linear 0s;
      -moz-transition: all 0.2s linear 0s;
      -o-transition: all 0.2s linear 0s;
      transition: all 0.2s linear 0s; }
  .shop-order-progress .active a {
    color: #444;
    font-weight: 500; }
    .shop-order-progress .active a::after {
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -o-transform: scaleX(1);
      transform: scaleX(1); }

.woocommerce-info .login-form,
.woocommerce-info .checkout-coupon {
  display: none; }
.woocommerce-info .input-field {
  margin: 0; }
  .woocommerce-info .input-field + .input-field {
    margin: 35px 0 0; }
  .woocommerce-info .input-field label {
    letter-spacing: 0;
    text-transform: none; }
.woocommerce-info .ml-input {
  position: relative;
  z-index: 10; }

.woocommerce-info-toggle {
  display: block; }
  .woocommerce-info-toggle:hover, .woocommerce-info-toggle:focus {
    color: #444; }

.password-field,
.diff-shipping-address {
  display: none; }

.woocommerce-checkout-review-order {
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  padding: 42px 30px 50px; }

.woocommerce-checkout-payment {
  padding: 50px 30px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1); }

.wc_payment_methods .payment_box {
  background-color: #fafafb;
  color: rgba(0, 0, 0, 0.54);
  display: none;
  margin-bottom: 15px;
  margin-top: 10px;
  padding: 10px 30px; }

.woocommerce-billing-fields .input-field {
  margin-bottom: 30px; }
  .woocommerce-billing-fields .input-field label {
    text-transform: none; }
.woocommerce-billing-fields .selectize-control .selectize-input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.ml-selectize .selectize-control .selectize-input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
.ml-selectize .item {
  font-weight: 300; }

.card-information label {
  letter-spacing: 0;
  text-transform: none; }

.payment-type li {
  background-color: #f7f7f7;
  border: 5px solid transparent;
  border-radius: 3px;
  margin-right: 25px;
  min-width: 190px;
  padding: 25px 0;
  text-align: center;
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }
  .payment-type li span {
    display: block; }
  .payment-type li.z-depth-1 {
    border-color: #fff; }

.woocommerce-thankyou-order-details {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 0; }
  .woocommerce-thankyou-order-details li {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 40px; }
    .woocommerce-thankyou-order-details li:last-child {
      border-right: 0 none;
      padding-right: 0; }

.order-details tfoot tr:first-child th, .order-details tfoot tr:first-child td {
  padding-top: 30px; }
.order-details tfoot tr th, .order-details tfoot tr td {
  color: #444;
  font-weight: 500;
  padding: 5px 30px; }

.cat-parallax-bg {
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  padding: 200px 0;
  position: relative;
  z-index: 0; }
  .cat-parallax-bg::before {
    background-color: #fff;
    content: "";
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: -1;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
  .cat-parallax-bg .cat-box {
    background-color: #fff;
    border-radius: 3px;
    margin: 0 auto;
    max-width: 350px;
    padding: 40px 20px 43px; }

.shop-dashboard-nav {
  padding: 45px 0 95px; }
  .shop-dashboard-nav li a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #848484;
    display: block;
    padding: 15px 40px;
    width: 100%; }
  .shop-dashboard-nav .active a {
    border-bottom-color: rgba(0, 0, 0, 0.25);
    color: #444; }

.shop-db-title {
  padding: 13px 50px 17px; }

.db-content {
  padding: 30px; }

/*==============================================
    Call to Action
===============================================*/
.cta .lead {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0; }
.cta a {
  font-size: 16px; }
.cta.cta-one {
  padding: 100px 0; }

.cta-wide h2 b {
  font-weight: 900; }
.cta-wide p {
  margin-top: 30px; }
.cta-wide a + a {
  margin-left: 10px; }
.cta-wide .btn-white {
  margin-top: 42px;
  font-size: 14px;
  font-weight: 500; }

.cta-gray {
  background-color: rgba(0, 0, 0, 0.1); }
  .cta-gray h3 {
    color: #848484;
    color: #848484;
    font-size: 28px;
    letter-spacing: 1.12px; }

.cta-gray-2 {
  background-color: rgba(0, 0, 0, 0.54); }

.cta-bakery .cta-text,
.cta-bakery .cta-button {
  min-height: 150px; }
  .cta-bakery .cta-text > *,
  .cta-bakery .cta-button > * {
    line-height: 150px; }
.cta-bakery .cta-text {
  background-color: #212121; }
.cta-bakery h2 {
  color: #fff; }
.cta-bakery .cta-button {
  background-color: #0D52FF;
  font-size: 24px;
  font-weight: 500;
  position: relative;
  z-index: 2; }
  .cta-bakery .cta-button::before {
    border: 5px solid #fff;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .cta-bakery .cta-button::after {
    background-color: #bf211c;
    bottom: 20px;
    content: "";
    left: 20px;
    opacity: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: -1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .cta-bakery .cta-button:hover::before {
    bottom: 10px;
    left: 10px;
    opacity: 1;
    right: 10px;
    top: 10px; }
  .cta-bakery .cta-button:hover::after {
    opacity: 1; }
  .cta-bakery .cta-button a {
    color: #fff;
    display: block; }

.cta-event {
  padding: 50px 0; }
  .cta-event .cta-logo {
    position: relative;
    padding-right: 40px; }
    .cta-event .cta-logo::after {
      background-color: #fff;
      content: "";
      height: 100%;
      width: 2px;
      position: absolute;
      right: 41px;
      top: 0; }
    .cta-event .cta-logo img {
      width: 180px; }
  .cta-event p {
    color: #fff;
    font-size: 16px;
    line-height: 26px; }

.cta-wave .cta-wave-svg {
  display: block; }
  .cta-wave .cta-wave-svg path {
    fill: #0D52FF; }
.cta-wave .cta-inner {
  bottom: 40px;
  left: 0;
  position: absolute;
  right: 0; }

/*==============================================
    Parallax
===============================================*/
/*----------------------------------------
    Parallax block 1
------------------------------------------*/
.parallax-block-1 {
  background-color: #fff;
  padding: 60px; }
  .parallax-block-1 h2 {
    font-size: 48px;
    line-height: 63px; }
  .parallax-block-1 p {
    margin-top: 7px; }

/*----------------------------------------
    Parallax block 2
------------------------------------------*/
.parallax-block-2 {
  background-color: #f7f7f7; }
  .parallax-block-2 p {
    font-size: 16px; }
  .parallax-block-2 .right-bg {
    background-color: #f7f7f7;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }

/*----------------------------------------
    Parallax block 3
------------------------------------------*/
.parallax-block-3 {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 150px 0;
  position: relative; }
  .parallax-block-3 p {
    font-size: 16px; }
  .parallax-block-3 .left-bg {
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
  .parallax-block-3 .right-curv {
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.9);
    border-style: solid;
    border-width: 0 0 150px 160px;
    bottom: 0;
    left: 100%;
    position: absolute; }

/*==============================================
    Tabs
===============================================*/
/*---------------- Tab-1 ------------*/
.tab-1 .nav-list {
  padding-right: 4px; }
  .tab-1 .nav-list li a {
    color: #444;
    font-family: "Poppins", sans-serif;
    display: block;
    font-size: 20px;
    padding: 38px 0 35px;
    text-align: center;
    text-transform: uppercase; }
  .tab-1 .nav-list li .icon {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    display: block;
    font-size: 28px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: 0 auto 27px; }
  .tab-1 .nav-list li.active {
    background-color: #fff; }
    .tab-1 .nav-list li.active span {
      color: #0D52FF; }
.tab-1 .tab-content {
  background-color: #fff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  padding: 93px 110px 99px; }
.tab-1 .tab-pane h2 {
  font-size: 36px;
  margin-bottom: 36px; }

/*---------------- Tab-2 ------------*/
.tab-2 .tab-nav {
  margin-bottom: 80px; }
  .tab-2 .tab-nav li {
    display: inline-block;
    margin-left: -4px; }
    .tab-2 .tab-nav li:first-child {
      margin-left: 0; }
    .tab-2 .tab-nav li a {
      border: 1px solid transparent;
      color: #848484;
      display: block;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      line-height: 60px;
      padding: 0 80px; }
      .tab-2 .tab-nav li a:hover {
        border-color: rgba(0, 0, 0, 0.2); }
    .tab-2 .tab-nav li.active a {
      color: #fff;
      background-color: #0D52FF;
      border-color: #0D52FF;
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
.tab-2 .tab-menu-list li {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 80px;
  padding: 0 50px; }
  .tab-2 .tab-menu-list li:nth-child(2n+1) {
    background-color: #f3f3f3; }
.tab-2 .tab-menu-list .dish-price {
  font-weight: 400; }

/*---------------- Tab-3 ------------*/
.tab-3 .nav-list li a {
  background-color: rgba(255, 255, 255, 0.54);
  color: #848484;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2.8px;
  margin-bottom: 1px;
  padding: 36px 50px;
  text-transform: uppercase; }
.tab-3 .nav-list li .icon {
  font-size: 30px;
  margin-right: 35px; }
.tab-3 .nav-list .active a {
  background-color: rgba(255, 255, 255, 0.87);
  color: #0D52FF; }
.tab-3 .tab-content {
  background-color: rgba(255, 255, 255, 0.87);
  padding: 83px 80px 95px; }
.tab-3 .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden; }
  .tab-3 .tab-pane.active {
    height: auto;
    opacity: 1;
    overflow: visible; }
  .tab-3 .tab-pane img {
    margin-bottom: 68px; }
  .tab-3 .tab-pane h2 {
    font-size: 30px;
    letter-spacing: 2.8px;
    margin: 0 0 20px;
    text-transform: uppercase; }
  .tab-3 .tab-pane .edu-block {
    font-family: "Poppins", sans-serif;
    margin-bottom: 44px; }
    .tab-3 .tab-pane .edu-block:last-child {
      margin-bottom: 0; }
    .tab-3 .tab-pane .edu-block h3 {
      font-size: 24px;
      margin-bottom: 5px; }
    .tab-3 .tab-pane .edu-block h4 {
      font-size: 16px;
      margin-bottom: 16px; }
    .tab-3 .tab-pane .edu-block p {
      font-family: "Roboto", sans-serif; }
.tab-3 .misc-slider-1 img {
  margin-bottom: 33px; }

/*---------------- Tab-4 ------------*/
.tab-4 .nav-list li a {
  background-color: #fff;
  color: #848484;
  font-weight: 300;
  padding: 36px 40px; }
.tab-4 .nav-list li .icon {
  font-size: 30px;
  margin-right: 35px; }
.tab-4 .nav-list li .text {
  font-size: 14px; }
  .tab-4 .nav-list li .text span {
    color: #0D52FF; }
  .tab-4 .nav-list li .text p {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    margin-top: 1px; }
.tab-4 .nav-list .active a {
  color: #fff;
  background-color: #0D52FF; }
  .tab-4 .nav-list .active a span {
    color: #fff; }
.tab-4 .tab-pane .img {
  background-repeat: no-repeat;
  background-size: cover; }
.tab-4 .tab-pane .content {
  background-color: #0D52FF;
  padding: 75px 70px; }
  .tab-4 .tab-pane .content span {
    display: block;
    font-size: 16px;
    margin-bottom: 10px; }
  .tab-4 .tab-pane .content h4 {
    font-size: 20px; }
  .tab-4 .tab-pane .content p {
    margin-top: 35px; }

/*---------------- Tab-5 ------------*/
.tab-5 .nav-lists li {
  display: inline-block;
  margin-right: 50px; }
  .tab-5 .nav-lists li:last-child {
    margin-right: 0; }
  .tab-5 .nav-lists li a {
    color: #444;
    font-size: 60px; }
.tab-5 .nav-lists .active a {
  color: #0D52FF; }
.tab-5 .tab-pane h3 {
  color: #848484;
  font-size: 24px;
  margin-bottom: 20px; }

/*---------------- Tab-6 ------------*/
.tab-6 .nav-list li {
  display: inline-block;
  margin-left: -4px; }
  .tab-6 .nav-list li:first-child {
    margin-left: 0; }
  .tab-6 .nav-list li a {
    background-color: #fff;
    color: #848484;
    display: block;
    padding: 12px 40px; }
.tab-6 .nav-list .active a {
  background-color: #0D52FF;
  color: #fff; }

.tab-7 .tab-nav li {
  display: inline-block;
  margin: 0 40px; }
  .tab-7 .tab-nav li a {
    color: #848484;
    display: block;
    font-size: 48px; }
.tab-7 .tab-nav .active a {
  color: #0D52FF; }

.misc-slider-2 {
  bottom: 150px;
  left: 0;
  position: absolute;
  width: -webkit-calc(50% - 15px);
  width: -moz-calc(50% - 15px);
  width: calc(50% - 15px); }
  .misc-slider-2 .item {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px; }
  .misc-slider-2 .owl-nav {
    margin: 0; }
    .misc-slider-2 .owl-nav div {
      left: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      z-index: 10;
      opacity: 0;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
    .misc-slider-2 .owl-nav .owl-next {
      left: auto;
      right: 0; }
  .misc-slider-2:hover .owl-nav div {
    opacity: 1; }

.tab-8 .tab-nav {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .tab-8 .tab-nav li {
    display: inline-block;
    margin-left: -4px; }
    .tab-8 .tab-nav li a {
      color: #848484;
      display: block;
      font-weight: 400;
      padding: 0 35px 25px;
      position: relative; }
      .tab-8 .tab-nav li a::before {
        background-color: #0D52FF;
        bottom: -2px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        width: 0;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s; }
  .tab-8 .tab-nav .active a {
    color: #0D52FF; }
    .tab-8 .tab-nav .active a::before {
      width: 100%; }

.tab-9 .tab-nav li a {
  border-bottom: 1px solid #848484;
  border-bottom-color: #848484;
  color: #848484;
  display: block;
  margin-right: 10px;
  padding: 0 18px 3px;
  position: relative; }
  .tab-9 .tab-nav li a::before {
    bottom: -3px;
    content: "";
    position: absolute;
    left: 0;
    height: 2px;
    width: 0;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s; }
.tab-9 .tab-nav li:last-child a {
  margin-right: 0; }
.tab-9 .tab-nav li .lean {
  display: block;
  left: 0;
  position: relative;
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }
.tab-9 .tab-nav .active .lean {
  left: -33px; }
.tab-9 .tab-nav.dark .active a {
  color: #000; }
  .tab-9 .tab-nav.dark .active a::before {
    background-color: #000;
    width: 100%; }
.tab-9 .tab-nav.primary .active a {
  color: #0D52FF;
  border-bottom-color: #0D52FF; }
  .tab-9 .tab-nav.primary .active a::before {
    background-color: #0D52FF;
    width: 100%; }
.tab-9 .tab-content {
  padding: 73px 50px 80px; }

.horiz-icon-tab-nav li + li {
  margin-left: 30px; }
.horiz-icon-tab-nav li a {
  color: #000;
  font-size: 18px;
  height: 170px;
  width: 170px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }
  .horiz-icon-tab-nav li a:hover {
    background-color: #0D52FF;
    color: #fff; }
.horiz-icon-tab-nav li i {
  display: block;
  font-size: 48px;
  line-height: 1;
  margin-bottom: 12px; }
.horiz-icon-tab-nav .active a {
  background-color: #0D52FF;
  color: #fff; }

.set-menu-1 {
  padding: 30px; }
  .set-menu-1 .dish-images img {
    width: 100%; }
  .set-menu-1 .dish-info h2 {
    font-size: 24px; }
  .set-menu-1 .dish-info .price {
    font-family: "Poppins", sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 1;
    text-align: center; }

/*==============================================
    Progress Bars
===============================================*/
.progress-item.type-1, .progress-item.style2, .progress-item.style3 {
  margin-bottom: 32px; }
  .progress-item.type-1:last-child, .progress-item.style2:last-child, .progress-item.style3:last-child {
    margin-bottom: 0; }
  .progress-item.type-1 p, .progress-item.style2 p, .progress-item.style3 p {
    color: #444;
    margin: 0 0 12px; }
  .progress-item.type-1 .progress, .progress-item.style2 .progress, .progress-item.style3 .progress {
    background-color: #d6d6d6;
    border-radius: 0;
    box-shadow: none;
    height: 20px;
    overflow: visible;
    margin-bottom: 0; }
  .progress-item.type-1 .progress-bar, .progress-item.style2 .progress-bar, .progress-item.style3 .progress-bar {
    background-color: #0D52FF;
    box-shadow: none;
    position: relative; }
  .progress-item.type-1 .value, .progress-item.style2 .value, .progress-item.style3 .value {
    color: #fff;
    height: 40px;
    line-height: 40px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -15px;
    width: 40px;
    -webkit-transform: translate(50%, -100%);
    -moz-transform: translate(50%, -100%);
    -ms-transform: translate(50%, -100%);
    -o-transform: translate(50%, -100%);
    transform: translate(50%, -100%); }
    .progress-item.type-1 .value:before, .progress-item.type-1 .value:after, .progress-item.style2 .value:before, .progress-item.style2 .value:after, .progress-item.style3 .value:before, .progress-item.style3 .value:after {
      border-radius: 50% 50% 0;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
    .progress-item.type-1 .value:before, .progress-item.style2 .value:before, .progress-item.style3 .value:before {
      background-color: #757575;
      z-index: -1; }
    .progress-item.type-1 .value:after, .progress-item.style2 .value:after, .progress-item.style3 .value:after {
      border: 2px solid #fff;
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
      bottom: 0; }
.progress-item.style2 .progress {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 10px; }
.progress-item.style2 .progress-bar {
  border-radius: 10px;
  height: 4px;
  left: 2px;
  top: 2px; }
.progress-item.style3 .progress {
  background-color: #d6d6d6;
  height: 8px; }
.progress-item.style3 .progress-bar {
  height: 4px;
  margin-top: 2px; }
.progress-item.style3 .value::before {
  background-color: #0D52FF; }
.progress-item.style3 .value::after {
  content: none; }
.progress-item.style4, .progress-item.style5 {
  margin-bottom: 20px; }
  .progress-item.style4:last-child, .progress-item.style5:last-child {
    margin-bottom: 0; }
  .progress-item.style4 p, .progress-item.style5 p {
    color: #444;
    font-size: 18px;
    margin-bottom: 9px; }
  .progress-item.style4 .progress, .progress-item.style5 .progress {
    background-color: #eeeeee;
    border-radius: 10px;
    box-shadow: none;
    height: 10px;
    margin: 0;
    overflow: visible; }
  .progress-item.style4 .progress-bar, .progress-item.style5 .progress-bar {
    background-color: #0D52FF;
    border-radius: 10px;
    box-shadow: none;
    position: relative; }
  .progress-item.style4 .value, .progress-item.style5 .value {
    color: #444;
    font-size: 18px;
    position: absolute;
    right: 0;
    top: -32px; }
.progress-item.style5, .progress-item.style6 {
  margin-bottom: 24px; }
  .progress-item.style5 .progress, .progress-item.style6 .progress {
    height: 4px; }
  .progress-item.style5 .value, .progress-item.style6 .value {
    top: 12px; }
  .progress-item.style5 p, .progress-item.style6 p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    letter-spacing: 2.8px;
    margin: 4px 0 0;
    text-transform: uppercase; }
.progress-item.style6 {
  margin-bottom: 33px; }
  .progress-item.style6:last-child {
    margin-bottom: 0; }
  .progress-item.style6 .progress {
    background-color: #0D52FF;
    margin: 0;
    overflow: visible; }
  .progress-item.style6 .progress-bar {
    background-color: #000; }
  .progress-item.style6 .value {
    color: #444;
    font-size: 14px;
    letter-spacing: 2.8px;
    position: absolute;
    right: 0; }
  .progress-item.style6 p {
    color: #444; }

/*==============================================
    Blog Posts
===============================================*/
.entry-post.type-1 {
  background-color: #fff;
  border-radius: 3px; }
  .entry-post.type-1 .post-thumb, .entry-post.type-1 .post-thumb img {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  .entry-post.type-1 .post-thumb img {
    width: 100%; }
  .entry-post.type-1 .entry-excerpt {
    padding: 68px 70px 50px; }
    .entry-post.type-1 .entry-excerpt h4 a {
      color: #444;
      display: inline-block;
      margin-bottom: 26px; }
      .entry-post.type-1 .entry-excerpt h4 a:hover {
        color: #0D52FF; }
  .entry-post.type-1 .entry-meta {
    padding: 0 70px 42px;
    position: relative; }
    .entry-post.type-1 .entry-meta time, .entry-post.type-1 .entry-meta span {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center;
      float: left;
      font-weight: 300;
      margin-right: 15px; }
      .entry-post.type-1 .entry-meta time i, .entry-post.type-1 .entry-meta span i {
        font-size: 18px;
        margin-right: 5px; }
    .entry-post.type-1 .entry-meta .entry-more {
      background-color: #fff;
      bottom: 34px;
      position: absolute;
      right: 30px;
      width: 46px;
      height: 46px; }
      .entry-post.type-1 .entry-meta .entry-more i {
        color: #848484;
        line-height: 46px;
        font-size: 18px; }
      .entry-post.type-1 .entry-meta .entry-more:hover {
        background-color: #0D52FF; }
        .entry-post.type-1 .entry-meta .entry-more:hover i {
          color: #fff; }
.entry-post.style2 img {
  width: 100%; }
.entry-post.style2 .entry-excerpt {
  margin-top: 38px; }
.entry-post.style2 h4 {
  font-size: 22px; }
.entry-post.style2 span {
  display: block;
  font-size: 18px;
  margin: 6px 0 20px; }
.entry-post.style2 p {
  letter-spacing: 0.7px; }
.entry-post.style2 .entry-meta {
  margin-top: 30px; }
.entry-post.style2 .btn-md {
  margin-top: 9px; }
  .entry-post.style2 .btn-md:hover {
    color: #0D52FF; }
.entry-post.style3 {
  position: relative; }
  .entry-post.style3 .shape-1 {
    border-color: rgba(255, 255, 255, 0.85);
    border-style: solid;
    border-width: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .entry-post.style3 .shape-1::before {
      border-color: #0D52FF;
      border-style: solid;
      border-width: 0;
      content: "";
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
  .entry-post.style3::before {
    border-color: transparent;
    border-style: solid;
    border-width: 1px 1px 1px 0;
    bottom: 0;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .entry-post.style3::after {
    border-color: rgba(255, 255, 255, 0.85);
    border-style: solid;
    border-width: 0;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .entry-post.style3:hover::before {
    border-color: #0D52FF;
    border-width: 1px 1px 1px 0;
    bottom: 30px;
    right: 30px;
    top: 30px; }
  .entry-post.style3:hover::after {
    border-width: 30px 30px 30px 0; }
  .entry-post.style3:hover .shape-1 {
    border-width: 30px 0 30px 30px; }
    .entry-post.style3:hover .shape-1::before {
      border-width: 1px 0 1px 1px;
      opacity: 1; }
  .entry-post.style3 .entry-content {
    padding-left: 50px;
    padding-right: 50px; }
  .entry-post.style3 h4 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 9px; }
  .entry-post.style3 .entry-meta {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 17px; }
  .entry-post.style3 a {
    color: #444; }
    .entry-post.style3 a:hover {
      color: #0D52FF; }
  .entry-post.style3 .more {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-top: 25px; }
    .entry-post.style3 .more i {
      margin-left: 3px; }
.entry-post.style4 {
  background-color: #fff; }
  .entry-post.style4 h2 {
    font-size: 30px;
    margin-bottom: 41px;
    text-transform: uppercase; }
  .entry-post.style4 .entry-content {
    padding: 68px 45px 77px; }
  .entry-post.style4 a {
    color: #444; }
    .entry-post.style4 a:hover {
      color: #0D52FF; }
  .entry-post.style4 p {
    padding: 0 30px; }
  .entry-post.style4 .more {
    color: #0D52FF;
    display: inline-block;
    font-size: 30px;
    font-weight: 700;
    margin-top: 30px;
    text-align: center;
    text-transform: uppercase; }
    .entry-post.style4 .more:hover {
      color: #444; }
.entry-post.style5 {
  background-color: #f8f8f8;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .entry-post.style5 img {
    width: 100%; }
  .entry-post.style5 .entry-content {
    padding: 52px 60px 72px; }
  .entry-post.style5 .post-date {
    line-height: 1; }
    .entry-post.style5 .post-date span {
      display: block; }
    .entry-post.style5 .post-date .month {
      font-size: 21px; }
    .entry-post.style5 .post-date .date {
      font-size: 48px; }
  .entry-post.style5 h2 {
    font-size: 24px;
    margin-bottom: 11px; }
  .entry-post.style5 a {
    color: #444; }
    .entry-post.style5 a:hover {
      color: #0D52FF; }
  .entry-post.style5 .entry-meta {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-size: 12px;
    margin-bottom: 25px; }
    .entry-post.style5 .entry-meta > *:not(:last-child) {
      margin-right: 15px; }
  .entry-post.style5 .more {
    font-weight: 500;
    line-height: 14px;
    margin-top: 10px; }
    .entry-post.style5 .more i {
      margin-left: 5px; }
  .entry-post.style5:hover {
    background-color: #fff; }
.entry-post.style6 {
  background-color: #fafafa; }
  .entry-post.style6 .content {
    padding: 53px 50px 55px; }
  .entry-post.style6 h4 {
    margin-bottom: 13px; }
  .entry-post.style6:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    color: #fff; }
    .entry-post.style6:hover .content {
      background-color: #0D52FF; }
    .entry-post.style6:hover h4 {
      color: #fff; }

.the-blog-meh .shade-text {
  color: #000;
  font-size: 200px;
  font-weight: 700;
  opacity: 0.1;
  position: absolute; }
  .the-blog-meh .shade-text.top {
    left: -110px;
    top: -50px; }
  .the-blog-meh .shade-text.bottom {
    bottom: 0px;
    right: -130px;
    z-index: -1; }

.blog-post-1 {
  position: relative; }
  .blog-post-1 .cat {
    font-size: 12px;
    font-weight: 300; }
  .blog-post-1 h3 {
    font-size: 24px; }
  .blog-post-1 .thumb img {
    margin-left: auto; }
  .blog-post-1.sticky::before {
    background-color: #fff;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 410px; }
  .blog-post-1.sticky .post-desc {
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    left: 80px;
    max-width: 535px;
    position: absolute;
    top: 0; }
  .blog-post-1.sticky h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 60px; }
    .blog-post-1.sticky h2::before {
      background-color: #212121;
      bottom: -19px;
      content: "";
      height: 3px;
      position: absolute;
      width: 100px; }
  .blog-post-1.sticky .more {
    letter-spacing: 5.6px; }
  .blog-post-1:hover .hover-ripple {
    -webkit-transform: scale(7);
    -moz-transform: scale(7);
    -ms-transform: scale(7);
    -o-transform: scale(7);
    transform: scale(7); }
  .blog-post-1:hover .thumb {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }

.browse-all-video {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  font-weight: 500;
  letter-spacing: 5.6px;
  margin-top: 60px;
  padding-top: 35px; }

.gray-bg-3 .browse-all-video {
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.blog-load-more-1,
.blog-load-more-2,
.blog-load-more-3 {
  font-weight: 400;
  line-height: 80px;
  letter-spacing: 5.6px; }
  .blog-load-more-1 a,
  .blog-load-more-2 a,
  .blog-load-more-3 a {
    color: #444; }

.blog-load-more-2 {
  font-size: 24px; }

.blog-load-more-3 {
  letter-spacing: 2.8px;
  line-height: 140px; }

.blog-post-2 {
  position: relative; }
  .blog-post-2 .post-desc {
    padding: 54px 80px 60px; }
  .blog-post-2 h2, .blog-post-2 h3 {
    padding-bottom: 33px; }
    .blog-post-2 h2::before, .blog-post-2 h3::before {
      background-color: #444;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100px;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
  .blog-post-2 h3 {
    font-size: 24px;
    line-height: 30px; }
  .blog-post-2.sticky .post-desc {
    height: 100%;
    max-width: 550px;
    padding: 80px;
    position: absolute;
    right: 0;
    top: 0; }
  .blog-post-2.sticky .meta {
    font-size: 18px; }
  .blog-post-2.sticky h2 {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 75px; }
  .blog-post-2.featured .meta {
    font-size: 18px; }
  .blog-post-2.featured .post-desc {
    position: absolute;
    left: 80px;
    top: 50%;
    padding: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    .blog-post-2.featured .post-desc h2 {
      font-size: 36px;
      line-height: 48px; }
      .blog-post-2.featured .post-desc h2::before {
        background-color: #fff; }
  .blog-post-2.featured:hover .meta {
    color: #848484; }
    .blog-post-2.featured:hover .meta a {
      color: #848484; }
      .blog-post-2.featured:hover .meta a:hover {
        color: #0D52FF; }
  .blog-post-2.featured:hover h2 a {
    color: #0D52FF; }
  .blog-post-2.featured:hover .post-desc h2::before {
    background-color: #0D52FF; }
  .blog-post-2:hover .hover-ripple {
    -webkit-transform: scale(8);
    -moz-transform: scale(8);
    -ms-transform: scale(8);
    -o-transform: scale(8);
    transform: scale(8); }

.blog-post-3 .post-desc {
  padding: 80px 0; }
.blog-post-3 h3 {
  font-size: 30px; }
.blog-post-3 .meta {
  font-size: 12px; }
.blog-post-3.no-media {
  margin-bottom: 90px; }

.blog-post-4 .meta {
  bottom: 0;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1);
  display: block;
  left: 0;
  padding: 12px 40px 14px;
  position: absolute;
  right: 0; }
.blog-post-4 .desc {
  background-color: #f9fafc;
  padding: 32px 40px 40px;
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }
.blog-post-4:hover .desc {
  background-color: #fff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }

.hentry-card .hentry-header {
  padding: 42px 40px 24px; }
.hentry-card h2 {
  font-size: 30px;
  line-height: 36px; }
.hentry-card .sep {
  background-color: rgba(0, 0, 0, 0.87);
  height: 3px;
  width: 100px; }
.hentry-card .hentry-content {
  padding: 0 40px 42px 40px; }
.hentry-card .hentry-meta {
  padding: 28px 40px; }
.hentry-card .owl-dots {
  background-color: rgba(0, 0, 0, 0.87);
  bottom: 0;
  padding: 30px 0;
  top: auto !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.hentry-vanilla {
  outline: 0 solid #fff; }
  .hentry-vanilla .hentry-header {
    padding: 42px 50px 24px; }
  .hentry-vanilla h2 {
    font-size: 30px;
    line-height: 36px; }
  .hentry-vanilla .hentry-content {
    padding: 0 50px 44px; }
  .hentry-vanilla:hover {
    outline-offset: -10px;
    outline-width: 10px; }
  .hentry-vanilla .owl-dots {
    background-color: rgba(0, 0, 0, 0.87);
    bottom: 0;
    padding: 30px 0;
    top: auto !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }

.if-scroll {
  padding: 28px 40px; }

.hentry-horiz h2 {
  font-size: 30px;
  line-height: 36px; }
.hentry-horiz .sep {
  background-color: rgba(0, 0, 0, 0.87);
  height: 3px;
  width: 100px; }

.timeline-container {
  position: relative; }
  .timeline-container .timeline-bar {
    background-color: #f7f7f7;
    height: 100%;
    left: 40px;
    margin-left: -5px;
    position: absolute;
    top: 0;
    width: 10px; }

.hentry-timeline {
  margin-left: 16.6667%; }
  .hentry-timeline .date-time {
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 80px;
    left: 0;
    position: absolute;
    text-align: center;
    top: 50px;
    width: 80px; }
    .hentry-timeline .date-time::after {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
      content: "";
      height: 1px;
      left: 80px;
      position: absolute;
      top: 50%;
      width: 100%;
      z-index: 3; }
  .hentry-timeline .datetime-holder {
    left: 50%;
    line-height: 1.3;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .hentry-timeline .day {
    font-size: 30px;
    line-height: 20px; }
  .hentry-timeline h2 {
    font-size: 30px;
    line-height: 36px; }
  .hentry-timeline .sep {
    background-color: rgba(0, 0, 0, 0.87);
    height: 3px;
    width: 100px; }
  .hentry-timeline .hentry-header {
    padding: 33px 40px 23px; }
  .hentry-timeline .hendtry-content,
  .hentry-timeline .hentry-meta {
    padding-left: 40px;
    padding-right: 40px; }

.comment + .comment {
  margin-top: 30px; }

.comment-body {
  border-radius: 3px;
  padding: 32px 175px 31px 35px; }
  .comment-body + .comment-body {
    margin-top: 30px;
    margin-left: 40px; }

.comment-form .ml-input {
  margin-bottom: 0; }

.fb-comments.fb_iframe_widget {
  display: block; }
  .fb-comments.fb_iframe_widget span {
    display: block;
    width: 100% !important; }
  .fb-comments.fb_iframe_widget iframe {
    width: 100% !important; }

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 30px; }

.alignright {
  display: inline;
  float: right;
  margin-left: 30px; }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

/*----------------------------------------
    Process
------------------------------------------*/
.process-1 {
  padding: 100px 0;
  text-align: center; }
  .process-1:nth-child(2n+1) {
    background-color: #fff; }
  .process-1:nth-child(2)::before {
    border-bottom-color: #f8f8f8;
    border-top-color: #f8f8f8; }
  .process-1::before {
    border-color: #fff transparent;
    border-style: solid;
    border-width: 162px 80px;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
  .process-1:last-child::after {
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    border-width: 162px 75px;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    -webkit-transform: translateX(98%);
    -moz-transform: translateX(98%);
    -ms-transform: translateX(98%);
    -o-transform: translateX(98%);
    transform: translateX(98%); }
  .process-1 .icon {
    color: #444;
    display: inline-block;
    font-size: 60px;
    margin-bottom: 27px; }
  .process-1 h3 {
    font-size: 24px; }

.process-2 .icon {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+1,ffffff+100 */
  background: #eeeeee;
  /* Old browsers */
  background: -webkit-radial-gradient(50% 50%, circle closest-side, #fff 0%, #eeeeee 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -moz-radial-gradient(50% 50%, circle closest-side, #fff 0%, #eeeeee 100%);
  /* FF3.6-15 */
  background: -o-radial-gradient(50% 50%, circle closest-side, #fff 0%, #eeeeee 100%);
  /* FF3.6-15 */
  background: radial-gradient(50% 50%, circle closest-side, #fff 0%, #eeeeee 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: #444;
  border-radius: 50%;
  font-size: 60px;
  height: 250px;
  line-height: 250px;
  margin: 0 auto 30px;
  position: relative;
  width: 250px; }
.process-2 .number {
  background-color: #eeeeee;
  border: 1px solid #0D52FF;
  border-radius: 50%;
  color: #444;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  height: 50px;
  left: 50%;
  line-height: 46px;
  position: absolute;
  top: 0;
  width: 50px;
  z-index: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .process-2 .number::before {
    border: 5px solid #fff;
    border-radius: 50%;
    content: "";
    height: 60px;
    left: 50%;
    margin: -30px 0 0 -30px;
    position: absolute;
    top: 50%;
    width: 60px;
    z-index: -1; }
.process-2:hover .number {
  background-color: #0D52FF;
  color: #fff; }
.process-2:hover h4 {
  color: #0D52FF; }

.process-3 .icon {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  color: #444;
  font-size: 60px;
  height: 250px;
  line-height: 260px;
  margin: 0 auto 40px;
  text-align: center;
  position: relative;
  width: 250px; }
  .process-3 .icon::after {
    background: blue;
    content: "";
    height: 31px;
    left: 100%;
    position: absolute;
    top: 50%;
    width: 50px;
    -webkit-transform: translate(30%, -50%);
    -moz-transform: translate(30%, -50%);
    -ms-transform: translate(30%, -50%);
    -o-transform: translate(30%, -50%);
    transform: translate(30%, -50%); }
.process-3 h4 {
  font-size: 18px; }
.process-3:hover .icon {
  color: #0D52FF; }

[class^="col-"]:last-child .process-3 .icon::after {
  content: none; }

.process-4 li a {
  display: block;
  position: relative; }
  .process-4 li a polygon {
    fill: rgba(0, 0, 0, 0.4);
    stroke: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .process-4 li a:hover polygon {
    fill: rgba(0, 0, 0, 0.2);
    stroke: #0D52FF; }
  .process-4 li a:hover .process-label {
    color: #0D52FF; }
.process-4 li .process-label {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
.process-4.tab-content {
  margin-top: 68px; }
.process-4 .tab-pane {
  border-top: 1px solid #0D52FF;
  padding: 40px 0; }

.process-5 .icon {
  background-color: #f7f7f7;
  border-radius: 50%;
  font-size: 48px;
  height: 190px;
  line-height: 190px;
  margin-bottom: 20px;
  position: relative;
  width: 190px; }
.process-5 svg {
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scale(1.075);
  -moz-transform: scale(1.075);
  -ms-transform: scale(1.075);
  -o-transform: scale(1.075);
  transform: scale(1.075); }
  .process-5 svg ellipse {
    fill: transparent;
    stroke: transparent;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-dasharray: 0,7.9543,0,7.9543,0,7.9543;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
.process-5:hover .icon {
  color: #0D52FF; }
.process-5:hover svg ellipse {
  stroke: #0D52FF; }

/*==============================================
    team
===============================================*/
.team-member figure img {
  width: 100%; }
.team-member.style1 {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .team-member.style1 figcaption {
    padding: 20px 25px;
    position: relative; }
  .team-member.style1 .fixed-action-btn {
    position: absolute;
    right: 20px;
    top: auto; }
  .team-member.style1 .btn-white {
    box-shadow: none;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .team-member.style1 .btn-white:hover {
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .team-member.style1:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
.team-member.style2 {
  border-radius: 2px; }
  .team-member.style2 img {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .team-member.style2 img:hover {
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .team-member.style2 figcaption span {
    font-size: 17px;
    margin-top: 2px; }
  .team-member.style2 .social-profiles {
    margin: 0;
    text-align: left; }
    .team-member.style2 .social-profiles li {
      padding: 0; }
      .team-member.style2 .social-profiles li a {
        color: #848484;
        border-radius: 100px;
        font-size: 17px;
        height: 42px;
        margin: 0;
        width: 42px;
        line-height: 42px;
        text-align: center; }
        .team-member.style2 .social-profiles li a:hover {
          box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
.team-member.style3 img {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .team-member.style3 img:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
.team-member.style3 figcaption span {
  font-size: 17px;
  margin-top: 2px; }
.team-member.style3 .social-profiles {
  margin-left: 0; }
  .team-member.style3 .social-profiles li {
    padding: 0; }
    .team-member.style3 .social-profiles li a {
      color: #848484;
      border-radius: 100px;
      font-size: 17px;
      height: 42px;
      margin: 0;
      width: 42px;
      line-height: 42px; }
      .team-member.style3 .social-profiles li a:hover {
        box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
.team-member.style4 {
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .team-member.style4 .member-info {
    padding: 60px; }
    .team-member.style4 .member-info h4 {
      font-size: 24px;
      margin-bottom: 4px;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
    .team-member.style4 .member-info span {
      display: inline-block;
      font-size: 18px;
      margin-bottom: 30px; }
    .team-member.style4 .member-info .contact {
      color: #444;
      display: inline-block;
      font-style: italic;
      font-weight: 400;
      margin-top: 34px;
      text-decoration: underline; }
  .team-member.style4:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }
    .team-member.style4:hover .member-info h4, .team-member.style4:hover .member-info .contact {
      color: #0D52FF; }
.team-member.style5 .bio {
  margin-bottom: 34px; }
  .team-member.style5 .bio h4 {
    font-size: 24px;
    margin-bottom: 4px; }
.team-member.style5 .social-profiles {
  margin-top: 7px; }
  .team-member.style5 .social-profiles a {
    color: #848484;
    margin: 0 6px; }
    .team-member.style5 .social-profiles a:hover {
      color: #0D52FF; }
.team-member.style5 img {
  width: 100%; }
.team-member.style5 .hover-ripple {
  opacity: .7;
  -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -moz-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -o-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
.team-member.style5 .hover {
  opacity: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: scale(0) translate(-50%, -50%);
  -moz-transform: scale(0) translate(-50%, -50%);
  -ms-transform: scale(0) translate(-50%, -50%);
  -o-transform: scale(0) translate(-50%, -50%);
  transform: scale(0) translate(-50%, -50%);
  -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -moz-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -o-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
  .team-member.style5 .hover a {
    color: #fff;
    font-size: 42px; }
.team-member.style5:hover .hover-ripple {
  -webkit-transform: scale(10);
  -moz-transform: scale(10);
  -ms-transform: scale(10);
  -o-transform: scale(10);
  transform: scale(10); }
.team-member.style5:hover .hover {
  opacity: 1;
  -webkit-transform: scale(1) translate(-50%, -50%);
  -moz-transform: scale(1) translate(-50%, -50%);
  -ms-transform: scale(1) translate(-50%, -50%);
  -o-transform: scale(1) translate(-50%, -50%);
  transform: scale(1) translate(-50%, -50%); }
.team-member.style6 figcaption {
  height: 100%;
  left: 0;
  padding: 95px 50px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10; }
.team-member.style6 h4, .team-member.style6 span, .team-member.style6 a {
  opacity: 0;
  -webkit-transform: translateX(40%);
  -moz-transform: translateX(40%);
  -ms-transform: translateX(40%);
  -o-transform: translateX(40%);
  transform: translateX(40%); }
.team-member.style6 h4 {
  font-size: 24px;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s; }
.team-member.style6 span {
  display: block;
  font-size: 18px;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.05s;
  -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.05s;
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.05s;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.05s; }
.team-member.style6 .mail {
  bottom: 100px;
  font-size: 16px;
  font-style: italic;
  left: 0;
  position: absolute;
  right: 0;
  text-decoration: underline;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s; }
.team-member.style6:hover {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }
  .team-member.style6:hover figcaption {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .team-member.style6:hover h4, .team-member.style6:hover span, .team-member.style6:hover a {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px); }
  .team-member.style6:hover .hover-ripple {
    -webkit-transform: scale(8);
    -moz-transform: scale(8);
    -ms-transform: scale(8);
    -o-transform: scale(8);
    transform: scale(8); }
.team-member.style7 {
  background-color: #f7f7f7;
  padding: 30px 30px 30px 0;
  position: relative; }
  .team-member.style7::before {
    background-color: #fff;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px; }
  .team-member.style7 .thumb {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .team-member.style7 h3 {
    font-size: 24px; }
  .team-member.style7 span {
    display: block;
    font-size: 16px;
    margin-top: 3px; }
  .team-member.style7 hr {
    border-top-color: rgba(0, 0, 0, 0.1); }
  .team-member.style7 .social a {
    border: 1px solid;
    border-radius: 4px;
    color: #dedede;
    display: inline-block;
    font-size: 18px;
    height: 35px;
    line-height: 35px;
    width: 35px;
    text-align: center; }
    .team-member.style7 .social a:hover {
      border-color: #0D52FF;
      color: #0D52FF; }
  .team-member.style7:hover .thumb {
    box-shadow: 0px 1px 6.86px 0.14px rgba(0, 0, 0, 0.16); }
.team-member.style8 {
  margin-bottom: 121px; }
  .team-member.style8 .desc {
    bottom: 0;
    left: 0;
    padding: 32px 40px 33px;
    position: absolute;
    right: 0; }
  .team-member.style8 h4 {
    font-size: 18px;
    margin-bottom: 5px; }
  .team-member.style8 .bottom {
    height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: bottom left 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .team-member.style8 li + li {
    margin-top: 4px; }
  .team-member.style8 .social li {
    display: inline-block;
    margin-top: 0; }
  .team-member.style8 .social a {
    border: 2px solid;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.2);
    display: block;
    font-size: 16px;
    height: 35px;
    line-height: 33px;
    text-align: center;
    width: 35px; }
    .team-member.style8 .social a:hover {
      border-color: #0D52FF;
      color: #0D52FF; }
  .team-member.style8:hover .desc {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    bottom: 10px; }
  .team-member.style8:hover h4 {
    color: #444; }
  .team-member.style8:hover span {
    color: #848484; }
  .team-member.style8:hover .top {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 32px;
    padding-bottom: 13px; }
  .team-member.style8:hover .bottom {
    height: auto;
    transform: scaleY(1); }
.team-member.style9:hover {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }
.team-member.style9 .border {
  border: 1px solid #fff;
  border-radius: 5px;
  bottom: 30px;
  left: 30px;
  position: absolute;
  right: 30px;
  top: 30px;
  -webkit-transform-origin: right top 0;
  -moz-transform-origin: right top 0;
  -ms-transform-origin: right top 0;
  -o-transform-origin: right top 0;
  transform-origin: right top 0;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
.team-member.style9 figcaption {
  color: #fff;
  left: 50%;
  padding: 50px 45px;
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%) scaleX(0);
  -moz-transform: translate(-50%, -50%) scaleX(0);
  -ms-transform: translate(-50%, -50%) scaleX(0);
  -o-transform: translate(-50%, -50%) scaleX(0);
  transform: translate(-50%, -50%) scaleX(0);
  -webkit-transform-origin: right top 0;
  -moz-transform-origin: right top 0;
  -ms-transform-origin: right top 0;
  -o-transform-origin: right top 0;
  transform-origin: right top 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
.team-member.style9 h3 {
  font-size: 24px; }
.team-member.style9 span {
  display: block;
  font-size: 18px;
  margin: 3px 0 22px; }
.team-member.style9 .btn-md {
  font-size: 13px;
  margin-top: 15px; }
.team-member.style9:hover .hover-ripple {
  -webkit-transform: scale(7);
  -moz-transform: scale(7);
  -ms-transform: scale(7);
  -o-transform: scale(7);
  transform: scale(7); }
.team-member.style9:hover .border {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1); }
.team-member.style9:hover figcaption {
  -webkit-transform: translate(-50%, -50%) scaleX(1);
  -moz-transform: translate(-50%, -50%) scaleX(1);
  -ms-transform: translate(-50%, -50%) scaleX(1);
  -o-transform: translate(-50%, -50%) scaleX(1);
  transform: translate(-50%, -50%) scaleX(1); }
.team-member.style10 .top {
  background-color: #f7f7f7;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }
.team-member.style10 .social-links a {
  color: #848484;
  font-size: 18px;
  margin: 5px 5px 0; }
  .team-member.style10 .social-links a:hover {
    color: #0D52FF; }
.team-member.style10 .bottom {
  background-color: #f7f7f7;
  padding: 30px 34px 36px; }
.team-member.style10:hover {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .team-member.style10:hover .top {
    background-color: #fff;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%); }
.team-member.style-11 {
  position: relative;
  z-index: 10; }
  .team-member.style-11 .short-desc {
    padding: 100px;
    position: relative; }
    .team-member.style-11 .short-desc::before {
      border: 2px solid rgba(0, 0, 0, 0.1);
      bottom: -20px;
      content: "";
      left: -40px;
      position: absolute;
      right: -20px;
      top: -20px;
      z-index: -1; }
    .team-member.style-11 .short-desc.ml-20::before {
      border-color: #fff;
      left: -20px;
      right: -40px; }

.team-member-12 .social-fab {
  bottom: 25px;
  position: absolute;
  right: 35px; }
  .team-member-12 .social-fab a {
    border-radius: 50%;
    color: #444;
    display: block;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 5px;
    text-align: center;
    width: 50px;
    border: 1px solid #dedede;
    opacity: 0;
    -webkit-transform: scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0px);
    -moz-transform: scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0px);
    -ms-transform: scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0px);
    -o-transform: scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0px);
    transform: scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0px); }
    .team-member-12 .social-fab a.dribbble {
      background: transparent; }
      .team-member-12 .social-fab a.dribbble:hover {
        background: #e40079; }
    .team-member-12 .social-fab a.behance {
      background: transparent; }
      .team-member-12 .social-fab a.behance:hover {
        background: #1769ff; }
    .team-member-12 .social-fab a.pinterest {
      background: transparent; }
      .team-member-12 .social-fab a.pinterest:hover {
        background: #fc3030; }
    .team-member-12 .social-fab a.twitter {
      background: transparent; }
      .team-member-12 .social-fab a.twitter:hover {
        background: #55acee; }
    .team-member-12 .social-fab a.facebook {
      background: transparent; }
      .team-member-12 .social-fab a.facebook:hover {
        background: #42599e; }
    .team-member-12 .social-fab a.facebook {
      background: transparent; }
      .team-member-12 .social-fab a.facebook:hover {
        background: #42599e; }
    .team-member-12 .social-fab a:hover {
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13);
      border: 1px solid transparent;
      color: #fff; }
.team-member-12:hover .thumb {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.13); }
.team-member-12:hover .hover-ripple {
  -webkit-transform: scale(5);
  -moz-transform: scale(5);
  -ms-transform: scale(5);
  -o-transform: scale(5);
  transform: scale(5); }
.team-member-12:hover .social-fab a {
  opacity: 1;
  -webkit-transform: scaleY(1) scaleX(1) translateY(0px) translateX(0px);
  -moz-transform: scaleY(1) scaleX(1) translateY(0px) translateX(0px);
  -ms-transform: scaleY(1) scaleX(1) translateY(0px) translateX(0px);
  -o-transform: scaleY(1) scaleX(1) translateY(0px) translateX(0px);
  transform: scaleY(1) scaleX(1) translateY(0px) translateX(0px); }
.team-member-12:hover h4 {
  color: #0D52FF; }

.team-carousel-2 .owl-stage-outer {
  padding-top: 20px;
  padding-bottom: 21px; }

.team-single-wrapper .mfp-close {
  background: blue;
  display: block;
  height: 75px;
  right: 50px;
  text-indent: -9999em;
  top: 50px;
  width: 75px; }

.team-single-desc {
  margin-top: 35px; }
  .team-single-desc .bio h2 {
    font-size: 36px; }
  .team-single-desc .bio span {
    font-size: 18px;
    font-weight: 300; }
  .team-single-desc .social-single li {
    display: inline-block; }
  .team-single-desc .social-single a {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    color: #848484;
    display: block;
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px; }
  .team-single-desc .progress-element {
    margin-top: 70px; }
  .team-single-desc .progress .value {
    font-size: 14px; }

/*==============================================
    client
===============================================*/
.client-logos {
  padding: 100px 0; }

.client-logo img {
  display: block;
  margin: 0 auto;
  opacity: 0.5;
  max-width: 100%;
  -webkit-transition: opacity 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s; }
.client-logo:hover img {
  opacity: 1; }

.clients-3col [class^="col-"] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 250px; }
  .clients-3col [class^="col-"]:nth-child(3), .clients-3col [class^="col-"]:nth-child(6) {
    border-right: 0; }
  .clients-3col [class^="col-"]:nth-last-child(1), .clients-3col [class^="col-"]:nth-last-child(2), .clients-3col [class^="col-"]:nth-last-child(3) {
    border-bottom: 0; }
  .clients-3col [class^="col-"]:hover img {
    opacity: .5; }
.clients-3col img {
  opacity: 0.1; }

.clients-4col [class^="col-"] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 250px; }
  .clients-4col [class^="col-"]:nth-child(4), .clients-4col [class^="col-"]:nth-child(8) {
    border-right: 0; }
  .clients-4col [class^="col-"]:nth-last-child(1), .clients-4col [class^="col-"]:nth-last-child(2), .clients-4col [class^="col-"]:nth-last-child(3), .clients-4col [class^="col-"]:nth-last-child(4) {
    border-bottom: 0; }
  .clients-4col [class^="col-"]:hover {
    background-color: #f7f7f7; }
    .clients-4col [class^="col-"]:hover img {
      opacity: .5; }
    .clients-4col [class^="col-"]:hover.no-hover-bg {
      background-color: transparent; }
.clients-4col.light [class^="col-"] {
  border-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1); }
.clients-4col img {
  opacity: 0.25; }

.clients-6col [class^="col-"] {
  min-height: 200px;
  padding: 25px; }
  .clients-6col [class^="col-"]:hover img {
    opacity: .5; }
.clients-6col img {
  opacity: 0.1; }

/*==============================================
    Counter
===============================================*/
/*----------------------------------------
    Counter style-1
------------------------------------------*/
.counter.style-1 .odometer {
  color: #0D52FF;
  display: block;
  font-size: 70px;
  font-weight: 100;
  line-height: 1; }
.counter.style-1 p {
  font-size: 20px;
  margin-top: 17px; }
.counter.style-2, .counter.style-4, .counter.style-5 {
  border-left: 2px solid #fff;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px; }
  .counter.style-2 .odometer, .counter.style-4 .odometer, .counter.style-5 .odometer {
    color: #0D52FF;
    font-family: "Poppins", sans-serif;
    font-size: 72px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 7px; }
  .counter.style-2 h4, .counter.style-4 h4, .counter.style-5 h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 16px; }
  .counter.style-2 p, .counter.style-4 p, .counter.style-5 p {
    font-size: 13px; }
.counter.style-3 h4 {
  font-size: 20px; }
.counter.style-3 .sep {
  background-color: #dedede;
  height: 3px;
  margin-top: 15px;
  width: 50px; }
.counter.style-3 .odometer {
  font-size: 48px;
  line-height: 1;
  margin-top: 20px; }
.counter.style-4 .odometer {
  color: #fff; }
.counter.style-5 {
  border-left-color: #0D52FF; }
  .counter.style-5 h4 {
    color: #444; }
.counter.style-6 .icon {
  color: #0D52FF;
  font-size: 36px; }
.counter.style-6 .odometer {
  font-size: 60px;
  line-height: 1; }

.counter-4-bg {
  background-position: center -50px;
  background-repeat: no-repeat; }

/*==============================================
    Subscription
===============================================*/
.subscribe-block-1 {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 10; }
  .subscribe-block-1 .left-bg {
    background-color: #0D52FF;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0; }
  .subscribe-block-1 .subscribe-inner {
    background-color: #0D52FF;
    color: #fff;
    padding: 50px 140px 50px 0; }
    .subscribe-block-1 .subscribe-inner p {
      font-size: 16px;
      margin: 0; }
    .subscribe-block-1 .subscribe-inner h3 {
      color: #fff;
      font-size: 24px; }
    .subscribe-block-1 .subscribe-inner .subscribe {
      background-color: #fff;
      border-radius: 4px;
      margin-top: 20px;
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center; }
      .subscribe-block-1 .subscribe-inner .subscribe label {
        font-size: 24px;
        margin: 0;
        padding: 0 21px 0 30px;
        text-align: center; }
      .subscribe-block-1 .subscribe-inner .subscribe input {
        border: 0 none;
        box-shadow: none;
        color: #848484;
        font-size: 16px;
        height: 60px;
        margin: 0; }
      .subscribe-block-1 .subscribe-inner .subscribe .mail-sub {
        background-color: #0D52FF;
        border: 0 none;
        border-radius: 4px;
        font-size: 30px;
        height: 56px;
        line-height: 56px;
        padding: 0 32px;
        position: relative;
        right: 2px; }

.newsletter-1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 40px 0 0 auto;
  max-width: 440px;
  position: relative;
  width: 100%; }
  .newsletter-1 .nl-1-input {
    border: 0 none;
    height: 50px;
    margin: 0;
    padding: 0 15px 0 30px;
    width: 100%; }
  .newsletter-1 .nl-1-submit {
    background-color: #0D52FF;
    border: 0 none;
    border-radius: 0 5px 5px 0;
    color: #fff;
    font-weight: 500;
    height: 46px;
    padding: 0 36px;
    position: relative;
    right: 2px;
    top: 2px; }

.newsletter-2 .nl-2-input {
  background-color: #f7f7f7;
  border: 0;
  border-radius: 50px 0 0 50px;
  height: 70px;
  margin: 0;
  padding: 0 20px 0 40px; }
.newsletter-2 .nl-2-submit {
  background-color: #0D52FF;
  border: 0 none;
  border-radius: 0 50px 50px 0;
  color: #fff;
  font-weight: 500;
  padding: 0 75px; }
.newsletter-2 p {
  font-size: 18px; }

.newsletter-3 .nl-input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  height: 68px;
  margin: 0;
  padding: 0 80px 0 60px;
  width: 100%; }
.newsletter-3 .nl-submit {
  background-color: #0D52FF;
  border: 0 none;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  margin-left: -60px;
  padding: 0 90px;
  white-space: nowrap; }
.newsletter-3.dark-border .nl-inner {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 1px; }
.newsletter-3 p {
  color: #fff;
  font-size: 16px; }
.newsletter-3.dark .nl-input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #848484; }
.newsletter-3.dark p {
  color: #848484; }
.newsletter-3.light .nl-inner {
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  padding: 1px; }
.newsletter-3.light .nl-input {
  border: 0; }
.newsletter-3.light .nl-submit {
  background-color: #fff;
  color: #444; }

.newsletter-4 {
  border-radius: 30px; }
  .newsletter-4 .nl-input {
    background-color: transparent;
    border: 0 none;
    border-radius: 30px 0 0 30px;
    height: 40px;
    margin-bottom: 0;
    padding: 0 0 0 30px;
    width: 100%; }
  .newsletter-4 .nl-submit {
    border: 0 none;
    border-radius: 30px;
    color: #fff;
    margin: 2px;
    padding: 0 40px; }

.section-newsletter .nl-logo {
  left: 50%;
  position: absolute;
  top: 80px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }
.section-newsletter .nl-input {
  height: 50px; }

/*==============================================
    Widgets
===============================================*/
.widget-area .widget:not(:last-child) {
  margin-bottom: 30px; }

.widget.type-1 {
  color: rgba(255, 255, 255, 0.87); }
  .widget.type-1 h4 {
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 31px;
    text-transform: uppercase; }
  .widget.type-1 h5 {
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 13px; }
  .widget.type-1 address {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    line-height: 24px;
    padding-bottom: 10px;
    position: relative; }
    .widget.type-1 address:last-child {
      border-bottom: 0 none;
      margin-bottom: 0;
      padding-bottom: 0; }
  .widget.type-1 .widget-link {
    font-weight: 300; }
    .widget.type-1 .widget-link i {
      float: left;
      font-size: 20px;
      line-height: inherit;
      padding-right: 9px; }
    .widget.type-1 .widget-link:hover {
      text-decoration: underline; }
.widget.type-2 {
  padding: 40px 37px; }
  .widget.type-2 a {
    color: #848484; }
    .widget.type-2 a:hover {
      color: #444; }

.pages_list li a {
  line-height: 2; }

.product-categories li a {
  line-height: 30px; }
.product-categories li ul {
  background-color: #f7f7f7;
  display: none;
  margin: 5px -37px;
  padding: 5px 40px 5px 78px; }

.noUi-connect,
.noUi-target .range-label,
.noUi-horizontal .noUi-handle {
  background-color: #0D52FF; }

.filter-by-color {
  -webkit-column-count: 2;
  /* Chrome, Safari, Opera */
  -moz-column-count: 2;
  /* Firefox */
  column-count: 2; }
  .filter-by-color li {
    margin-bottom: 5px; }
  .filter-by-color .product-radio {
    border: 0 none !important;
    height: auto;
    width: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }
    .filter-by-color .product-radio label {
      border: 2px solid #fff;
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
      height: 19px;
      margin-right: 15px;
      min-width: 19px;
      position: relative;
      width: 19px;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1); }

.mag-widget-title {
  background-color: #fff;
  border-top: 2px solid #0D52FF;
  line-height: 58px; }

.mag-widget-title-2 {
  background-color: #f7f7f7;
  border-left: 2px solid #0D52FF;
  font-size: 16px;
  letter-spacing: 2.8px;
  margin-bottom: 10px;
  padding: 21px 30px 23px;
  text-transform: uppercase; }

.magazine-widget .widget-inner {
  background-color: #f7f7f7;
  padding: 30px; }
.magazine-widget .search-form input {
  background-color: #fff;
  border: 0 none;
  border-radius: 2px;
  font-size: 12px;
  font-style: italic;
  height: 40px;
  padding: 0 20px; }
.magazine-widget .search-form button {
  background-color: #0D52FF;
  border-radius: 2px;
  border: 0 none;
  color: #fff;
  line-height: 40px;
  width: 100%; }

.mag-categories {
  margin: -30px; }
  .mag-categories li {
    line-height: 50px;
    position: relative; }
    .mag-categories li::after {
      background-color: #009688;
      bottom: 0;
      content: "";
      height: 0;
      position: absolute;
      right: 0;
      transition: all 0.3s ease 0s;
      width: 3px; }
    .mag-categories li:hover::after {
      height: 100%; }
    .mag-categories li:nth-child(2n+1) {
      background-color: rgba(0, 0, 0, 0.02); }
    .mag-categories li .post-count {
      background-color: #009688;
      border-radius: 0 5px 5px 0;
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      width: 50px; }
    .mag-categories li:nth-child(2) .post-count {
      background-color: #ba68c8; }
    .mag-categories li:nth-child(2):hover::after {
      background-color: #ba68c8; }
    .mag-categories li:nth-child(3) .post-count {
      background-color: #f06292; }
    .mag-categories li:nth-child(3):hover::after {
      background-color: #f06292; }
    .mag-categories li:nth-child(4) .post-count {
      background-color: #ffa726; }
    .mag-categories li:nth-child(4):hover::after {
      background-color: #ffa726; }
    .mag-categories li:nth-child(5) .post-count {
      background-color: #8d6e63; }
    .mag-categories li:nth-child(5):hover::after {
      background-color: #8d6e63; }
    .mag-categories li:nth-child(6) .post-count {
      background-color: #78909c; }
    .mag-categories li:nth-child(6):hover::after {
      background-color: #78909c; }
    .mag-categories li:nth-child(7) .post-count {
      background-color: #f44336; }
    .mag-categories li:nth-child(7):hover::after {
      background-color: #f44336; }
    .mag-categories li:nth-child(8) .post-count {
      background-color: #2196f3; }
    .mag-categories li:nth-child(8):hover::after {
      background-color: #2196f3; }
    .mag-categories li:nth-child(9) .post-count {
      background-color: #8bc34a; }
    .mag-categories li:nth-child(9):hover::after {
      background-color: #8bc34a; }
    .mag-categories li a {
      color: #444;
      display: block;
      padding-left: 80px; }

.widget-post-list li,
.widget-post-list-2 li {
  margin-bottom: 30px; }
  .widget-post-list li:last-child,
  .widget-post-list-2 li:last-child {
    margin-bottom: 0; }
  .widget-post-list li.border,
  .widget-post-list-2 li.border {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 30px; }
    .widget-post-list li.border .thumb,
    .widget-post-list-2 li.border .thumb {
      min-width: auto;
      width: auto; }
    .widget-post-list li.border .text,
    .widget-post-list-2 li.border .text {
      padding-left: 20px; }
.widget-post-list .thumb,
.widget-post-list-2 .thumb {
  min-width: 150px;
  width: 150px; }
.widget-post-list .text,
.widget-post-list-2 .text {
  padding-left: 30px; }
.widget-post-list .tag,
.widget-post-list-2 .tag {
  display: inline-block; }
.widget-post-list .title,
.widget-post-list-2 .title {
  display: block; }
.widget-post-list time,
.widget-post-list-2 time {
  font-size: 12px; }

.widget-post-list-2 li {
  background-color: #f7f7f7;
  padding: 20px;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .widget-post-list-2 li:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
.widget-post-list-2 .thumb {
  min-width: 100px;
  width: 100px; }
.widget-post-list-2 .text {
  padding-left: 15px; }

.social-media li {
  display: inline-block; }
  .social-media li a {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #444;
    display: block;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 13px 8px; }
    .social-media li a:hover {
      background-color: #0D52FF;
      border-color: #0D52FF;
      color: #fff; }
  .social-media li .follower-count {
    display: block;
    margin-top: 4px; }

.social-media-2 li:not(:last-child) {
  margin-bottom: 5px; }
.social-media-2 a {
  border-radius: 2px;
  display: block;
  line-height: 45px; }
  .social-media-2 a:hover, .social-media-2 a:focus {
    color: #fff; }
.social-media-2 .icon {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  float: left;
  font-size: 20px;
  width: 60px;
  text-align: center; }
.social-media-2 .user-count {
  float: left;
  padding-left: 25px; }
.social-media-2 .like-btn {
  color: rgba(255, 255, 255, 0.32);
  font-size: 18px;
  float: right;
  margin-right: 25px; }

.twitter-feed-widget li {
  border-bottom: 1px solid #fff;
  margin: 0 -30px 24px;
  padding: 0 30px 23px; }
  .twitter-feed-widget li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0; }
  .twitter-feed-widget li p {
    margin-bottom: 12px; }

.widget-tag-cloud a {
  display: inline-block;
  padding: 6px 10px; }

.instagram-widget {
  margin: -5px; }
  .instagram-widget li {
    display: inline-block;
    width: 33.3333%; }
    .instagram-widget li a {
      display: block;
      margin: 5px; }
    .instagram-widget li img {
      width: 100%; }

.dribble-shots {
  margin: -5px; }
  .dribble-shots li {
    display: inline-block;
    width: 33.3333%; }
    .dribble-shots li a {
      display: block;
      margin: 5px; }
    .dribble-shots li img {
      width: 100%; }

.twitter-feed-footer li {
  margin-bottom: 27px; }
  .twitter-feed-footer li:last-child {
    margin-bottom: 0; }
  .twitter-feed-footer li p {
    margin-bottom: 15px; }

.widget-post-slider .owl-dots {
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 43%; }

.sitemap-widget > li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #444;
  display: block;
  margin-bottom: 15px;
  padding-bottom: 10px; }
.sitemap-widget .active > a {
  border-bottom-color: #444; }
.sitemap-widget li li a {
  color: #848484;
  display: block;
  line-height: 26px; }
  .sitemap-widget li li a:hover {
    color: #0D52FF; }

/*==============================================
    Misc
===============================================*/
.site-content {
  clear: both; }

.section-gray .mac {
  position: relative;
  top: -70px; }

.imac-lg-left {
  margin: -140px 0 0 -130px; }

.text-block-1 h2 {
  font-size: 36px; }

.text-block-3 h2 {
  font-size: 30px; }

.text-block-4 h2 {
  font-size: 30px; }

.sep-1 {
  background-color: #444;
  display: block;
  width: 80px;
  height: 3px; }

.misc-slider-3 {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 150px 140px 140px 80px;
  margin-bottom: 100px; }
  .misc-slider-3 .bottom-badge {
    border-color: transparent rgba(255, 255, 255, 0.85);
    border-style: solid;
    border-width: 0 370px 99px;
    bottom: 0;
    left: 0;
    position: absolute;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%); }
  .misc-slider-3 .item h2 {
    font-size: 36px;
    line-height: 48px; }
  .misc-slider-3 .item b {
    display: block; }
  .misc-slider-3 .owl-dots {
    bottom: -90px;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    z-index: 10; }

/*----------------------------------------
    card panel
------------------------------------------*/

  .card-panel h4 {
    margin-bottom: 14px; }
  .card-panel.style2 {
    padding: 48px 80px 57px; }
    .card-panel.style2 p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 0; }
    .card-panel.style2 a {
      color: #444;
      display: inline-block;
      text-transform: uppercase;
      margin-top: 31px; }
      .card-panel.style2 a:hover {
        color: #0D52FF; }
  .card-panel:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }

.img-text-section .bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: -webkit-calc(50% - 15px);
  width: -moz-calc(50% - 15px);
  width: calc(50% - 15px); }
  .img-text-section .bg-img.left {
    left: 0;
    right: auto; }

.hover-ripple {
  background-color: transparent;
  border-top-right-radius: 100px;
  bottom: 0;
  height: 100px;
  left: 0;
  position: absolute;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left bottom 0;
  -moz-transform-origin: left bottom 0;
  -ms-transform-origin: left bottom 0;
  -o-transform-origin: left bottom 0;
  transform-origin: left bottom 0;
  -webkit-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -moz-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -o-transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  width: 100px; }
  .hover-ripple.right {
    border-top-left-radius: 100px;
    border-top-right-radius: 0;
    left: auto;
    right: 0;
    -webkit-transform-origin: right bottom 0;
    -moz-transform-origin: right bottom 0;
    -ms-transform-origin: right bottom 0;
    -o-transform-origin: right bottom 0;
    transform-origin: right bottom 0; }

.iphone-8 {
  left: 50%;
  position: absolute;
  top: -webkit-calc(50% + 12px);
  top: -moz-calc(50% + 12px);
  top: calc(50% + 12px);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.sp-ability {
  left: 0;
  position: absolute;
  right: 0;
  top: 70px; }

.iphone-9 {
  margin-top: -130px; }

.sp-carousel-2 {
  overflow: hidden;
  padding: 0 195px; }
  .sp-carousel-2 .service {
    min-height: 410px; }
    .sp-carousel-2 .service .icon {
      font-size: 48px;
      margin-bottom: 47px; }
    .sp-carousel-2 .service h3 {
      font-size: 24px; }
  .sp-carousel-2 .absolute-center {
    left: 0;
    right: 0;
    white-space: normal;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .sp-carousel-2 + .wireframe {
    left: 15px;
    position: absolute;
    right: 15px;
    top: 0; }
  .sp-carousel-2 .owl-prev,
  .sp-carousel-2 .owl-next {
    color: #fff;
    font-size: 60px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .sp-carousel-2 .owl-prev {
    left: 134px; }
  .sp-carousel-2 .owl-next {
    right: 134px; }
  .sp-carousel-2:hover .owl-prev, .sp-carousel-2:hover .owl-next {
    opacity: 0.2; }
  .sp-carousel-2:hover .owl-prev:hover,
  .sp-carousel-2:hover .owl-next:hover {
    opacity: 1; }

/*==============================================
    Page
===============================================*/
.page-header {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 0 none;
  margin: 0;
  padding: 200px 0 100px; }
  .page-header.style-2 {
    padding: 125px 0 140px; }
  .page-header.primary-bg {
    padding: 104px 0 114px; }
  .page-header.bg-scroll {
    background-attachment: scroll; }
  .page-header .page-title {
    position: relative; }
    .page-header .page-title p {
      color: rgba(255, 255, 255, 0.87);
      font-size: 16px;
      margin-top: 17px; }
  .page-header.google-map {
    padding: 200px 0; }
    .page-header.google-map .overlay {
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
    .page-header.google-map:hover .overlay,
    .page-header.google-map:hover .page-title,
    .page-header.google-map:hover .page-breadcrumb {
      opacity: 0;
      visibility: hidden; }
  .page-header #map-half {
    width: 100%; }

.page-breadcrumb li {
  display: inline-block; }
  .page-breadcrumb li::after {
    color: #fff;
    content: "/";
    display: inline-block;
    padding: 0 5px; }
  .page-breadcrumb li:last-child::after {
    content: none; }
  .page-breadcrumb li a {
    letter-spacing: 2.1px; }
.page-breadcrumb.text li::after {
  color: #848484; }

/*==============================================
    BreadCrumb
===============================================*/
.breadcrumb-wrapper {
  padding: 80px 0; }

.mat-breadcrumb li {
  display: inline-block;
  padding-right: 50px;
  position: relative; }
  .mat-breadcrumb li::after {
    content: "\279D";
    font-size: 28px;
    position: absolute;
    right: 15px;
    top: 2px; }
  .mat-breadcrumb li a {
    color: #848484;
    display: block; }
    .mat-breadcrumb li a:hover {
      color: #0D52FF; }
.mat-breadcrumb .active {
  font-weight: 700;
  padding-right: 0; }
  .mat-breadcrumb .active:after {
    content: none; }

/* --process page-- */
.process-section {
  position: relative; }
  .process-section::before {
    border-right: 1px dotted rgba(0, 0, 0, 0.1);
    content: "";
    height: 100%;
    left: 50%;
    margin-left: -0.5px;
    position: absolute;
    top: 0; }

.process-section {
  padding-top: 300px; }

.triangle-shape {
  left: 50%;
  position: absolute;
  top: -30px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }
  .triangle-shape::before, .triangle-shape::after {
    border-color: transparent transparent #f3f3f3;
    border-style: solid;
    border-width: 30px;
    content: "";
    left: -29px;
    position: absolute;
    top: -30px; }
  .triangle-shape::after {
    left: auto;
    right: -29px; }
  .triangle-shape .shape-1 {
    display: block;
    overflow: hidden;
    padding: 10px; }
  .triangle-shape .shape-2 {
    background-color: #fff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    display: block;
    height: 400px;
    width: 400px;
    -webkit-transform: rotate(45deg) translate(-50%, -50%);
    -moz-transform: rotate(45deg) translate(-50%, -50%);
    -ms-transform: rotate(45deg) translate(-50%, -50%);
    -o-transform: rotate(45deg) translate(-50%, -50%);
    transform: rotate(45deg) translate(-50%, -50%); }
  .triangle-shape .triangle-title {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 47px;
    z-index: 1;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }

.half-bg .half-bg-container {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 1; }
  .half-bg .half-bg-container.white-bg {
    background-color: #fff; }
  .half-bg .half-bg-container.right-bg {
    left: auto;
    right: 0; }

.overlap-top-50 {
  margin-top: -50px; }

/*==============================================
    Page submenu
===============================================*/
.page-submenu > li > a {
  color: #444;
  display: block;
  height: 80px;
  line-height: 80px;
  padding-left: 22px;
  padding-right: 22px; }

/*==============================================
    Page Login
===============================================*/
.page-login-wrapper .matex-login {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%; }
.page-login-wrapper.style-3 {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 120px 0 100px; }

.hr-white {
  background-color: #fff;
  display: block;
  height: 1px; }

/*==============================================
    Animation block
===============================================*/
.animation-block .icon {
  font-size: 48px; }

/*==============================================
    Page Search
===============================================*/
.search-result-box {
  padding: 43px 60px 46px;
  margin-bottom: 30px; }
  .search-result-box:last-child {
    margin-bottom: 0; }

/*==============================================
    404 - 1
===============================================*/
.section-404-1 .newsletter-3 {
  margin-left: auto;
  margin-right: auto;
  max-width: 530px; }
  .section-404-1 .newsletter-3 .nl-input {
    height: 58px; }

/*==============================================
    Page Maintanance
===============================================*/
.maintanance-page h1 {
  font-size: 72px;
  line-height: 1; }


/*==============================================
    Page Login
===============================================*/
/*==============================================
    Social Links
===============================================*/
.social-links li {
  display: inline-block;
  vertical-align: middle; }
  .social-links li a {
    display: block; }

.social-1 li a {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #fff;
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px; }
  .social-1 li a:hover {
    background-color: rgba(0, 0, 0, 0.54); }

.social-2 li a {
  width: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  height: 40px; }
  .social-2 li a:hover {
    color: #fff; }

/*==============================================
    Datetime picker
===============================================*/
.bootstrap-datetimepicker-widget.dropdown-menu {
  border: 0 none;
  border-radius: 0;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  color: #444;
  font-family: "Poppins", sans-serif;
  margin: 0;
  min-width: 370px;
  padding: 35px; }
.bootstrap-datetimepicker-widget.dropdown-menu.top::before {
  border-top: 7px solid rgba(0, 0, 0, 0.06); }
.bootstrap-datetimepicker-widget thead tr .prev,
.bootstrap-datetimepicker-widget thead tr .picker-switch,
.bootstrap-datetimepicker-widget thead tr .next {
  font-weight: 300;
  height: auto;
  line-height: inherit;
  padding: 0 0 20px; }
  .bootstrap-datetimepicker-widget thead tr .prev:hover,
  .bootstrap-datetimepicker-widget thead tr .picker-switch:hover,
  .bootstrap-datetimepicker-widget thead tr .next:hover {
    background-color: transparent !important; }
.bootstrap-datetimepicker-widget thead tr .picker-switch {
  font-size: 18px; }
.bootstrap-datetimepicker-widget thead .dow {
  background-color: #f3f3f3;
  border-left: 2px solid #fff;
  font-weight: 300;
  margin-right: 2px;
  min-width: 38px;
  padding: 0;
  width: 38px; }
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #0D52FF;
  text-shadow: none; }
.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: #0D52FF; }
.bootstrap-datetimepicker-widget table td span {
  border-radius: 0;
  height: 45px;
  line-height: 43px;
  margin: 1px;
  width: 45px; }
  .bootstrap-datetimepicker-widget table td span:hover {
    background-color: transparent; }
  .bootstrap-datetimepicker-widget table td span.active {
    background-color: #0D52FF;
    text-shadow: none; }
.bootstrap-datetimepicker-widget table td, .bootstrap-datetimepicker-widget table th, .bootstrap-datetimepicker-widget table td.day {
  border-radius: 0;
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  padding: 0;
  width: 40px; }
.bootstrap-datetimepicker-widget .timepicker-picker a {
  background-color: #f7f7f7;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  color: #444;
  font-size: 18px;
  padding: 0; }
  .bootstrap-datetimepicker-widget .timepicker-picker a:hover {
    background-color: #0D52FF;
    color: #fff; }
  .bootstrap-datetimepicker-widget .timepicker-picker a span {
    height: auto;
    line-height: inherit;
    margin: 0; }
.bootstrap-datetimepicker-widget .timepicker-picker [data-action="togglePeriod"] {
  background-color: #f7f7f7;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  color: #444;
  font-size: 12px;
  height: 36px;
  padding: 0;
  width: 36px; }
  .bootstrap-datetimepicker-widget .timepicker-picker [data-action="togglePeriod"]:hover {
    background-color: #0D52FF;
    color: #fff; }
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  font-weight: 400; }


/*==============================================
    List Style
===============================================*/
.list li::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  margin-right: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.list-bullhorn li::before {
  content: "\f0a1"; }

.list-long-arrow li::before {
  content: "\f178"; }

.list-gavel li::before {
  content: "\f0e3"; }

.list-graduation li::before {
  content: "\f19d"; }

.list-heart li::before {
  content: "\f004"; }

.list-play li::before {
  content: "\f04b"; }

.list-leaf li::before {
  content: "\f06c"; }

.list-photo li::before {
  content: "\f03e"; }

.list-plane li::before {
  content: "\f072"; }

.list-taxi li::before {
  content: "\f1ba"; }

.list-trophy li::before {
  content: "\f091"; }

.list-wrench li::before {
  content: "\f0ad"; }

.list-hourglass li::before {
  content: "\f251"; }

.list-bed li::before {
  content: "\f236"; }

.list-life-buoy li::before {
  content: "\f1cd"; }

.list-folder li::before {
  content: "\f07b"; }

.list-caret > li {
  position: relative; }
  .list-caret > li::before {
    content: "\f0da";
    display: inline-block;
    font: normal normal normal 16px/1 FontAwesome;
    font-size: inherit;
    margin-right: 15px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .list-caret > li.active {
    color: #444; }
    .list-caret > li.active::before {
      content: "\f0d7"; }

.list-group-item {
  background-color: #f7f7f7;
  border: 0 none;
  padding: 10px 30px; }
  .list-group-item:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .list-group-item:nth-child(2n) {
    background-color: #fafafa; }
  .list-group-item:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }
  .list-group-item .badge {
    background-color: #717171;
    border-radius: 30px;
    color: #fff;
    float: right;
    font-weight: 400;
    height: 30px;
    line-height: 31px;
    padding: 0 15px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .list-group-item:hover .badge {
    background-color: #0D52FF; }
  .list-group-item.active, .list-group-item .active:hover, .list-group-item .active:focus {
    background-color: #0D52FF;
    border-color: #0D52FF;
    color: #fff;
    z-index: 2; }
  .list-group-item.list-group-item-success {
    background-color: #dff0d8;
    color: #3c763d; }
  .list-group-item.list-group-item-info {
    background-color: #d9edf7;
    color: #31708f; }
  .list-group-item.list-group-item-warning {
    background-color: #fcf8e3;
    color: #8a6d3b; }
  .list-group-item.list-group-item-danger {
    background-color: #f2dede;
    color: #a94442; }

/*==============================================
    Matex Label
===============================================*/
.mtx-label {
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0 30px; }
  .mtx-label.round {
    border-radius: 30px; }
  .mtx-label.default {
    background-color: #f7f7f7;
    color: #848484; }
  .mtx-label.primary {
    background-color: #0D52FF;
    color: #fff; }
  .mtx-label.success {
    background-color: #6ab55b;
    color: #fff; }
  .mtx-label.info {
    background-color: #6fc0df;
    color: #fff; }
  .mtx-label.warning {
    background-color: #e8ac47;
    color: #fff; }
  .mtx-label.danger {
    background-color: #cf574b;
    color: #fff; }
  .mtx-label.absolute-right {
    position: absolute;
    right: 30px;
    top: 30px; }
  .mtx-label .text {
    position: absolute;
    right: 20px;
    top: 27px; }
  .mtx-label.style-2, .mtx-label.style-3 {
    background-color: transparent;
    height: 150px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 150px; }
    .mtx-label.style-2 .shape-1, .mtx-label.style-3 .shape-1 {
      border-color: transparent;
      border-style: solid;
      border-width: 75px;
      position: absolute;
      right: 0;
      top: 0; }
    .mtx-label.style-2.primary .shape-1, .mtx-label.style-3.primary .shape-1 {
      border-color: #0D52FF #0D52FF transparent transparent; }
  .mtx-label.style-3 .shape-1 {
    right: -10px;
    top: -10px; }
  .mtx-label.style-3 .shape-2 {
    border-color: transparent;
    border-style: solid;
    border-width: 10px;
    left: 0;
    position: absolute;
    top: -20px; }
  .mtx-label.style-3 .shape-3 {
    border-color: transparent;
    border-style: solid;
    border-width: 10px;
    bottom: 0;
    position: absolute;
    right: -20px; }
  .mtx-label.style-3 .text {
    top: 20px;
    right: 15px; }
  .mtx-label.style-3.primary .shape-2 {
    border-color: transparent transparent #af2b27; }
  .mtx-label.style-3.primary .shape-3 {
    border-color: transparent transparent transparent #af2b27; }

/*==============================================
    Editable Field
===============================================*/
.editable-field {
  border-radius: 2px;
  padding: 10px 30px 12px; }
  .editable-field .popover {
    border-radius: 2px; }
  .editable-field .popover-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px 2px 0 0; }
  .editable-field .select2-input {
    height: auto; }

.selectize-dropdown,
.selectize-input,
.selectize-input input {
  color: #848484; }

.editableform .form-control {
  border-bottom-color: rgba(0, 0, 0, 0.1);
  height: 35px;
  margin-bottom: 0; }
  .editableform .form-control:focus {
    box-shadow: none; }
.editableform .btn {
  height: auto;
  line-height: 1.5;
  padding: 5px 10px;
  width: auto; }
  .editableform .btn i {
    font-size: 12px; }

.editable-checklist input {
  height: auto;
  width: auto; }
.editable-checklist label {
  font-size: 14px;
  font-weight: 400; }

.editable-click,
a.editable-click,
a.editable-click:hover {
  border-bottom-color: #0D52FF; }

/*==============================================
    File Upload
===============================================*/
.upload-field .form-control {
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px 0 0 2px;
  box-shadow: none;
  height: 40px;
  line-height: 40px;
  padding-bottom: 0;
  padding-top: 0; }
.upload-field .file-caption-name {
  height: 40px; }
.upload-field .btn {
  height: 40px; }
  .upload-field .btn i {
    font-size: inherit; }

/*==============================================
    Default Panels
===============================================*/
.panel {
  border: 0 none;
  border-radius: 2px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .panel:last-child {
    margin-bottom: 0; }
  .panel > .panel-heading {
    background-color: #f7f7f7;
    border-bottom: 0; }

.panel-heading {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 10px 30px; }

.panel-body {
  padding: 23px 30px 26px; }

.panel-primary > .panel-heading {
  background-color: #0D52FF;
  color: #fff; }

.panel-success > .panel-heading {
  background-color: #dff0d8;
  color: #3c763d; }

.panel-info > .panel-heading {
  background-color: #d9edf7;
  color: #31708f; }

.panel-warning > .panel-heading {
  background-color: #fcf8e3;
  color: #8a6d3b; }

.panel-danger > .panel-heading {
  background-color: #f2dede;
  color: #a94442; }

/*==============================================
    Modal
===============================================*/
.modal .modal-heading {
  padding-bottom: 31px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
.modal .modal-content {
  padding: 40px 75px; }
.modal .modal-footer {
  background-color: transparent;
  padding: 0; }

.popover {
  border: 0;
  border-radius: 2px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .popover.top > .arrow {
    border-bottom-width: 0;
    border-top-color: rgba(0, 0, 0, 0.08); }
  .popover.right > .arrow {
    border-right-color: rgba(0, 0, 0, 0.08); }
  .popover.bottom > .arrow {
    border-bottom-color: rgba(0, 0, 0, 0.08); }
  .popover.left > .arrow {
    border-left-color: rgba(0, 0, 0, 0.08); }

.popover-content {
  padding: 20px 30px 23px; }

/*==============================================
    Pagination
===============================================*/
.pagination-1 a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 0;
  color: #848484;
  display: block;
  font-size: 12px;
  height: 30px;
  line-height: 29px;
  padding: 0 15px; }
  .pagination-1 a:hover {
    color: #444; }
  .pagination-1 a.active {
    background-color: #0D52FF;
    border-color: #0D52FF;
    color: #fff; }
.pagination-1 li:first-child a {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px; }
.pagination-1 li:last-child a {
  border-bottom-right-radius: 2px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-top-right-radius: 2px; }

.pagination-2 a {
  background-color: #f7f7f7;
  color: #848484;
  display: block;
  font-size: 12px;
  height: 30px;
  line-height: 29px;
  padding: 0 15px;
  margin-right: 1px; }
  .pagination-2 a:hover {
    color: #444; }
  .pagination-2 a.active {
    background-color: #0D52FF;
    border-color: #0D52FF;
    color: #fff; }
.pagination-2 li:first-child a {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px; }
.pagination-2 li:last-child a {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  margin-right: 0; }

.pagination-3 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .pagination-3 li a {
    display: block;
    color: #848484;
    padding-left: 15px;
    padding-right: 15px;
    height: 37px;
    line-height: 37px;
    position: relative; }
    .pagination-3 li a::before {
      background-color: #000;
      bottom: -1px;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      width: 0;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
  .pagination-3 .active a:before {
    width: 100%; }

.pagination-5 li {
  display: inline-block; }
  .pagination-5 li a {
    border: 1px solid transparent;
    color: #848484;
    display: block;
    font-size: 12px;
    padding: 3px 15px; }
.pagination-5 .active a {
  color: #444;
  border-color: #444; }
.pagination-5.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.pagination-sm > li > a,
.pagination-sm > li > span,
.pagination-lg > li > a,
.pagination-lg > li > span {
  height: auto; }

.pagination-lg > li > a,
.pagination-lg > li > span {
  font-size: 16px; }

.chart-item {
  position: relative; }
  .chart-item .percent {
    background-color: #f7f7f7;
    border-radius: 50%;
    font-size: 24px;
    height: 80px;
    left: 50%;
    line-height: 80px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 80px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .chart-item .percent::after {
      content: "%"; }
  .chart-item canvas {
    display: block;
    margin: 0 auto; }


/*==============================================
    Star Ratings
===============================================*/
.rating[type="number"] {
  -moz-appearance: textfield; }
  .rating[type="number"]::-webkit-outer-spin-button, .rating[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.rating-container .label {
  font-weight: 400;
  padding: 0.32em 0.7em 0.24em; }
.rating-container .label-success {
  background-color: #0D52FF; }
.rating-container .filled-stars {
  color: #0D52FF; }


/*==============================================
    Thumbnails
===============================================*/
.mt-thumbnail {
  position: relative;
  outline: 10px solid transparent;
  outline-offset: -10px; }
  .mt-thumbnail:hover {
    outline-color: #fff; }

.thumbnail-gallery-nav {
  margin-left: -6px;
  margin-right: -6px; }
  .thumbnail-gallery-nav .item {
    opacity: 0.5;
    margin-right: 6px;
    margin-left: 6px; }
  .thumbnail-gallery-nav .slick-center {
    opacity: 1; }

span.twitter-typeahead {
  display: block !important; }

.tt-menu {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  margin: 4px 0;
  padding: 8px 0;
  width: 100%; }

.tt-suggestion {
  font-size: 15px;
  line-height: 24px;
  padding: 3px 20px; }
  .tt-suggestion.tt-cursor {
    background-color: #0D52FF;
    color: #fff; }
  .tt-suggestion:hover {
    background-color: #0D52FF;
    color: #fff;
    cursor: pointer; }

/*==============================================
    Switch & radio
===============================================*/
.switch-group .bootstrap-switch + .bootstrap-switch {
  margin-left: 20px; }

.bootstrap-switch {
  border: 0 none;
  font-family: "Poppins", sans-serif;
  font-weight: 300; }
  .bootstrap-switch .bootstrap-switch-handle-on,
  .bootstrap-switch .bootstrap-switch-handle-off,
  .bootstrap-switch .bootstrap-switch-label {
    font-size: 14px;
    height: 50px;
    line-height: 0;
    padding: 0 28px;
    width: auto !important;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s; }
    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
    .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
    .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
    .bootstrap-switch .bootstrap-switch-label.bootstrap-switch-primary,
    .bootstrap-switch .bootstrap-switch-label.bootstrap-switch-primary {
      background-color: #0D52FF; }
  .bootstrap-switch .bootstrap-switch-label {
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .bootstrap-switch.bootstrap-switch-on.style-2 .bootstrap-switch-label {
    border-bottom-right-radius: 80px;
    border-top-right-radius: 80px; }
  .bootstrap-switch.bootstrap-switch-off.style-2 .bootstrap-switch-label {
    border-bottom-left-radius: 80px;
    border-top-left-radius: 80px; }
  .bootstrap-switch.bootstrap-switch-off.style-2 .bootstrap-switch-handle-off {
    border-bottom-right-radius: 80px;
    border-top-right-radius: 80px; }
  .bootstrap-switch.style-2 .bootstrap-switch-handle-on {
    border-bottom-left-radius: 80px;
    border-top-left-radius: 80px; }

.custom-radio {
  position: relative; }
  .custom-radio input {
    height: auto;
    opacity: 0;
    width: auto; }
    .custom-radio input:checked + label::after {
      background-color: #000;
      border-color: rgba(0, 0, 0, 0.38); }
  .custom-radio label {
    cursor: pointer;
    margin: 0 0 0 13px;
    position: relative; }
    .custom-radio label::after, .custom-radio label::before {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      content: "";
      height: 15px;
      left: -29px;
      position: absolute;
      top: 50%;
      width: 15px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
  .custom-radio.right input {
    float: right; }
  .custom-radio.right label::before,
  .custom-radio.right label::after {
    left: auto;
    right: -32px; }
  .custom-radio.right label::after {
    right: -30.5px; }
  .custom-radio.light label::after,
  .custom-radio.light label::before {
    border: 1px solid #fff; }
  .custom-radio.light input:checked + label::after {
    background-color: #fff;
    border-color: rgba(255, 255, 255, 0.38); }

/*==============================================
    Table 1
===============================================*/
.table-1 thead {
  border-bottom: 0; }
.table-1 tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

/*==============================================
    Switch & radio
===============================================*/
/*----------------------------------------
    Collapsible
------------------------------------------*/
.collapsible.style-1 {
  border: 0 none;
  box-shadow: none;
  margin: 0; }
  .collapsible.style-1 li {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 15px; }
    .collapsible.style-1 li:last-child {
      margin-bottom: 0; }
  .collapsible.style-1 .collapsible-header {
    color: #444;
    font-family: "Poppins", sans-serif;
    border: 0;
    line-height: 30px;
    font-size: 16px;
    font-weight: 300;
    padding: 12px 18px 10px; }
    .collapsible.style-1 .collapsible-header.active {
      background-color: #0D52FF;
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
      color: #fff; }
    .collapsible.style-1 .collapsible-header i {
      font-size: 20px;
      line-height: 30px;
      margin-right: 24px; }
  .collapsible.style-1 .collapsible-body {
    border: 0 none;
    padding: 25px; }
    .collapsible.style-1 .collapsible-body p {
      padding: 0; }
    .collapsible.style-1 .collapsible-body img {
      background-color: #fff;
      display: inline-block;
      border-radius: 2px;
      float: left;
      margin-right: 15px;
      padding: 2px; }
.collapsible.style2 li {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 15px; }
  .collapsible.style2 li:last-child {
    margin-bottom: 0; }
.collapsible.style2 .collapsible-header {
  position: relative;
  background-color: #e6e9ed;
  color: #444;
  font-family: "Poppins", sans-serif;
  border: 0;
  line-height: 30px;
  font-size: 16px;
  font-weight: 300;
  padding: 12px 18px 10px; }
  .collapsible.style2 .collapsible-header.active {
    background-color: #0D52FF;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    color: #fff; }
    .collapsible.style2 .collapsible-header.active i {
      background-color: #0D52FF;
      color: #fff; }
    .collapsible.style2 .collapsible-header.active .open {
      display: none; }
    .collapsible.style2 .collapsible-header.active .close {
      display: block; }
  .collapsible.style2 .collapsible-header i {
    background-color: rgba(246, 247, 249, 0.5);
    border-top-right-radius: 2px;
    height: 52px;
    line-height: 52px;
    position: absolute;
    right: -24px;
    top: 0;
    width: 52px;
    opacity: 1;
    font-size: 20px;
    margin-right: 24px; }
  .collapsible.style2 .collapsible-header .close {
    display: none; }
.collapsible.style2 .collapsible-body {
  border: 0 none;
  padding: 25px; }
  .collapsible.style2 .collapsible-body p {
    padding: 0; }
  .collapsible.style2 .collapsible-body img {
    background-color: #fff;
    display: inline-block;
    border-radius: 2px;
    float: left;
    margin-right: 15px;
    padding: 2px; }
.collapsible.style-3 {
  box-shadow: none;
  border: 0;
  margin: 0; }
  .collapsible.style-3 .collapsible-header {
    padding-left: 0;
    padding-right: 0; }
  .collapsible.style-3 .active .collapsible-header {
    border-bottom-color: transparent;
    color: #444; }
  .collapsible.style-3 .active .collapsible-body {
    border-bottom-color: #444;
    padding: 5px 30px 22px 30px; }
.collapsible.style-4 {
  box-shadow: none;
  border: 0;
  margin: 0; }
  .collapsible.style-4 li + li {
    margin-top: 2px; }
  .collapsible.style-4 .collapsible-header {
    background-color: #f7f7f7;
    border-bottom: 0;
    line-height: inherit;
    padding: 9px 30px; }
  .collapsible.style-4 .collapsible-body {
    border-bottom: 0; }
  .collapsible.style-4 .active .collapsible-header {
    background-color: #fafafa;
    color: #444; }
  .collapsible.style-4 .active .collapsible-body {
    background-color: #fafafa;
    padding: 5px 30px 22px 30px; }

/*----------------------------------------
    accordion
------------------------------------------*/
.accordion-item.style-1 + .accordion-item {
  margin-top: 25px; }
.accordion-item.style-1 .accordion-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 25px; }
.accordion-item.style-1 .accordion-body {
  padding: 35px 30px 0; }

.mt-ac-1 {
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  padding: 0; }
  .mt-ac-1 .panel-heading {
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0; }
    .mt-ac-1 .panel-heading a {
      display: block;
      padding: 8px 30px 11px; }
    .mt-ac-1 .panel-heading.active {
      border-bottom-color: #000; }
      .mt-ac-1 .panel-heading.active a {
        color: #000; }
  .mt-ac-1 .panel-body {
    padding: 23px 0; }

.mt-ac-2 {
  box-shadow: none;
  margin: 0 0 1px;
  padding: 0; }
  .mt-ac-2 .panel-heading {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0; }
    .mt-ac-2 .panel-heading a {
      display: block;
      padding: 8px 30px 11px; }
    .mt-ac-2 .panel-heading.active {
      border-color: #000; }
      .mt-ac-2 .panel-heading.active a {
        color: #000; }

.mt-ac-3 {
  margin: 0 0 1px;
  padding: 0; }
  .mt-ac-3 .panel-heading {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 30px 11px;
    padding: 0; }
    .mt-ac-3 .panel-heading a {
      display: block;
      padding: 8px 30px 11px; }
    .mt-ac-3 .panel-heading.active {
      background-color: #0D52FF;
      border-color: #0D52FF; }
      .mt-ac-3 .panel-heading.active a {
        color: #fff; }

.mt-ac-4 {
  border: 1px solid transparent;
  box-shadow: none;
  margin: 0 0 1px;
  padding: 0; }
  .mt-ac-4 .panel-heading {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s; }
    .mt-ac-4 .panel-heading a {
      display: block;
      padding: 8px 30px 11px; }
  .mt-ac-4.active {
    border-color: #000; }
    .mt-ac-4.active .panel-heading {
      border-color: transparent transparent #000; }
      .mt-ac-4.active .panel-heading a {
        color: #000; }

.mt-ac-5 {
  border: 1px solid transparent;
  box-shadow: none;
  margin: 0 0 1px;
  padding: 0; }
  .mt-ac-5 .panel-heading {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s; }
    .mt-ac-5 .panel-heading a {
      display: block;
      padding: 8px 30px 11px; }
  .mt-ac-5.active {
    border-color: transparent;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .mt-ac-5.active .panel-heading {
      border-color: transparent transparent rgba(0, 0, 0, 0.1); }
      .mt-ac-5.active .panel-heading a {
        color: #000; }

.mt-ac-6 {
  border: 1px solid transparent;
  box-shadow: none;
  margin: 0 0 1px;
  padding: 0; }
  .mt-ac-6 .panel-heading {
    background-color: #f7f7f7;
    border: 0;
    border-radius: 2px;
    padding: 0;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s; }
    .mt-ac-6 .panel-heading a {
      display: block;
      padding: 8px 30px 11px; }
  .mt-ac-6 .panel-body {
    border-radius: 2px; }
  .mt-ac-6.active {
    border-color: transparent;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .mt-ac-6.active .panel-heading {
      background-color: #0D52FF; }
      .mt-ac-6.active .panel-heading a {
        color: #fff; }
    .mt-ac-6.active .panel-body {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-top: 0; }

/*==============================================
    Footer Widgets
===============================================*/
.widget-toggle-btn {
  left: 50%;
  padding: 0 !important;
  position: absolute;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  .widget-toggle-btn.show-widget {
    display: none; }
  .widget-toggle-btn .fa-angle-up {
    display: none; }
  .widget-toggle-btn.active .fa-angle-down {
    display: none; }
  .widget-toggle-btn.active .fa-angle-up {
    display: inline-block; }

.footer-widgets.type-1 {
  background-color: #444; }
.footer-widgets.bg-color-2 {
  background-color: #21282f; }

.footer-widget-3 .social {
  float: left;
  margin-right: 50px;
  width: 85px; }
  .footer-widget-3 .social li {
    display: inline-block; }
    .footer-widget-3 .social li a {
      background-color: rgba(0, 0, 0, 0.15);
      color: #fff;
      display: block;
      height: 40px;
      line-height: 40px;
      margin-bottom: 3px;
      text-align: center;
      width: 40px; }
      .footer-widget-3 .social li a:hover {
        background-color: #444; }
.footer-widget-3 .about {
  float: left;
  margin-top: -6px;
  width: -webkit-calc(100% - 135px);
  width: -moz-calc(100% - 135px);
  width: calc(100% - 135px); }

.footer-nav-3 > li {
  display: inline-block; }
  .footer-nav-3 > li > a {
    color: #848484;
    display: block;
    font-size: 13px;
    font-weight: 400;
    padding: 0 18px; }
  .footer-nav-3 > li:first-child > a {
    padding-left: 0; }
  .footer-nav-3 > li:last-child > a {
    padding-right: 0; }

.social-profiles li {
  display: inline-block; }
  .social-profiles li a {
    display: block;
    margin: 0 5px; }
  .social-profiles li .twitter {
    background-color: #55acee; }
  .social-profiles li .facebook {
    background-color: #42599e; }
  .social-profiles li .dribbble {
    background-color: #e40079; }
  .social-profiles li .pinterest {
    background-color: #fc3030; }
  .social-profiles li .behance {
    background-color: #1769ff; }
  .social-profiles li .gplus {
    background-color: #db4437; }

.footer {
  background-color: #101010;
  padding: 30px 0 25px; }
  .footer.bg-color-2 {
    background-color: #21282f;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }

.primary-footer.dark {
  background-color: #171b22; }

.secondery-footer.dark {
  background-color: #13171c; }
.secondery-footer .inner-footer {
  padding: 30px 0; }
.secondery-footer .border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }
.secondery-footer p {
  margin-top: 9px; }

.footer-light {
  background-color: #fff;
  padding: 52px 0 53px; }
  .footer-light .copyright {
    color: #848484;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase; }

.copyright {
  color: rgba(255, 255, 255, 0.87); }

.footer-nav ul {
  font-size: 0; }
.footer-nav li {
  display: inline-block; }
  .footer-nav li a {
    color: rgba(255, 255, 255, 0.87);
    font-size: 14px;
    padding: 0 15px; }
    .footer-nav li a:hover {
      color: #0D52FF; }

.footer-nav-2 li {
  display: inline-block;
  margin-left: -4px;
  margin-right: 25px; }
  .footer-nav-2 li:first-child {
    margin-left: 0; }
  .footer-nav-2 li:last-child {
    margin-right: 0; }
  .footer-nav-2 li a {
    color: #fff;
    display: block;
    font-weight: 400;
    text-transform: uppercase; }
    .footer-nav-2 li a:hover {
      color: #0D52FF; }

#go-top {
  bottom: 0;
  color: #fff;
  font-size: 30px;
  height: 82px;
  line-height: 82px;
  position: fixed;
  right: 0;
  text-align: center;
  width: 82px;
  z-index: 50;
  -webkit-transform: translate(100%, 0px);
  -moz-transform: translate(100%, 0px);
  -ms-transform: translate(100%, 0px);
  -o-transform: translate(100%, 0px);
  transform: translate(100%, 0px); }
  #go-top:after {
    border-color: transparent #444 #444 transparent;
    border-style: solid;
    border-width: 41px;
    bottom: 0;
    content: "";
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  #go-top:hover::after {
    border-color: transparent #0D52FF #0D52FF transparent; }
  #go-top.active {
    -webkit-transform: translate(0px);
    -moz-transform: translate(0px);
    -ms-transform: translate(0px);
    -o-transform: translate(0px);
    transform: translate(0px); }
  #go-top i {
    bottom: 14px;
    position: absolute;
    right: 16px; }

/*============================================================
    For 15 inch and larger
==============================================================*/
@media only screen and (min-width: 1601px) {
  .medical-banner-content {
    max-height: 800px !important; }

  .hero-slider-12 .slick-prev,
  .hero-slider-13 .slick-prev {
    left: 100px; }
  .hero-slider-12 .slick-next,
  .hero-slider-13 .slick-next {
    right: 100px; } }
/*============================================================
    For 15 inch and larger
==============================================================*/
@media only screen and (min-width: 1367px) {
  .left-side-nav {
    padding-left: 240px;
    -webkit-transition: padding 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -moz-transition: padding 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -o-transition: padding 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: padding 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }
    .left-side-nav.closed {
      padding-left: 0; }

  .sidenav-overlay {
    display: none; }

  .side-nav-wrap.opened {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px); }
    .side-nav-wrap.opened + .sidenav-overlay {
      opacity: 0;
      visibility: hidden; } }
/*============================================================
    For 15 inch Laptop
==============================================================*/
@media only screen and (min-width: 1367px) and (max-width: 1600px) {
  .subscribe-block-1 .subscribe-inner {
    padding: 30px 140px 30px 0; }
    .subscribe-block-1 .subscribe-inner .subscribe label {
      padding: 0 20px 0 25px; }
    .subscribe-block-1 .subscribe-inner .subscribe input {
      height: 45px; }
    .subscribe-block-1 .subscribe-inner .subscribe .mail-sub {
      height: 41px;
      line-height: 42px;
      padding: 0 25px; }

  .bakery-banner .slide-content h3 {
    font-size: 36px;
    line-height: 45px; }
  .bakery-banner .slide-content img {
    width: 400px;
    height: auto; }

  .hero-slider-7 .slide-content::before,
  .hero-slider-7 .slide-content::after {
    height: 90px;
    width: 180px; }

  .hero-slider-8 .slide-1 img {
    max-width: 20%; } }
/*============================================================
    For 15 inch Laptop
==============================================================*/
@media only screen and (min-width: 1367px) and (max-width: 1410px) {
  .cta.cta-one h1 {
    font-size: 38px; } }
/*============================================================
    Macbook pro 13 inch
==============================================================*/
@media only screen and (min-width: 1151px) and (max-width: 1366px) {
  body {
    overflow-x: hidden; }

  .container {
    width: 1170px; }

  .section-title .shade-text {
    font-size: 180px; }
    .section-title .shade-text + h2 {
      padding-top: 90px; }

  /*==============================================
      Header
  ===============================================*/
  .site-logo {
    line-height: 65px; }
    .site-logo img {
      height: 40px; }

  .nav-hover-1 > li > a {
    line-height: 65px; }

  .header-right .top-cart > a,
  .header-right .top-search > a {
    height: 65px;
    line-height: 65px; }

  .header-action-btns .top-search,
  .header-action-btns .top-cart {
    height: 65px; }

  .site-header.style12 {
    padding-top: 20px; }
    .site-header.style12 .header-top {
      position: relative;
      top: 1.5px; }
    .site-header.style12 .header-bottom .bottom-inner {
      padding-bottom: 10px;
      padding-top: 10px; }
  .site-header.style13 .social-links {
    padding-left: 30px;
    white-space: nowrap; }

  .header-wedding .header-top {
    margin-top: 20px; }

  /*==============================================
      Sliders
  ===============================================*/
  .slider2 .slide-content {
    width: 100%; }
    .slider2 .slide-content h1 {
      font-size: 50px;
      line-height: 65px; }

  .banner3 .slide-content h1 {
    font-size: 80px;
    line-height: 80px; }

  .bakery-banner .slide-content img {
    height: auto;
    width: 30%; }

  .realestate-slide-content {
    padding: 40px 60px 60px; }
    .realestate-slide-content h2 {
      font-size: 38px;
      margin-bottom: 25px; }
    .realestate-slide-content .price {
      font-size: 50px;
      margin-bottom: 30px; }

  .app-content img {
    max-height: 600px; }

  .medical-content h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 25px; }
  .medical-content p {
    font-size: 15px;
    margin-top: 30px; }

  .leaned-layer {
    top: -webkit-calc(50% + 22px);
    top: -moz-calc(50% + 22px);
    top: calc(50% + 22px); }

  .appointment-form {
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75); }

  .mdcl-patient--forum .leaned-layer {
    width: 1339px;
    left: -webkit-calc(50% - 21px);
    left: -moz-calc(50% - 21px);
    left: calc(50% - 21px); }

  .slider-grid-post p {
    margin: 5px 0; }

  .gpc-control .prev-arrow {
    border-width: 30px 30px 30px 17px; }
  .gpc-control .next-arrow {
    border-width: 30px 17px 30px 30px; }

  .wedding-slide-parallax {
    margin-top: 100px; }

  .shop-3-left-wdget ul {
    max-height: 270px;
    overflow: auto; }

  .banner-sports h2 {
    font-size: 80px;
    line-height: 1; }

  .hero-slider-7 .slide-content::before, .hero-slider-7 .slide-content::after {
    height: 80px;
    left: 20px;
    width: 191px; }

  .hero-slider-8 .slide-1 img {
    margin-bottom: 20px;
    max-width: 23%; }

  .hero-slider-12 .slide-content h1 {
    font-size: 42px; }

  .count-wrapper {
    padding: 42px 20px 48px 18px; }

  .shop-3-cat h6 br {
    display: none !important; }

  .shop-3-cat2 {
    padding: 65px 37px; }

  .hero-slider-1 h1 {
    font-size: 50px; }

  .hero-slider-2 .parallax-content h1 {
    font-size: 40px; }

  /*==============================================
      CTA
  ===============================================*/
  .cta h2 {
    font-size: 35px; }

  .cta-event .cta-logo::after {
    right: 32px; }
  .cta-event .cta-logo img {
    width: 90px; }

  /*==============================================
      Gallery
  ===============================================*/
  .cp-gallery figcaption {
    bottom: 20px;
    left: 30px;
    right: 30px; }
    .cp-gallery figcaption h3 {
      font-size: 22px; }

  /*==============================================
      Service
  ===============================================*/
  .service.style7 {
    padding: 50px 40px 53px; }

  /*==============================================
      tab
  ===============================================*/
  .tab-2 .tab-nav li a {
    padding: 0 70px; }

  /*==============================================
      Misc
  ===============================================*/
  .contact-block.style-2 {
    padding: 65px; }

  .subscribe-block-1 .subscribe-inner {
    background-color: #0D52FF;
    color: #fff;
    padding-bottom: 10px;
    padding-top: 10px; }
    .subscribe-block-1 .subscribe-inner h3 {
      font-size: 22px; }
    .subscribe-block-1 .subscribe-inner .subscribe {
      margin-top: 7px; }
      .subscribe-block-1 .subscribe-inner .subscribe input {
        height: 50px; }
      .subscribe-block-1 .subscribe-inner .subscribe .mail-sub {
        height: 46px;
        line-height: 46px; }

  .owl-controls-custom {
    padding: 20px 0; }

  /*==============================================
      Portfolio
  ===============================================*/
  .imac-lg-left {
    margin: 0 0 0 -50px; }
    .imac-lg-left img {
      width: 100%; }

  /*==============================================
      Portfolio
  ===============================================*/
  .masonry-broken > .col-sm-4 {
    width: 33%; }

  .portfolio-carousel .owl-prev, .portfolio-carousel .owl-next {
    left: 5%; }
  .portfolio-carousel .owl-next {
    left: auto;
    right: 5%; }

  .portfolio.type-2 figure {
    min-height: 350px;
    position: relative; }
  .portfolio.type-8 .image-popup {
    top: 25%; }

  .blog-post-2.sticky .post-desc {
    padding: 60px; }
  .blog-post-2.sticky h2 {
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 45px; }

  .blog-load-more-3 {
    line-height: 119px; }

  .footer-nav-3 > li > a {
    padding: 0 12px; }

  /*==============================================
      MISC
  ===============================================*/
  .service.style13 {
    padding-left: 60px;
    padding-right: 60px; }

  .travel-overlap {
    margin-top: -70px; }

  .misc-slider-3 .bottom-badge {
    border-width: 0 326px 75px; }

  .feature-carousel-1 .slick-dots {
    right: -55px; }

  .process-3 .icon {
    font-size: 50px;
    height: 200px;
    line-height: 210px;
    width: 200px; }

  .tab-7 .tab-nav li {
    margin: 0 35px; }

  .the-blog-meh .shade-text {
    font-size: 170px; }
    .the-blog-meh .shade-text.top {
      left: -50px; }
    .the-blog-meh .shade-text.bottom {
      right: -50px; }

  .sp-feature-1 .desc {
    margin: 0 0 0 30px; }

  .sp-ability {
    top: 40px; }

  .sp-carousel-2 {
    padding: 0 90px; }
    .sp-carousel-2 .service {
      min-height: 360px; }

  .tab-8 .tab-nav li a {
    padding: 0 30px 25px; }

  .widget-post-slider .owl-dots {
    top: 38%; }

  .social-media li a {
    padding: 10px 12px 8px; }

  .product-cat-style-3 .mt-80.mb-80 {
    margin: 35px 0 72px; } }
@media only screen and (min-width: 1024px) and (max-width: 1280px) and (max-height: 700px) {
  .section-under-construction .site--logo {
    margin: 30px 0 40px; }
    .section-under-construction .site--logo img {
      max-width: 170px; }
  .section-under-construction h2 {
    font-size: 28px; }
  .section-under-construction figure img {
    max-width: 250px; }

  .section-bubble-left {
    width: 50px; }

  .portfolio-bubble {
    height: 153px;
    width: 50px; }

  .team-member-12 .social-fab {
    bottom: 10px;
    right: 20px; }

  .cta-wave .cta-inner {
    bottom: 20px; }

  .iphone-holder {
    margin-bottom: -145px;
    margin-left: -60px; }

  .s-service-carousel-for {
    margin-top: 30px; } }
/*============================================================
    For Small Desktop / tablet landscape
==============================================================*/
@media (min-width: 980px) and (max-width: 1150px) {
  body {
    overflow-x: hidden; }

  h2, .h2 {
    font-size: 30px; }

  h3, .h3 {
    font-size: 22px; }

  .bottom-margin:not(:last-child) {
    margin-bottom: 30px; }

  .section-title .shade-text + h2 {
    padding-top: 78px; }

  /*==============================================
      header
  ===============================================*/
  .site-logo {
    line-height: 65px; }
    .site-logo img {
      height: 40px; }

  .nav-hover-1 > li > a {
    line-height: 65px; }

  .header-right .top-cart > a,
  .header-right .top-search > a {
    height: 65px;
    line-height: 65px; }

  .header-action-btns .top-search,
  .header-action-btns .top-cart {
    height: 65px; }

  .site-header.style4 .select-box {
    padding-right: 40px; }
  .site-header.style4 .middle-search {
    min-width: 400px; }
  .site-header.style5 .nice-select {
    padding-left: 12px;
    padding-right: 25px; }
  .site-header.style5 .shop-nav li a {
    padding: 0 10px; }
  .site-header.style5 .toggle-my-account {
    padding-left: 10px; }
  .site-header.style5 .social-links, .site-header.style5 .header-cart {
    white-space: nowrap; }
  .site-header.style6 .product-actions li a {
    margin-right: 20px; }
  .site-header.style7 .primary-nav > ul > li > a {
    line-height: 70px; }
  .site-header.style7 .social-links li a {
    padding: 0 12px; }
  .site-header.style11 .header-middle .site-logo {
    padding-right: 100px; }
  .site-header.style12 {
    padding-top: 25px; }
    .site-header.style12 .header-top {
      padding-top: 0;
      margin-bottom: 0; }
    .site-header.style12 .header-bottom {
      margin-top: 0; }
      .site-header.style12 .header-bottom .bottom-inner {
        padding: 0 15px 0 0; }
    .site-header.style12 .primary-nav {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      justify-content: flex-start; }
  .site-header.style13 .header-top {
    margin-bottom: 0; }
    .site-header.style13 .header-top .flex-cell .row {
      margin-left: 0;
      margin-right: 0; }
      .site-header.style13 .header-top .flex-cell .row > [class^="col-"] {
        padding-left: 0;
        padding-right: 0; }
    .site-header.style13 .header-top .social-links {
      white-space: nowrap; }
  .site-header.style13 .header-bottom {
    margin-bottom: 0; }
    .site-header.style13 .header-bottom .bottom-inner {
      padding: 10px 15px; }

  .header-megazine .search-box input {
    width: 240px; }
  .header-megazine.is-sticky .search-box input {
    width: 200px; }

  .header-shop-3 .header-action-btns > li {
    height: auto; }

  .header-seo .primary-nav {
    margin-left: -111px; }
  .header-seo .right-nav > li > a {
    padding: 0 40px; }

  .header-blog-3 .blog-3-intro p {
    letter-spacing: 3px; }

  /*==============================================
      Sliders
  ===============================================*/
  .slider2 .slide-content {
    padding: 0 20px; }
    .slider2 .slide-content h1 {
      font-size: 50px;
      line-height: 70px; }

  .banner3 .slide-content h2 {
    font-size: 40px; }
  .banner3 .slide-content h1 {
    font-size: 60px;
    line-height: 70px; }

  .restaurant-banner .banner-content h2 {
    font-size: 60px;
    margin: 19px 0 24px; }
    .restaurant-banner .banner-content h2 b {
      padding: 11px 10px 15px 23px; }

  .bakery-banner .slide-content img {
    height: auto;
    width: 50%; }

  .creative-3-banner .slide-content img {
    width: 82%; }

  .app-content img {
    max-height: 600px; }
  .app-content p {
    font-size: 16px;
    width: 53%; }

  .leaned-layer {
    top: -webkit-calc(50% + 17px);
    top: -moz-calc(50% + 17px);
    top: calc(50% + 17px);
    width: 780px; }

  .medical-content h2 {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 25px; }
  .medical-content p {
    font-size: 15px;
    margin: 20px 0 10px; }

  .mdcl-patient--forum .leaned-layer {
    width: 1100px;
    left: -webkit-calc(50% - 18px);
    left: -moz-calc(50% - 18px);
    left: calc(50% - 18px); }

  .mdcl-patient-forum {
    padding: 24px 80px 40px; }

  .slider-grid-post .content {
    left: 20px;
    right: 20px; }
  .slider-grid-post .title {
    padding: 10px 0; }
  .slider-grid-post .post-tag {
    display: none; }
  .slider-grid-post h3 {
    font-size: 14px; }
  .slider-grid-post p {
    margin: 14px 0 10px; }
  .slider-grid-post.col-md-6 .content {
    left: 50px;
    right: 50px; }
  .slider-grid-post.col-md-6 .title {
    padding: 40px 0 20px; }
  .slider-grid-post.col-md-6 h3 {
    font-size: 22px; }
  .slider-grid-post.col-md-6 .post-tag {
    display: inline-block;
    font-size: 16px;
    line-height: 45px;
    padding: 0 70px; }
  .slider-grid-post.col-md-6 p {
    font-size: 15px;
    line-height: 25px;
    margin: 20px 0 15px; }
  .slider-grid-post.col-md-6 .meta {
    font-size: 14px; }

  .gpc-control .prev-arrow {
    border-width: 24px 24px 24px 14px; }
  .gpc-control .next-arrow {
    border-width: 24px 14px 24px 24px; }

  .wedding-slide-parallax {
    margin-top: 150px; }

  .portfolio-2-banner {
    background-size: 50% auto; }

  .section-title .shade-text {
    font-size: 150px; }

  .sp-slide-parallax .section-title .shade {
    font-size: 80px; }
  .sp-slide-parallax h3 {
    font-size: 35px; }

  .sp-feature-1 {
    margin-bottom: 50px; }
    .sp-feature-1::before, .sp-feature-1::after {
      left: 120px;
      top: 47%; }
    .sp-feature-1 .icon {
      font-size: 45px;
      height: 120px;
      line-height: 125px;
      min-width: 120px; }
    .sp-feature-1 .desc {
      margin-left: 40px;
      top: 50px; }

  .sp-ability {
    top: 25px; }
    .sp-ability h3 {
      margin-bottom: 20px; }

  .sp-carousel-2 {
    padding: 0 95px; }
    .sp-carousel-2 .service {
      min-height: 375px; }

  .contact-block.style-2 {
    padding: 40px; }

  .shop-3-left-wdget ul {
    max-height: 185px; }

  .shop-slider-3 .caption h2 {
    font-size: 20px; }

  .count-wrapper {
    padding: 34px 15px 40px 15px; }

  .product-countdown .time-slot {
    font-size: 24px; }

  .banner-sports h2 {
    font-size: 80px;
    line-height: 1; }

  .sports-sponsors li {
    height: 240px;
    line-height: 235px; }

  .match-update-tab-nav li a {
    font-size: 20px; }

  .team-member.style-11 .short-desc {
    padding: 33px;
    position: relative; }

  .hero-slider-1 .slide-3 .slide-content {
    margin: 0 0 0 30px;
    padding: 100px 50px; }

  .hero-slider-1 h1 {
    font-size: 45px; }

  .hero-slider-2 .parallax-content {
    margin-left: -50px; }
    .hero-slider-2 .parallax-content h1 {
      font-size: 38px; }

  .hero-slider-6-nav .slick-slide {
    margin: 0 12px; }
  .hero-slider-6-nav .slick-current {
    width: 80px;
    -webkit-transform: scale(1.6);
    -moz-transform: scale(1.6);
    -ms-transform: scale(1.6);
    -o-transform: scale(1.6);
    transform: scale(1.6); }

  .hero-slider-7 .slide-content h1 {
    font-size: 40px; }
  .hero-slider-7 .slide-content::before, .hero-slider-7 .slide-content::after {
    height: 80px;
    left: 20px;
    width: 191px; }

  .hero-slider-8 .slide-1 img {
    margin-bottom: 20px;
    max-width: 23%; }

  .hero-slider-12 .slide-content h1 {
    font-size: 42px; }

  /*==============================================
      Price Table
  ===============================================*/
  .col-md-6:nth-child(1) .price-table, .col-md-6:nth-child(2) .price-table {
    margin-bottom: 30px; }

  /*==============================================
      Portfolio
  ===============================================*/
  .portfolio-carousel .owl-prev, .portfolio-carousel .owl-next {
    left: 5%; }
  .portfolio-carousel .owl-next {
    left: auto;
    right: 5%; }

  .portfolio.type-2 figure {
    min-height: 400px; }
    .portfolio.type-2 figure img {
      top: -90px; }
  .portfolio.type-8 .image-popup {
    top: 25%; }

  /*==============================================
      Gallery
  ===============================================*/
  .cp-gallery figcaption {
    bottom: 20px;
    left: 30px;
    right: 30px; }
    .cp-gallery figcaption h3 {
      font-size: 22px; }

  /*==============================================
      Service
  ===============================================*/
  .service.style7 {
    padding: 50px 40px 53px; }
    .service.style7 h4 {
      font-size: 20px; }
  .service.style13 {
    padding: 80px 40px 50px; }
  .service.style14 .icon {
    height: 200px;
    line-height: 200px;
    width: 200px; }
  .service.style16:hover .more {
    width: 100%; }
  .service.style23 .desc {
    padding: 0; }

  [class^="col-"]:nth-last-child(1) .service.style10, [class^="col-"]:nth-last-child(2) .service.style10, [class^="col-"]:nth-last-child(3) .service.style10 {
    margin-bottom: 0; }

  .process-2 .icon {
    height: 200px;
    line-height: 200px;
    width: 200px; }

  /*==============================================
      tabs
  ===============================================*/
  /* tab 1 */
  .tab-1 .nav-list li a {
    padding: 34px 0 32px; }

  /* tab 4 */
  .tab-4 .nav-list li a {
    padding: 36px 30px; }
  .tab-4 .nav-list li .icon {
    margin-right: 20px; }
  .tab-4 .tab-pane .content {
    padding: 60px 50px; }

  .tab-7 .tab-nav li {
    margin: 0 20px; }

  /*==============================================
      team
  ===============================================*/
  .team-member.style2 .social-profiles li {
    margin-left: -4px; }
    .team-member.style2 .social-profiles li:first-child {
      margin-left: 0; }
  .team-member.style4 .member-info {
    padding: 60px 40px; }
  .team-member.style6 figcaption {
    padding: 45px 35px; }
  .team-member.style6 .mail {
    bottom: 50px; }
  .team-member.style9 .border {
    display: none; }
  .team-member.style9 figcaption {
    padding: 0 15px; }
  .team-member.style9 span {
    font-size: 16px;
    margin: 3px 0 12px; }
  .team-member.style9 .btn-md {
    font-size: 12px;
    margin-top: 0; }

  .team-member-12 .social-fab {
    bottom: 10px;
    right: 15px; }
    .team-member-12 .social-fab a {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      width: 40px; }

  .team-single-wrapper .mfp-close {
    right: 30px;
    top: 30px; }

  .team-single-desc {
    margin-top: -14px; }

  /*==============================================
      Products
  ===============================================*/
  .product-cat-style-3 .desc {
    padding-left: 25px;
    padding-right: 25px; }

  .product--summery {
    padding: 0 50px; }
    .product--summery .mb-25 {
      margin-bottom: 15px; }
    .product--summery .mb-40,
    .product--summery .mb-50 {
      margin-bottom: 25px; }

  /*==============================================
      CTA
  ===============================================*/
  .cta h2 {
    font-size: 28px; }

  .cta-bakery .cta-text h2 {
    font-size: 32px; }

  .cta-event .cta-logo {
    padding-right: 70px; }
    .cta-event .cta-logo::after {
      right: 32px; }
    .cta-event .cta-logo img {
      width: 90px; }
  .cta-event br {
    display: none; }

  .product.type-5 .desc {
    padding: 26px 20px 30px; }

  .product-cat-style-3 .mt-80.mb-80 {
    margin: 17px 0 40px; }

  /*==============================================
      Testimonial Carousel
  ===============================================*/
  .tm-carousel-8 .slick-dots {
    right: -50px; }

  /*==============================================
      Blog
  ===============================================*/
  .entry-post.style3 img {
    width: 100%; }
  .entry-post.style3 .entry-content {
    padding-left: 20px;
    padding-right: 19px; }
  .entry-post.style3 h4 {
    margin-bottom: 5px; }
  .entry-post.style3 .entry-meta {
    margin-bottom: 10px; }
  .entry-post.style3 .more {
    margin-top: 10px; }
  .entry-post.style4 h2 {
    font-size: 20px; }
  .entry-post.style5 .entry-content {
    padding: 42px 40px 52px; }

  .blog-post-1.sticky .post-desc {
    max-width: 400px; }
  .blog-post-1.sticky h2 {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 40px; }

  .blog-post-2.sticky .post-desc {
    max-width: 500px;
    padding: 40px; }
  .blog-post-2.sticky h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 35px;
    padding-bottom: 20px; }
  .blog-post-2.featured .post-desc {
    left: 50px; }
  .blog-post-2.featured h2 {
    font-size: 24px;
    line-height: 35px; }

  .blog-load-more-3 {
    line-height: 119px; }

  .blog-post-2 .post-desc {
    padding: 54px 40px 60px; }
  .blog-post-2 h3 {
    font-size: 20px; }

  .blog-load-more-3 {
    line-height: 92px; }

  .footer-nav li a {
    padding: 0 10px; }

  .footer-nav-3 > li > a {
    padding: 0 10px; }

  /*==============================================
      MISC
  ===============================================*/
  .imac-lg-left {
    margin: 0; }
    .imac-lg-left img {
      width: 90%; }

  .hand-phone img {
    margin: 50px 0 0;
    width: 100%; }

  .bottom-full-md {
    padding-bottom: 150px; }

  .indent-process {
    padding-left: 30px;
    margin-right: 34px; }
    .indent-process::before {
      border-width: 57px; }
    .indent-process::after {
      border-width: 57px;
      right: -114px; }
    .indent-process .number {
      font-size: 40px; }
    .indent-process .process-name {
      font-size: 23px;
      font-weight: 700; }

  .subscribe-block-1 .subscribe-inner {
    background-color: #0D52FF;
    color: #fff;
    padding-bottom: 30px;
    padding-top: 30px; }
    .subscribe-block-1 .subscribe-inner h3 {
      font-size: 22px; }
    .subscribe-block-1 .subscribe-inner .subscribe input {
      height: 50px; }
    .subscribe-block-1 .subscribe-inner .subscribe .mail-sub {
      height: 46px;
      line-height: 46px; }

  .re-block-wrapper {
    background-size: contain; }

  .re-block {
    padding: 30px 20px 20px; }
    .re-block h2 {
      font-size: 28px; }

  .dish-carousel .dish-img {
    margin-top: 50px; }
    .dish-carousel .dish-img img {
      margin: auto;
      width: auto; }

  .tab-2 .tab-nav li a {
    padding: 0 65px; }

  .map-control {
    padding-bottom: 130px;
    padding-top: 120px; }
    .map-control .contact-block p {
      font-size: 13px; }

  .dish--content-1,
  .dish--content-2,
  .dish--content-3 {
    padding: 42px 50px 50px; }

  .dish--carousel-1,
  .dish--carousel-2,
  .dish--carousel-3 {
    padding: 30px 40px; }
    .dish--carousel-1 .slick-prev,
    .dish--carousel-2 .slick-prev,
    .dish--carousel-3 .slick-prev {
      left: 15px; }
    .dish--carousel-1 .slick-next,
    .dish--carousel-2 .slick-next,
    .dish--carousel-3 .slick-next {
      right: 15px; }

  .misc-slider-3 {
    padding: 80px 80px 120px; }
    .misc-slider-3 .bottom-badge {
      border-width: 0 268px 55px; }

  .widget-post-slider .owl-dots {
    top: 30%; }

  .col-lg-2 .portfolio.style-11 img {
    width: 100%; }

  .fc-item .icon {
    margin-right: 280px; }
  .fc-item .text::before {
    left: -webkit-calc(-35% + 10px);
    left: -moz-calc(-35% + 10px);
    left: calc(-35% + 10px); }
  .fc-item.slick-center .text::before {
    width: 80px; }

  .process-3 .icon {
    font-size: 40px;
    height: 180px;
    line-height: 190px;
    width: 180px; }
    .process-3 .icon::after {
      margin-left: -8px; }

  .widget-post-list .thumb {
    min-width: 100px;
    width: 100px; }

  .widget-post-list .text {
    padding-left: 20px; }

  .process-4 li .process-label {
    font-size: 16px; }

  .the-blog-meh .shade-text {
    font-size: 150px; }
    .the-blog-meh .shade-text.top {
      left: -20px; }
    .the-blog-meh .shade-text.bottom {
      right: -20px; }

  .widget-area + .col-lg-6 {
    margin-bottom: 50px;
    margin-top: 50px; }

  .comment-body {
    padding-right: 30px; }

  .tab-8 .tab-nav li a {
    padding: 0 20px 20px; }

  .section-bubble-left {
    height: 120px;
    width: 30px; }

  .portfolio-bubble {
    height: 91px;
    width: 30px; }

  .team-member-12 .social-fab {
    bottom: 10px;
    right: 20px; }

  .cta-wave .cta-inner {
    bottom: 20px; }

  .s-service-carousel-nav .item {
    max-width: 240px;
    text-align: left; }

  .s-service-carousel-for {
    margin-left: 40px; }

  .iphone-holder {
    margin-bottom: -150px;
    margin-left: -20px; }

  .blog-bubble {
    height: 140px;
    width: 46px; } }
/*============================================================
    screen size md to up
==============================================================*/
@media only screen and (min-width: 1024px) {
  .center .tm-item-10 {
    text-align: left; }
    .center .tm-item-10 blockquote, .center .tm-item-10 .caret-bottom::before {
      background-color: #0D52FF;
      color: #fff; }
    .center .tm-item-10 h5 {
      color: #0D52FF; } }
/*============================================================
    Ipad Portrait 1024px
==============================================================*/
@media only screen and (max-width: 1025px) {
  .parallax-bg {
    background-attachment: scroll !important;
    background-position: center top !important;
    background-size: cover !important; } }
/*============================================================
    Tablet (Portrait) Design for a width of 768px
==============================================================*/
@media (min-width: 768px) and (max-width: 979px) {
  body {
    overflow-x: hidden; }

  h2, .h2 {
    font-size: 30px; }

  h3, .h3 {
    font-size: 22px; }

  .float-left-sm {
    float: left; }

  .float-right-sm {
    float: right; }

  .sm-left {
    text-align: left; }

  .mb-30-sm,
  .bottom-margin:not(:last-child) {
    margin-bottom: 30px; }

  .disable-flex-sm {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

  .section-title .shade-text {
    font-size: 110px; }
    .section-title .shade-text + h2 {
      padding-top: 43px; }

  /*==============================================
      Header
  ===============================================*/
  .site-logo {
    line-height: 65px; }
    .site-logo img {
      height: 33px; }

  .nav-hamburger {
    height: 65px; }

  .header-right {
    padding-right: 30px; }
    .header-right .devider {
      left: auto;
      right: 0; }

  .primary-nav {
    background-color: #fff;
    display: none;
    float: none;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 100%; }
    .primary-nav ul li {
      float: none; }

  .menu-list {
    max-height: 400px;
    overflow: auto; }
    .menu-list .sub-menu {
      opacity: 1;
      visibility: visible; }

  .sticky-header .primary-nav ul li a,
  .primary-nav ul li a {
    line-height: 35px;
    color: #848484; }

  .header-right .top-cart > a,
  .header-right .top-search > a {
    height: 65px;
    line-height: 65px; }

  .header-action-btns .top-search,
  .header-action-btns .top-cart {
    height: 65px; }

  .site-header.style3.is-sticky .nav-hamburger {
    height: 60px; }
  .site-header.style4 .site-logo {
    line-height: 95px; }
    .site-header.style4 .site-logo img {
      height: 35px; }
  .site-header.style4 .mid-content .left-content {
    display: none; }
  .site-header.style4 .select-box {
    padding-right: 40px; }
  .site-header.style4 .middle-search {
    min-width: 300px; }
  .site-header.style4 .social-links li a {
    padding: 0 5px; }
  .site-header.style4 .shop-nav li a {
    padding: 0 8px; }
  .site-header.style4 .cart-button {
    padding: 0 20px; }
  .site-header.style4 .primary-nav ul li a {
    color: #848484; }
    .site-header.style4 .primary-nav ul li a:hover {
      color: #444; }
  .site-header.style4.is-sticky .site-logo {
    padding: 0;
    line-height: 75px; }
  .site-header.style4.is-sticky .nav-hamburger {
    height: 75px; }
  .site-header.style5 .col-md-6.text-right, .site-header.style6 .col-md-6.text-right, .site-header.style7 .col-md-6.text-right {
    text-align: left; }
  .site-header.style5 .header-bottom .site-logo, .site-header.style6 .header-bottom .site-logo, .site-header.style7 .header-bottom .site-logo {
    display: block; }
  .site-header.style5 .nav-hamburger, .site-header.style6 .nav-hamburger, .site-header.style7 .nav-hamburger {
    height: 70px; }
  .site-header.style5 .site-logo img, .site-header.style6 .site-logo img, .site-header.style7 .site-logo img {
    height: 38px; }
  .site-header.style5 .primary-nav ul, .site-header.style6 .primary-nav ul, .site-header.style7 .primary-nav ul {
    float: none !important; }
  .site-header.style5.is-sticky .site-logo, .site-header.style6.is-sticky .site-logo, .site-header.style7.is-sticky .site-logo {
    line-height: 75px; }
  .site-header.style5.is-sticky .nav-hamburger, .site-header.style6.is-sticky .nav-hamburger, .site-header.style7.is-sticky .nav-hamburger {
    height: 75px; }
  .site-header.style5.is-sticky .menu-list > li > a, .site-header.style6.is-sticky .menu-list > li > a, .site-header.style7.is-sticky .menu-list > li > a {
    line-height: 40px; }
  .site-header.style6 .header-middle {
    margin-top: 10px; }
  .site-header.style6 .product-actions {
    margin-left: 30px; }
    .site-header.style6 .product-actions li a {
      margin-right: 20px; }
  .site-header.style6.is-sticky .site-logo,
  .site-header.style6.is-sticky .nav-hamburger {
    height: 60px;
    line-height: 60px; }
  .site-header.style6.is-sticky .site-logo img {
    height: 35px; }
  .site-header.style6.is-sticky .primary-nav ul {
    float: none; }
  .site-header.style6.is-sticky .primary-nav li a {
    line-height: 35px; }
  .site-header.style7 .header-middle {
    padding: 15px 0; }
    .site-header.style7 .header-middle .row {
      display: block;
      width: auto; }
      .site-header.style7 .header-middle .row [class^="col-"] {
        display: block;
        float: left;
        padding-left: 15px;
        padding-right: 15px; }
  .site-header.style7 .middle-search {
    display: table;
    width: 100%; }
    .site-header.style7 .middle-search > * {
      display: table-cell;
      vertical-align: middle; }
  .site-header.style7 .cart-button {
    padding: 0 65px 0 0; }
  .site-header.style7 .product-actions {
    margin: 0; }
  .site-header.style7 .primary-nav {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .site-header.style7 .social-links {
    float: none;
    position: static;
    right: auto;
    top: auto;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
  .site-header.style8 .header-middle, .site-header.style9 .header-middle {
    padding: 5px; }
  .site-header.style8 .nav-hamburger, .site-header.style9 .nav-hamburger {
    height: 40px; }
    .site-header.style8 .nav-hamburger span, .site-header.style8 .nav-hamburger span::before, .site-header.style8 .nav-hamburger span::after, .site-header.style9 .nav-hamburger span, .site-header.style9 .nav-hamburger span::before, .site-header.style9 .nav-hamburger span::after {
      background-color: #a2a2a2; }
    .site-header.style8 .nav-hamburger.active span, .site-header.style9 .nav-hamburger.active span {
      background-color: transparent; }
  .site-header.style8 .site-logo, .site-header.style9 .site-logo {
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    .site-header.style8 .site-logo img, .site-header.style9 .site-logo img {
      height: 35px; }
  .site-header.style8 .primary-nav, .site-header.style9 .primary-nav {
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    top: 113%;
    z-index: 120; }
    .site-header.style8 .primary-nav ul:first-child, .site-header.style9 .primary-nav ul:first-child {
      display: block;
      float: none;
      text-align: left; }
    .site-header.style8 .primary-nav li, .site-header.style9 .primary-nav li {
      display: block; }
  .site-header.style8 .middle-search, .site-header.style9 .middle-search {
    min-width: auto; }
  .site-header.style8 .cart-button, .site-header.style9 .cart-button {
    padding: 0 65px 0 25px; }
  .site-header.style9 .header-middle {
    padding: 15px 0; }
    .site-header.style9 .header-middle .row {
      display: block;
      width: auto; }
      .site-header.style9 .header-middle .row [class^="col-"] {
        padding-left: 15px;
        padding-right: 15px;
        float: left;
        display: block; }
  .site-header.style9 .header-bottom {
    padding: 8px 0; }
    .site-header.style9 .header-bottom .site-logo {
      display: block;
      line-height: 60px; }
      .site-header.style9 .header-bottom .site-logo img {
        height: 35px; }
  .site-header.style9.is-sticky .primary-nav li a {
    line-height: 40px; }
  .site-header.style10 .header-middle .site-logo img {
    height: 40px; }
  .site-header.style10 [class^="col-"]:first-child .info-cell {
    margin-left: 40px; }
  .site-header.style10 [class^="col-"]:last-child .info-cell {
    margin-left: 10px; }
  .site-header.style10 .cart-button {
    padding-left: 0; }
  .site-header.style10 .nav-hamburger {
    height: 50px; }
  .site-header.style10 .primary-nav {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .site-header.style10 .menu-list {
    text-align: left; }
    .site-header.style10 .menu-list li a {
      color: #848484; }
      .site-header.style10 .menu-list li a:hover {
        background-color: #0D52FF;
        color: #fff; }
  .site-header.style10.is-sticky .primary-nav {
    display: none; }
  .site-header.style10.is-sticky .menu-list > li > a {
    color: #848484;
    line-height: 40px; }
  .site-header.style11 .header-middle .site-logo {
    padding-right: 30px; }
    .site-header.style11 .header-middle .site-logo img {
      height: 40px; }
  .site-header.style11 .nav-hamburger {
    height: 50px;
    margin-right: 20px; }
  .site-header.style11 .header-right {
    padding-right: 0; }
  .site-header.style11 .primary-nav {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    padding-left: 15px;
    margin-left: -15px;
    padding-right: 15px;
    margin-right: -15px; }
    .site-header.style11 .primary-nav > ul > li > a {
      line-height: 40px; }
  .site-header.style11 .menu-list {
    text-align: left; }
    .site-header.style11 .menu-list li a:hover, .site-header.style11 .menu-list li a:focus {
      background-color: #0D52FF;
      color: #fff; }
  .site-header.style11.is-sticky .primary-nav {
    display: none; }
  .site-header.style11.is-sticky .menu-list {
    text-align: left; }
    .site-header.style11.is-sticky .menu-list li a {
      color: #848484; }
  .site-header.style12 .header-top {
    background-color: #fff;
    margin: 0;
    padding: 10px 0; }
  .site-header.style12 .header-right {
    padding-right: 0; }
  .site-header.style12 .social-links li a {
    margin-right: 8px; }
  .site-header.style12 .header-bottom {
    margin-top: 0; }
    .site-header.style12 .header-bottom .bottom-inner {
      background-color: transparent;
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: none; }
    .site-header.style12 .header-bottom .col-xs-12 {
      min-height: auto; }
  .site-header.style12 .site-logo {
    line-height: 50px; }
    .site-header.style12 .site-logo img {
      height: 35px; }
  .site-header.style12 .top-search > a {
    height: 50px;
    line-height: 50px; }
  .site-header.style12 .nav-hamburger {
    height: 50px; }
    .site-header.style12 .nav-hamburger span, .site-header.style12 .nav-hamburger span::before, .site-header.style12 .nav-hamburger span::after {
      background-color: #a2a2a2; }
    .site-header.style12 .nav-hamburger.active span {
      background-color: transparent; }
  .site-header.style12 .primary-nav {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    float: none; }
  .site-header.style12 .menu-list > li {
    display: block; }
    .site-header.style12 .menu-list > li > a {
      line-height: 40px; }
  .site-header.style12.is-sticky {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .site-header.style12.is-sticky .header-top {
      display: block; }
    .site-header.style12.is-sticky .header-bottom .container {
      padding-bottom: 0;
      padding-top: 0; }
  .site-header.style13 .header-bottom {
    -webkit-transform: translate(0px);
    -moz-transform: translate(0px);
    -ms-transform: translate(0px);
    -o-transform: translate(0px);
    transform: translate(0px); }
    .site-header.style13 .header-bottom.whitebg {
      background-color: #fff; }
    .site-header.style13 .header-bottom .bottom-inner {
      background-color: transparent;
      padding: 0;
      box-shadow: none; }
  .site-header.style13 .site-logo img {
    height: 35px; }
  .site-header.style13 .header-right .devider {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .site-header.style13 .nav-hamburger {
    height: 65px; }
  .site-header.style13.is-sticky {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .site-header.style13.is-sticky .nav-hamburger span, .site-header.style13.is-sticky .nav-hamburger span::before, .site-header.style13.is-sticky .nav-hamburger span::after {
      background-color: #fff; }
    .site-header.style13.is-sticky .nav-hamburger.active span {
      background-color: transparent; }
  .site-header.style14 .menu-list > li > a {
    line-height: 35px;
    height: auto; }
  .site-header.style14 .site-logo img {
    height: 35px; }
  .site-header.style14 .site-logo,
  .site-header.style14 .header-right .top-cart > a,
  .site-header.style14 .header-right .top-search > a,
  .site-header.style14 .nav-hamburger {
    height: 60px;
    line-height: 60px; }
  .site-header.style14 .nav-hamburger span,
  .site-header.style14 .nav-hamburger span::before,
  .site-header.style14 .nav-hamburger span::after {
    background-color: #848484; }
  .site-header.style14 .nav-hamburger.active span {
    background-color: transparent; }
  .site-header.style14.is-sticky .site-logo img {
    height: 30px; }
  .site-header.style14.is-sticky .menu-list > li > a {
    line-height: 35px;
    height: auto; }

  .menu-list {
    padding-top: 10px;
    padding-bottom: 10px; }
    .menu-list > li {
      display: block !important; }
      .menu-list > li > a {
        color: #848484 !important;
        line-height: 40px; }
    .menu-list .sub-menu {
      display: none;
      position: relative; }
      .menu-list .sub-menu .sub-menu {
        left: 0;
        padding-left: 20px;
        top: 0; }

  .is-sticky .menu-list > li > a {
    height: 36px;
    line-height: 36px; }

  .style15 .menu-list {
    padding: 10px 0; }
    .style15 .menu-list > li > a {
      font-size: 12px;
      line-height: 35px; }
  .style15 .nav-hamburger {
    height: 79px; }
  .style15.is-sticky .nav-hamburger {
    height: 60px; }

  .header-megazine .primary-nav {
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 100%;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none; }
  .header-megazine .menu-list {
    margin-left: auto;
    margin-right: auto;
    width: 750px; }
  .header-megazine .search-box {
    background-color: #fff;
    display: block;
    margin-left: -47px;
    z-index: 10;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .header-megazine .search-box input {
      width: 100%; }
  .header-megazine .nav-hamburger span, .header-megazine .nav-hamburger span::before, .header-megazine .nav-hamburger span::after {
    background-color: #848484; }
  .header-megazine .nav-hamburger.active span {
    background-color: transparent; }
  .header-megazine.is-sticky .search-box {
    margin-left: 30px; }
  .header-megazine.is-sticky .primary-nav {
    margin-left: 0; }

  .header-magazine-2 .primary-nav {
    border-bottom: 0 none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    text-align: left;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none; }
  .header-magazine-2 .nav-hamburger span, .header-magazine-2 .nav-hamburger span::before, .header-magazine-2 .nav-hamburger span::after {
    background-color: #848484; }
  .header-magazine-2 .nav-hamburger.active span {
    background-color: transparent; }

  .header-interior .menu-list > li > a {
    line-height: 40px; }

  .header-seo .site-logo.bottom {
    display: block; }
  .header-seo .primary-nav {
    position: absolute !important;
    top: 100%;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none; }
  .header-seo .menu-list > li > a {
    color: #fff !important;
    line-height: 40px; }

  .header-wedding {
    position: fixed !important; }
    .header-wedding .header-top {
      display: none; }
    .header-wedding .site-logo {
      display: block; }
    .header-wedding .primary-nav {
      float: none;
      text-align: left; }

  .primary-bg .nav-hamburger span, .primary-bg .nav-hamburger span::before, .primary-bg .nav-hamburger span::after {
    background-color: #fff; }
  .primary-bg .nav-hamburger.active span {
    background-color: transparent; }

  .seo-top-nav li a {
    padding: 0 4px; }

  .header-travel .search-box {
    padding: 0; }
  .header-travel > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
    .header-travel > .container > * {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1; }
  .header-travel .nav-hamburger {
    max-width: 30px; }

  .is-sticky .header-travel .search-box {
    padding-top: 0; }

  .header-sp .menu-list > li > a, .header-sp.is-sticky .menu-list > li > a {
    height: 40px;
    line-height: 40px; }

  .header-shop-3 .header-action-btns > li {
    height: auto; }

  /*==============================================
      Hover style
  ===============================================*/
  /*-------------- nav-hover-2 -------------*/
  .nav-hover-2 > li > a::before, .nav-hover-2 > li > a::after {
    content: none; }

  /*==============================================
      Page
  ===============================================*/
  .page-submenu > li > a {
    height: 60px;
    line-height: 60px;
    padding-left: 15px;
    padding-right: 15px; }

  .section-login-register .half-bg-container {
    opacity: 0.9;
    width: 100%; }

  
  /*==============================================
      Testimonials
  ===============================================*/
  .tm-item-12 .text {
    margin: 0 30px; }

  /*==============================================
      CTA
  ===============================================*/
  .cta .table-cell {
    display: block;
    text-align: center; }
  .cta h2 {
    font-size: 28px; }
  .cta.cta-one h2, .cta.cta-two h2 {
    margin-bottom: 20px; }
  .cta.cta-two a {
    margin-top: 35px; }

  .cta-event .table-cell {
    display: table-cell;
    text-align: left; }
  .cta-event .cta-logo {
    padding-right: 36px; }
    .cta-event .cta-logo::after {
      right: 16px; }
    .cta-event .cta-logo img {
      width: 80px; }
  .cta-event p {
    font-size: 14px;
    line-height: 26px; }
  .cta-event .btn-large {
    font-size: 14px; }

  .cta-bakery .flex {
    display: table;
    width: 100%; }
  .cta-bakery .flex-grow {
    display: table-cell;
    padding: 30px 0;
    vertical-align: middle; }
    .cta-bakery .flex-grow > * {
      line-height: 1.2; }
  .cta-bakery h2 {
    font-size: 24px; }
  .cta-bakery .cta-button {
    font-size: 20px;
    white-space: nowrap;
    padding: 20px; }

  .template-layer {
    margin-top: 100px; }

  .match-update-tab-nav li a {
    font-size: 18px;
    padding-left: 20px; }
  .match-update-tab-nav li i {
    font-size: 30px; }

  .team-member.style-11 {
    display: block; }
    .team-member.style-11 .short-desc {
      margin: 0;
      padding: 50px; }
      .team-member.style-11 .short-desc::before {
        content: none; }

  .sports-sponsors li {
    height: 230px;
    line-height: 220px; }

  /*==============================================
      Products
  ===============================================*/
  .product.type-1 .product-name a {
    font-size: 16px;
    line-height: 28px; }
  .product.type-1 .product-meta a {
    font-size: 16px; }
    .product.type-1 .product-meta a:after {
      content: none; }
  .product.type-4 .meta h4 {
    font-size: 16px; }

  .product--summery {
    padding: 50px; }
    .product--summery .mb-25 {
      margin-bottom: 15px; }
    .product--summery .mb-40,
    .product--summery .mb-50 {
      margin-bottom: 25px; }

  .count-wrapper {
    padding: 34px 30px 40px 30px; }

  .product-countdown .time-slot {
    font-size: 20px; }

  .ctrl-nav-4 .owl-prev {
    left: 0; }
  .ctrl-nav-4 .owl-next {
    right: 0; }

  /*==============================================
      Portfolio
  ===============================================*/
  .masonry-filter.style1 li a {
    padding: 0 25px; }

  .portfolio.type-2 figure {
    min-height: 350px; }
    .portfolio.type-2 figure img {
      top: -90px; }
  .portfolio.type-8 .image-popup {
    top: 25%; }
  .portfolio.style-12 .caption {
    max-width: 430px;
    padding: 0 40px; }
    .portfolio.style-12 .caption i {
      font-size: 30px;
      margin-bottom: 10px; }
    .portfolio.style-12 .caption h3, .portfolio.style-12 .caption p {
      margin-bottom: 10px; }
    .portfolio.style-12 .caption .btn-large {
      font-size: 13px;
      height: 45px;
      line-height: 45px;
      padding: 0 40px; }

  /*==============================================
      feature
  ===============================================*/
  .feature-3 h2 {
    font-size: 30px; }

  .feature-carousel-1 {
    padding: 70px 0 0 74px; }
    .feature-carousel-1 + .device-nexus {
      background-size: 60%; }

  .fc-item {
    margin-bottom: 10px; }
    .fc-item .icon {
      font-size: 25px;
      height: 80px;
      line-height: 80px;
      margin-right: 200px;
      width: 80px; }
      .fc-item .icon::after {
        left: -webkit-calc(100% + 30px);
        left: -moz-calc(100% + 30px);
        left: calc(100% + 30px); }
    .fc-item .text {
      margin: 0;
      width: 340px; }
      .fc-item .text h3 {
        font-size: 24px; }
      .fc-item .text::before {
        top: 40px;
        left: -webkit-calc(-37% + 15px);
        left: -moz-calc(-37% + 15px);
        left: calc(-37% + 15px); }
    .fc-item.slick-center .icon::after {
      width: 46px; }
    .fc-item.slick-center .text::before {
      width: 62px; }

  .footer-nav-3 {
    text-align: left; }

  .newsletter-1 {
    margin: 30px 0 0; }

  /*==============================================
      tab
  ===============================================*/
  .tab-1 .tab-content {
    padding: 93px 80px 99px; }

  .tab-4 .nav-list li a {
    padding: 20px 40px; }

  .tab-7 .tab-nav li {
    margin: 0 15px; }
    .tab-7 .tab-nav li a {
      font-size: 30px; }

  .horiz-icon-tab-nav li a {
    width: 100%; }
  .horiz-icon-tab-nav li + li {
    margin-left: 0; }

  /*==============================================
      team
  ===============================================*/
  .team-member.style4 .member-info {
    padding: 30px; }
    .team-member.style4 .member-info .contact {
      display: inline;
      word-wrap: break-word; }
  .team-member.style6 figcaption {
    padding: 35px 25px; }
  .team-member.style6 h4 {
    font-size: 18px; }
  .team-member.style6 span {
    font-size: 16px; }
  .team-member.style6 .mail {
    bottom: 50px;
    font-size: 12px; }

  .team-single-wrapper .mfp-close {
    right: 30px;
    top: 30px; }

  .team-single-desc {
    margin-top: -10px; }
    .team-single-desc .bio h2 {
      font-size: 26px; }

  /*==============================================
      Progress
  ===============================================*/
  .progress-item.type-1 .progress-bar .value {
    right: 10px; }

  /*==============================================
      Accordions
  ===============================================*/
  .collapsible-header i {
    margin-right: 15px; }

  .section-title h2 {
    font-size: 35px; }

  /*==============================================
      Blog
  ===============================================*/
  .entry-post.style3 img {
    width: 100%; }
  .entry-post.style3 .entry-content {
    padding: 30px; }
  .entry-post.style3 h4 {
    margin-bottom: 5px; }
  .entry-post.style3 .entry-meta {
    margin-bottom: 10px; }
  .entry-post.style3 .more {
    margin-top: 10px; }
  .entry-post.style4 .entry-content {
    padding: 58px 25px 50px; }
  .entry-post.style4 h2 {
    font-size: 18px; }
  .entry-post.style4 p {
    margin: 0;
    padding: 0; }
  .entry-post.style5 .entry-content {
    padding: 32px 20px 52px; }
  .entry-post.style5 h2 {
    font-size: 20px; }

  .blog-post-1.sticky .post-desc {
    left: 30px;
    max-width: 425px;
    padding-right: 30px; }
  .blog-post-1.sticky h2 {
    font-size: 31px;
    line-height: 40px;
    margin-bottom: 40px; }

  .blog-post-2 .post-desc {
    padding: 44px 30px 45px; }
  .blog-post-2 h3 {
    font-size: 18px;
    padding-bottom: 15px; }
  .blog-post-2.sticky .post-desc {
    max-width: none;
    padding: 50px 40px;
    position: static; }
  .blog-post-2.sticky h2 {
    font-size: 24px;
    line-height: 1;
    margin-bottom: 30px;
    padding-bottom: 20px; }
  .blog-post-2.featured .post-desc {
    left: 30px; }
    .blog-post-2.featured .post-desc .meta {
      font-size: 16px; }
    .blog-post-2.featured .post-desc h2 {
      font-size: 18px;
      line-height: 28px;
      padding-bottom: 20px; }

  .blog-load-more-2 {
    font-size: 18px; }

  .blog-load-more-3 {
    line-height: 62px; }

  .widget-area + * {
    margin-top: 50px; }
  .widget-area + .col-lg-6 {
    margin-bottom: 50px;
    margin-top: 50px; }

  .widget-post-slider .owl-dots {
    top: 66%; }

  .contact-block.style-2 {
    padding: 65px; }

  /*==============================================
      Footer
  ===============================================*/
  .footer-nav ul {
    text-align: left; }

  /*==============================================
      Tabs
  ===============================================*/
  .tab-2 .tab-nav li a {
    padding: 0 40px; }

  .tab-3 .nav-list li a {
    padding: 30px 20px; }

  /*==============================================
      Tesimonial carousel
  ===============================================*/
  .tm-item.style8 blockquote {
    font-size: 16px;
    line-height: 28px; }

  .tm-carousel-8 .slick-dots {
    top: auto;
    right: 0;
    text-align: center;
    left: 0;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
    .tm-carousel-8 .slick-dots li {
      display: inline-block; }
    .tm-carousel-8 .slick-dots button {
      margin-left: 0; }

  /*==============================================
      MISC
  ===============================================*/
  .indent-process {
    padding-left: 30px;
    margin-right: 29px; }
    .indent-process::before {
      border-width: 52px; }
    .indent-process::after {
      border-width: 52px;
      right: -104px; }
    .indent-process .number {
      font-size: 30px; }
    .indent-process .process-name {
      font-size: 16px;
      font-weight: 500; }

  .subscribe-block-1 .subscribe-inner {
    padding-right: 30px; }

  .dish-carousel .dish-img {
    margin-top: 50px; }
    .dish-carousel .dish-img img {
      margin: auto;
      width: auto; }

  .map-control #map-half {
    height: 400px;
    margin-top: 30px;
    position: static;
    width: 100%; }

  .text-block-1.mt-60 {
    margin: 0; }

  .event-carousel-1 .text {
    margin-top: -10px; }
    .event-carousel-1 .text h2 {
      font-size: 26px; }

  .event-schedule .entry-post:nth-child(2) {
    margin-top: 320px; }
  .event-schedule .entry-post .entry-content {
    padding: 50px 40px; }

  .imac-lg-left {
    margin: 0; }

  .hand-phone img {
    margin: 80px 0 0;
    width: 100%; }

  .misc-slider-3 {
    padding: 90px; }
    .misc-slider-3 .bottom-badge {
      border-width: 0 266.79px 99px; }

  .process-3 .icon::after {
    content: none; }

  .process-4 li .process-label {
    font-size: 14px; }

  .the-blog-meh .shade-text {
    font-size: 120px; }
    .the-blog-meh .shade-text.top {
      left: -20px; }
    .the-blog-meh .shade-text.bottom {
      right: -20px; }

  .clients-4col [class^="col-"] {
    min-height: 180px;
    padding: 10px; }

  .hentry-footer .social-2 {
    margin-top: 30px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start; }

  .comment-body {
    padding-right: 30px; }

  .author-card {
    padding: 20px; }

  .woocommerce-thankyou-order-details li {
    padding: 0 15px; }

  .main-example .time {
    font-size: 70px;
    height: 115px;
    line-height: 118px;
    width: 130px; }

  .s-service-carousel-nav {
    margin-top: 0; }
    .s-service-carousel-nav .item {
      font-size: 16px;
      max-width: none; }
      .s-service-carousel-nav .item::before, .s-service-carousel-nav .item::after {
        content: none; }
    .s-service-carousel-nav .slick-center {
      font-size: 20px; }

  .s-service-carousel-for {
    margin-top: 0;
    margin-left: 0; }

  .service-bubble {
    height: 150px;
    width: 49px; }

  .cta-wave .cta-inner {
    background-color: #0D52FF;
    bottom: auto;
    left: 0;
    padding: 50px 0;
    position: static; } }
/*============================================================
    Mobile (Portrait) Design for a width of 320px
==============================================================*/
@media only screen and (min-width: 1200px) {
  .cc-lg-5 {
    width: 20%; } }
/*============================================================
    Mobile (Portrait) Design for a width of 320px
==============================================================*/
@media only screen and (max-width: 767px) {
  body {
    overflow-x: hidden; }

  h2, .h2 {
    font-size: 30px; }

  h3, .h3 {
    font-size: 22px; }

  .disable-fullscreen-xs,
  .disable-fitscreen-xs {
    height: auto !important; }

  .disable-flex-xs {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

  .float-left-xs {
    float: left; }

  .float-right-xs {
    float: right; }

  .xs-left {
    text-align: left; }

  .mt-30-xs {
    margin-top: 30px; }

  .mb-30-xs,
  .bottom-margin:not(:last-child) {
    margin-bottom: 30px; }

  .container {
    width: auto; }

  .section-title {
    margin-bottom: 75px; }
    .section-title h2 {
      font-size: 24px;
      line-height: 35px; }
    .section-title .shade-text {
      font-size: 50px; }
      .section-title .shade-text + h2 {
        padding-top: 20px; }
    .section-title .sub {
      font-size: 18px;
      margin-top: 20px; }
      .section-title .sub + p {
        font-size: 14px;
        line-height: 26px; }

  .btn-group a:not(:last-child) {
    margin-right: 0; }

  /*==============================================
      header
  ===============================================*/
  .site-logo,
  .nav-hamburger,
  .header-right .top-cart > a,
  .header-right .top-search > a {
    height: 60px;
    line-height: 60px; }

  .header-action-btns .top-search,
  .header-action-btns .top-cart {
    height: 60px; }

  .header-cart-2 {
    left: auto;
    right: -60px; }

  .top-cart:hover .header-cart-2 {
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0); }
  .top-cart.active .header-cart-2 {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1); }

  .site-logo img {
    height: 30px; }

  .sticky-header .site-logo > img {
    height: 25px; }

  .header-right {
    padding-right: 30px; }
    .header-right .devider {
      left: auto;
      right: 0; }

  .search-wrapper .search-popup label {
    font-size: 24px; }

  .primary-nav {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: none;
    float: none;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 100%; }
    .primary-nav ul li {
      float: none; }

  .sticky-header .primary-nav ul li a,
  .primary-nav ul li a {
    line-height: 35px; }

  .is-sticky .menu-list > li > a {
    height: 40px;
    line-height: 40px; }

  .site-header.style3.is-sticky .menu-list > li > a {
    line-height: 35px;
    height: auto; }
  .site-header.style4 .header-top .table-cell {
    display: block; }
  .site-header.style4 .site-logo {
    line-height: 60px; }
  .site-header.style4 .nav-hamburger {
    margin-left: 16px; }
  .site-header.style4 .middle-search {
    margin-bottom: 10px;
    min-width: auto; }
  .site-header.style4 .select-box {
    padding-right: 0; }
  .site-header.style4 .shop-nav li a {
    padding: 0 4px; }
  .site-header.style4 .header-cart {
    display: block;
    margin: 0 0 10px;
    text-align: center; }
    .site-header.style4 .header-cart .cart-button {
      display: block; }
  .site-header.style4 .primary-nav li a {
    color: #848484; }
  .site-header.style4.is-sticky .site-logo {
    padding: 0; }
    .site-header.style4.is-sticky .site-logo img {
      height: 30px; }
  .site-header.style4.is-sticky .primary-nav {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .site-header.style5 .header-middle .row, .site-header.style6 .header-middle .row, .site-header.style7 .header-middle .row {
    display: block;
    width: auto; }
    .site-header.style5 .header-middle .row [class^="col-"], .site-header.style6 .header-middle .row [class^="col-"], .site-header.style7 .header-middle .row [class^="col-"] {
      display: block;
      padding-left: 15px;
      padding-right: 15px; }
  .site-header.style5 .header-middle .header-bottom .site-logo, .site-header.style6 .header-middle .header-bottom .site-logo, .site-header.style7 .header-middle .header-bottom .site-logo {
    display: block; }
  .site-header.style5 .header-middle .nav-hamburger, .site-header.style6 .header-middle .nav-hamburger, .site-header.style7 .header-middle .nav-hamburger {
    height: 70px; }
  .site-header.style5 .header-middle .site-logo img, .site-header.style6 .header-middle .site-logo img, .site-header.style7 .header-middle .site-logo img {
    height: 38px; }
  .site-header.style5 .header-middle .middle-search, .site-header.style6 .header-middle .middle-search, .site-header.style7 .header-middle .middle-search {
    margin-top: 15px; }
  .site-header.style5 .header-middle .cart-button, .site-header.style6 .header-middle .cart-button, .site-header.style7 .header-middle .cart-button {
    display: block;
    margin: 15px 0 10px;
    padding: 0 20px;
    text-align: center; }
  .site-header.style5 .header-bottom .site-logo, .site-header.style6 .header-bottom .site-logo, .site-header.style7 .header-bottom .site-logo {
    display: block; }
  .site-header.style5 .primary-nav ul:first-child, .site-header.style6 .primary-nav ul:first-child, .site-header.style7 .primary-nav ul:first-child {
    float: none; }
  .site-header.style5 .primary-nav li a, .site-header.style6 .primary-nav li a, .site-header.style7 .primary-nav li a {
    color: #848484;
    line-height: 30px; }
  .site-header.style5.is-sticky .primary-nav, .site-header.style6.is-sticky .primary-nav, .site-header.style7.is-sticky .primary-nav {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .site-header.style5.is-sticky .primary-nav li a, .site-header.style6.is-sticky .primary-nav li a, .site-header.style7.is-sticky .primary-nav li a {
      line-height: 30px; }
  .site-header.style6 .header-top,
  .site-header.style6 .header-bottom {
    background-color: transparent; }
  .site-header.style7 .header-top {
    position: relative;
    z-index: 101; }
  .site-header.style7 .header-middle {
    padding: 15px 0; }
    .site-header.style7 .header-middle .row {
      display: block;
      width: auto; }
      .site-header.style7 .header-middle .row [class^="col-"] {
        display: block;
        float: left;
        padding-left: 15px;
        padding-right: 15px; }
    .site-header.style7 .header-middle .cart-button {
      display: block;
      padding: 0 65px 0 0;
      margin: 0; }
  .site-header.style7 .middle-search {
    display: table;
    width: 100%; }
    .site-header.style7 .middle-search > * {
      display: table-cell;
      vertical-align: middle; }
  .site-header.style7 .header-cart {
    margin-top: 15px; }
  .site-header.style7 .primary-nav {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .site-header.style7 .header-bottom .site-logo {
    display: block; }
  .site-header.style7 .social-links {
    float: none;
    position: static;
    right: auto;
    top: auto;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
  .site-header.style8 .header-top {
    position: relative;
    z-index: 120; }
    .site-header.style8 .header-top .nice-select {
      padding-left: 0; }
    .site-header.style8 .header-top .more-vert a {
      color: #848484; }
      .site-header.style8 .header-top .more-vert a:hover {
        color: #444; }
      .site-header.style8 .header-top .more-vert a i {
        position: relative;
        top: 5px; }
  .site-header.style8 .header-middle {
    padding: 5px 0; }
  .site-header.style8 .nav-hamburger {
    height: 40px; }
    .site-header.style8 .nav-hamburger span, .site-header.style8 .nav-hamburger span::before, .site-header.style8 .nav-hamburger span::after {
      background-color: #a2a2a2; }
    .site-header.style8 .nav-hamburger.active span {
      background-color: transparent; }
  .site-header.style8 .site-logo {
    height: auto;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    .site-header.style8 .site-logo img {
      height: 30px; }
  .site-header.style8 .primary-nav {
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    top: 113%;
    z-index: 120; }
    .site-header.style8 .primary-nav ul:first-child {
      display: block;
      float: none;
      text-align: left; }
    .site-header.style8 .primary-nav li {
      display: block; }
  .site-header.style8 .middle-search {
    display: table;
    margin-bottom: 15px;
    min-width: auto;
    width: 100%; }
    .site-header.style8 .middle-search > * {
      display: table-cell;
      vertical-align: middle; }
    .site-header.style8 .middle-search label {
      line-height: 30px; }
  .site-header.style8 .header-bottom .table-cell {
    display: block; }
  .site-header.style8 .cart-button {
    padding: 0 65px 0 25px; }
  .site-header.style9 .more-vert > a {
    color: rgba(255, 255, 255, 0.87); }
    .site-header.style9 .more-vert > a:hover {
      color: #fff; }
    .site-header.style9 .more-vert > a i {
      position: relative;
      top: 4px; }
  .site-header.style9 .header-middle {
    padding: 15px 0; }
    .site-header.style9 .header-middle .row {
      display: block;
      width: auto; }
      .site-header.style9 .header-middle .row [class^="col-"] {
        padding-left: 15px;
        padding-right: 15px;
        float: left;
        display: block; }
  .site-header.style9 .header-cart {
    margin-top: 15px; }
  .site-header.style9 .header-bottom {
    padding: 8px 0; }
    .site-header.style9 .header-bottom .site-logo {
      display: block;
      line-height: 60px; }
      .site-header.style9 .header-bottom .site-logo img {
        height: 35px; }
  .site-header.style9 .nav-hamburger span, .site-header.style9 .nav-hamburger span::before, .site-header.style9 .nav-hamburger span::after {
    background-color: #a2a2a2; }
  .site-header.style9 .nav-hamburger.active span {
    background-color: transparent; }
  .site-header.style9 .primary-nav ul:first-child {
    text-align: left; }
  .site-header.style9 .primary-nav li {
    display: block;
    margin-left: 0; }
  .site-header.style9.is-sticky .primary-nav li a {
    line-height: 40px; }
  .site-header.style10 .header-middle .site-logo {
    padding: 30px 0;
    height: auto; }
    .site-header.style10 .header-middle .site-logo img {
      height: 40px; }
  .site-header.style10 .header-middle .more-vert {
    text-align: right; }
    .site-header.style10 .header-middle .more-vert > a {
      color: #848484; }
  .site-header.style10 [class^="col-"]:first-child .info-cell {
    margin-left: 40px; }
  .site-header.style10 [class^="col-"]:last-child .info-cell {
    margin-left: 10px; }
  .site-header.style10 .cart-button {
    padding-left: 0; }
  .site-header.style10 .nav-hamburger {
    height: 50px; }
  .site-header.style10 .primary-nav {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .site-header.style10 .menu-list {
    text-align: left; }
    .site-header.style10 .menu-list li a {
      color: #848484; }
      .site-header.style10 .menu-list li a:hover {
        background-color: #0D52FF;
        color: #fff; }
  .site-header.style10.is-sticky .header-middle .site-logo {
    padding: 0;
    height: auto; }
  .site-header.style10.is-sticky .header-bottom {
    margin-top: 0; }
    .site-header.style10.is-sticky .header-bottom .site-logo {
      height: 50px; }
  .site-header.style10.is-sticky .primary-nav {
    display: none; }
  .site-header.style10.is-sticky .menu-list > li > a {
    color: #848484;
    line-height: 40px; }
  .site-header.style11 .more-vert > a {
    color: #848484;
    font-size: 18px; }
    .site-header.style11 .more-vert > a:hover {
      color: #fff; }
  .site-header.style11 .more-vert-menu li a {
    color: #848484; }
    .site-header.style11 .more-vert-menu li a:hover {
      color: #444; }
  .site-header.style11 .header-middle {
    padding: 10px 0; }
    .site-header.style11 .header-middle .site-logo {
      height: auto;
      padding: 0; }
  .site-header.style11 .menu-list {
    text-align: left; }
    .site-header.style11 .menu-list li a {
      color: #848484; }
      .site-header.style11 .menu-list li a:hover {
        background-color: #0D52FF;
        color: #fff; }
  .site-header.style11 .nav-hamburger {
    height: 50px; }
  .site-header.style11.is-sticky .header-bottom {
    margin-top: 0; }
  .site-header.style11.is-sticky .primary-nav {
    display: none; }
  .site-header.style11.is-sticky .menu-list > li > a {
    color: #848484;
    line-height: 40px; }
  .site-header.style12 {
    padding-top: 0; }
    .site-header.style12 .header-top {
      margin-bottom: 0;
      padding: 10px 0; }
    .site-header.style12 .header-right {
      padding-right: 0; }
    .site-header.style12 .social-links {
      text-align: center; }
      .site-header.style12 .social-links li a {
        margin-right: 8px; }
    .site-header.style12 .header-bottom .bottom-inner {
      background-color: transparent;
      padding-bottom: 0;
      padding-top: 0; }
    .site-header.style12 .header-bottom .header-right {
      padding-right: 0; }
    .site-header.style12 .header-bottom .col-xs-12 {
      min-height: auto; }
    .site-header.style12 .site-logo {
      height: auto;
      line-height: 50px; }
      .site-header.style12 .site-logo img {
        height: 35px; }
    .site-header.style12 .top-search > a {
      height: 50px;
      line-height: 50px; }
    .site-header.style12 .nav-hamburger {
      height: 50px; }
      .site-header.style12 .nav-hamburger span, .site-header.style12 .nav-hamburger span::before, .site-header.style12 .nav-hamburger span::after {
        background-color: #a2a2a2; }
      .site-header.style12 .nav-hamburger.active span {
        background-color: transparent; }
    .site-header.style12 .primary-nav {
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
      float: none; }
    .site-header.style12 .menu-list > li {
      display: block; }
      .site-header.style12 .menu-list > li > a {
        line-height: 40px; }
    .site-header.style12.is-sticky {
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
      .site-header.style12.is-sticky .header-top {
        display: block; }
      .site-header.style12.is-sticky .header-bottom .container {
        padding-bottom: 0;
        padding-top: 0; }
  .site-header.style13 .header-bottom {
    background-color: #0D52FF;
    -webkit-transform: translate(0px);
    -moz-transform: translate(0px);
    -ms-transform: translate(0px);
    -o-transform: translate(0px);
    transform: translate(0px); }
    .site-header.style13 .header-bottom.whitebg {
      background-color: #fff; }
    .site-header.style13 .header-bottom .bottom-inner {
      background-color: transparent;
      padding: 0;
      box-shadow: none; }
  .site-header.style13 .site-logo {
    line-height: 60px; }
    .site-header.style13 .site-logo img {
      height: 35px; }
  .site-header.style13 .header-right .devider {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .site-header.style13 .nav-hamburger {
    height: 65px; }
  .site-header.style13.is-sticky {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
    .site-header.style13.is-sticky .nav-hamburger span, .site-header.style13.is-sticky .nav-hamburger span::before, .site-header.style13.is-sticky .nav-hamburger span::after {
      background-color: #fff; }
    .site-header.style13.is-sticky .nav-hamburger.active span {
      background-color: transparent; }
  .site-header.style14 .menu-list > li > a {
    line-height: 35px;
    height: auto; }
  .site-header.style14 .nav-hamburger span,
  .site-header.style14 .nav-hamburger span::before,
  .site-header.style14 .nav-hamburger span::after {
    background-color: #848484; }
  .site-header.style14 .nav-hamburger.active span {
    background-color: transparent; }
  .site-header.style14.is-sticky .site-logo img {
    height: 30px; }
  .site-header.style14.is-sticky .menu-list > li > a {
    line-height: 35px;
    height: auto; }

  .style15 .menu-list {
    padding: 10px 0; }
    .style15 .menu-list > li > a {
      font-size: 12px;
      line-height: 30px; }

  .section-title.style4 h2 {
    font-size: 22px; }

  .mini-cart .product-name,
  .mini-cart .product-thumb,
  .mini-cart .product-count,
  .mini-cart .product-price {
    padding-right: 10px; }
  .mini-cart .product-remove {
    padding-right: 20px; }

  .menu-list {
    padding-top: 10px;
    padding-bottom: 10px; }
    .menu-list > li {
      display: block !important; }
      .menu-list > li > a {
        color: #848484 !important;
        line-height: 40px; }
    .menu-list .sub-menu {
      display: none;
      position: relative;
      opacity: 1;
      visibility: visible;
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      transition: none; }
      .menu-list .sub-menu .sub-menu {
        left: 0;
        padding-left: 20px;
        top: 0; }

  .header-megazine .primary-nav {
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 100%;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none; }
  .header-megazine .menu-list {
    margin-left: auto;
    margin-right: auto;
    width: 750px; }
  .header-megazine .search-box {
    background-color: #fff;
    display: block;
    z-index: 10;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .header-megazine .search-box input {
      width: 100%; }
  .header-megazine .nav-hamburger span, .header-megazine .nav-hamburger span::before, .header-megazine .nav-hamburger span::after {
    background-color: #848484; }
  .header-megazine .nav-hamburger.active span {
    background-color: transparent; }
  .header-megazine.is-sticky .primary-nav {
    margin-left: 0; }

  .header-magazine-2 {
    z-index: 12; }
    .header-magazine-2 .header-top .row {
      display: block; }
    .header-magazine-2 .social-links {
      margin-bottom: 15px;
      text-align: center; }
    .header-magazine-2 .primary-nav {
      border-bottom: 0 none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      position: absolute;
      text-align: left;
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      transition: none; }
    .header-magazine-2 .nav-hamburger span, .header-magazine-2 .nav-hamburger span::before, .header-magazine-2 .nav-hamburger span::after {
      background-color: #848484; }
    .header-magazine-2 .nav-hamburger.active span {
      background-color: transparent; }

  .header-interior .site-logo {
    line-height: 60px; }
  .header-interior .header-right {
    padding-right: 0; }
    .header-interior .header-right .top-cart > a,
    .header-interior .header-right .top-search > a {
      height: 60px;
      line-height: 60px; }
  .header-interior .menu-list > li > a {
    line-height: 40px; }

  .header-seo .primary-nav {
    float: none;
    margin-left: 0;
    position: absolute;
    top: 100%;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none; }
  .header-seo .menu-list > li > a {
    color: #fff !important;
    line-height: 40px; }
  .header-seo .is-sticky .primary-nav {
    margin-left: 0; }

  .header-wedding {
    position: fixed !important; }
    .header-wedding .header-top {
      display: none; }
    .header-wedding .site-logo {
      display: block; }
    .header-wedding .primary-nav {
      float: none;
      text-align: left; }

  .primary-bg .nav-hamburger span, .primary-bg .nav-hamburger span::before, .primary-bg .nav-hamburger span::after {
    background-color: #fff; }
  .primary-bg .nav-hamburger.active span {
    background-color: transparent; }

  .welcome-notice,
  .seo-top-nav.text-right {
    text-align: center; }

  .finance-slide h2 {
    font-size: 18px;
    margin-bottom: 10px; }
  .finance-slide .mt-50 {
    margin-top: 10px; }
  .finance-slide .ml-10 {
    margin-left: 5px; }

  .header-travel .search-box {
    padding: 0; }
  .header-travel > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
    .header-travel > .container > * {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1; }
  .header-travel .nav-hamburger {
    max-width: 30px; }

  .is-sticky .header-travel .search-box {
    padding-top: 0; }

  .header-sp .menu-list > li > a, .header-sp.is-sticky .menu-list > li > a {
    height: 40px;
    line-height: 40px; }

  .header-shop-3 .header-action-btns > li {
    height: auto; }
  .header-shop-3 .seo-top-nav li a {
    font-size: 12px;
    line-height: 30px;
    padding: 0 8px; }
  .header-shop-3 .search-box {
    border: 0;
    border-radius: 0; }
    .header-shop-3 .search-box input {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      height: 43px;
      margin: 0;
      padding-left: 20px; }
  .header-shop-3 .nice-select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    height: 43px;
    line-height: 43px;
    margin-bottom: 10px;
    padding-left: 20px; }

  /*==============================================
      Hover style
  ===============================================*/
  /*-------------- nav-hover-2 -------------*/
  .nav-hover-2 > li > a::before, .nav-hover-2 > li > a::after {
    content: none; }

  /*==============================================
      Sliders
  ===============================================*/
  .bullet-one .owl-dots {
    display: block !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px; }
  .bullet-one .owl-nav {
    display: none; }

  /*----------------------------------------
      slider 1
  ------------------------------------------*/
  .slider1 .slide-content h1 {
    font-size: 30px; }
  .slider1 .slide-content p {
    font-size: 18px; }

  .cta-wave .cta-inner {
    background-color: #0D52FF;
    padding: 41px 0 50px;
    position: static; }

  /*----------------------------------------
      slider 2
  ------------------------------------------*/
  .slider2 .slide-content h1 {
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 30px; }
  .slider2 .slide-content h2 {
    font-size: 36px; }
  .slider2 .slide-content p {
    font-size: 14px;
    line-height: 26px; }
  .slider2 .slide-content a {
    float: left;
    font-size: 13px;
    height: 45px;
    line-height: 45px;
    padding: 0 10px;
    width: -webkit-calc(50% - 5px);
    width: calc(50% - 5px); }
    .slider2 .slide-content a:last-child {
      margin-left: 10px; }

  .banner3 .slide-content h2 {
    font-size: 14px; }
  .banner3 .slide-content h1 {
    font-size: 30px;
    line-height: 40px; }
  .banner3 .slide-content p {
    font-size: 16px;
    line-height: 26px; }

  .restaurant-banner .banner-content h3 {
    font-size: 18px; }
  .restaurant-banner .banner-content h2 {
    font-size: 20px;
    margin: 14px 0 20px; }
    .restaurant-banner .banner-content h2 b {
      padding: 3px 5px 2px 4px; }
  .restaurant-banner .banner-content h4 {
    font-size: 16px; }

  .resume-banner .slide-content h3 {
    font-size: 20px;
    margin-bottom: 10px; }
  .resume-banner .slide-content h2 {
    font-size: 35px;
    line-height: 1.4; }
  .resume-banner .slide-content p {
    font-size: 20px;
    margin-top: 9px; }

  .slider5 .slide-content img {
    width: 100%; }

  .app-content h2 {
    font-size: 24px; }
  .app-content p {
    font-size: 14px; }
  .app-content .btn-large {
    height: 40px;
    line-height: 39px;
    padding: 0 31px; }

  .app-download-section .btn-large {
    padding: 0 22px; }

  .btn-large, .btn-flat {
    font-size: 12px;
    height: 45px;
    line-height: 45px; }

  .interior-slide .btn-large {
    padding: 0 29px; }

  .tm-4-title {
    position: static; }

  .tm-carousel-8 .slick-list {
    padding: 20px 30px 0 !important; }
  .tm-carousel-8 .author-info {
    display: block; }
  .tm-carousel-8 .thumb {
    display: block;
    margin-bottom: 10px; }
  .tm-carousel-8 .slick-dots {
    left: 0;
    right: 0;
    text-align: center;
    top: 100%;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
    .tm-carousel-8 .slick-dots li {
      display: inline-block;
      margin: 0 3px; }
    .tm-carousel-8 .slick-dots button {
      margin-left: 0; }

  .newsletter-1 {
    padding-left: 10px; }
    .newsletter-1 .nl-1-input {
      padding: 0; }

  .template-layer {
    height: 425px; }

  .text-block-1 h2 {
    font-size: 25px; }

  .hero-slider-1 .slide-1 {
    padding-top: 40px; }
    .hero-slider-1 .slide-1 img {
      max-height: 40vh; }
    .hero-slider-1 .slide-1 .btn {
      margin-top: 20px; }
    .hero-slider-1 .slide-1 .social-links {
      margin-top: 40px; }
  .hero-slider-1 h1 {
    font-size: 22px; }
  .hero-slider-1 .slide-3 .slide-content {
    margin: 0;
    padding: 50px 20px; }

  .hero-slider-2 .parallax-content h1 {
    font-size: 24px; }

  .hero-slider-3 .right-half,
  .hero-slider-3 .left-half {
    width: 100%; }
  .hero-slider-3 h1 {
    font-size: 32px; }
  .hero-slider-3 h3 {
    font-size: 20px; }
  .hero-slider-3 .slick-dots {
    bottom: 20px;
    position: absolute;
    right: 50%;
    top: auto;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%); }
    .hero-slider-3 .slick-dots li {
      display: inline-block;
      margin: 0 2.5px; }

  .hero-slider-4-for h1 {
    font-size: 30px; }

  .hero-slider-4-nav .slick-slide {
    border-width: 2px;
    height: 30px; }

  .hero-slider-5 .slick-dots {
    bottom: 20px;
    position: absolute;
    right: 50%;
    top: auto;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%); }
    .hero-slider-5 .slick-dots li {
      display: inline-block;
      margin: 0 2.5px; }

  .hero-slider-6-nav .slick-slide {
    height: 30px;
    margin: 0 10px; }

  .hero-slider-7 .slide-content h1 {
    font-size: 28px;
    line-height: 1.5; }
  .hero-slider-7 .slide-content::before, .hero-slider-7 .slide-content::after {
    content: none; }

  .hero-slider-8 .slide-1 img {
    margin-bottom: 20px;
    max-width: 60%; }
  .hero-slider-8 h3 {
    font-size: 20px;
    line-height: 1.5; }

  .hero-slider-10-for .slide-content h1 {
    font-size: 25px; }

  .hero-slider-10-nav {
    border-left: 0 none;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    bottom: 0;
    height: 70px;
    right: 0;
    top: auto;
    width: 100%; }
    .hero-slider-10-nav .slick-list {
      margin-left: 0;
      margin-top: -15px;
      padding-left: 0;
      padding-top: 30px; }
    .hero-slider-10-nav .slick-slide {
      margin: 0 5px;
      height: 30px; }
      .hero-slider-10-nav .slick-slide::before {
        height: 3px;
        left: 0;
        top: -22px;
        width: 0; }
    .hero-slider-10-nav .slick-current::before {
      width: 100%; }

  .hero-slider-11-for img {
    margin-bottom: 30px;
    max-width: 150px; }
  .hero-slider-11-for h1 {
    font-size: 19px;
    line-height: 1.2;
    margin-bottom: 20px; }

  .hero-slider-11-nav {
    height: 60px; }
    .hero-slider-11-nav .slick-slide {
      height: 30px;
      width: 30px !important; }
      .hero-slider-11-nav .slick-slide::before {
        border: 2px solid #797878; }

  .hero-slider-12 .slide-content h1 {
    font-size: 28px;
    line-height: 1.4; }

  .hero-slider-13 .slide-content {
    background-color: transparent;
    border: 0 none;
    margin-left: 15px;
    margin-right: 15px;
    padding: 0; }
    .hero-slider-13 .slide-content h1 {
      font-size: 22px;
      margin-bottom: 10px; }
    .hero-slider-13 .slide-content .btn {
      margin-top: 0; }

  .hero-slider-15 .slide-content h1 {
    font-size: 22px; }

  .event-parallax .date {
    font-size: 22px;
    margin-bottom: 10px; }
  .event-parallax h2 {
    font-size: 24px;
    margin-bottom: 15px; }
  .event-parallax p {
    font-size: 14px;
    margin-bottom: 25px; }
  .event-parallax .btn-large {
    height: 45px;
    line-height: 45px;
    padding: 0 40px; }

  .event-schedule .entry-post {
    margin-bottom: 30px;
    width: 100%; }
    .event-schedule .entry-post:nth-child(2n) {
      margin: 0; }
  .event-schedule .es-devider {
    display: none; }

  .filter-with-actions {
    border-bottom: 0 none; }

  .matex-sorting.style-4 li a {
    padding: 0 20px 10px; }

  .portfolio-2-banner {
    background-position: right bottom;
    background-size: 60% auto; }

  .portfolio2-parallax h2 {
    font-size: 40px;
    line-height: 1.11; }

  .portfolio.style-11 figcaption {
    padding: 20px 30px; }

  .tm-item.style2, .tm-item.style4 {
    padding: 30px 25px; }
  .tm-item.style7 .thumb {
    display: block;
    min-width: auto;
    padding: 0 0 30px; }
    .tm-item.style7 .thumb img {
      margin: 0 auto;
      width: auto; }
  .tm-item.style8 {
    padding: 30px 30px 40px; }
    .tm-item.style8 blockquote {
      font-size: 14px;
      line-height: 24px; }

  .bakery-banner .slide-content {
    margin-top: 0; }
    .bakery-banner .slide-content h3 {
      font-size: 18px;
      line-height: 1.5; }
    .bakery-banner .slide-content img {
      height: auto;
      width: 70%; }

  .creative-banner .slide-content h2,
  .business-banner .slide-content h2 {
    font-size: 39px;
    line-height: 1.2; }

  .realestate-slide-content {
    padding: 35px 40px 40px;
    width: 90%; }
    .realestate-slide-content::after {
      border-width: 43px 30px; }
    .realestate-slide-content h2 {
      font-size: 22px;
      line-height: 1.5; }
    .realestate-slide-content .price {
      font-size: 20px; }
      .realestate-slide-content .price small {
        font-size: 15px; }
    .realestate-slide-content .btn-large {
      height: 45px;
      line-height: 45px;
      padding: 0 30px; }

  .seo-get-a-quote .btn-large {
    padding: 0 28px; }

  .section-title.style6 h2 {
    font-size: 26px;
    margin-top: 10px; }

  .re-block-wrapper {
    background: none; }

  .re-block {
    padding: 0; }
    .re-block h2 {
      font-size: 24px;
      margin-bottom: 13px; }

  .business-3-banner h2 {
    font-size: 30px; }
  .business-3-banner p {
    font-size: 14px;
    line-height: 24px;
    margin: 13px auto 0;
    width: 100%; }
  .business-3-banner .btn-primary {
    margin-top: 20px; }

  .gym-content img {
    width: 100%; }
  .gym-content p {
    margin-bottom: 0; }

  .medical-banner-content {
    display: block;
    height: auto !important;
    margin-top: 65px; }

  .medical-content h2 {
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 20px; }
  .medical-content p {
    font-size: 14px;
    line-height: 26px;
    margin: 25px 0 0; }
  .medical-content .btn-large {
    padding: 0 28px; }

  .appointment-form {
    background-color: #fff;
    margin: 50px 0;
    padding: 30px; }
    .appointment-form::before, .appointment-form::after {
      content: none; }
    .appointment-form .bg-shape,
    .appointment-form .leaned-layer {
      display: none; }

  .mdcl-patient--forum .leaned-layer, .mdcl-patient--forum .bevel {
    display: none; }

  .mdcl-patient-forum {
    padding: 30px 30px 40px; }
    .mdcl-patient-forum h2 {
      font-size: 20px;
      line-height: 35px;
      margin-bottom: 20px; }
    .mdcl-patient-forum p {
      font-size: 14px;
      line-height: 26px; }
    .mdcl-patient-forum .btn-large {
      border-radius: 2px;
      height: 40px;
      line-height: 40px;
      padding: 0 15px; }

  .mag-slide-content h2 {
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 25px; }
  .mag-slide-content .sep {
    margin-bottom: 25px; }
  .mag-slide-content p {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; }

  .mag-slider-1 .owl-dots {
    bottom: 30px; }

  .mag-featured-top .content {
    padding: 30px 0; }

  .accordion-mag-post {
    float: none;
    height: auto;
    width: auto; }
    .accordion-mag-post .thumb {
      position: relative;
      width: auto; }
    .accordion-mag-post figcaption {
      bottom: 20px;
      left: 20px;
      right: 0;
      width: auto; }
    .accordion-mag-post:hover {
      width: auto; }

  .widget-area + * {
    margin-top: 50px; }
  .widget-area + .col-lg-6 {
    margin-bottom: 50px;
    margin-top: 50px; }

  .widget-post-list li.flex,
  .widget-post-list .col-xs-12 {
    display: block; }

  .widget-post-list figure {
    margin-bottom: 15px; }
    .widget-post-list figure.col-xs-12 {
      margin-bottom: 15px; }
    .widget-post-list figure img {
      max-width: 100%;
      margin-right: auto; }
  .widget-post-list .thumb {
    min-width: auto;
    width: auto; }

  .widget-post-list .text {
    padding-left: 0; }

  .slider-grid-post.col-xs-12 .content {
    left: 40px;
    right: 40px; }
  .slider-grid-post.col-xs-12 .title {
    padding: 25px 0 15px; }
  .slider-grid-post.col-xs-12 .post-tag {
    font-size: 12px;
    line-height: 25px;
    padding: 0 25px; }
  .slider-grid-post.col-xs-12 h3 {
    font-size: 14px; }
  .slider-grid-post.col-xs-12 p {
    font-size: 16px;
    line-height: 25px;
    margin: 20px 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .slider-grid-post.col-xs-12 .meta {
    font-size: 13px; }

  .post-grid-slider .row {
    overflow: auto;
    white-space: nowrap; }
    .post-grid-slider .row [class^="col-"] {
      display: inline-block;
      float: none;
      width: auto; }

  .gpc-control .prev-arrow {
    border-width: 17px 17px 17px 10px; }
  .gpc-control .next-arrow {
    border-width: 17px 10px 17px 17px; }

  .interior-slide h2 {
    font-size: 60px; }
  .interior-slide h3 {
    margin: 5px 0 30px; }

  .shop-slider-3 .caption {
    bottom: 20px;
    left: 20px;
    right: 20px; }
    .shop-slider-3 .caption h2 {
      font-size: 18px; }
    .shop-slider-3 .caption a {
      font-size: 12px; }

  .shop-3-left-wdget {
    margin-bottom: 30px; }
    .shop-3-left-wdget ul {
      max-height: none;
      overflow: visible !important; }

  .countdown-product {
    margin-bottom: 30px; }
    .countdown-product img {
      width: 100%; }

  .shop3-right-banner img,
  .product--cat img {
    width: 100%; }

  .charity-slide-parallax h2 {
    font-size: 25px;
    padding-bottom: 30px; }
  .charity-slide-parallax .text-shade {
    font-size: 30px;
    top: 60px; }
  .charity-slide-parallax p {
    font-size: 14px;
    line-height: 26px; }

  .sp-slide-parallax h3 {
    font-size: 22px;
    line-height: 34px; }
  .sp-slide-parallax .btn-large {
    padding: 0 30px; }

  .section-title.style8 .shade {
    font-size: 45px; }
  .section-title.style8 .title-color {
    font-size: 20px; }

  .disable-flex-xs {
    display: block; }

  .sp-feature-1 {
    margin-bottom: 30px; }
    .sp-feature-1::before, .sp-feature-1::after {
      content: none; }
    .sp-feature-1 .icon {
      font-size: 25px;
      height: 70px;
      line-height: 70px;
      min-width: 70px;
      width: 70px; }
    .sp-feature-1 .desc {
      margin: 0 0 0 20px;
      top: 6px; }
    .sp-feature-1 h3 {
      font-size: 16px;
      margin-bottom: 5px; }

  .sp-ability {
    position: relative;
    margin-bottom: 20px;
    top: 0; }
    .sp-ability h3 {
      font-size: 18px;
      margin-bottom: 15px; }

  .iphone-9 {
    margin-top: 0; }

  .sp-carousel-2 {
    padding: 0 35px; }
    .sp-carousel-2 .service {
      min-height: 140px; }
      .sp-carousel-2 .service .icon {
        font-size: 18px;
        margin-bottom: 0; }
      .sp-carousel-2 .service h3 {
        font-size: 12px;
        margin-bottom: 5px; }
      .sp-carousel-2 .service p {
        font-size: 8px;
        line-height: 15px; }
    .sp-carousel-2 .owl-prev,
    .sp-carousel-2 .owl-next {
      font-size: 40px; }
    .sp-carousel-2 .owl-prev {
      left: 30px; }
    .sp-carousel-2 .owl-next {
      right: 30px; }

  .shop-slider-2 .item, .shop-slider-2 .item2 {
    padding-bottom: 50px;
    padding-top: 50px; }
  .shop-slider-2 .row {
    height: auto !important; }
  .shop-slider-2 .slide-content h2 {
    font-size: 25px;
    line-height: 36px; }
  .shop-slider-2 .slide-content .img1, .shop-slider-2 .slide-content .img2 {
    margin-right: auto; }

  .banner-sports .left-bg,
  .banner-sports .right-bg {
    height: 200px; }
  .banner-sports h2 {
    font-size: 30px;
    line-height: 1.7; }

  .donate-section {
    padding: 20px; }
    .donate-section .donate-inner {
      padding: 25px; }

  .sports-sponsors li {
    border-bottom: 1px solid #eee;
    border-left: 0 none !important;
    height: 180px;
    line-height: 160px; }

  .widget-toggle-btn {
    padding: 0; }

  .process-section::before {
    content: none; }

  .process-1 {
    padding: 50px 0; }
    .process-1::before {
      content: none; }

  .triangle-shape .triangle-title {
    top: 10px; }
  .triangle-shape .shape-2 {
    height: 230px;
    width: 230px; }

  .half-bg .half-bg-container {
    width: 100%; }

  /*==============================================
      Team
  ===============================================*/
  .team-member.style4 .member-info {
    padding: 30px; }
  .team-member.style6 figcaption {
    padding: 60px 35px; }
  .team-member.style6 h4 {
    font-size: 18px;
    margin-bottom: 3px; }
  .team-member.style6 span {
    font-size: 16px; }
  .team-member.style6 .mail {
    bottom: 70px;
    font-size: 14px; }
  .team-member.style7 {
    padding: 30px; }
    .team-member.style7::before {
      content: none; }
    .team-member.style7 .thumb {
      margin-bottom: 15px; }
  .team-member.style9 figcaption {
    padding: 0 15px; }
  .team-member.style9 span {
    margin: 3px 0 14px; }
  .team-member.style9 .btn-md {
    font-size: 12px;
    margin-top: 0; }
  .team-member.style9:hover .border {
    display: none; }
  .team-member.style-11 {
    display: block; }
    .team-member.style-11 .short-desc {
      margin: 0;
      padding: 30px; }
      .team-member.style-11 .short-desc::before {
        content: none; }

  .team-single-desc .bio h2 {
    font-size: 24px; }

  .team-single-wrapper .mfp-close {
    margin-right: -37px;
    right: 50%;
    top: 30px; }

  .food-carousel .item {
    border-width: 8px; }

  .dish--content-1,
  .dish--content-2,
  .dish--content-3 {
    padding: 30px 30px 50px; }
    .dish--content-1 .item,
    .dish--content-2 .item,
    .dish--content-3 .item {
      height: 300px; }

  .dish--carousel-1,
  .dish--carousel-2,
  .dish--carousel-3 {
    padding: 30px; }

  /*==============================================
      Portfolio
  ===============================================*/
  .masonry-filter li {
    display: block;
    margin-left: 0; }
  .masonry-filter.style1 {
    display: block; }
    .masonry-filter.style1 li {
      display: block;
      margin: 0; }
      .masonry-filter.style1 li a {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-top: 0; }
      .masonry-filter.style1 li:first-child a {
        border-radius: 5px 5px 0 0;
        border-top: 1px solid rgba(255, 255, 255, 0.2); }
      .masonry-filter.style1 li:last-child a {
        border-radius: 0 0 5px 5px; }
    .masonry-filter.style1.dark li:first-child a {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .masonry-filter.style1.dark li a {
      border-left: 1px solid rgba(0, 0, 0, 0.1); }
  .masonry-filter.style-2 li {
    display: block; }
  .masonry-filter.style-4 li a {
    padding: 0 10px 10px; }
  .masonry-filter.style-6 li:first-child a {
    border-radius: 0; }
  .masonry-filter.style-6 li a {
    border-bottom: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
  .masonry-filter.style-6 li:last-child a {
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

  .matex-sorting li {
    display: inline-block; }

  .portfolio.type-8 .image-popup {
    top: 25%;
    font-size: 20px;
    height: 50px;
    line-height: 49px;
    width: 50px; }
  .portfolio.type-8 .hover- {
    bottom: 0;
    left: 15px;
    right: 15px; }
  .portfolio.type-8:hover .hover- {
    bottom: 15px; }
  .portfolio.style-11 {
    overflow: visible; }
    .portfolio.style-11 figcaption {
      position: static;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none; }
  .portfolio.style-12 {
    overflow: visible; }
    .portfolio.style-12 .caption {
      max-width: none;
      padding: 30px;
      position: static;
      transform: none;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
      .portfolio.style-12 .caption i, .portfolio.style-12 .caption h3, .portfolio.style-12 .caption p, .portfolio.style-12 .caption .btn-large {
        opacity: 1;
        left: 0; }
      .portfolio.style-12 .caption i {
        font-size: 30px;
        margin-bottom: 10px; }
      .portfolio.style-12 .caption h3, .portfolio.style-12 .caption p {
        margin-bottom: 10px; }
      .portfolio.style-12 .caption .btn-large {
        font-size: 13px;
        height: 45px;
        line-height: 45px;
        padding: 0 40px; }
    .portfolio.style-12:hover .caption {
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1); }
  .portfolio.style-13 {
    padding: 50px 0; }

  /*==============================================
      CTA
  ===============================================*/
  .cta .table-cell {
    display: block;
    text-align: center; }
  .cta h2 {
    font-size: 24px;
    line-height: 35px; }
  .cta a + a {
    margin-left: 0; }
  .cta.cta-one h2, .cta.cta-two h2 {
    margin-bottom: 20px; }
  .cta.cta-two a {
    margin-top: 35px; }

  .cta-bakery .flex {
    display: table;
    width: 100%; }
  .cta-bakery .flex-grow {
    display: table-cell;
    padding: 20px 0;
    vertical-align: middle; }
    .cta-bakery .flex-grow > * {
      line-height: 1.6; }
  .cta-bakery h2 {
    font-size: 14px; }
  .cta-bakery .cta-button {
    font-size: 15px;
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px; }

  .cta-event .cta-logo {
    padding: 0;
    margin-bottom: 20px; }
    .cta-event .cta-logo::after {
      content: none; }
    .cta-event .cta-logo img {
      width: 90px; }
  .cta-event p {
    font-size: 14px;
    margin-bottom: 20px; }
  .cta-event .btn-large {
    font-size: 13px;
    height: 45px;
    line-height: 47px;
    padding: 0 40px; }

  .travel-overlap {
    margin-top: 0;
    padding-top: 150px; }

  /*==============================================
      Service
  ===============================================*/
  .service.style7 {
    padding: 40px 30px; }
    .service.style7 .icon {
      font-size: 50px; }
    .service.style7 h4 {
      font-size: 18px; }
  .service.style9.shadow {
    padding: 30px; }
  .service.style13 {
    padding: 90px 40px 60px; }
  .service.style16:not(:last-child) {
    margin-bottom: 50px; }
  .service.style-24 {
    padding-bottom: 40px;
    padding-top: 80px; }

  /*==============================================
      Products
  ===============================================*/
  .product.type-4 .meta h4 {
    font-size: 14px; }
  .product.type-4 .meta::before {
    border-width: 85px 190px; }
  .product.type-4 .meta::after {
    border-width: 50px 100px; }
  .product.type-5 .desc {
    padding: 36px 30px 40px; }

  .product-cat-style-2 figcaption {
    bottom: 30px;
    left: 30px;
    right: 30px; }

  .product-cat-style-3 .desc {
    padding-left: 20px;
    padding-right: 20px; }

  .deals-countdown .time-block {
    margin-right: 15px; }
  .deals-countdown .time {
    font-size: 19px;
    height: auto;
    line-height: inherit;
    padding: 15px 12px;
    width: auto; }

  .product--summery {
    padding: 50px; }
    .product--summery .mb-25 {
      margin-bottom: 15px; }
    .product--summery .mb-40,
    .product--summery .mb-50 {
      margin-bottom: 25px; }

  .product-specs li span {
    padding: 10px 15px 9px; }

  .card-panel {
    padding: 40px; }

  /*==============================================
      Blog
  ===============================================*/
  .entry-post.type-1 .entry-excerpt {
    padding: 38px 20px 30px; }
  .entry-post.type-1 .entry-meta {
    padding: 0 15px 43px; }
  .entry-post.style3 {
    display: block; }
    .entry-post.style3 img {
      width: 100%; }
    .entry-post.style3 .entry-content {
      padding: 30px; }
    .entry-post.style3 h4 {
      margin-bottom: 5px; }
    .entry-post.style3 .entry-meta {
      margin-bottom: 10px; }
    .entry-post.style3 .more {
      margin-top: 10px; }
    .entry-post.style3::before, .entry-post.style3::after {
      content: none; }
    .entry-post.style3 .shape-1 {
      display: none; }
  .entry-post.style4 .entry-content {
    padding: 55px 30px; }
  .entry-post.style4 h2 {
    font-size: 20px;
    margin-bottom: 20px; }
  .entry-post.style4 p {
    padding: 0;
    margin: 0; }
  .entry-post.style5 .entry-content {
    padding: 32px 20px 52px; }
  .entry-post.style5 h2 {
    font-size: 20px; }

  .event-schedule .entry-post .entry-thumb::before {
    content: none; }

  .finance-slide .btn-large {
    padding: 0 30px; }

  .blog-post-1 h3 {
    font-size: 22px; }
  .blog-post-1 .post-desc {
    margin-left: 0; }
  .blog-post-1 .embed-responsive {
    margin-bottom: 30px; }
  .blog-post-1.sticky::before {
    content: none; }
  .blog-post-1.sticky .post-desc {
    max-width: none;
    position: static;
    padding: 30px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
  .blog-post-1.sticky h2 {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 30px; }

  .blog-post-2 .post-desc {
    padding: 44px 30px 45px; }
  .blog-post-2 h3 {
    font-size: 18px;
    padding-bottom: 15px; }
  .blog-post-2.sticky .meta {
    font-size: 14px; }
  .blog-post-2.sticky .post-desc {
    max-width: none;
    padding: 50px 40px;
    position: static; }
  .blog-post-2.sticky h2 {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 30px;
    padding-bottom: 20px; }
  .blog-post-2.featured .post-desc {
    left: 20px;
    right: 20px; }
    .blog-post-2.featured .post-desc .meta {
      font-size: 16px; }
    .blog-post-2.featured .post-desc h2 {
      font-size: 18px;
      line-height: 28px;
      padding-bottom: 20px; }

  .blog-load-more-2 {
    font-size: 18px; }

  .blog-load-more-3 {
    line-height: 62px; }

  .blog-post-3 .post-desc {
    padding: 40px 0; }
  .blog-post-3 h3 {
    font-size: 20px; }

  .pagination-1 li {
    margin-left: -4px; }
    .pagination-1 li a {
      font-size: 12px;
      padding: 1px 11px; }

  .portfolio-meta li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }

  /*==============================================
      Footer
  ===============================================*/
  .footer-nav ul {
    text-align: left; }
    .footer-nav ul a {
      padding-left: 0; }

  /*==============================================
      Tabs
  ===============================================*/
  .tab-1 .tab-content {
    padding: 15px; }
  .tab-1 .tab-pane h2 {
    font-size: 26px; }

  .resume-contact {
    background-color: #fafafa; }
    .resume-contact [class^="bg-"] {
      display: none; }

  .tab-3 .nav-list li a {
    padding: 30px 40px; }
  .tab-3 .tab-content {
    padding: 35px 30px 45px; }

  .tab-4 .nav-list li a {
    padding: 20px; }
  .tab-4 .nav-list li .icon {
    margin-right: 20px; }
  .tab-4 .tab-pane .content {
    padding: 45px 30px; }

  .tab-6 .nav-list li a {
    padding: 10px 19px; }

  .tab-7 .tab-nav {
    margin: 80px 0 50px; }
    .tab-7 .tab-nav li {
      margin: 0 9px; }
      .tab-7 .tab-nav li a {
        font-size: 24px; }

  .tab-8 .tab-nav li a {
    padding: 0 15px 25px; }

  .tab-9 .tab-nav {
    margin-left: 0; }
    .tab-9 .tab-nav li {
      margin-bottom: 15px; }
    .tab-9 .tab-nav a {
      text-align: left; }
    .tab-9 .tab-nav .active .lean {
      left: 0; }

  .horiz-icon-tab-nav li + li {
    margin-left: 0; }
  .horiz-icon-tab-nav li a {
    display: block;
    font-size: 14px;
    height: auto;
    padding: 5px 13px 8px 12px;
    width: auto; }
  .horiz-icon-tab-nav li i {
    display: none; }

  /*==============================================
      testimonials
  ===============================================*/
  .tm-item-12 {
    display: block; }
    .tm-item-12 .thumb {
      min-width: auto; }
    .tm-item-12 .text {
      margin: 20px; }

  /*==============================================
      Page
  ===============================================*/
  .page-submenu > li > a {
    height: auto;
    line-height: inherit;
    padding: 5px 20px;
    text-transform: capitalize; }

  .section-login-register .half-bg-container {
    opacity: 0.9;
    height: 100% !important;
    width: 100%; }
  .section-login-register .masonry-filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 40px; }
  .section-login-register .page-login-wrapper {
    padding-bottom: 50px;
    padding-top: 50px; }

  /*==============================================
      Newsletter
  ===============================================*/
  .nl-2-inner {
    display: block; }
    .nl-2-inner .nl-2-input {
      border-radius: 50px;
      height: 60px;
      margin: 0;
      padding: 0;
      text-indent: 30px; }
    .nl-2-inner .nl-2-submit {
      border-radius: 50px;
      margin-top: 10px;
      padding: 10px 75px;
      width: 100%; }

  .newsletter-3 .nl-inner {
    display: block; }
  .newsletter-3 .nl-input {
    height: 48px;
    padding: 0;
    text-indent: 30px; }
  .newsletter-3 .nl-submit {
    height: 48px;
    margin: 15px 0 0 0;
    padding: 0;
    width: 100%; }
  .newsletter-3.light .nl-inner {
    border: 0 none;
    border-radius: 0;
    padding: 0; }
  .newsletter-3.light .nl-input {
    border: 1px solid rgba(255, 255, 255, 0.7); }
  .newsletter-3.dark-border .nl-inner {
    border: 0 none;
    border-radius: 0;
    padding: 0; }
  .newsletter-3.dark-border .nl-input {
    border: 1px solid rgba(0, 0, 0, 0.7); }

  .section-newsletter {
    padding: 50px 0; }
    .section-newsletter .nl-logo {
      display: block;
      left: auto;
      position: static;
      text-align: center;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none; }
      .section-newsletter .nl-logo img {
        max-width: 150px; }

  /*==============================================
      MISC
  ===============================================*/
  .bottom-full-xs {
    padding-bottom: 150px; }

  .contact-block.style-2 {
    padding: 39px; }

  .sub-title {
    margin-bottom: 30px; }
    .sub-title h2 {
      font-size: 22px; }

  .section-full {
    padding: 90px 0; }

  .top-half .mac,
  .top-full .mac {
    margin-top: -80px; }

  .parallax-block-1 {
    padding: 30px; }
    .parallax-block-1 h2 {
      font-size: 28px;
      line-height: 43px; }

  .subscribe-block-1 {
    position: relative; }
    .subscribe-block-1 .container {
      padding-left: 0;
      padding-right: 0; }
    .subscribe-block-1 .subscribe-inner {
      padding: 24px 15px 30px; }
      .subscribe-block-1 .subscribe-inner .subscribe label {
        min-width: 50px;
        padding: 0; }
      .subscribe-block-1 .subscribe-inner .subscribe .mail-sub {
        padding: 0;
        width: 130px; }

  .owl-controls-custom {
    display: none; }

  .our-story-1 .left {
    font-size: 20px;
    line-height: 35px;
    margin-bottom: 30px; }
  .our-story-1 .right {
    text-align: left; }

  .misc-slider-2 {
    bottom: 0;
    margin: 30px 0 0;
    padding: 0 15px;
    position: relative;
    width: 100%; }
    .misc-slider-2 .item {
      height: 155px; }

  .mag-slide-content p {
    margin-bottom: 5px; }

  /*---------------- Accordion ------------*/
  .collapsible-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }
    .collapsible-header i {
      margin-right: 14px; }

  /*---------------- indent process ------------*/
  .dish-carousel .dish-img {
    margin-top: 30px; }

  .tab-2 .tab-menu-list li {
    line-height: 30px;
    padding: 30px; }

  .map-control {
    padding-bottom: 50px;
    padding-top: 10px; }
    .map-control #map-half {
      height: 400px;
      margin-top: 30px;
      position: static;
      width: 100%; }

  .feature-1 .hover {
    display: none; }

  .feature-3-devider {
    display: none; }

  .feature-3 {
    padding: 0 15px 30px; }
    .feature-3:last-child {
      padding-bottom: 0; }

  .text-block-1.mt-60 {
    margin: 0 0 50px; }

  .set-menu-1 .dish-images .col-sm-8 img {
    margin-bottom: 30px; }

  .event-carousel-1 .text h2 {
    font-size: 22px; }
  .event-carousel-1 .text .btn-large {
    font-size: 13px;
    height: 45px;
    line-height: 45px;
    padding: 0 40px; }

  .iphone.text-center img {
    display: block;
    margin: 50px auto 0;
    max-width: 100%; }

  .imac-lg-left {
    margin: 0 0 40px; }
    .imac-lg-left img {
      max-width: 100%; }

  .hand-phone img {
    margin: 40px 0 0;
    max-width: 100%; }

  .misc-slider-3 {
    margin-bottom: 80px;
    padding: 50px 30px 80px; }
    .misc-slider-3 .item h2 {
      font-size: 20px;
      line-height: 32px; }
    .misc-slider-3 .bottom-badge {
      border-width: 0 145px 39px; }

  .process-2 {
    margin-bottom: 60px; }

  .parallax-block-2 {
    padding: 100px 30px; }

  .feature-6 .icon {
    font-size: 26px;
    float: none;
    height: 80px;
    line-height: 80px;
    margin: 0 auto;
    width: 80px; }
    .feature-6 .icon::after {
      content: none; }
  .feature-6 .text {
    float: none;
    margin: 20px 0 0;
    text-align: center;
    width: 100%; }
    .feature-6 .text h3 {
      font-size: 24px; }

  .bullet-vertical.horiz-xs {
    padding: 0; }
    .bullet-vertical.horiz-xs .slick-dots {
      position: static;
      text-align: center; }
      .bullet-vertical.horiz-xs .slick-dots li {
        display: inline-block;
        margin: 0 3px; }
        .bullet-vertical.horiz-xs .slick-dots li button {
          margin: 2px 0 0; }
    .bullet-vertical.horiz-xs .owl-dots {
      top: auto;
      bottom: 0;
      left: 0;
      text-align: center; }
      .bullet-vertical.horiz-xs .owl-dots .owl-dot {
        display: inline-block;
        margin: 0 5px; }

  .fc-item {
    text-align: center; }
    .fc-item .icon {
      float: none;
      margin: 0 auto; }
      .fc-item .icon::after {
        content: none; }
    .fc-item .text {
      float: none;
      width: auto; }
      .fc-item .text h3 {
        font-size: 20px; }

  .footer-widget-3 .social {
    margin-right: 20px; }

  .footer-nav-3 {
    text-align: left; }
    .footer-nav-3 > li:first-child a {
      padding-left: 10px; }
    .footer-nav-3 > li > a {
      font-size: 12px;
      padding: 5px 10px; }

  .primary-footer [class^="col-"]:not(:last-child) {
    margin-bottom: 30px; }

  .secondery-footer .social-1 {
    margin-top: 30px;
    text-align: left; }

  .process-3 .icon {
    font-size: 40px;
    height: 180px;
    line-height: 190px;
    margin: 0 auto 20px;
    width: 180px; }
    .process-3 .icon::after {
      content: none; }

  .schedule-top > li,
  .schedule-bottom li {
    line-height: inherit;
    padding: 10px 0; }

  .process-4 li .process-label {
    font-size: 12px; }

  .the-blog-meh .shade-text {
    font-size: 50px; }
    .the-blog-meh .shade-text.top {
      left: 0;
      top: -25px; }
    .the-blog-meh .shade-text.bottom {
      bottom: 5px;
      font-size: 40px;
      right: 0; }

  .widget-post-slider .owl-dots {
    top: 36%; }

  .featured-item.flex {
    display: block; }

  .clients-3col [class^="col-"],
  .clients-4col [class^="col-"] {
    min-height: 150px; }
    .clients-3col [class^="col-"]:nth-child(3), .clients-3col [class^="col-"]:nth-child(6),
    .clients-4col [class^="col-"]:nth-child(3),
    .clients-4col [class^="col-"]:nth-child(6) {
      border-right: 1px solid rgba(0, 0, 0, 0.1); }
    .clients-3col [class^="col-"]:nth-last-child(3),
    .clients-4col [class^="col-"]:nth-last-child(3) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .clients-3col [class^="col-"]:nth-child(2n),
    .clients-4col [class^="col-"]:nth-child(2n) {
      border-right: 0; }

  .clients-4col [class^="col-"]:nth-child(3) {
    border-right: 1px solid rgba(0, 0, 0, 0.1); }
  .clients-4col [class^="col-"]:nth-last-child(3), .clients-4col [class^="col-"]:nth-last-child(4) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .clients-4col.light [class^="col-"]:nth-child(3) {
    border-right: 1px solid rgba(255, 255, 255, 0.1); }
  .clients-4col.light [class^="col-"]:nth-last-child(3), .clients-4col.light [class^="col-"]:nth-last-child(4) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }

  .switch-group .bootstrap-switch:first-child {
    margin-left: 8px;
    margin-top: 15px; }
  .switch-group .bootstrap-switch + .bootstrap-switch {
    margin: 15px 0 0 8px; }

  .hentry-footer .social-2 {
    margin-top: 30px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start; }

  .author-card {
    padding: 20px; }

  .comment-body {
    padding: 30px; }
    .comment-body .author-vcard {
      margin: 0 0 15px; }

  .cart-subtotal {
    padding-left: 0;
    padding-right: 0; }
    .cart-subtotal hr {
      margin-left: 0;
      margin-right: 0; }

  .cart-actions {
    margin-top: 40px;
    text-align: center; }
    .cart-actions .ml-20 {
      margin-left: 0;
      margin-top: 20px; }

  .shop-order-progress li a {
    padding: 0 8px 15px; }

  .payment-type li {
    margin: 0 0 20px; }
    .payment-type li:last-child {
      margin-bottom: 0; }

  .page-login-wrapper.style-3 {
    margin: 50px 0;
    padding: 50px 30px; }

  .cat-parallax-bg .cat-box {
    max-width: 300px; }

  .maintanance-page h1 {
    font-size: 38px;
    line-height: 1.2; }
  .maintanance-page p {
    font-size: 14px; }

  .comingsoon-page h1 {
    font-size: 40px;
    line-height: 1.3; }
  .comingsoon-page .newsletter-3 {
    box-shadow: none; }

  .main-example .time {
    font-size: 30px;
    height: 65px;
    line-height: 65px;
    width: 54px; }
  .main-example .label {
    bottom: -30px;
    font-size: 16px; }

  .hentry-timeline {
    margin-left: -0px; }
    .hentry-timeline .date-time {
      background-color: transparent;
      border: 0 none;
      border-radius: 0;
      display: block;
      height: auto;
      left: 0;
      margin-bottom: 10px;
      position: static;
      text-align: left;
      top: auto;
      width: auto; }
      .hentry-timeline .date-time::after {
        content: none; }
    .hentry-timeline .datetime-holder {
      left: auto;
      position: static;
      top: auto;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none; }
    .hentry-timeline .day {
      font-size: 20px; }
    .hentry-timeline .hentry-header {
      padding: 23px 15px 13px; }
    .hentry-timeline h2 {
      font-size: 18px;
      line-height: 26px; }
    .hentry-timeline .hendtry-content,
    .hentry-timeline .hentry-meta {
      padding-left: 15px;
      padding-right: 15px; }

  .home-default h1 {
    font-size: 24px; }
  .home-default p {
    font-size: 14px;
    margin-bottom: 30px;
    margin-top: 10px; }

  .s-service-carousel-for {
    margin: 0; }
    .s-service-carousel-for .slick-dots {
      text-align: center;
      margin-top: 30px; }
      .s-service-carousel-for .slick-dots li {
        border: 1px solid #ccc;
        border-radius: 15px;
        cursor: pointer;
        display: inline-block;
        height: 15px;
        margin: 0 5px;
        width: 15px; }
        .s-service-carousel-for .slick-dots li button {
          display: none; }
      .s-service-carousel-for .slick-dots .slick-active {
        background-color: #0D52FF;
        border-color: #0D52FF; } }
.video-play-button {
  position: absolute;
  z-index: 10;
  top: 30% !important;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #4CAF50;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(#fa183d, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}