.container {
}

.wrapper {
  position: relative;
}

.wrapper p {
  font-weight: 600;
  padding: 5px;
  background: #e6e7e9 !important;
  border-bottom: 2px solid #78a9a9;
  margin-bottom: 5px;
}

.selectWrapper {
  padding: 5px;
  border: 1px solid #999;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 5px;
}
.selectWrapper:focus {
  outline: 2px dashed #000 !important;
  outline-offset: 3px !important;
}
.select {
  display: flex;
  align-items: center;
}

.dropdownWrapper {
  opacity: 0;
  max-height: 0;
  transition: max-height 50ms ease-in;
  overflow: hidden;
  position: absolute;
  border: 1px solid #999;
  border-radius: 2px;

  border-top: none;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  margin-top: -5px;
}

.dropdownWrapper.expanded {
  z-index: 1;
  opacity: 1;
  max-height: 300px;
}

.optionWrapper {
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
}

.image {
}

.optionWrapper.active {
  background-color: #e1eeff;
}

.optionWrapper:hover {
  background-color: #f5f5f5;
}

.validationMessage {
  color: #cc0000;
  width: 100%;
}
div.inputValidationFailed {
  border: 1px solid #cc0000 !important;
  box-shadow: 0 0 2px #cc0000;
}
